import React from 'react'
import { Box, Slide } from '@mui/material'
import { LayoutContants } from '@constants/layout.constants'

interface SlideInPanelProps {
  value: boolean
}

const SlideInPanel: React.FC<SlideInPanelProps> = ({ value, children }) => {
  return (
    <Slide direction="left" in={value} mountOnEnter unmountOnExit>
      <Box
        sx={{
          background: '#fff',
          bottom: `${LayoutContants.appbarHeight * 2}px`,
          top: 0,
          width: '100%',
          zIndex: 100,
          position: 'absolute',
        }}
      >
        {children}
      </Box>
    </Slide>
  )
}

export default SlideInPanel
