import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { use100vh } from 'react-div-100vh'

const AuthLayout: React.FC<ReactNode> = ({ children }) => {
  const height = use100vh()
  return (
    <Box
      sx={{
        height: height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        '&:before': {
          content: "''",
          position: 'fixed',
          left: '-22%',
          top: '60%',
          right: 0,
          zIndex: -1,
          width: '125%',
          height: ' 100%',
          background: 'linear-gradient(135deg, #fefefe 0%, #f2f2f2 100%)',
          transform: 'rotate(15deg)',
        },
      }}
    >
      {children}
    </Box>
  )
}

export default AuthLayout
