import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import { ThemeColors } from '@theme/colors'

import _ from 'lodash'
import { UserLoginResponse } from '@services/auth.services'
import { ExitToApp, Public } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export interface AdminHeaderProps {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
  handleLogOut: () => void
  userData: UserLoginResponse
}

const AdminHeader: React.FC<AdminHeaderProps> = ({
  open,
  setOpen,
  handleLogOut,
  title,
  userData,
}) => {
  return (
    <Box
      sx={{
        transition: 'all 0.24s ease-in-out',
        background: '#fff',
        flexGrow: 1,
        '& .MuiAppBar-root': {
          zIndex: 900,
          background: ThemeColors.white,
          paddingLeft: 0,
          borderBottom: '1px solid #eee',
          '@media screen and (min-width:600px) and (max-width:900px) ': {
            '& .MuiAppBar-root': {
              paddingLeft: '70px !important',
            },
          },
        },
        '& .MuiToolbar-regular': {
          minHeight: 50,
        },
      }}
    >
      <AppBar elevation={0} position="relative" color="default">
        <Toolbar>
          <IconButton
            sx={{ border: '0 none', marginRight: 2 }}
            disableRipple
            onClick={() => setOpen(!open)}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ fontWeight: 600, flexGrow: 1 }} noWrap={true}>
            {title}
          </Typography>

          <>
            <IconButton
              sx={{ border: '0 none' }}
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
            >
              <Link style={{ color: 'inherit' }} to="/" target="_blank">
                <Public />
              </Link>
            </IconButton>
            <Box
              display="flex"
              flexDirection="row"
              sx={{ pl: 2, pr: 2 }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                {_.get(userData, 'name', '')}
              </Typography>
              {/* <Typography
                component="span"
                variant="body2"
                data-testid="username"
                sx={{ color: ThemeColors.primary, fontWeight: 600, ml: 1 }}
              >
                {_.get(userData, 'name', '')}
              </Typography> */}
            </Box>
            <IconButton
              sx={{ border: '0 none' }}
              onClick={handleLogOut}
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
            >
              <ExitToApp />
            </IconButton>
          </>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default AdminHeader
