import { SEX } from '@constants/common.constants'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface FilterFormProps {
  sex: number
  onFilter: (data: number) => void
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const { onFilter, sex } = props
  const { t } = useTranslation()
  const filterData = [
    { label: t('genre.filter_all'), value: 0 },
    { label: t('genre.filter_unisex'), value: SEX.UNISEX },
    { label: t('genre.filter_ladies'), value: SEX.FEMALE },
  ]
  return (
    <Grid container sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
      <Grid item>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {filterData.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={
                  <Radio
                    checked={sex === item.value}
                    onChange={() => {
                      onFilter(item.value)
                    }}
                  />
                }
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default FilterForm
