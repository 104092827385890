/* eslint-disable no-console */
import * as React from 'react'
import { MarkingItem, PartItem, PositionItem } from '@services/item.services'
import { Box, Button, ButtonGroup, Paper, Stack } from '@mui/material'
import _ from 'lodash'
import { Side } from '@constants/editor.constants'
import PaperHeader from '@components/PaperHeader'
import { MARKING_TYPE } from '@constants/common.constants'
import { useState } from 'react'
import { ThemeColors } from '@theme/colors'
import { FontItem } from '@services/fonts.services'
import i18n from '@locales/i18n'
import { Rect, useRect } from 'react-use-rect'

interface ChipArrayProps {
  data: PartItem[]
  side: Side
  marking?: MarkingItem[]
  fonts?: FontItem[]
  activePosition?: Omit<PositionItem, 'id' | 'side'>
  positionMode?: boolean
  onPositionSelect?: (x: number, y: number) => void
  activeColorMarking?: string | null
  mode?: string
}

const PartPreview: React.FC<ChipArrayProps> = ({
  data,
  side,
  marking,
  mode = 'create',
  fonts,
  activePosition,
  onPositionSelect,
  activeColorMarking,
  positionMode = false,
}) => {
  const [markingMode, setMarkingMode] = useState<'position' | 'content'>(
    'position'
  )

  const [mousePos, setMousePos] = useState<any>({})

  const [rect, setRect] = useState<Rect | null>(null)
  const [rectRef] = useRect(setRect)

  React.useEffect(() => {
    const handleMouseMove = (event: any) => {
      if (rect) {
        setMousePos({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        })
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [rect])

  const isMouseInside =
    mousePos &&
    rect &&
    mousePos.x >= 0 &&
    mousePos.y >= 0 &&
    mousePos.x < rect.width &&
    mousePos.y < rect.height

  const renderSVG = () => {
    return _.map(data, (part, i) => {
      const { attributes } = part
      const graph =
        side === Side.Front ? attributes.front_svg : attributes.back_svg

      if (graph) {
        return (
          <g key={i}>
            {attributes.colors?.fill && attributes.is_editable ? (
              <style>{`g#path-${part.id} path {
                fill:${attributes.colors?.fill} !important;
           }`}</style>
            ) : null}
            <Box
              component="g"
              style={{
                overflow: 'hidden',
              }}
              id={`path-${part.id}`}
              dangerouslySetInnerHTML={{
                __html: graph,
              }}
            ></Box>
          </g>
        )
      } else {
        return null
      }
    })
  }

  const renderMarkings = () => {
    if (!_.isEmpty(marking)) {
      return _.map(marking, (p) => {
        return renderMarkingItem(p)
      })
    }
    return null
  }

  const renderActiveMarkings = () => {
    if (activePosition && activePosition.x > 0 && activePosition.y > 0) {
      return (
        <Box
          component="g"
          sx={{
            '& circle': {
              opacity: 0.5,
              cursor: 'pointer',
            },
          }}
          id={`active-marking`}
        >
          <circle
            cx={activePosition.x}
            cy={activePosition.y}
            r="10"
            fill="red"
          />
        </Box>
      )
    }
    return null
  }

  const renderFontImport = () => {
    if (marking) {
      return (
        <style>
          {_.map(marking, (m) => {
            const family = _.get(m, 'attributes.settings.family', '')
            const currentFont = _.find(fonts, (f) => f.id === family)

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const url = _.get(currentFont, 'attributes.style_path', undefined)
            if (url) {
              return `@import url(${url});`
            }
            return ''
          })}
        </style>
      )
    }
    return ''
  }

  const renderMarkingItem = (item: MarkingItem) => {
    return _.map(
      _.get(item, 'attributes.settings.positions.values', []),
      (p, i) => {
        if (
          _.get(item, 'attributes.settings.positions.side', undefined) ===
            side &&
          p.isActive
        ) {
          if (markingMode === 'position') {
            return (
              <Box
                key={i}
                component="g"
                sx={{
                  '& circle': {
                    opacity: 0.5,
                    cursor: 'pointer',
                  },
                }}
                id={`shape-${i}`}
              >
                <circle cx={p.x} cy={p.y} r="10" fill="yellow" />
              </Box>
            )
          } else if (
            markingMode === 'content' &&
            _.get(item, 'attributes.marking_type') !== MARKING_TYPE.LOGO
          ) {
            const firstFamily = _.get(
              item,
              'attributes.settings.family',
              undefined
            )
              ? _.get(item, 'attributes.settings.family', '')
              : _.get(item, 'attributes.settings.fonts', [])[0]
            const currentFont = _.find(fonts, (f) => f.id === firstFamily)
            // eslint-disable-next-line no-console
            return (
              <Box key={i} component="g" id={`text-${i}`}>
                <text
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    transform: `translate(${p.x}px, ${p.y}px )`,
                    fontSize: _.get(item, 'attributes.settings.font_size', 0),
                    fill:
                      activeColorMarking !== null && mode == 'edit'
                        ? activeColorMarking
                        : activeColorMarking !== null &&
                          mode == 'create' &&
                          item.id === 'temp'
                        ? activeColorMarking
                        : _.get(item, 'attributes.settings.color.fill', ''),
                    fontFamily: currentFont?.attributes.font_family,
                  }}
                >
                  {_.get(item, 'attributes.settings.sample_text', '')}
                </text>
              </Box>
            )
          } else {
            return (
              <Box
                key={i}
                component="g"
                sx={{
                  '& circle': {
                    opacity: 0.5,
                    cursor: 'pointer',
                  },
                }}
                id={`shape-${i}`}
              >
                <text
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    transform: `translate(${p.x}px, ${p.y}px )`,
                    fontSize: 14,
                    fill: '#000',
                  }}
                >
                  LOGO
                </text>
              </Box>
            )
          }
        }
        return null
      }
    )
  }

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (positionMode && mousePos) {
      onPositionSelect &&
        onPositionSelect(Math.round(mousePos.x), Math.round(mousePos.y))
    }
  }

  return (
    <Paper
      sx={{
        mb: 2,
        background: '#fff',
        position: 'relative',
      }}
    >
      <PaperHeader title={i18n.t('part.preview')} />
      <Box
        ref={rectRef}
        onClick={handleSelect}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: isMouseInside && positionMode ? 'crosshair' : 'inherit',
        }}
      >
        {positionMode && isMouseInside ? (
          <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
            <Stack>
              <Box sx={{ width: 50, fontSize: 11 }}>
                <b>X:</b> {Math.round(mousePos?.x)}
              </Box>
              <Box sx={{ width: 50, fontSize: 11 }}>
                <b>Y:</b> {Math.round(mousePos?.y)}
              </Box>
            </Stack>
          </Box>
        ) : null}
        <div
          id="bottom-preview"
          style={{
            width: '350px',
            height: `450px`,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'relative',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 350 450"
            width="350px"
            height="450px"
            xmlSpace="preserve"
            style={{ filter: ' drop-shadow(rgba(0, 0, 0, 0.2) 1px 3px 7px)' }}
          >
            {renderFontImport()}
            {renderSVG()}
            {renderActiveMarkings()}
            {renderMarkings()}
          </svg>
        </div>
      </Box>
      {marking !== undefined && (
        <ButtonGroup sx={{ position: 'absolute', bottom: 5, right: 5 }}>
          <Button
            onClick={() => setMarkingMode('position')}
            disableRipple
            className={markingMode === 'position' ? 'is-active' : ''}
            variant="outlined"
            size="small"
            sx={{
              color: '#111',
              borderColor: '#999',
              '&:hover': {
                borderColor: '#aaa',
              },
              '&.is-active': {
                color: ThemeColors.secondary,
              },
            }}
          >
            {i18n.t('preview_content.position')}
          </Button>
          <Button
            onClick={() => setMarkingMode('content')}
            disableRipple
            size="small"
            className={markingMode === 'content' ? 'is-active' : ''}
            sx={{
              color: '#111',
              borderColor: '#999',
              '&.is-active': {
                color: ThemeColors.secondary,
              },
              '&:hover': {
                borderColor: '#aaa',
              },
            }}
            variant="outlined"
          >
            {i18n.t('preview_content.content')}
          </Button>
        </ButtonGroup>
      )}
    </Paper>
  )
}

export default PartPreview
