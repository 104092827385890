import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/admins.services'
import { ADMINS_ACTION_TYPE } from './types'

export const adminList = createAsyncThunk<
  services.AdminsResponse,
  services.AdminsParams
>(ADMINS_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.list(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const adminCreate = createAsyncThunk<
  services.AdminDetail,
  services.AdminCreateParams
>(ADMINS_ACTION_TYPE.CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const adminUpdate = createAsyncThunk<
  services.AdminDetail,
  services.AdminUpdateParams
>(ADMINS_ACTION_TYPE.UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.update(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const adminDetail = createAsyncThunk<services.AdminDetail, string>(
  ADMINS_ACTION_TYPE.DETAIL,
  async (id, { rejectWithValue }) => {
    try {
      const res = await services.detail(id)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adminDelete = createAsyncThunk<
  services.AdminsResponse,
  services.AdminDeleteParams
>(ADMINS_ACTION_TYPE.DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteAdmin(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
