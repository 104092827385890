import * as React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import {
  FormControl,
  InputBase,
  FormControlProps,
  SxProps,
  FormHelperText,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import { ReactElement } from 'react'
import ClassicInputLabel from '../ClassInputLabel'

export type Option = {
  id: number
  name: string
}

interface ClassicSelectProps extends SelectProps {
  error?: boolean
  formProps?: FormControlProps
  value: string | number
  label?: string
  disabled?: boolean
  helperText?: string
  required?: boolean
  labelStyle?: SxProps
  labelSecondary?: ReactElement
}

const ClassicSelect: React.FC<ClassicSelectProps> = ({
  error,
  value,
  label,
  formProps,
  disabled = false,
  helperText,
  required,
  labelStyle,
  labelSecondary,
  ...rest
}) => {
  return (
    <FormControl sx={{ width: '100%' }} {...formProps}>
      {(label || labelSecondary) && (
        <ClassicInputLabel
          required={required}
          label={label}
          id={rest.id ? rest.id : ''}
          labelStyle={labelStyle}
        />
      )}
      <Select
        value={value}
        sx={{
          height: 36,
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
        SelectDisplayProps={{
          style: {
            padding: '3px 8px 5px',
            paddingRight: '32px',
          },
        }}
        MenuProps={{
          PaperProps: {
            elevation: 1,
          },
        }}
        disabled={disabled}
        {...rest}
        input={
          <InputBase
            sx={{
              '&.MuiInputBase-root': {
                border: error ? `1px solid ${ThemeColors.yellow}` : '',
              },
            }}
            margin="dense"
          />
        }
      />
      {helperText && (
        <FormHelperText
          sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ClassicSelect
