import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import useDesignList from '@containers/Designs/useDesignList'
import FilterForm from '@containers/Designs/DesignFilter'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import { ThemeColors } from '@theme/colors'
import {
  Box,
  TableCell,
  TableRow,
  Pagination,
  Stack,
  Button,
} from '@mui/material'
import { Design, DesignFilterParams } from '@services/design.services'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { GENRE_TYPES } from '@constants/common.constants'
import {
  SIMPLE_DATE_TIME_FORMAT,
  SIMPLE_DATE_FORMAT,
} from '@constants/common.constants'
import { currency } from 'utils/helper/common.helpers'
import DesignThumb from '@components/@editor-components/DesignThumb'
import { useConfirm } from '@components/Confirm'

const Designs = () => {
  const { paging, data, fetch, meta, deleteDesign } = useDesignList()
  const [filter, setFilter] = useState<any>({
    start_at: moment().subtract(1, 'months').format(SIMPLE_DATE_FORMAT),
    end_at: moment().format(SIMPLE_DATE_FORMAT),
    item_id: null,
  })

  useEffect(() => {
    fetch({ ...filter })
  }, [filter])

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
      ...filter,
    })
  }
  const confirm = useConfirm()

  const handleFilter = (data: DesignFilterParams) => {
    setFilter(data)
  }

  const getImage = (genre_type: GENRE_TYPES, data: any) => {
    if (
      data.images &&
      data.genre_types &&
      data.genre_types.includes(genre_type)
    ) {
      const genre_types = data.genre_types.split('|')
      const images = data.images.split('|')
      const idx = genre_types.indexOf(genre_type.toString())
      return (
        <DesignThumb
          width={'auto'}
          sx={{
            position: 'relative',
            height: {
              xs: '100%',
              sm: '100%',
            },
            '& svg': {
              position: 'relative',
              height: '100%',
              width: '100%',
              left: 0,
              top: 0,
            },
            paddingTop: { xs: '0', sm: '0' },
            width: '100%',
          }}
          svg={data.aws_cdn + images[idx]}
        />
      )
    }
    return <Box sx={{ width: '100%', textAlign: 'center' }}>-</Box>
  }

  const renderRow = (item: Design, index: number) => {
    const data = item.attributes
    return (
      <TableRow
        key={index}
        sx={
          data.is_expired
            ? {
                backgroundColor: ThemeColors.expired,
              }
            : {}
        }
      >
        <TableCell align="left">
          <Box sx={{ pl: 1.5, pr: 1.5 }}>
            {getImage(GENRE_TYPES.TOPS, data)}
          </Box>
        </TableCell>
        <TableCell align="left">
          <Box sx={{ pl: 1.5, pr: 1.5 }}>
            {getImage(GENRE_TYPES.BOTTOMS, data)}
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box sx={{ pl: 1.5, pr: 1.5 }}>
            <a href={'/design/' + data.code} target="_blank" rel="noreferrer">
              {data.code}
            </a>
          </Box>
        </TableCell>
        <TableCell align="right">{currency(data.price)}</TableCell>
        <TableCell align="center">
          {moment(data.created_at).format(SIMPLE_DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell align="left">{data.email}</TableCell>
        <TableCell align="center">{data.created_ip}</TableCell>
        <TableCell align="center">
          {moment(data.expire_at).format(SIMPLE_DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell align="center">
          <Box sx={{ pl: 1, pr: 1 }}>
            {moment(data.last_access).format(SIMPLE_DATE_TIME_FORMAT)}
          </Box>
        </TableCell>
        <TableCell align="center">
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              confirm({
                title: i18n.t('designs.delete_confirm_title'),
                description: i18n.t('designs.delete_confirm_desc'),
              })
                .then(() => {
                  deleteDesign({ id: item.id, filter: filter })
                })
                .catch(() => null)
            }}
          >
            {i18n.t('button.delete')}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={meta.pending}
        pagintionBox={{ sx: { pt: 2 } }}
        pagination={
          paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )
        }
        headSection={
          <TableHeadSection
            title={i18n.t('SYSCOMMON.designs')}
            total={data?.length}
            actions={
              <Stack justifyContent="flex-end" flexDirection={'row'}>
                <FilterForm onFilter={handleFilter} />
              </Stack>
            }
          />
        }
        head={
          <TableRow>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              <Box>{i18n.t('designs.top_image_front')}</Box>
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.bottom_image_front')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.code')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="right"
            >
              {i18n.t('designs.price')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.created_at')}
            </TableCell>
            <TableCell
              sx={{
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="left"
            >
              {i18n.t('SYSCOMMON.email')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.created_ip')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.expire_at')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '160px',
                width: '160px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('designs.last_access')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '160px',
                width: '160px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            ></TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default Designs
