import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'
import {
  Design,
  DesignDeleteParams,
  DesignFilterParams,
} from '@services/design.services'
import { designDelete, designList } from '@store/design/actions'
import { list, pagemeta } from '@store/design/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  paging: MetaPage
  meta: Meta
  data?: Design[]
  fetch: (params: DesignFilterParams) => void
  deleteDesign: (params: DesignDeleteParams) => void
}

const useDesignList = (): ReturnType => {
  const paging = useAppSelector(pagemeta)
  const data = useAppSelector(list)
  const dispatch = useDispatch()
  const fetch = (params: DesignFilterParams) => {
    dispatch(designList(params))
  }
  const deleteDesign = (params: DesignDeleteParams) => {
    dispatch(designDelete(params))
  }
  const _meta = createMetaSelector(designList)
  const meta = useAppSelector(_meta)

  return {
    paging,
    meta,
    fetch,
    data,
    deleteDesign,
  }
}

export default useDesignList
