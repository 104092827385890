import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box, Divider, Paper, Typography } from '@mui/material'
import i18n from '@locales/i18n'
import { Item } from '@services/item.services'
import { useEffect } from 'react'
import { useConfirm } from '@components/Confirm'
import { useNavigate } from 'react-router-dom'

export enum TAB_STEPS {
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
}

export default function StepTitle({
  step,
  data,
  isDirty = false,
}: {
  id: string
  step: number
  data: Item
  isDirty?: boolean
}) {
  const currentStep = data?.attributes.current_step

  const navigate = useNavigate()

  const confirm = useConfirm()

  useEffect(() => {
    if (data) {
      if (step > currentStep) {
        navigate(`/admin/items/${data.id}/step/${currentStep}`)
      }
    }
  }, [data])

  const handleNavigate = (value: number) => {
    if (step > value || currentStep >= value) {
      if (isDirty) {
        confirm({
          title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
          description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
        })
          .then(() => {
            navigate(`/admin/items`)
          })
          .catch(() => null)
      } else {
        navigate(`/admin/items/${data.id}/step/${value}`)
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          mt: -1,
        }}
      >
        {data && (
          <Box
            sx={{
              position: { xs: 'relative', md: 'absolute', display: 'block' },
              left: 0,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                background: '#000',
                color: '#fff',
                pl: 1,
                pr: 1,
                mb: 1,
                pb: 0.3,
                pt: 0.3,
                display: 'inline-block',
              }}
            >
              {i18n.t('SYSCOMMON.item_name')}
            </Typography>
            <Typography variant="h4" sx={{ margin: 0 }}>
              {data.attributes.name}
              <Typography variant="body2" sx={{ pl: 1 }} component="span">
                ({data.attributes.code})
              </Typography>
            </Typography>
          </Box>
        )}
        <Paper
          sx={{
            display: { xs: 'flex', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Tabs value={step}>
            <Tab
              sx={{
                cursor:
                  currentStep > TAB_STEPS.STEP_ONE || step > TAB_STEPS.STEP_ONE
                    ? 'pointer'
                    : 'inherit',
                opacity:
                  currentStep >= TAB_STEPS.STEP_ONE ||
                  step >= TAB_STEPS.STEP_THREE
                    ? '1'
                    : '0.5',
              }}
              label={`${i18n.t('SYSCOMMON.step1')}`}
              onClick={() => handleNavigate(TAB_STEPS.STEP_ONE)}
              value={TAB_STEPS.STEP_ONE}
            ></Tab>
            <Tab
              sx={{
                cursor:
                  currentStep > TAB_STEPS.STEP_TWO || step > TAB_STEPS.STEP_TWO
                    ? 'pointer'
                    : 'inherit',
                opacity:
                  currentStep >= TAB_STEPS.STEP_TWO ||
                  step >= TAB_STEPS.STEP_THREE
                    ? '1'
                    : '0.5',
              }}
              label={`${i18n.t('SYSCOMMON.step2')}`}
              onClick={() => handleNavigate(TAB_STEPS.STEP_TWO)}
              value={TAB_STEPS.STEP_TWO}
            ></Tab>
            <Tab
              sx={{
                cursor:
                  currentStep >= TAB_STEPS.STEP_THREE ||
                  step > TAB_STEPS.STEP_THREE
                    ? 'pointer'
                    : 'inherit',
                opacity:
                  currentStep >= TAB_STEPS.STEP_THREE ||
                  step >= TAB_STEPS.STEP_THREE
                    ? '1'
                    : '0.5',
              }}
              label={`${i18n.t('SYSCOMMON.step3')}`}
              onClick={() => handleNavigate(TAB_STEPS.STEP_THREE)}
              value={TAB_STEPS.STEP_THREE}
            ></Tab>
          </Tabs>
        </Paper>
      </Box>
      <Divider sx={{ mb: 4 }} />
    </>
  )
}
