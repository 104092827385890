/**
 * App Colors:
 * This contains all the color config for the application
 * /* Array 
["0f0a0a","f5efed","2292a4","0abf28","d96c06"]
 */

import { grey } from '@mui/material/colors'

export const ThemeColors = {
  primary: '#222222',
  secondary: '#D40505',
  background: '#0f0a0a',
  light: '#f5efed',
  dark: '#393e41',
  info: '#2292a4',
  grey: {
    '10': '#060606',
    '100': '#212121',
    '200': '#4D4D4D',
    '300': '#ADABAB',
    '400': '#707070',
  },
  black: '#000000',
  white: '#ffffff',
  yellow: '#bdbf09',
  green: '#0ABF28',
  gray: '#B8B8B8',
  text: {
    '100': grey[100],
    '200': grey[200],
    '300': grey[300],
    '400': grey[400],
    '500': grey[500],
    '600': grey[600],
    '700': grey[700],
    '800': grey[800],
    '900': grey[900],
  },
  avatar: [
    '#8E47FF',
    '#476AFF',
    '#2CEBB0',
    '#DCEB2C',
    '#FF9E1A',
    '#FF6A1C',
    '#502298',
    '#2744BF',
    '#7D8805',
    '#981643',
    '#FF4786',
  ],
  expired: '#EBEDEF',
}
