import * as React from 'react'
import { Box, CircularProgress, Fade, FadeProps, SxProps } from '@mui/material'

interface SpinnerProps {
  sx?: SxProps
  fadeProps?: FadeProps
  loading: boolean
  size?: number
}

const Spinner: React.FC<SpinnerProps> = ({
  sx,
  fadeProps,
  loading,
  size = 20,
}) => {
  return (
    <Box sx={{ height: size, width: size, ...sx }}>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
        {...fadeProps}
      >
        <CircularProgress size={size} />
      </Fade>
    </Box>
  )
}

export default Spinner
