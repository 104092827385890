/* eslint-disable no-console */
import { Autocomplete, TextField, Box } from '@mui/material'
import { FontItem } from '@services/fonts.services'
import * as React from 'react'
import _ from 'lodash'
import i18n from '@locales/i18n'

interface FontEditorProps {
  fonts?: FontItem[]
  onChange: (value: string[]) => void
  value?: string[]
  disabled?: boolean
}

const FontEditor: React.FC<FontEditorProps> = ({
  fonts,
  value,
  onChange,
  disabled = false,
}) => {
  const defaultValue =
    fonts && value ? _.chain(fonts).keyBy('id').at(value).value() : []

  const getOptions = () => {
    return fonts
      ? disabled
        ? fonts
        : fonts.filter((obj) => obj.attributes && obj.attributes?.status == 1)
      : []
  }

  const handleChange = (event: any, value: FontItem[]) => {
    const ids = _.map(value, (v) => v.id)
    onChange(ids)
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <Autocomplete
        multiple
        ChipProps={{
          size: 'small',
          color: 'info',
          sx: {
            fontSize: 11,
            '& .MuiChip-label': {
              maxWidth: 100,
            },
          },
        }}
        sx={{
          '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
            p: '2px',
            background: '#fff',
            zIndex: 1000,
          },
          '& .MuiAutocomplete-popupIndicator': {
            border: '0 none',
          },
        }}
        id="tags-standard"
        options={getOptions()}
        value={defaultValue}
        readOnly={disabled}
        disablePortal
        limitTags={3}
        onChange={handleChange}
        getOptionLabel={(option: FontItem) => {
          if (option) {
            return option.attributes.name
          } else {
            return i18n.t('fonts.not_found')
          }
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            placeholder={i18n.t('marking.selectable_font')}
            {...params}
          />
        )}
      />
    </Box>
  )
}

export default FontEditor
