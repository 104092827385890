import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import i18n from '@locales/i18n'
export const initialValues = {
  design_expire_month: '',
  order_expire_month: '',
}

const useFormSetting = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        design_expire_month: Yup.string()
          .required(
            i18n.t('ERROR.E000001', {
              field: i18n.t('settings.design_expire_month'),
            })
          )
          .max(
            3,
            i18n.t('ERROR.E000002', {
              field: i18n.t('settings.design_expire_month'),
              character: 3,
            })
          ),
        order_expire_month: Yup.string()
          .required(
            i18n.t('ERROR.E000001', {
              field: i18n.t('settings.order_expire_month'),
            })
          )
          .max(
            3,
            i18n.t('ERROR.E000002', {
              field: i18n.t('settings.order_expire_month'),
              character: 3,
            })
          ),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useFormSetting
