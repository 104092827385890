import DesignThumb from '@components/@editor-components/DesignThumb'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import TableLayout from '@components/@material-extend/TableLayout'
import { Side } from '@constants/editor.constants'
import i18n from '@locales/i18n'
import {
  Box,
  Paper,
  Grid,
  FormControl,
  Button,
  Typography,
  ButtonGroup,
  TableCell,
  TableRow,
  MenuItem,
  ListItemText,
  Stack,
  Alert,
  useMediaQuery,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ClassicSelect from '@components/@material-extend/ClassicSelect'
import _ from 'lodash'
import { use100vh } from 'react-div-100vh'
import { LayoutContants } from '@constants/layout.constants'
import useDesign from '@containers/EditorContainer/useDesign'
import useOrder from './useOrder'
import { LoadingButton } from '@mui/lab'
import useShareForm from '@containers/DesignConfirm/useShareForm'
import { FieldValues } from 'react-hook-form'
import useDesignForm from '@containers/DesignConfirm/useDesignForm'
import PaperHeaderReverse from '@components/PaperHeader/PaperHeaderReverse'
import { ArrowBack } from '@mui/icons-material'
import customHistory from 'customHistory'

const OrderContainer = () => {
  const [side, onChangeSide] = useState<Side>(Side.Front)
  const [compare, onChangeCompare] = useState<string>('')

  const { data, toast, share, metaShare } = useDesign()

  const { showOrder, order } = useOrder()

  const { id } = useParams()
  const isMobile = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    if (id) {
      showOrder({ code: id })
    }
  }, [id])

  const handleCompare = () => {
    if (!_.isEmpty(compare)) {
      customHistory.push(`/compare?a=${id}&b=${compare}`)
    }
  }

  const top = _.find(
    data?.attributes.design_items,
    (item) => item.attributes.genre_type === 1
  )
  const bottom = _.find(
    data?.attributes.design_items,
    (item) => item.attributes.genre_type === 2
  )

  const topSvg =
    side === Side.Front ? top?.attributes.front_url : top?.attributes.back_url
  const bottomSvg =
    side === Side.Front
      ? bottom?.attributes.front_url
      : bottom?.attributes.back_url

  const height = use100vh()

  const fillHeight = height ? height - LayoutContants.appbarHeight - 96 : '100%'
  const tableHeight = height
    ? height - LayoutContants.appbarHeight - 380
    : '300px'

  const { Controller: ControllerEmail, methods: methodsEmail } = useShareForm()

  const topActiveMarking = top
    ? _.filter(
        top.attributes.design_markings,
        (m) => m.attributes.is_common === false
      )
    : []

  const bottomActiveMarking = bottom
    ? _.filter(
        bottom.attributes.design_markings,
        (m) => m.attributes.is_common === false
      )
    : []

  const {
    control: controlEmail,
    handleSubmit: handleSubmitEmail,
    formState: {
      errors: errorsEmail,
      isValid: isValidEmail,
      isDirty: isDirtyEmail,
    },
  } = methodsEmail

  const { fields, errors, Controller, control, reset } = useDesignForm(
    top,
    bottom
  )

  const isEmptyAllFields =
    _.isEmpty(top?.attributes.size) &&
    _.isEmpty(bottom?.attributes.size) &&
    top &&
    bottom

  useEffect(() => {
    if (order) {
      const transform = _.map(order.attributes.details, (o) => {
        return {
          ..._.omit(o.attributes, 'set_order'),
        }
      })
      reset({ orders: transform })
    }
  }, [order])

  const handleChangeMarking = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: any[],
    id: string,
    callback: (value: any) => void
  ) => {
    const filterMarking = _.filter(value, (v) => v.marking_id !== id)
    const data = [
      ...filterMarking,
      {
        marking_id: id,
        value: event.target.value,
      },
    ]
    callback(data)
  }

  const renderRowMobile = (_item: any, index: number) => {
    return (
      <Box sx={{ width: '100%', display: 'block', mb: 4 }}>
        <Box
          sx={{
            padding: 1,
            background: '#cecece',
            borderRadius: 2,
            width: '100%',
            textAlign: 'center',
            height: 34,
            mb: 1,
          }}
        >
          {index + 1}
        </Box>
        <Stack
          mb={1}
          sx={{ background: ThemeColors.primary, color: '#fff', p: 1 }}
          direction={'row'}
          justifyContent={'space-between'}
        >
          {top && !_.isEmpty(top.attributes.size) ? (
            <Box
              sx={{
                borderRight: '1px solid #eee',
                width: '100%',
                textAlign: 'center',
              }}
            >
              サイズ ({i18n.t('SYSCOMMON.tops')})
            </Box>
          ) : null}
          {bottom && !_.isEmpty(bottom.attributes.size) ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              サイズ ({i18n.t('SYSCOMMON.bottoms')})
            </Box>
          ) : null}
        </Stack>

        <Stack
          direction={'row'}
          spacing={1}
          mb={2}
          justifyContent={'space-between'}
        >
          {top && !_.isEmpty(top.attributes.size) ? (
            <Box sx={{ width: '100%' }}>
              <Controller
                name={`orders.${index}.tops_size`}
                control={control}
                render={({ field: { value, onChange, ref } }: FieldValues) => (
                  <Box sx={{ width: !isMobile ? 140 : '100%' }}>
                    <ClassicSelect
                      disabled
                      id={`orders.${index}.tops_size`}
                      inputProps={{ sx: { background: '#fff' } }}
                      helperText={
                        errors?.orders?.[index]?.tops_size
                          ? i18n.t('orders.size_required')
                          : ''
                      }
                      value={value}
                      error={errors?.orders?.[index]?.tops_size ? true : false}
                      onChange={onChange}
                      inputRef={ref}
                    >
                      {!_.isEmpty(top.attributes.size) ? (
                        top.attributes.size.map((item, k) => (
                          <MenuItem key={k} value={item}>
                            <ListItemText primary={`${item}サイズ`} />
                          </MenuItem>
                        ))
                      ) : (
                        <Typography>{i18n.t('orders.no_size')}</Typography>
                      )}
                    </ClassicSelect>
                  </Box>
                )}
              />
            </Box>
          ) : null}
          {bottom && !_.isEmpty(bottom.attributes.size) ? (
            <Box sx={{ width: '100%' }}>
              <Controller
                name={`orders.${index}.bottoms_size`}
                control={control}
                render={({ field: { value, onChange, ref } }: FieldValues) => (
                  <Box sx={{ width: !isMobile ? 140 : '100%' }}>
                    <ClassicSelect
                      disabled
                      id={`orders.${index}.bottoms_size`}
                      helperText={
                        errors?.orders?.[index]?.bottoms_size
                          ? i18n.t('orders.size_required')
                          : ''
                      }
                      value={value}
                      error={
                        errors?.orders?.[index]?.bottoms_size ? true : false
                      }
                      inputRef={ref}
                      onChange={onChange}
                    >
                      {!_.isEmpty(bottom.attributes.size) ? (
                        bottom.attributes.size.map((item, k) => (
                          <MenuItem key={k} value={item}>
                            <ListItemText primary={`${item}サイズ`} />
                          </MenuItem>
                        ))
                      ) : (
                        <Typography>{i18n.t('orders.no_size')}</Typography>
                      )}
                    </ClassicSelect>
                  </Box>
                )}
              />
            </Box>
          ) : null}
        </Stack>

        {top
          ? topActiveMarking.map((d) => {
              return (
                <>
                  <Box
                    sx={{
                      background: ThemeColors.primary,
                      color: '#fff',
                      p: 1,
                      mb: 1,
                    }}
                  >
                    {
                      _.find(top.attributes.item_markings, {
                        id: d.attributes.item_marking_id.toString(),
                      })?.attributes.name
                    }
                  </Box>
                  <Box
                    key={d.id}
                    sx={{
                      height: 50,
                      mt: 1,
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  >
                    <Controller
                      name={`orders.${index}.marking_values`}
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                      }: FieldValues) => (
                        <ClassicTextField
                          disabled
                          error={errors?.orders?.[index]?.marking_values}
                          inputRef={ref}
                          fullWidth
                          value={_.find(value, { marking_id: d.id })?.value}
                          onChange={(v) =>
                            handleChangeMarking(v, value, d.id, onChange)
                          }
                        />
                      )}
                    />
                  </Box>
                </>
              )
            })
          : null}

        {bottom
          ? bottomActiveMarking.map((d) => {
              return (
                <>
                  <Box
                    sx={{
                      background: ThemeColors.primary,
                      color: '#fff',
                      p: 1,
                      mb: 1,
                    }}
                  >
                    {
                      _.find(bottom.attributes.item_markings, {
                        id: d.attributes.item_marking_id.toString(),
                      })?.attributes.name
                    }
                  </Box>
                  <Box
                    key={d.id}
                    sx={{
                      height: 50,
                      mt: 1,
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  >
                    <Controller
                      name={`orders.${index}.marking_values`}
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                      }: FieldValues) => (
                        <ClassicTextField
                          disabled
                          inputRef={ref}
                          error={errors?.orders?.[index]?.marking_values}
                          fullWidth
                          value={_.find(value, { marking_id: d.id })?.value}
                          onChange={(v) =>
                            handleChangeMarking(v, value, d.id, onChange)
                          }
                        />
                      )}
                    />
                  </Box>
                </>
              )
            })
          : null}
      </Box>
    )
  }

  const renderRow = (_item: any, index: number) => {
    return (
      <TableRow
        key={index}
        sx={{ background: errors?.orders?.[index] ? '#eee' : '' }}
      >
        <TableCell align="center" width={60}>
          <Box
            sx={{
              padding: 1,
              background: '#eee',
              borderRadius: 3,
              width: 40,
              height: 40,
              margin: '0 auto',
            }}
          >
            {index + 1}
          </Box>
        </TableCell>
        {top && !_.isEmpty(top.attributes.size) ? (
          <TableCell align="center">
            <Box sx={{ height: 50, mt: 1 }}>
              <Controller
                name={`orders.${index}.tops_size`}
                control={control}
                render={({ field: { value, onChange, ref } }: FieldValues) => (
                  <Box sx={{ width: '100%' }}>
                    <ClassicSelect
                      disabled
                      id={`orders.${index}.tops_size`}
                      inputProps={{ sx: { background: '#fff' } }}
                      helperText={
                        errors?.orders?.[index]?.tops_size
                          ? i18n.t('orders.size_required')
                          : ''
                      }
                      value={value}
                      error={errors?.orders?.[index]?.tops_size ? true : false}
                      onChange={onChange}
                      inputRef={ref}
                    >
                      {!_.isEmpty(top.attributes.size) ? (
                        top.attributes.size.map((item, k) => (
                          <MenuItem key={k} value={item}>
                            <ListItemText primary={`${item}サイズ`} />
                          </MenuItem>
                        ))
                      ) : (
                        <Typography>{i18n.t('orders.no_size')}</Typography>
                      )}
                    </ClassicSelect>
                  </Box>
                )}
              />
            </Box>
          </TableCell>
        ) : null}
        {bottom && !_.isEmpty(bottom.attributes.size) ? (
          <TableCell align="center">
            <Box sx={{ height: 50, mt: 1 }}>
              <Controller
                name={`orders.${index}.bottoms_size`}
                control={control}
                render={({ field: { value, onChange, ref } }: FieldValues) => (
                  <Box sx={{ width: '100%' }}>
                    <ClassicSelect
                      disabled
                      id={`orders.${index}.bottoms_size`}
                      helperText={
                        errors?.orders?.[index]?.bottoms_size
                          ? i18n.t('orders.size_required')
                          : ''
                      }
                      value={value}
                      error={
                        errors?.orders?.[index]?.bottoms_size ? true : false
                      }
                      inputRef={ref}
                      onChange={onChange}
                    >
                      {!_.isEmpty(bottom.attributes.size) ? (
                        bottom.attributes.size.map((item, k) => (
                          <MenuItem key={k} value={item}>
                            <ListItemText primary={`${item}サイズ`} />
                          </MenuItem>
                        ))
                      ) : (
                        <Typography>{i18n.t('orders.no_size')}</Typography>
                      )}
                    </ClassicSelect>
                  </Box>
                )}
              />
            </Box>
          </TableCell>
        ) : null}

        {top
          ? topActiveMarking.map((d) => {
              return (
                <TableCell
                  key={d.id}
                  scope="row"
                  align="center"
                  sx={{ position: 'relative' }}
                >
                  <Box sx={{ height: 50, mt: 1, width: '100%' }}>
                    <Controller
                      name={`orders.${index}.marking_values`}
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                      }: FieldValues) => (
                        <ClassicTextField
                          error={errors?.orders?.[index]?.marking_values}
                          inputRef={ref}
                          disabled
                          fullWidth
                          value={_.find(value, { marking_id: d.id })?.value}
                          onChange={(v) =>
                            handleChangeMarking(v, value, d.id, onChange)
                          }
                        />
                      )}
                    />
                  </Box>
                </TableCell>
              )
            })
          : null}

        {bottom
          ? bottomActiveMarking.map((d) => {
              return (
                <TableCell
                  key={d.id}
                  scope="row"
                  align="center"
                  sx={{ position: 'relative' }}
                >
                  <Box
                    sx={{ height: 50, mt: 1, maxWidth: '200px', width: '100%' }}
                  >
                    <Controller
                      name={`orders.${index}.marking_values`}
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                      }: FieldValues) => (
                        <ClassicTextField
                          inputRef={ref}
                          disabled
                          error={errors?.orders?.[index]?.marking_values}
                          fullWidth
                          value={_.find(value, { marking_id: d.id })?.value}
                          onChange={(v) =>
                            handleChangeMarking(v, value, d.id, onChange)
                          }
                        />
                      )}
                    />
                  </Box>
                </TableCell>
              )
            })
          : null}
      </TableRow>
    )
  }

  const onClickDetail = () => null

  const onSubmitEmail = (values: any) => {
    if (id && values.email) {
      share({
        code: id,
        email: values.email,
      })
    }
  }

  return (
    <>
      <Box>
        {data ? (
          <Grid container spacing={2} sx={{ minHeight: fillHeight }}>
            <Grid item md={6} sm={12} xs={12}>
              <Paper sx={{ mt: 2, height: '100%' }}>
                {data.attributes.is_expired ? (
                  <Box mt={1}>
                    <Alert severity="warning">{i18n.t('ERROR.BE000007')}</Alert>
                  </Box>
                ) : null}
                <PaperHeaderReverse
                  title={i18n.t('SYSCOMMON.design_preview')}
                  actions={
                    <>
                      <Button
                        startIcon={<ArrowBack />}
                        disabled={
                          !data.attributes.is_active ||
                          data.attributes.is_expired
                        }
                        sx={{ mt: 0.5 }}
                        variant="contained"
                        size="small"
                      >
                        <Link
                          style={{ textDecoration: 'none', color: 'inherit' }}
                          to={`/editor?copy=${id}`}
                        >
                          {!isMobile
                            ? i18n.t('designs.design_correction')
                            : '修正'}
                        </Link>
                      </Button>
                    </>
                  }
                />
                <Grid container>
                  <Grid xs={12} item>
                    <Box
                      sx={{
                        overflow: 'hidden',
                      }}
                    >
                      <Stack
                        direction={{
                          md: 'row',
                          lg: 'row',
                          xl: 'row',
                          sm: 'column',
                          xs: 'column',
                        }}
                        justifyContent={'space-around'}
                        spacing={1}
                      >
                        {!_.isEmpty(top) && (
                          <Box
                            sx={{
                              p: {
                                xs: 0,
                                sm: 0,
                                md: 2,
                              },
                              width: 340,
                              margin: {
                                sm: '0 auto',
                                xs: '0 auto',
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                border: {
                                  xs: '0 none',
                                  sm: '0 none',
                                  md: '2px solid #eee',
                                },
                              }}
                            >
                              <DesignThumb
                                width={'340'}
                                ratioHeight="80%"
                                sx={{
                                  position: 'relative',
                                  height: {
                                    xs: '100%',
                                    sm: '100%',
                                  },
                                  width: '100%',
                                }}
                                svg={topSvg ? topSvg : ''}
                              />
                            </Box>
                            <Box
                              sx={{
                                textAlign: 'center',
                                mt: 1,
                              }}
                            >
                              <Box onClick={() => onClickDetail()}>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    display: '-webkit-box',
                                    margin: '0 auto',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                    height: 20,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                      transition: 'all 0.3s ease',
                                      color: ThemeColors.secondary,
                                    },
                                  }}
                                  component="div"
                                >
                                  {top?.attributes.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {!_.isEmpty(bottom) && (
                          <Box
                            sx={{
                              p: {
                                xs: 0,
                                sm: 0,
                                md: 2,
                              },
                              width: 340,
                              margin: {
                                sm: '0 auto !important',
                                xs: '0 auto !important',
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                border: {
                                  xs: '0 none',
                                  sm: '0 none',
                                  md: '2px solid #eee',
                                },
                                height: {
                                  md: 'auto',
                                },
                              }}
                            >
                              <DesignThumb
                                ratioHeight="80%"
                                svg={bottomSvg ? bottomSvg : ''}
                                sx={{
                                  position: 'relative',
                                  height: {
                                    xs: '100%',
                                    sm: '100%',
                                  },
                                  width: '100%',
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                textAlign: 'center',
                                mt: 1,
                              }}
                            >
                              <Box onClick={() => onClickDetail()}>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    display: '-webkit-box',
                                    margin: '0 auto',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                    height: 20,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                      transition: 'all 0.3s ease',
                                      color: ThemeColors.secondary,
                                    },
                                  }}
                                  component="div"
                                >
                                  {bottom?.attributes.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Stack>
                    </Box>
                    <Box sx={{ textAlign: 'center', pb: 2, pt: 1 }}>
                      <Box
                        sx={{
                          border: '1px solid #ccc',
                          width: 210,
                          margin: '0 auto',
                        }}
                      >
                        <ButtonGroup sx={{ p: 0.5, background: '#fff' }}>
                          <Button
                            onClick={() => onChangeSide(Side.Front)}
                            disableRipple
                            className={side === Side.Front ? 'is-active' : ''}
                            variant="outlined"
                            sx={{
                              color: '#222',
                              borderColor: '#999',
                              border: '0 none',
                              width: 100,
                              '&:hover': {
                                border: '0 none',
                              },
                              '&.is-active': {
                                color: '#fff',
                                background: ThemeColors.primary,
                              },
                            }}
                          >
                            {i18n.t('SYSCOMMON.front')}
                          </Button>
                          <Button
                            onClick={() => onChangeSide(Side.Back)}
                            disableRipple
                            className={side === Side.Back ? 'is-active' : ''}
                            sx={{
                              color: '#222',
                              border: '0 none',
                              width: 100,
                              '&.is-active': {
                                color: '#fff',
                                background: ThemeColors.primary,
                              },
                              '&:hover': {
                                border: '0 none',
                              },
                            }}
                          >
                            {i18n.t('SYSCOMMON.back')}
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                  </Grid>

                  <Box
                    sx={{
                      p: 2,
                      pl: {
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 4,
                      },
                      pr: {
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 4,
                      },
                    }}
                  >
                    <FormControl sx={{ mb: { sm: 2, xs: 2, md: 1 } }} fullWidth>
                      <Grid container spacing={2} alignItems={'flex-end'}>
                        <Grid item xs={12} sm={12} md={9}>
                          <ClassicTextField
                            fullWidth
                            label="デザイン案コード"
                            readOnly
                            value={`${id}`}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          <Button
                            sx={{ maxWidth: 200 }}
                            fullWidth
                            variant="contained"
                          >
                            印刷
                          </Button>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ mb: { sm: 2, xs: 2, md: 1 } }} fullWidth>
                      <Grid
                        container
                        spacing={2}
                        alignItems={{
                          xs: 'center',
                          sm: 'center',
                          md: 'flex-end',
                        }}
                      >
                        <Grid item xs={12} sm={12} md={9}>
                          <ClassicTextField
                            readOnly
                            value={`${window.location.href}`}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          <Button
                            fullWidth
                            sx={{ maxWidth: 200 }}
                            onClick={() => {
                              toast({
                                message: i18n.t('designs.copy_to_clipboard'),
                              })
                              navigator.clipboard.writeText(
                                `${window.location.href}`
                              )
                            }}
                            variant="contained"
                          >
                            URLコピー
                          </Button>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ mb: { sm: 2, xs: 2, md: 1 } }} fullWidth>
                      <form
                        id="share-email"
                        onSubmit={handleSubmitEmail(onSubmitEmail)}
                      >
                        <Grid container spacing={2} alignItems={'flex-end'}>
                          <Grid item xs={12} sm={12} md={9}>
                            <ControllerEmail
                              name={'email'}
                              control={controlEmail}
                              render={({
                                field: { value, onChange, ref },
                              }: FieldValues) => (
                                <ClassicTextField
                                  error={!!errorsEmail.email?.message}
                                  helperText={
                                    errorsEmail?.email
                                      ? errorsEmail.email?.message
                                      : ''
                                  }
                                  inputRef={ref}
                                  fullWidth
                                  value={value}
                                  placeholder="メールアドレス"
                                  onChange={(v) => onChange(v)}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            <LoadingButton
                              fullWidth
                              type="submit"
                              sx={{ height: 36, maxWidth: 200 }}
                              variant="contained"
                              loading={metaShare.pending}
                              disabled={
                                !isValidEmail ||
                                !isDirtyEmail ||
                                metaShare.pending ||
                                data.attributes.is_expired
                              }
                            >
                              送信
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </form>
                    </FormControl>
                    <FormControl sx={{ mb: { sm: 2, xs: 2, md: 1 } }} fullWidth>
                      <Grid container spacing={2} alignItems={'flex-end'}>
                        <Grid item xs={12} sm={12} md={9}>
                          <ClassicTextField
                            value={compare}
                            placeholder="他のデザイン案コード"
                            onChange={(e) => onChangeCompare(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          <Button
                            disabled={_.isEmpty(compare)}
                            onClick={() => handleCompare()}
                            variant="contained"
                            fullWidth
                            sx={{ maxWidth: 200 }}
                          >
                            比較
                          </Button>
                        </Grid>
                      </Grid>
                    </FormControl>
                    {/* <Divider sx={{ pt: 1, pb: 1 }}> 共通マーキング設定内容</Divider>
              <Typography variant="h5" sx={{ mt: 1, mb: 1 }}></Typography>
              <FormControl sx={{ mb: 1 }} fullWidth>
                <ClassicTextField
                  value={'Team Name'}
                  label="チーム名"
                  readOnly
                  onChange={(e) => onChangeCompare(e.target.value)}
                  fullWidth
                />
              </FormControl>
              <FormControl>
                <Label>ロゴ</Label>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                    height: 'auto',
                    border: '1px solid #eee',
                  }}
                  src="/sample/team-logo.png"
                />
              </FormControl> */}
                  </Box>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Paper sx={{ mt: 2, height: '100%' }}>
                <PaperHeaderReverse title={i18n.t('SYSCOMMON.settings')} />
                <Box
                  sx={{
                    p: { xs: 1, sm: 2, md: 4 },
                    paddingBottom: '0 !important',
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ClassicTextField
                            label="セット数"
                            disabled
                            value={order?.attributes.set_number}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ClassicTextField
                            fullWidth
                            label="合計金額"
                            inputProps={{
                              sx: {
                                textAlign: 'right',
                              },
                            }}
                            readOnly
                            value={`${
                              Number(order?.attributes.set_number) *
                              order?.attributes.price
                            }円 `}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Box>

                  <form>
                    <Box
                      className="scroll-container"
                      sx={{
                        display: 'flex',
                        height: !isMobile ? tableHeight : 'auto',
                        overflowY: 'auto',
                        border: !isMobile ? '1px solid #eee' : '0 none',
                      }}
                    >
                      {!isMobile && isEmptyAllFields !== undefined ? (
                        <TableLayout
                          paperProps={{
                            sx: {
                              boxShadow: 'none',
                              background: '#fefefe',
                              width: '100%',
                            },
                          }}
                          renderRow={renderRow}
                          data={fields}
                          minWidth={'100%'}
                          head={
                            <TableRow
                              sx={{
                                '& .MuiTableCell-head': {
                                  background: '#222',
                                  color: '#fff !important',
                                  borderRight: '1px solid #fff',
                                },
                              }}
                            >
                              <TableCell align="center" />
                              {top && !_.isEmpty(top.attributes.size) ? (
                                <TableCell
                                  align="center"
                                  sx={{ minWidth: 180 }}
                                >
                                  サイズ ({i18n.t('SYSCOMMON.tops')})
                                </TableCell>
                              ) : null}
                              {bottom && !_.isEmpty(bottom.attributes.size) ? (
                                <TableCell
                                  align="center"
                                  sx={{ minWidth: 180 }}
                                >
                                  サイズ ({i18n.t('SYSCOMMON.bottoms')})
                                </TableCell>
                              ) : null}

                              {top
                                ? topActiveMarking.map((d) => {
                                    return (
                                      <TableCell
                                        key={d.id}
                                        align="center"
                                        sx={{ minWidth: 180 }}
                                      >
                                        {
                                          _.find(top.attributes.item_markings, {
                                            id: d.attributes.item_marking_id.toString(),
                                          })?.attributes.name
                                        }
                                      </TableCell>
                                    )
                                  })
                                : null}

                              {bottom
                                ? bottomActiveMarking.map((d) => {
                                    return (
                                      <TableCell
                                        key={d.id}
                                        align="center"
                                        sx={{ minWidth: 180 }}
                                      >
                                        {
                                          _.find(
                                            bottom.attributes.item_markings,
                                            {
                                              id: d.attributes.item_marking_id.toString(),
                                            }
                                          )?.attributes.name
                                        }
                                      </TableCell>
                                    )
                                  })
                                : null}
                            </TableRow>
                          }
                        />
                      ) : isEmptyAllFields !== undefined ? (
                        <Box sx={{ width: '100%' }}>
                          {_.map(fields, (item, index) => {
                            return renderRowMobile(item, index)
                          })}
                        </Box>
                      ) : null}
                    </Box>

                    <Stack
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      justifyContent="space-around"
                      spacing={2}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        mt: 2,
                        p: 0,
                      }}
                    >
                      <Link
                        to={`/design/${data?.attributes.code}?order=${order?.attributes.code}`}
                        style={{
                          color: 'inherit',
                          textDecoration: 'none',
                          width: '100%',
                        }}
                        state={{
                          tops: true,
                          bottoms: true,
                        }}
                      >
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          disabled={data?.attributes.is_expired}
                        >
                          注文情報修正
                        </Button>
                      </Link>

                      <Button
                        fullWidth
                        startIcon={
                          <Box
                            sx={{
                              width: 13,
                              height: 13,
                              mr: 1,
                              position: 'relative',
                              top: '-17px',
                            }}
                          >
                            <Box
                              sx={{ width: '100%', height: 'auto' }}
                              component="img"
                              src="/images/ribonicon.png"
                            ></Box>
                          </Box>
                        }
                        size="large"
                        sx={{ minWidth: 140 }}
                        variant="contained"
                        color="secondary"
                      >
                        ダウンロード
                      </Button>
                    </Stack>
                  </form>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </>
  )
}

export default OrderContainer
