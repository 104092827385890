import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { Box, Container } from '@mui/material'
import { LayoutContants } from '@constants/layout.constants'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type FullScreenModalProps = {
  open: boolean
  onClose: () => void
  content: React.ReactNode
  title?: string
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  open,
  title,
  onClose,
  content,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <AppBar
          elevation={0}
          position="fixed"
          color="default"
          sx={{
            background: '#fff',
            borderBottom: '1px solid #e3e4e5',
            boxShadow: '0 1px 3px rgb(0 0 0 / 4%)',
            '& .MuiPaper-root-MuiAppBar-root': {
              borderRadius: 0,
            },
          }}
        >
          <Toolbar
            sx={{
              '&.MuiToolbar-root': {
                minHeight: LayoutContants.appbarHeight,
                paddingLeft: 2,
                paddingRight: 2,
              },
            }}
          >
            <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
              {title && title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ border: '0 none' }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ paddingTop: `${LayoutContants.appbarHeight}px` }}>
          <Container maxWidth="lg">{content && content}</Container>
        </Box>
      </Dialog>
    </div>
  )
}

export default FullScreenModal
