import * as React from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { Side } from '@constants/editor.constants'
import { use100vh } from 'react-div-100vh'
import DesignThumb from '@components/@editor-components/DesignThumb'
import { LayoutContants } from '@constants/layout.constants'
import { ThemeColors } from '@theme/colors'
import { Close } from '@mui/icons-material'
import { DesignDetail } from '@services/design.services'
import _ from 'lodash'
import DetailPrice from '@components/@editor-components/DetailPrice'
import PriceContentCompare from '@components/PriceContent/PriceContentCompare'
import { Link } from 'react-router-dom'

export type CompareViewProps = {
  value: DesignDetail
  side: Side
  index: number
  onClickDetail?: (id: number) => void
  onClose: (id: string, index: number) => void
}

const CompareView: React.FC<CompareViewProps> = ({
  value,
  onClickDetail,
  side,
  index,
  onClose,
}) => {
  const height = use100vh()
  const previewHeight = height && height / 2 - LayoutContants.appbarHeight - 170

  const outerHeight = height && height - LayoutContants.appbarHeight - 170

  const top = _.find(
    value?.attributes.design_items,
    (item) => item.attributes.genre_type === 1
  )
  const bottom = _.find(
    value?.attributes.design_items,
    (item) => item.attributes.genre_type === 2
  )

  const topSvg =
    side === Side.Front ? top?.attributes.front_url : top?.attributes.back_url

  const bottomSvg =
    side === Side.Front
      ? bottom?.attributes.front_url
      : bottom?.attributes.back_url

  const code = value?.attributes.code

  const activeMarkingsTopIds = top
    ? _.map(top.attributes.design_markings, (m) => m.attributes.item_marking_id)
    : []

  const selectedMarkingTop = top
    ? _.filter(top?.attributes?.item_markings, (t) => {
        return activeMarkingsTopIds.includes(Number(t.id))
      })
    : []

  const activeMarkingsBottomIds = bottom
    ? _.map(
        bottom.attributes.design_markings,
        (m) => m.attributes.item_marking_id
      )
    : []

  const selectedMarkingBottom = bottom
    ? _.filter(bottom?.attributes?.item_markings, (t) => {
        return activeMarkingsBottomIds.includes(Number(t.id))
      })
    : []

  const markingTopTotalPrice = selectedMarkingTop
    ? _.sumBy(selectedMarkingTop, function (o) {
        return o.attributes.additional_price
      })
    : 0
  const markingBottomTotalPrice = selectedMarkingBottom
    ? _.sumBy(selectedMarkingBottom, function (o) {
        return o.attributes.additional_price
      })
    : 0

  const renderPriceContent = () => {
    return (
      <PriceContentCompare
        markingPriceTop={markingTopTotalPrice}
        markingPriceBottom={markingBottomTotalPrice}
        topData={top}
        bottomData={bottom}
        markingsTop={selectedMarkingTop}
        markingsBottom={selectedMarkingBottom}
      />
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: outerHeight,
        position: 'relative',
        border: '1px solid #ccc',
        pb: 1,
      }}
    >
      <Chip
        sx={{ position: 'absolute', top: 10, right: 10, borderRadius: 2 }}
        label={code}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          border: '0 none',
          zIndex: 1001,
        }}
        onClick={() => code && onClose(code, index)}
      >
        <Close />
      </IconButton>
      {top ? (
        <Box
          sx={{
            p: {
              xs: 0,
              sm: 0,
              md: 0,
            },
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: {
                xs: '0 none',
                sm: '0 none',
              },
              height: {
                sm: `${previewHeight}px`,
                xs: `${previewHeight}px`,
              },
            }}
          >
            <DesignThumb
              width={'auto'}
              sx={{
                position: 'relative',
                height: {
                  xs: '100%',
                  sm: '100%',
                },
                paddingTop: { xs: '0', sm: '0' },
                width: '100%',
              }}
              svg={topSvg ? topSvg : ''}
            />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              mt: -1,
            }}
          >
            <Box
              onClick={() =>
                onClickDetail && onClickDetail(top.attributes.item_id)
              }
            >
              <Typography
                variant="h5"
                sx={{
                  display: '-webkit-box',
                  margin: '0 auto',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  height: 20,
                  fontWeight: 500,
                  cursor: 'pointer',
                  '&:hover': {
                    transition: 'all 0.3s ease',
                    color: ThemeColors.secondary,
                  },
                }}
                component="div"
              >
                {top.attributes.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}

      {bottom ? (
        <Box
          sx={{
            p: {
              xs: 0,
              sm: 0,
              md: 0,
            },
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: {
                xs: '0 none',
                sm: '0 none',
              },
              height: {
                sm: `${previewHeight}px`,
                xs: `${previewHeight}px`,
              },
            }}
          >
            <DesignThumb
              width={'auto'}
              sx={{
                position: 'relative',
                height: {
                  xs: '100%',
                  sm: '100%',
                },
                paddingTop: { xs: '0', sm: '0' },
                width: '100%',
              }}
              svg={bottomSvg ? bottomSvg : ''}
            />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              mt: -1,
            }}
          >
            <Box
              onClick={() =>
                onClickDetail && onClickDetail(bottom.attributes.item_id)
              }
            >
              <Typography
                variant="h5"
                sx={{
                  display: '-webkit-box',
                  margin: '0 auto',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  height: 20,
                  fontWeight: 500,
                  cursor: 'pointer',
                  '&:hover': {
                    transition: 'all 0.3s ease',
                    color: ThemeColors.secondary,
                  },
                }}
                component="div"
              >
                {bottom.attributes.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        {value && (
          <DetailPrice
            sx={{
              position: 'relative',
              boxShadow: 'none',
              width: '100%',
            }}
            total={value?.attributes.price}
            content={renderPriceContent()}
          />
        )}

        <Divider />
        <Box sx={{ p: 0.5 }}>
          <Stack direction="row" spacing={2} justifyContent={'space-around'}>
            <Button
              sx={{
                borderColor: '#999',
                '&:hover': {
                  borderColor: '#aaa',
                },
                '&.is-active': {
                  color: ThemeColors.secondary,
                },
              }}
              fullWidth
              variant="contained"
              disableRipple
            >
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={{
                  pathname: `/editor?copy=${code}`,
                }}
                state={{
                  bottoms: true,
                  tops: true,
                }}
              >
                デザイン修正
              </Link>
            </Button>
            <Button
              fullWidth
              sx={{
                borderColor: '#999',
                '&:hover': {
                  borderColor: '#aaa',
                },
                '&.is-active': {
                  color: ThemeColors.secondary,
                },
              }}
              variant="contained"
              color="secondary"
              disableRipple
            >
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={`/design/${value?.attributes.code}`}
              >
                注文情報入力
              </Link>
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default CompareView
