import DateTimePicker from '@components/@material-extend/DatePicker'
import { Box, Button, Grid } from '@mui/material'
import { OrderFilterParams } from '@services/order.services'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useFormFilter from './useFormFilter'
export type FilterParams = Omit<OrderFilterParams, 'per_page' | 'page'>

interface FilterFormProps {
  onFilter: (data: FilterParams) => void
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const { onFilter } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormFilter()
  const { control, handleSubmit } = methods
  const onSubmit = (values: any) => {
    onFilter({
      start_at: values.start_at,
      end_at: values.end_at,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 1 }} pb={2}>
        <Grid item xs={5} lg={4}>
          <Box sx={{ width: '100%', mt: '6px' }}>
            <Controller
              control={control}
              name="start_at"
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  onChange={onChange}
                  title={t('orders.start_at')}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={5} lg={4}>
          <Box sx={{ width: '100%', mt: '6px' }}>
            <Controller
              control={control}
              name="end_at"
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  onChange={onChange}
                  title={t('orders.end_at')}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2} lg={4}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              type="submit"
            >
              {t('SYSCOMMON.search')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default FilterForm
