import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { MARKING_TYPE } from '@constants/common.constants'
import _ from 'lodash'
import i18n from '@locales/i18n'

export const initialValues = {
  name: '',
  is_common: 1,
  additional_price: 0,
  max_size: 10,
  marking_type: MARKING_TYPE.TEXT,
  font_size: 0,
  fonts: [],
  positions: [],
  colors: [],
  sample_text: i18n.t('marking.default_text'),
  fill: '',
  family: '',
  side: 'front',
}

const useMarkingForm = (max_size: number) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .max(
          15,
          t('ERROR.E000002', {
            field: t('marking.marking_name'),
            character: 15,
          })
        )
        .required(
          t('ERROR.E000001', {
            field: t('marking.marking_name'),
          })
        ),
      positions: Yup.array()
        .test(
          'positions',
          t('ERROR.E000009', {
            field: t('marking.positions'),
            character: 50,
          }),
          (value: any) => {
            const isValid = !_.isEmpty(value) ? true : false
            return isValid
          }
        )
        .required(),
      // required when text or number
      fill: Yup.string().when('marking_type', {
        is: (val: any) =>
          val === MARKING_TYPE.TEXT || Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.string().required(
          t('ERROR.E000001', {
            field: t('marking.marking_name'),
          })
        ),
        otherwise: Yup.string().nullable(),
      }),
      sample_text: Yup.string().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.string().when('marking_type', {
          is: (val: any) => Number(val) === MARKING_TYPE.TEXT,
          then: Yup.string()
            .max(
              max_size,
              t('ERROR.E000002', {
                field: t('marking.sample_text'),
                character: max_size,
              })
            )
            .required(
              t('ERROR.E000001', {
                field: t('marking.sample_text'),
              })
            ),
          otherwise: Yup.string()
            .max(
              2,
              t('ERROR.E000002', {
                field: t('marking.sample_text'),
                character: 2,
              })
            )
            .required(
              t('ERROR.E000001', {
                field: t('marking.sample_text'),
              })
            ),
        }),
        otherwise: Yup.string().nullable(),
      }),
      colors: Yup.array().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.array()
          .test(
            'colors',
            t('ERROR.E000009', {
              field: t('marking.colors'),
              character: 50,
            }),
            (value: any) => {
              const isValid = !_.isEmpty(value) ? true : false
              return isValid
            }
          )
          .required(
            t('ERROR.E000001', {
              field: t('marking.colors'),
            })
          ),
        otherwise: Yup.array().nullable(),
      }),
      font_size: Yup.number().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.number().required(
          t('ERROR.E000001', {
            field: t('marking.font_size'),
          })
        ),
        otherwise: Yup.number()
          .typeError(i18n.t('ERROR.number_type'))
          .nullable(),
      }),
      fonts: Yup.array().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.array().min(1, t('marking.selectable_fonts_required')),
        otherwise: Yup.array().nullable(),
      }),

      additional_price: Yup.number().when('is_common', {
        is: (val: any) => Boolean(Number(val)) === false,
        then: Yup.number().required(
          t('ERROR.E000001', {
            field: t('marking.additional_price'),
          })
        ),
        otherwise: Yup.number()
          .typeError(i18n.t('ERROR.number_type'))
          .nullable(),
      }),

      is_common: Yup.number().required(),

      max_size: Yup.number().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.number().required(
          t('ERROR.E000001', {
            field: t('marking.max_size'),
          })
        ),
        otherwise: Yup.number().nullable(),
      }),
      family: Yup.string().when('marking_type', {
        is: (val: any) =>
          Number(val) === MARKING_TYPE.TEXT ||
          Number(val) === MARKING_TYPE.NUMBER,
        then: Yup.string().required(t('marking.default_font_required')),
        otherwise: Yup.string().nullable(),
      }),

      side: Yup.string().required(),
    },
    [['marking_type', 'is_common']]
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useMarkingForm
