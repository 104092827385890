import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  genre_id: '',
  code: '',
  copy_id: null,
}

const useHansyaFilter = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(
            50,
            t('ERROR.E000002', {
              field: t('SYSCOMMON.item_code'),
              character: 50,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('SYSCOMMON.item_name'),
            })
          ),
        code: Yup.string().required(
          t('ERROR.E000001', {
            field: t('SYSCOMMON.item_code'),
          })
        ),
        genre_id: Yup.string().required(
          t('ERROR.E000001', {
            field: t('SYSCOMMON.genre'),
          })
        ),
        copy_id: Yup.object().nullable(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useHansyaFilter
