import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { BoxProps } from '@mui/system'
import i18n from '@locales/i18n'
import { currency } from 'utils/helper/common.helpers'

interface DetailPriceProps extends BoxProps {
  title: string
  product: string
  markingsPrice: number
  productPrice: number
}

const PriceRow: React.FC<DetailPriceProps> = ({
  title,
  product,
  markingsPrice,
  productPrice,
  ...rest
}) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          color: '#222',
          ...rest.sx,
        }}
      >
        <Typography
          textAlign={'center'}
          sx={{ fontWeight: 'bold', fontSize: 14 }}
        >
          {title}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: 14, background: '#eee' }}>
          <Box sx={{ maxWidth: 500, margin: '0 auto', pl: 2 }}>{product}</Box>
        </Typography>
        <Box>
          <Box sx={{ width: '100%', background: '#fff' }}>
            <Stack
              sx={{ maxWidth: 500, margin: '0 auto' }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="body1" sx={{ pl: 2 }}>
                {i18n.t('SYSCOMMON.product_unit_price')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: '600', fontSize: 14, pr: 2 }}
              >
                {currency(productPrice)}
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: '100%', background: '#eee' }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ maxWidth: 500, margin: '0 auto' }}
            >
              <Typography variant="body1" sx={{ fontSize: 14, pl: 2 }}>
                {i18n.t('SYSCOMMON.markings_unit_price')}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: '600', fontSize: 14, pr: 2 }}
              >
                {currency(markingsPrice)}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PriceRow
