import { Meta } from '@store/metadata/actions/types'
import { AdminItem, AdminUpdateParams } from '@services/admins.services'
import { ADMINS_ACTION_TYPE } from '@store/admins/actions/types'
import { adminUpdate, adminDetail } from '@store/admins/actions'
import { detail } from '@store/admins/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
import { clearMetaData } from '@store/metadata/actions'
interface ReturnType {
  data?: AdminItem
  metaUpdate: Meta
  fetch: (id: string) => void
  update: (params: AdminUpdateParams) => void
  clearCreateMeta: () => void
}

const useAdminEdit = (): ReturnType => {
  const data = useAppSelector(detail)
  const dispatch = useDispatch()
  const fetch = (id: string) => {
    dispatch(adminDetail(id))
  }

  const update = (params: AdminUpdateParams) => {
    dispatch(adminUpdate(params))
  }
  const clearCreateMeta = () => {
    dispatch(clearMetaData(ADMINS_ACTION_TYPE.UPDATE))
  }

  const _metaCreate = createMetaSelector(adminUpdate)
  const metaUpdate = useAppSelector(_metaCreate)

  return {
    data,
    metaUpdate,
    fetch,
    update,
    clearCreateMeta,
  }
}

export default useAdminEdit
