import { FieldValues } from 'react-hook-form'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { Button, Box, Paper, FormControl } from '@mui/material'
import PaperHeader from '@components/PaperHeader'
import { useTranslation } from 'react-i18next'
import useSystemSettings from '@containers/SystemSettings/useSystemSettings'
import { useConfirm } from '@components/Confirm'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import _ from 'lodash'
import { useEffect } from 'react'
import useFormSetting from '@containers/SystemSettings/useFormSetting'
import CurrencyInput from '@components/CurrencyInput'
const SystemSettings = () => {
  const { t } = useTranslation()
  const { data, fetch, save } = useSystemSettings()
  const { Controller, methods } = useFormSetting()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = methods
  const confirm = useConfirm()
  useEffect(() => {
    fetch()
  }, [])
  useEffect(() => {
    if (data) {
      const item = data.attributes
      const defaultValues: any = {}
      defaultValues.design_expire_month = item.design_expire_month
      defaultValues.order_expire_month = item.order_expire_month
      reset({ ...defaultValues })
    }
  }, [data])

  const onSubmit = (values: any) => {
    confirm({
      title: t('settings.save_confirm_title'),
      description: t('settings.save_confirm_desc'),
    })
      .then(() => {
        save(values)
      })
      .catch(() => null)
  }

  return (
    <ContentBox>
      <Paper sx={{ width: '100%', mt: 1, mb: 1 }}>
        <PaperHeader title={t('settings.title')} />
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <FormControl sx={{ mb: 2, width: '100%' }}>
            <Controller
              name="design_expire_month"
              control={control}
              render={({ field: { ref, onChange, ...rest } }: FieldValues) => {
                return (
                  <>
                    <CurrencyInput
                      {...rest}
                      ref={ref}
                      fullWidth
                      disableGroupSeparators
                      allowNegativeValue={false}
                      customInput={ClassicTextField}
                      onValueChange={onChange}
                      inputProps={{
                        sx: { textAlign: 'right', pr: 1 },
                      }}
                      placeholder={t('settings.design_expire_month')}
                      label={t('settings.design_expire_month')}
                      error={_.has(errors, 'design_expire_month')}
                      helperText={_.get(errors, 'design_expire_month')?.message}
                      endAdornment={
                        <Box sx={{ width: 40 }}> {t('SYSCOMMON.months')}</Box>
                      }
                    />
                  </>
                )
              }}
            />
          </FormControl>
          <Box sx={{ maxWidth: 300 }}>
            <FormControl sx={{ mb: 2, width: '100%' }}>
              <Controller
                name="order_expire_month"
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                }: FieldValues) => (
                  <CurrencyInput
                    {...rest}
                    ref={ref}
                    fullWidth
                    inputProps={{
                      sx: { textAlign: 'right', pr: 1 },
                    }}
                    disableGroupSeparators
                    allowNegativeValue={false}
                    customInput={ClassicTextField}
                    onValueChange={onChange}
                    placeholder={t('settings.order_expire_month')}
                    label={t('settings.order_expire_month')}
                    error={_.has(errors, 'order_expire_month')}
                    endAdornment={
                      <Box sx={{ width: 40 }}> {t('SYSCOMMON.months')}</Box>
                    }
                    helperText={_.get(errors, 'order_expire_month')?.message}
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControl sx={{ mb: 2 }}>
            <Button
              color="secondary"
              variant="contained"
              disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </Button>
          </FormControl>
        </Box>
      </Paper>
    </ContentBox>
  )
}

export default SystemSettings
