import { Box } from '@mui/material/'
import PriceRow from '@components/@editor-components/DetailPrice/PriceRow'
import { DesignDetailItem } from '@services/design.services'
import i18n from '@locales/i18n'
import Typography from '@mui/material/Typography'
import { MarkingItem } from '@services/item.services'
import { currency } from 'utils/helper/common.helpers'
import { ThemeColors } from '@theme/colors'

export type PriceContentCompareProps = {
  bottomData?: DesignDetailItem
  topData?: DesignDetailItem
  markingsTop?: MarkingItem[]
  markingsBottom?: MarkingItem[]
  markingPriceTop: number
  markingPriceBottom: number
}

const PriceContentCompare: React.FC<PriceContentCompareProps> = ({
  bottomData,
  topData,
  markingsTop,
  markingsBottom,
  markingPriceTop,
  markingPriceBottom,
}) => {
  return (
    <Box sx={{ p: 2, background: '#fff', color: '#222' }}>
      {topData ? (
        <>
          <PriceRow
            title={i18n.t('SYSCOMMON.tops')}
            productPrice={
              topData.attributes.price
                ? topData.attributes.price - markingPriceTop
                : 0
            }
            product={topData.attributes.name}
            markingsPrice={markingPriceTop}
          />
          <Box sx={{ pl: 2, textAlign: 'left' }}>
            {markingsTop?.map((m, i) => {
              return (
                <Typography variant="body1" key={i}>
                  - {m.attributes.name}
                  <Box
                    component="span"
                    sx={{ color: ThemeColors.primary, ml: 1 }}
                  >
                    {currency(m?.attributes.additional_price)}
                  </Box>
                </Typography>
              )
            })}
          </Box>
        </>
      ) : null}
      {bottomData ? (
        <>
          <PriceRow
            title={i18n.t('SYSCOMMON.bottoms')}
            productPrice={
              bottomData.attributes.price
                ? bottomData.attributes.price - markingPriceBottom
                : 0
            }
            product={bottomData.attributes.name}
            markingsPrice={markingPriceBottom}
          />
          <Box sx={{ pl: 2, textAlign: 'center' }}>
            {markingsBottom?.map((m, i) => {
              return (
                <Typography variant="body2" key={i}>
                  - {m.attributes.name}
                  <Box
                    component="span"
                    sx={{ color: ThemeColors.secondary, ml: 1 }}
                  >
                    {currency(m?.attributes.additional_price)}
                  </Box>
                </Typography>
              )
            })}
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default PriceContentCompare
