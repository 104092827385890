import LoginLayout from '@containers/Auth/LoginLayout'
import SentMail from '@containers/Auth/SentMail'

const SentLink = () => {
  return (
    <LoginLayout>
      <SentMail />
    </LoginLayout>
  )
}

export default SentLink
