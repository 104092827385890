import * as React from 'react'
import { Box, Paper } from '@mui/material'
import { defaultStyles, FileIcon } from 'react-file-icon'
import { getFileExtension } from '@utils/helper/fileHelper'
import { AttachFile, Edit } from '@mui/icons-material'

interface AttachementProps {
  onClick: () => void
  path?: string
}

const Attachement: React.FC<AttachementProps> = ({ onClick, path }) => {
  return (
    <Paper
      onClick={() => onClick()}
      sx={{
        width: 100,
        height: 100,
        cursor: 'pointer',
        position: 'relative',
        mt: 0.5,
        backgroundSize: 'cover',
      }}
    >
      {path === null ? (
        <AttachFile
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            right: 0,
            margin: '0 auto',
          }}
        />
      ) : (
        <>
          <Box
            sx={{
              width: 50,
              height: 50,
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
              right: 0,
              margin: '0 auto',
            }}
          >
            {path !== undefined && (
              <FileIcon
                extension={getFileExtension(path)}
                {...defaultStyles[getFileExtension(path)]}
              />
            )}
          </Box>
          <Edit
            sx={{
              position: 'absolute',
              top: '2px',
              right: '2px',
              fontSize: 14,
            }}
          />
        </>
      )}
    </Paper>
  )
}

export default Attachement
