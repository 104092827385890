import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/design.services'
import { DESIGN_ACTION_TYPE } from './types'

export const designList = createAsyncThunk<
  services.DesignListResponse,
  services.DesignFilterParams
>(DESIGN_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.list(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createNew = createAsyncThunk<
  services.NewDesignResponse,
  services.NewParams
>(DESIGN_ACTION_TYPE.NEW_DESIGN, async (params, { rejectWithValue }) => {
  try {
    const res = await services.newDesign(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fontListPublic = createAsyncThunk<services.ClientFontResponse>(
  DESIGN_ACTION_TYPE.PUBLIC_FONTS,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.clientFonts()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createDesign = createAsyncThunk<any, services.CreateDesignParams>(
  DESIGN_ACTION_TYPE.CREATE_DESIGN,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.createDesign(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const detail = createAsyncThunk<services.DesignDetailResponse, string>(
  DESIGN_ACTION_TYPE.DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.designDetail(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const compareDesign = createAsyncThunk<
  services.DesignCompareResponse,
  services.CompareParams
>(DESIGN_ACTION_TYPE.COMPARE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.compareDesign(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shareDesign = createAsyncThunk<
  services.DesignShareResponse,
  services.DesignShareParams
>(DESIGN_ACTION_TYPE.SHARE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.designShare(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const copyDesign = createAsyncThunk<
  services.DesignCopyResponse,
  services.DesignCopyParams
>(DESIGN_ACTION_TYPE.COPY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.copy(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const designDelete = createAsyncThunk<
  services.DesignListResponse,
  services.DesignDeleteParams
>(DESIGN_ACTION_TYPE.DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteDesign(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const resetCopy = createAction('items/resetCopy')
