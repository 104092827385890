import CompareContainer from '@containers/CompareContainer'
import { Box, Container } from '@mui/material'

const Compare = () => {
  return (
    <Box>
      <Container maxWidth="lg">
        <CompareContainer />
      </Container>
    </Box>
  )
}

export default Compare
