import {
  InputBase,
  styled,
  InputProps,
  FormHelperText,
  FormControl,
  alpha,
  SxProps,
  colors,
  Box,
  Stack,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import theme from '@theme/index'
import { ReactElement } from 'react'
import ClassicInputLabel from '../ClassInputLabel'

interface HorizontalTextFieldProps extends InputProps {
  helperText?: string
  label?: string
  error?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  labelStyle?: SxProps
  sx?: any
  labelWidth?: number
  maxInputWidth?: any
}

export const Label = styled('label')({
  padding: '2px 1px',
  fontSize: 12,
  marginRight: '3px',
})

const HorizontalTextField: React.FC<HorizontalTextFieldProps> = ({
  helperText,
  label,
  labelSecondary,
  error,
  required = false,
  labelStyle,
  labelWidth = 160,
  maxInputWidth = '100%',
  sx,
  ...rest
}) => {
  const { readOnly } = rest
  return (
    <FormControl fullWidth={rest.fullWidth ? rest.fullWidth : false}>
      <Stack direction={'row'} spacing={1}>
        {(label || labelSecondary) && (
          <Box
            sx={{
              width: labelWidth,
              display: 'inline-block',
              pt: 0.8,
              textAlign: 'right',
              '& div': {
                display: 'block',
              },
            }}
          >
            <ClassicInputLabel
              required={required}
              label={label}
              id={rest.id ? rest.id : ''}
              style={{ textAlign: 'right' }}
              labelStyle={labelStyle}
            />
          </Box>
        )}
        <Box sx={{ maxWidth: maxInputWidth, width: '100%' }}>
          <InputBase
            sx={{
              '&.MuiInputBase-root': {
                background: ThemeColors.white,
                color: ThemeColors.text[900],
                border: `1px solid ${ThemeColors.text[600]}`,
                fontSize: 12,
                padding: '4px 8px',
                transition: theme.transitions.create([
                  'border-color',
                  'background-color',
                  'box-shadow',
                ]),
                '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
                  '& .MuiIconButton-root': {
                    border: '0 none',
                    marginRight: 0,
                  },
                },
                '&.MuiInputBase-adornedStart ': {
                  '& .MuiIconButton-root': {
                    border: '0 none',
                    marginRight: 0.5,
                  },
                },
                '&.r-only': {
                  background: '#f9f9f9',
                  border: '1px solid #ddd',
                },
                '&.Mui-focused': {
                  boxShadow: `${alpha(
                    theme.palette.secondary.main,
                    0.15
                  )} 0 0 0 0.2rem`,
                  borderColor: theme.palette.secondary.main,
                },

                '&.Mui-error': {
                  borderColor: colors.red[400],
                },
                ...sx,
              },
            }}
            error={error}
            className={`${readOnly ? 'r-only' : ''}`}
            margin="dense"
            {...rest}
          />
          {helperText && (
            <FormHelperText
              sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
              error={error}
            >
              {helperText}
            </FormHelperText>
          )}
        </Box>
      </Stack>
    </FormControl>
  )
}

export default HorizontalTextField
