import * as React from 'react'
import { Alert, Box, Chip, IconButton } from '@mui/material'

import { use100vh } from 'react-div-100vh'

import { LayoutContants } from '@constants/layout.constants'

import { Close } from '@mui/icons-material'
import { AlertColor } from '@mui/lab'

export type CompareViewProps = {
  value: { id?: string; code: string; type: string }
  onClose: (id: string, index: number) => void
  message: string
  severity?: AlertColor
  index: number
}

const CompareNotFound: React.FC<CompareViewProps> = ({
  value,
  onClose,
  index,
  severity = 'warning',
  message,
}) => {
  const height = use100vh()

  const outerHeight = height && height - LayoutContants.appbarHeight - 170

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: outerHeight,
        position: 'relative',
        boxShadow: 1,
        pb: 1,
      }}
    >
      <Chip
        sx={{ position: 'absolute', top: 10, right: 10, borderRadius: 2 }}
        label={value.code}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          border: '0 none',
          zIndex: 1001,
        }}
        onClick={() => value.code && onClose(value.code, index)}
      >
        <Close />
      </IconButton>
      <Box>
        <Alert severity={severity}>{message}</Alert>
      </Box>
    </Box>
  )
}

export default CompareNotFound
