import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  reset_password_token: '',
  new_password: '',
}

const usePasswordResetForm = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        new_password: Yup.string()
          .min(
            5,
            t('ERROR.E000002', {
              field: t('SYSCOMMON.new_password'),
              character: 5,
            })
          )
          .max(
            50,
            t('ERROR.E000002', {
              field: t('SYSCOMMON.new_password'),
              character: 50,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('SYSCOMMON.new_password'),
            })
          ),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default usePasswordResetForm
