import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { SettingItem } from '@services/settings.services'

type StateType = {
  data?: SettingItem
}

const initialState: StateType = {
  data: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.setting.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.data = action.payload.data
  })
  builder.addCase(actions.settingsSave.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.data = action.payload.data
  })
})
