import { AlertColor } from '@mui/material'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/common.service'
import { COMMON_ACTION_TYPE } from './types'

export type toastParams = {
  message: string
  severity?: AlertColor
  isDialog?: { url?: string }
}

export type routeParams = {
  path: string | undefined
}

export const addToast = createAction<toastParams>('toast/addToast')
export const removeToast = createAction<string>('toast/removeToast')
export const cleanToasts = createAction('toast/cleanToasts')
export const helperHide = createAction('helper/helperHide')
export const helperShow = createAction('helper/helperShow')
export const previousRoute = createAction<routeParams>('route/saveRoute')

export const contentImageList =
  createAsyncThunk<services.ContentImageListResponse>(
    COMMON_ACTION_TYPE.LIST,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.list()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const sendInquiry = createAsyncThunk<
  services.InquiryResponse,
  services.InquiryParams
>(COMMON_ACTION_TYPE.INQUIRY, async (params, { rejectWithValue }) => {
  try {
    const res = await services.inquires(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
