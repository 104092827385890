import Box from '@mui/material/Box'
import { ShopItem } from '@services/shops.services'
import { Grid, Paper, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import CardMedia from '@mui/material/CardMedia'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  AccessTime,
  DirectionsCar,
  Fax,
  LocalPhone,
  LocationOn,
  Person,
} from '@mui/icons-material'
import { ReactNode } from 'react'
import { ThemeColors } from '@theme/colors'

interface HtmlParserProps {
  data: ShopItem
}

export type InfoRowProps = {
  icon: ReactNode
  label: string
  children: any
}

const InfoRow: React.FC<InfoRowProps> = ({ icon, label, children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ borderBottom: 0.5, borderBottomColor: '#B8B8B8' }}
    >
      <Grid container>
        <Grid
          item
          lg={4}
          sm={12}
          xs={12}
          sx={{
            pb: 0.5,
            pt: 0.5,
            backgroundColor: '#EEEEEE',
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}
          >
            <Box
              sx={{
                color: '#000000',
                padding: '8px',
                height: 40,
                width: 40,
                textAlign: 'left',
                borderRadius: 1,
              }}
            >
              {icon}
            </Box>
            <Box
              fontSize={13}
              sx={{
                fontWeight: 400,
                textAlign: { xs: 'center', sm: 'center', md: 'left' },
                color: '#222222',
                marginLeft: 0.5,
                display: 'block',
              }}
              color="text.secondary"
            >
              {label}
            </Box>
          </Stack>
        </Grid>

        <Grid item lg={8} sm={12} xs={12}>
          <Box ml={2} mr={2}>
            <Box
              component="p"
              sx={{
                wordBreak: 'break-all',
                cursor: 'pointer',
                margin: 0,
                marginTop: 1,
                marginBottom: 1,
              }}
              fontSize={13}
              color="text.primary"
            >
              {children}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShopCard: React.FC<HtmlParserProps> = ({ data }) => {
  const { attributes } = data
  const { t } = useTranslation()
  return (
    <Box sx={{ mb: 2 }}>
      <Paper sx={{ position: 'relative', p: { xs: 0.5, sm: 0.5, md: 2 } }}>
        <Box sx={{ pl: 1, pb: 2, pr: 1, pt: 1 }}>
          <Typography variant="h4" sx={{ mt: 1, ml: 1, mr: 1 }}>
            {attributes.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{ whiteSpace: 'pre-wrap', mb: 2, ml: 1, mr: 1 }}
          >
            {attributes.descriptions}
          </Typography>

          <Grid container spacing={{ xs: 1, sm: 1, md: 4 }}>
            <Grid item xl={5} sm={12} lg={4} xs={12}>
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  paddingTop: '60%',
                  display: 'block',
                }}
                ml={1}
                mr={1}
              >
                <CardMedia
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: '#eee',
                    right: 0,
                  }}
                  image={attributes.image}
                  src="img"
                />
              </Box>
            </Grid>
            <Grid item xl={7} sm={12} lg={8} xs={12}>
              <Box sx={{ pl: 0 }}>
                <Box
                  ml={1}
                  mr={1}
                  sx={{ border: 1, borderColor: '#B8B8B8', borderBottom: 0 }}
                >
                  <InfoRow
                    icon={<LocationOn sx={{ position: 'relative' }} />}
                    label={t('shops.address')}
                  >
                    {attributes.address}
                  </InfoRow>
                  <InfoRow
                    icon={<DirectionsCar sx={{ position: 'relative' }} />}
                    label={t('shops.access_info')}
                  >
                    <Stack
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      width={'100%'}
                    >
                      <Box sx={{ mr: { xs: 'auto', sm: 'auto' } }}>
                        {attributes.access_info}
                      </Box>
                      {!_.isEmpty(attributes.map_url) && (
                        <Box
                          component={'a'}
                          sx={{
                            marginLeft: 1,
                            minWidth: '60px',
                            textDecoration: 'none',
                            backgroundColor: ThemeColors.primary,
                            color: ThemeColors.white,
                            textAlign: 'center',
                            paddingLeft: 1,
                            paddingRight: 1,
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            borderRadius: '4px',
                            fontSize: 12,
                            mt: { sm: 0.5, xs: 0.5 },
                          }}
                          rel="noopener noreferrer"
                          target={'_blank'}
                          href={attributes.map_url ? attributes.map_url : ''}
                        >
                          {t('shops.map')}
                        </Box>
                      )}
                    </Stack>
                  </InfoRow>
                  <InfoRow
                    icon={<LocalPhone sx={{ position: 'relative' }} />}
                    label={t('shops.phone')}
                  >
                    {attributes.phone}
                  </InfoRow>

                  <InfoRow
                    icon={<Fax sx={{ position: 'relative' }} />}
                    label={t('shops.fax')}
                  >
                    {attributes.fax}
                  </InfoRow>

                  <InfoRow
                    icon={<Person sx={{ position: 'relative' }} />}
                    label={t('shops.manager')}
                  >
                    {attributes.manager}
                  </InfoRow>

                  <InfoRow
                    icon={<AccessTime sx={{ position: 'relative' }} />}
                    label={t('shops.time_table')}
                  >
                    {attributes.time_table}
                  </InfoRow>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default ShopCard
