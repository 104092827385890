import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'

export const initialValues = {
  name: '',
  front_path: '',
  back_path: '',
  back_svg: '',
  front_svg: '',
  is_editable: 1,
  back_url: '',
  front_url: '',
}

const usePartCreateForm = (names: string[]) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .max(
          15,
          t('ERROR.E000002', {
            field: t('part.part_name'),
            character: 15,
          })
        )
        .test(
          'name',
          t('ERROR.E000011', { field: t('part.part_name') }),
          (value: any) => {
            const isValid = names ? !_.includes(names, value) : true
            return isValid
          }
        )
        .required(
          t('ERROR.E000001', {
            field: t('part.part_name'),
          })
        ),
      back_path: Yup.string().when('front_path', {
        is: (val: any) => !val,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      back_svg: Yup.string().nullable(),
      front_path: Yup.string().when('back_path', {
        is: (val: any) => !val,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      front_svg: Yup.string().nullable(),
      is_editable: Yup.boolean().required(),
      front_url: Yup.string().nullable(),
      back_url: Yup.string().nullable(),
    },
    [['back_path', 'front_path']]
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default usePartCreateForm
