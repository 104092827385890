export default {
  login: {
    username: 'ユーザー名',
    password: 'パスワード',
    button: 'ログイン',
  },
  SYSCOMMON: {
    panel_list_text: 'パーツごとのカラー・配置等を指定してください',
    part_list_text: 'カラーを選択',
    marking_list_text: '配置、デザインを指定',
    category_select: '性別を選択してください',
    part_done: '設定完了',
    marking_done: '設定完了',
    settings: 'ご注文内容',
    no_data: 'データなし',
    inquiry: '問い合わせ',
    design_compare: 'デザイン比較',
    shop_information: 'ショップ案内',
    ec_button: 'ECサイトに連携',
    email_save: 'メールアドレス (任意)',
    logo: 'ロゴ',
    tops: 'トップス',
    bottoms: 'ボトムス',
    design_proposal_search: 'デザイン案検索',
    order_information_search: '注文情報検索',
    simulate: 'シミュレーションを開始する',
    contact_us: 'お問い合わせ',
    in_progress: '進行中',
    privacy: 'プライバシーポリシー',
    terms: '利用規約',
    material: '素材',
    size: 'サイズ',
    mail: 'メールアドレス',
    tel: '電話',
    front: '前',
    back: '後',
    color: 'カラー',
    marking: 'マーキング',
    enter_design_code: 'デザイン案コードを入力してください。',
    name: '名前',
    email: 'メールアドレス',
    login_button: 'ログイン',
    password_forgot_submit_button: '送信',
    password_reset_submit_button: '設定',
    login_username: 'ユーザー名',
    login_password: 'パスワード',
    password: 'パスワード',
    password_confirm: '新しいパスワード確認',
    upload: 'アップロード',
    select_file: 'ファイル選択',
    create: '登録',
    staff_management: 'スタッフ管理',
    genre_management: 'ジャンル管理',
    system_settings: 'システム設定',
    merchandise: '商品管理',
    design_plan: 'デザイン案管理',
    orders: '注文管理',
    designs: 'デザイン案',
    shop_management: 'ショップ情報管理',
    fonts_management: 'フォント管理',
    admins_management: 'スタッフ管理',
    logout: 'ログアウト',
    total_price: '参考価格（お1人様）',
    more_detail: '内訳',
    close: '閉じる',
    markings_unit_price: 'マーキング単価',
    product_unit_price: '商品単価',
    edit_simulation: 'シミュレーション編集',
    see_simulation: 'シミュレーション参照',
    edit_detail: '詳細ページ編集',
    delete: '削除',
    edit: '編集',
    private: '非公開',
    publish: '公開',
    deleted: '削除済み',
    register: '新規登録',
    item_name: '商品名',
    sex: '性別',
    genre: 'ジャンル',
    template_item: 'テンプレート商品',
    status: '状態',
    search: '検索',

    confirm: 'OK',
    cancel: 'キャンセル',
    update_confirm_title: 'ステータス変更',
    update_confirm_desc: '対象商品のステータスを「{{target}}」にしますか。',
    item_code: '商品番号',
    item_price: '単価',
    remove_size: '削除',
    add_size: '追加',
    upload_thumbnail: 'サムネイル',
    upload_images: '写真集',
    price: '単価',
    description: '説明',
    save: '保存',
    next: 'パーツ構成設定へ',
    back_confirm_title: 'デザイン案作成の中止',
    back_confirm_desc: 'デザイン案が保存されていません。画面を離脱しますか。',
    default_upload_title: 'アップロード',
    article: '自由説明',
    thumbnail: 'サムネイル ',
    product_image: '写真集',
    delete_confirm_title: '商品削除',
    delete_confirm_desc: 'この商品を削除しますか。',
    basic_info: '基本情報',
    back_path: '裏',
    front_path: '表',
    step1: 'パーツ構成設定',
    step2: 'カラー設定',
    step3: 'マーキング設定',
    unsaved_change_confirm_title: '変更が保存されていません。',
    unsaved_change_confirm_desc: '変更を保存せずに画面を離脱しますか。',
    fogot_password: 'パスワードを忘れた?',
    back_to_login: 'ログインへ戻る',
    reset_password_confirm_code: 'コード確認',
    new_password: '新しいパスワード',
    sent_reset_link_to_your_email: 'メールをチェックして下さい。',

    yen: '円',
    page_not_found: 'お探しのページは見つかりませんでした。',
    back_home: 'ホームページへ戻る',
    howto: 'How To',
    im_not_robot: '私はロボットではありません',
    captcha: 'CAPTCHA',
    email_sent: 'メールが送信されました。',
    success: '成功しました!',
    months: 'ヶ月',
    item_updated: '変更を保存しました。',
    design_preview: 'プレビュー',
    button_edit: 'デザインを変更する',
    button_preview: 'デザインを確認してみる',
    file_uploaded: '保存されました。',
    required: '必須',
    terms_confirm: '同意する',
    no_options: '未指定',
  },
  admins: {
    name: 'スタッフ名',
    email: 'メールアドレス',
    password: 'パスワード',
    delete_confirm_title: 'スタッフ削除',
    delete_confirm_desc: 'スタッフを削除しますか。',
    edit_title: 'スタッフ情報編集',
    password_change_title: 'パスワード変更',
    change_password: 'パスワード変更',
    password_not_match: 'パスワードが一致しません。',
    success_admin_update: 'スタッフ情報が更新されました。',
    success_admin_password_change: 'パスワードが変更されました。',
    delete_admin_success_toast: 'スタッフが削除されました。',
    create_admin_success_toast: 'スタッフが作成されました。',
  },
  items: {
    create_success: '商品が作成されました。',
    update_success: '商品が保存されました。',
    status_draft_success: '商品が非表示になりました。',
    status_publish_success: '商品が公開されました。',
    status_delete_success: '商品が削除されました。',
    status_draft_with_deny_success: '一部の商品は非表示されませんでした。',
    status_publish_with_deny_success: '一部の商品は公開されませんでした。',
    status_delete_with_deny_success: '一部の商品は削除されませんでした。',
  },
  helper: {
    active_marking: 'Please choose position and fill marking settings form',
    active_marking_mobile:
      'Please choose position and fill marking settings form',
    active_color: 'Please pick color and click done',
    active_color_mobile: 'Please pick color and click done',
    active_catalogy: 'Please choose items from catalogy',
    active_catalogy_mobile: 'Please choose items from catalogy',
    init: 'From right side list pick item part to change color or pick marking and edit settings',
    init_mobile:
      'Click on Edit icon on right top corner. Then from list pick item part to change color or pick marking and edit settings',
  },
  genre: {
    genre_name: 'ジャンル名',
    genre_type: 'タイプ',
    item_count: 'アイテム数',
    filter_all: '全て',
    filter_unisex: 'MENS/UNISEX',
    filter_ladies: 'LADIES',
    delete_confirm_title: 'ジャンル削除',
    delete_confirm_desc: 'ジャンル「{{target}}」を削除しますか。',
    save_success_toast: 'ジャンルが更新されました。',
    create_success_toast: 'ジャンルが作成されました。',
    delete_success_toast: 'ジャンルが削除されました。',
    not_found: 'ジャンルは削除されたか存在していません。',
  },
  inquiry: {
    last_name: '姓',
    first_name: '名',
    name: '名前',
    email: 'メールアドレス',
    phone: '電話番号',
    company_name: '企業名',
    body: '本文',
    submit: '送信',
  },
  part: {
    part_name: 'パーツ名',
    back_step: '戻る',
    order_changed: 'パーツ順序が変更されました。',
    changing_order_title: 'パーツ順序の変更',
    changing_order_desc: 'パーツ順序を変更しますか。',
    create_success: 'パーツは設定されました。',
    update_success: 'パーツカラーが設定されました。',
    next_step2: '「カラー設定」へ',
    next_step3: '「マーキング設定」へ',
    save_and_next_step2: '保存してス「カラー設定」へ',
    save_and_next_step3: '保存して「マーキング設定」へ',
    save_and_finish: '商品登録完了',
    finish: '完了',
    color: '色',
    add_color: '色を追加',
    default_color: '既定色',
    is_editable: 'カラー設定あり',
    delete_confirm_title: 'パーツ削除',
    delete_confirm_desc: 'パーツを削除しますか。',
    preview: 'プレビュー',
    create_confirm_title: 'パーツ設定完了',
    create_confirm_desc: 'パーツ設定を保存し、「カラー設定」へ進みますか。',
    add_new_part: 'パーツを追加',
    edit_part: 'パーツ編集',
    new_part: '新規パーツ',
  },
  marking: {
    marking_name: 'マーキング名',
    marking_type: 'マーキングの種類 ',
    is_common_true: '共通設定のみ',
    is_common_false: '個別指定可能 ',
    max_length: '最長文字数',
    font_size: 'フォントサイズ ',
    selectable_font: 'フォント',
    add_font: '追加',
    remove_font: '削除',
    position_side: 'サイド',
    add_position: '追加',
    is_common: '　',
    sample_text: 'サンプルテキスト',
    default_text: 'テキスト',
    additional_price: '個別指定の追加料金',
    add_marking: 'マーキング追加',
    update_marking: 'マーキング更新',
    remove_marking: 'マーキング削除',
    text: 'テキスト',
    position: '位置',
    number: '番号',
    logo: 'ロゴ',
    x: '縦座標',
    y: '横座標',
    update_confirm_title: 'マーキング更新',
    update_confirm_desc: 'マーキングを更新しますか。',
    create_confirm_title: 'マーキング追加',
    create_confirm_desc: '新しいマーキングを追加しますか。',
    delete_confirm_title: 'マーキング削除',
    delete_confirm_desc: 'マーキングを削除しますか。',
    update_success: 'マーキングが更新されました。',
    create_success: 'マーキングが追加されました。',
    delete_success: 'マーキングが削除されました。',
    default_family: 'デフォルトフォント',
    up_to_character: '{{limit}}文字まで入力可能',
    please_choose_position:
      '左画面上の赤丸を選択しネームを入れる場所を指定してください。 \n 赤丸を中心に配置されます。',
    please_choose_position_mobile:
      '上のボタンからデザイン画面に移動し、マーキングを配置する場所を選択してください。選択した赤丸の位置を中心に配置されます',
    change_location: '位置を変更する',
    marking_position_required: 'マーキング位置を設定する（必須）',
    marking_unit_price: 'マーキング単価',
    delete: '削除',
    active: '表示位置',
    fonts: 'フォント',
    positions: 'ポジション',
    colors: 'カラー',
    position_helper_text:
      '注) 座標に表示文字のセンター位置を指定してください。\n「ポジション表示」を選択するとマウスクリックで座標を自動セットできます。',
    is_common_text: '個別指定すると{{price}}円増額されます。',
    discard_changes_confirm_title: '変更を破棄しますか。',
    discard_changes_confirm_desc: '変更が保存されていません。',
    default_font_required: 'デフォルトフォントを指定して下さい',
    selectable_fonts_required: 'フォントを１つ以上選択して下さい',
  },
  preview_content: {
    position: 'ポジション表示',
    content: 'サンプル表示',
  },
  settings: {
    title: '設定',
    name: '設定名',
    value: '設定値',
    design_expire_month: 'デザイン有効期間',
    order_expire_month: '注文有効期間',
    save_confirm_title: '設定更新',
    save_confirm_desc: '保存しますか。',
    save_success_toast: 'システム設定が更新されました。',
  },
  fonts: {
    name: 'フォント名',
    status: 'ステータス',
    style_file: 'スタイルファイル',
    style_path: 'スタイルパス',
    woff_path: 'woffパス',
    woff_file: 'woffファイル',
    woff2_path: 'woff2パス',
    woff2_file: 'woff2ファイル',
    file: 'ファイル',
    update_confirm_desc: '選択したフォントを「{{target}}」しますか。',
    delete_confirm_title: 'フォント削除',
    delete_confirm_desc: 'フォントを削除しますか。',
    edit_title: 'フォント編集',
    update_success_toast: 'フォントが更新されました。',
    save_success_toast: 'フォントが保存されました。',
    status_draft_success: 'フォントが非表示されました。',
    status_publish_success: 'フォントが公開されました。',
    status_delete_success: 'フォントが削除されました。',
    create_success_toast: 'フォントが作成されました。',
    published: '公開',
    unpublished: '非公開',
    upload_file_info: '各ファイルはアップロードされた時点で保存されます。',
    not_found: 'font not found',
  },
  shops: {
    name: '店舗名',
    manager: 'ショップ担当者',
    phone: '連絡先',
    fax: 'FAX番号',
    status: 'ステータス',
    address: '住所',
    map_url: 'Google Map',
    map: 'MAP',
    access_info: 'アクセス',
    time_table: '営業時間',
    descriptions: '店舗情報',
    update_confirm_desc: '選択した店舗を「{{target}}」しますか。',
    delete_confirm_title: '店舗削除',
    delete_confirm_desc: '店舗を削除しますか。',
    edit_title: '店舗情報編集',
    shop_list: 'ショップ一覧',
    save_success_toast: '店舗情報が更新されました。',
    create_success_toast: '店舗が作成されました。',
    status_draft_success: '店舗が非表示になりました。',
    status_publish_success: '店舗が公開されました。',
    delete_success_toast: '店舗が削除されました。',
  },
  orders: {
    code: '注文コード',
    design_code: 'デザインコード',
    set_number: '枚数',
    price: '価格',
    created_at: '作成日時',
    created_ip: '作成元IP',
    expire_at: '有効期限日時',
    last_access: '最終閲覧',
    start_at: '開始日付',
    end_at: '終了日付',
    save_order: '注文として保存',
    success_create_order: '注文が保存されました。',
    success_create_order_ec: '注文が保存されました。EC',
    failed_create_order: '注文を保存できませんでした。何かがおかしいです。',
    check_order_title: '注文を保存しますか。',
    check_order_desc: 'サイズやマーキング入力をご確認ください。',
    no_size: 'サイズなし',
    size_required: 'サイズを選択してください。',
  },
  designs: {
    top_image_front: 'トップス',
    bottom_image_front: 'ボトムス',
    code: 'コード',
    price: '価格',
    created_at: '作成日時',
    created_ip: '作成元IP',
    expire_at: '有効期限日時',
    last_access: '最終閲覧',
    start_at: '開始日付',
    end_at: '終了日付',
    save_confirm_title: 'デザイン案を保存しますか。',
    save_confirm_desc: 'カラーやマーキングを保存しました。',
    compare_design_code: '比較デザイン案コード：',
    copy_to_clipboard: 'URLがクリップボードに保存されました。',
    failed_share: 'メールを送信できませんでした。',
    success_share: 'デザイン案をメールで共有しました。',
    not_found_compare: 'このコードのデザイン案は見つかりませんでした。',
    design_correction: 'デザイン案修正',
    delete_confirm_title: 'デザイン案の削除',
    delete_confirm_desc: 'このデザイン案を削除しますか。',
    delete_success_toast: 'デザインが削除されました。',
    confirm_remove_design_title: 'アイテムを削除しようとしています。',
    confirm_remove_design_desc: 'アイテムを削除してよろしいですか。',
    confirm_change_title: 'アイテムを変更しようとしています。',
    confirm_change_desc: 'アイテムを変更してよろしいですか。',
  },
  button: {
    create: '新規作成',
    save: '保存',
    edit: '編集',
    delete: '削除',
    cancel: '取消',
  },
  ERROR: {
    E000001: '{{field}} を入力してください。',
    E000002: '{{field}} は{{character}}文字以下を入力してください。', // max character
    E000003: 'エラーが発生しました。システム管理者に連絡してください。', // unknown error
    E000004: '有効なメールアドレスを入力してください。', // email must be
    E000005:
      'パスワードはの長さは、半角8文字以上かつ英大文字・英小文字・数字・シンボルそれぞれを最低1文字ずつ含む必要があります。', // password strength
    E000006:
      'ログインできませんでした。ログインに利用したアカウント情報を確認してください。', // login failed
    E000007: '有効な値を入力してください', // valid value
    E000008: '{{field}} はURLである必要があります。',
    E000009: '１つ以上の{{field}}を追加する必要があります。',
    E000010: '{{min}}以上にしてください',
    E000011: '{{field}} はすでに存在しています。',
    E000013: '全てのパーツに１つ以上のSVG画像を設定する必要があります。',
    E000014: '全てのパーツに１つ以上のカラーを設定する必要があります。',
    E000015: '商品コードが存在しています。',
    E000016: 'メールアドレスが見つかりませんでした。',
    E000017: 'パスワードをリセットできませんでした。',
    E000018: '有効なデザインがありますので、編集できません。',
    E000019: '１つ以上のパーツを設定して下さい。',
    E000020: 'Must be exactly {{character}} characters',
    E000021: 'カラーコードが間違っています。',
    BE000001: '商品名が重複しています。',
    BE000002: '商品コードが重複しています。',
    BE000003: 'wrong email',
    BE000004: 'メールアドレスが重複しています。',
    BE000005: 'トークンが無効になっています。',
    BE000006: 'お探しのデザインが見つかりませんでした。',
    BE000007: 'お探しのデザインは有効期限が切れています。',
    BE000008: 'お探しの注文は存在しません。',
    BE000009: 'お探しの注文は有効期限が切れています。',
    BE000015: 'Design is missing you want to copy',
    number_type: '整数を入力して下さい。',
    BE000011: 'ジャンル名は既に存在しています。',
    BE000012: 'ジャンルは存在しません。',
    BE000013: 'フォント名は重複しています。',
    BE000014: 'フォントは存在しません。',
    max_reached: 'Max size is reached',
    should_be_number: 'Marking input should be number',
  },
}
