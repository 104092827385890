import { Box, Typography } from '@mui/material/'
import { BoxProps } from '@mui/system'
import { ReactNode } from 'react'

export type PaperHeaderProps = {
  actions?: ReactNode
  title: string
}

const PaperHeader: React.FC<BoxProps & PaperHeaderProps> = ({
  actions,
  title,
  ...rest
}) => {
  return (
    <Box
      sx={{
        padding: {
          xs: '5px 8px',
          sm: '5px 8px',
          md: '16px 16px',
        },
        background: ' #fafafa',
        borderBottom: '1px solid  #eaeded',
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h2" sx={{ margin: 0 }}>
            {title}
          </Typography>
        </Box>
        <Box>{actions && actions}</Box>
      </Box>
    </Box>
  )
}

export default PaperHeader
