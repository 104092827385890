import React from 'react'
import { Box, IconButton } from '@mui/material/'
import { Close } from '@mui/icons-material'

interface LargeSideBarProps {
  onCloseClick?: () => void
}

const LargeSideBar: React.FC<LargeSideBarProps> = ({
  onCloseClick,
  children,
}) => {
  return (
    <Box
      id="sidebar-main"
      className={`sidebar-main`}
      sx={{
        position: 'fixed',
        top: '0',
        bottom: '0',
        paddingLeft: 0,
        WebkitBoxSizing: 'border-box',
        boxSizing: 'border-box',
        width: 230,
        background: {
          xl: 'transparent',
          lg: 'transparent',
          md: 'transparent',
          sm: '#fff',
          xs: '#fff',
        },
        display: 'flex',
        height: '100%',
        WebkitBoxOrient: 'vertical',
        WebkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        left: 'calc(-230px - 20px)',
        zIndex: 1000,
        willChange: 'auto',
        visibility: 'hidden',
        opacity: 0,
        boxShadow: {
          xl: 'none',
          lg: 'none',
          md: 'none',
          sm: '0 4px 20px 1px rgb(0 0 0 / 7%)',
          xs: '0 4px 20px 1px rgb(0 0 0 / 7%)',
        },
      }}
    >
      <Box
        sx={{
          listStyle: 'none',
          width: '100%',
          height: '100%',
          display: 'none',
          margin: '0',
        }}
        className={`navigation-content`}
      >
        <Box
          sx={{
            display: { sm: 'block', xs: 'block', md: 'none', lg: 'none' },
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 100,
          }}
        >
          <IconButton
            sx={{ border: '0 none' }}
            onClick={(e) => {
              e.stopPropagation()
              onCloseClick && onCloseClick()
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  )
}

export default LargeSideBar
