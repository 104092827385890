import React from 'react'
import { Alert, Box, Button, Typography } from '@mui/material'
import i18n from '@locales/i18n'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const Page404: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const message = _.get(location, 'state.message', undefined)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        {i18n.t('SYSCOMMON.page_not_found')}
      </Typography>
      {message && (
        <Alert sx={{ mb: 1 }} severity="warning">
          {t('ERROR.' + message)}
        </Alert>
      )}
      <Button variant="contained">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to={'/'}>
          {i18n.t('SYSCOMMON.back_home')}
        </Link>
      </Button>
    </Box>
  )
}

export default Page404
