import { LayoutContants } from '@constants/layout.constants'
import { mainmenu } from '@constants/menu.constants'
import {
  Drawer,
  List,
  ListItem,
  Box,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import React from 'react'
import { Link } from 'react-router-dom'

export type DrawerProps = {
  open: boolean
  onClose: () => void
}

const SideDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const menu = () => {
    return (
      <Box
        sx={{
          width: '100vw',
          background: ThemeColors.primary,
          height: '100%',
        }}
        role="presentation"
        onClick={() => onClose()}
        onKeyDown={() => onClose()}
      >
        <List>
          {mainmenu.map((text) => (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={text.route}
              key={text.label}
            >
              <ListItem sx={{ borderBottom: '1px dotted #222' }} disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={text.label}
                    sx={{
                      color: '#fefefe',
                      textAlign: 'center',
                      pt: 1,
                      pb: 1,
                      '&:hover': {
                        color: ThemeColors.secondary,
                        tranistion: ' all 0.6s ease',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
    )
  }

  return (
    <Drawer
      open={open}
      anchor={'left'}
      PaperProps={{ sx: { top: `${LayoutContants.appbarHeight}px` } }}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
    >
      {menu()}
    </Drawer>
  )
}

export default SideDrawer
