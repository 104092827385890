import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useFormFilter from './useFormFilter'
import DateTimePicker from '@components/@material-extend/DatePicker'
import { FieldValues } from 'react-hook-form'
import ClassicInputLabel from '@components/@material-extend/ClassInputLabel'
import { Item } from '@services/item.services'
import useItemList from '@containers/Items/useItemList'
import { DesignFilterParams } from '@services/design.services'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
interface FilterFormProps {
  onFilter: (data: DesignFilterParams) => void
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const { onFilter } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormFilter()
  const { data: items, fetch } = useItemList()
  const { control, handleSubmit } = methods
  const onSubmit = (values: any) => {
    onFilter({
      start_at: values.start_at,
      end_at: values.end_at,
      item_id: values.item_id?.id,
      email: values.email,
    })
  }
  useEffect(() => {
    fetch({})
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 1 }} pb={2}>
        <Grid item xs={3} lg={3}>
          <Box sx={{ width: '100%', mt: '6.5px' }}>
            <Controller
              name="item_id"
              control={control}
              render={({ field: { onChange, value } }: FieldValues) => (
                <>
                  <ClassicInputLabel
                    id="item_id"
                    label={t('SYSCOMMON.template_item')}
                  />
                  <Autocomplete
                    ChipProps={{
                      size: 'small',
                      color: 'info',
                      sx: { fontSize: 11 },
                    }}
                    sx={{
                      '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                        p: '2px',
                      },
                    }}
                    options={items ? items : []}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.attributes.name}
                      </Box>
                    )}
                    getOptionLabel={(option: Item) => option.attributes?.name}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        placeholder={t('SYSCOMMON.template_item')}
                        {...params}
                      />
                    )}
                  />
                </>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Box sx={{ width: '100%', mt: '6px' }}>
            <Controller
              control={control}
              name="start_at"
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  onChange={onChange}
                  title={t('designs.start_at')}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Box sx={{ width: '100%', mt: '6px' }}>
            <Controller
              control={control}
              name="end_at"
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  onChange={onChange}
                  title={t('designs.end_at')}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2} lg={3}>
          <Box sx={{ width: '100%', mt: '6px' }}>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...rest } }: FieldValues) => (
                <ClassicTextField
                  {...rest}
                  inputRef={ref}
                  fullWidth
                  label={t('SYSCOMMON.email')}
                  placeholder={t('SYSCOMMON.email')}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              mt: '-2px',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              type="submit"
            >
              {t('SYSCOMMON.search')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default FilterForm
