export enum ITEM_ACTION_TYPE {
  CREATE = 'items/create',
  LIST = 'items/list',
  UPDATE = 'items/update',
  STATUS = 'items/status',
  DETAIL = 'items/detail',
  IMAGE_LIST = 'items/imageList',
  IMAGE_CREATE = 'items/imageCreate',
  IMAGE_DELETE = 'items/imageDelete',
  PART_CREATE = 'items/partCreate',
  PART_UPDATE = 'items/partUpdate',
  PART_LIST = 'items/partList',
  PART_DELETE = 'items/partDelete',
  PART_ORDER = 'items/partOrder',
  MARKING_CREATE = 'items/markingCreate',
  MARKING_UPDATE = 'items/markingUpdate',
  MARKING_LIST = 'items/markingList',
  MARKING_DELETE = 'items/markingDelete',
  TOP_LIST = 'items/topList',
  PUBLIC_ITEM = 'items/publicItem',
  COMPLETE = 'items/complete',
}
