import { styled, InputProps, Box, Typography, SxProps } from '@mui/material'
import { ThemeColors } from '@theme/colors'
import { ReactElement } from 'react'

interface ClassicTextFieldProps extends InputProps {
  helperText?: string
  label?: string
  error?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  labelStyle?: SxProps
  id: string
}

export const Label = styled('label')({
  padding: '2px 1px',
  fontSize: 13,
  marginRight: '3px',
  fontWeight: '600',
})

const ClassicInputLabel: React.FC<ClassicTextFieldProps> = ({
  label,
  labelSecondary,
  required = false,
  labelStyle,
  id,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <Label
          sx={{
            fontWeight: 500,
            color: ThemeColors.dark,
            fontSize: 14,
            ...labelStyle,
          }}
          id={id ? id : ''}
        >
          {label}
        </Label>
        {required === true && (
          <Typography
            component="span"
            variant="body1"
            style={{ color: ThemeColors.secondary }}
          >
            *
          </Typography>
        )}
      </Box>
      {labelSecondary}
    </Box>
  )
}

export default ClassicInputLabel
