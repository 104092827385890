import { Box, Stack, Button, FormHelperText } from '@mui/material'
import { ItemPartUpdate, PartItem } from '@services/item.services'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Meta } from '@store/metadata/actions/types'
import ColorPicker from '@components/@editor-components/ColorPicker'
import _ from 'lodash'
import { Check, Delete } from '@mui/icons-material'
import ColorEditor from '@components/@admin/Markings/ColorEditor'

interface ColorConfigrationProps {
  id: string
  selectedId?: string | null
  onChangeColor?: (params: ItemPartUpdate) => void
  meta?: Meta
  color: string
  list: PartItem[]
  onChange: (color: string) => void
  onIsDirtyChange?: (value: boolean) => void
  showEditable: boolean
}

const ColorConfigration: React.FC<ColorConfigrationProps> = (props) => {
  const {
    selectedId,
    list,
    color,
    onChange,
    id,
    showEditable,
    onChangeColor,
    onIsDirtyChange,
  } = props
  const [data, setData] = useState<PartItem>()
  const [error, setError] = useState<null | string>(null)
  const { t } = useTranslation()

  const isDirty = !_.isEqual(
    _.find(list, (v) => v.id === selectedId),
    data
  )

  useEffect(() => {
    onIsDirtyChange && onIsDirtyChange(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (selectedId !== null && list) {
      setData(_.find(list, (v) => v.id === selectedId))
    }
  }, [list, selectedId])

  const onAdd = (value: string) => {
    let params
    if (data?.attributes.colors) {
      params = {
        values: !_.includes(data.attributes.colors.values, value)
          ? [...data.attributes.colors.values, value]
          : data.attributes.colors.values,
        fill:
          1 > _.size(data.attributes.colors.values)
            ? value
            : data.attributes.colors.fill,
      }
    } else {
      params = {
        values: [value],
        fill: value,
      }
    }
    onChange(value)

    if (data) {
      setData({
        ...data,
        attributes: {
          ...data.attributes,
          colors: params,
        },
      })
      setError('')
    }
  }

  const handleDelete = () => {
    if (data && _.size(data?.attributes.colors?.values) > 1) {
      const fill =
        color === data.attributes.colors?.fill
          ? _.filter(data?.attributes.colors?.values, (v) => v !== color)[0]
          : data.attributes.colors?.fill
      const params = {
        values: _.filter(data?.attributes.colors?.values, (v) => v !== color),
        fill: fill,
      }
      onChange(fill === null || typeof fill === 'undefined' ? '' : fill)
      setData({
        ...data,
        attributes: {
          ...data.attributes,
          colors: params,
        },
      })
    } else if (data) {
      const params = {
        values: [],
        fill: null,
      }
      onChange('')
      setData({
        ...data,
        attributes: {
          ...data.attributes,
          colors: params,
        },
      })
    }
  }

  const handleDefault = () => {
    // eslint-disable-next-line no-console

    if (data) {
      const params = {
        values:
          _.get(data, 'attributes.colors.values', undefined) &&
          data.attributes.colors
            ? data.attributes.colors.values
            : [],
        fill: color,
      }
      setData({
        ...data,
        attributes: {
          ...data.attributes,
          colors: params,
        },
      })
      setError('')
    }
  }

  const handleSubmit = () => {
    if (_.get(data, 'attributes.colors', undefined) && data) {
      onChangeColor &&
        onChangeColor({
          id: id,
          part_id: data.id,
          values: {
            colors: _.get(data, 'attributes.colors', undefined),
          },
        })
      setError('')
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: {
          xs: '100%',
          sm: '100%',
          md: '100%',
        },
      }}
    >
      {data && (
        <Box sx={{ width: 'auto', pb: 2 }}>
          <ColorPicker
            colors={_.get(data, 'attributes.colors.values', [])}
            onChangeComplete={(v) => onChange(v)}
            value={color}
            perRow={12}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Stack sx={{ mt: 1 }} spacing={3} direction="row">
            <Box
              sx={{
                width: 35,
                height: 35,
                border: '1px solid #eee',
                backgroundColor: data?.attributes.colors?.fill,
              }}
            ></Box>
            <Button
              variant="outlined"
              size="small"
              disabled={!showEditable || _.isEmpty(color)}
              onClick={() => handleDefault()}
              sx={{ mr: 1 }}
            >
              <Check sx={{ fontSize: 14 }} />
              {t('part.default_color')}
            </Button>

            <Button
              size="small"
              variant="contained"
              onClick={() => handleDelete()}
              disabled={!showEditable || _.isEmpty(color)}
            >
              <Delete sx={{ fontSize: 14 }} />
              {t('button.delete')}
            </Button>
          </Stack>
        </Box>
      )}

      {showEditable && data && (
        <>
          <ColorEditor
            colors={_.get(data, 'attributes.colors.values', [])}
            onChange={onAdd}
          />
          <Stack direction="row" justifyContent={'right'}>
            <Button
              variant="contained"
              disabled={!isDirty}
              onClick={() => handleSubmit()}
            >
              {t('SYSCOMMON.save')}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  )
}

export default ColorConfigration
