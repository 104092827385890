export enum DESIGN_ACTION_TYPE {
  LIST = 'design/list',
  NEW_DESIGN = 'design/newDesign',
  PUBLIC_FONTS = 'design/fonts',
  CREATE_DESIGN = 'design/create',
  DETAIL = 'design/detail',
  COMPARE = 'design/compare',
  SHARE = 'design/share',
  COPY = 'design/copy',
  DELETE = 'design/delete',
}
