import CompareView from '@components/CompareView'
import { Side } from '@constants/editor.constants'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import 'swiper/css/navigation'
import useCompareForm from './useCompareForm'
import { useTranslation } from 'react-i18next'
import useDesign from '@containers/EditorContainer/useDesign'
import usePublicItem from '@containers/Items/usePublicItem'
import Detail from '@components/Detail'
import CompareNotFound from '@components/CompareView/CompareNotFound'
import { DesignDetail } from '@services/design.services'
import { Box, Button, ButtonGroup, Paper, Stack } from '@mui/material'
import ClassicInputLabel from '@components/@material-extend/ClassInputLabel'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { FieldValues } from 'react-hook-form'
import { ThemeColors } from '@theme/colors'
import i18n from '@locales/i18n'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import FullScreenModal from '@components/FullScreenModal'

const CompareContainer = () => {
  const [search, setSearchParams] = useSearchParams()
  const [side, setSide] = useState<Side>(Side.Front)
  const [open, setOpen] = useState<number | null>(null)
  const [array, setArray] = useState<string[]>([])

  const { detail, data } = usePublicItem()

  const { compareList, compareCodes } = useDesign()

  const renderContent = () => {
    if (data) {
      return <Detail data={data} />
    }
  }

  const { t } = useTranslation()

  const { Controller, methods } = useCompareForm()
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    // reset,
  } = methods

  useEffect(() => {
    const params: string[] = []

    setValue('a', search.get('a') ? (search.get('a') as string) : '')
    setValue('b', search.get('b') ? (search.get('b') as string) : '')
    setValue('c', search.get('c') ? (search.get('c') as string) : '')

    params.push(search.get('a') as string)

    params.push(search.get('b') as string)

    params.push(search.get('c') as string)

    setArray(params)
  }, [search])

  useEffect(() => {
    if (!_.isEmpty(array)) {
      compareCodes({ codes: array })
    }
  }, [array])

  const onSubmit = (values: any) => {
    setSearchParams({
      a: values.a,
      b: values.b,
      c: values.c,
    })
  }

  const handleClose = (id: string, index: number) => {
    if (search.get('a') === id && index === 0) {
      search.delete('a')
    }
    if (search.get('b') === id && index === 1) {
      search.delete('b')
    }
    if (search.get('c') === id && index === 2) {
      search.delete('c')
    }
    setSearchParams(search)
  }

  const handleDetail = (id: number) => {
    setOpen(id)
  }

  useEffect(() => {
    if (open) {
      detail(open.toString())
    }
  }, [open])

  const orderCompareList = _.map(array, (a) => {
    const findByCode = _.find(compareList, {
      attributes: {
        code: a,
      },
    })
    return findByCode
      ? findByCode
      : {
          id: undefined,
          type: '404',
          code: a,
        }
  })

  const renderRow = (
    p: DesignDetail | { id: undefined; type: string; code: string },
    i: number
  ) => {
    if (p.id && !p.attributes.is_expired) {
      return (
        <CompareView
          onClose={handleClose}
          onClickDetail={handleDetail}
          value={p}
          key={i}
          index={i}
          side={side}
        />
      )
    } else if (p.id && p.attributes.is_expired) {
      return (
        <CompareNotFound
          message={i18n.t('ERROR.BE000007')}
          index={i}
          key={i}
          severity={'info'}
          value={{ id: p.id, code: p.attributes.code, type: 'Expired' }}
          onClose={handleClose}
        />
      )
    } else if (p.id === undefined && !_.isEmpty(p.code)) {
      return (
        <CompareNotFound
          message={i18n.t('ERROR.BE000006')}
          value={p as any}
          index={i}
          key={i}
          onClose={handleClose}
        />
      )
    }
    return null
  }

  return (
    <Box>
      <Paper sx={{ mt: 2, mb: 2, pb: 2 }}>
        <Box>
          <Box sx={{ p: 1 }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <form onSubmit={handleSubmit(onSubmit)} method="post">
                <ClassicInputLabel
                  id="compare-label"
                  label={t('designs.compare_design_code')}
                  sx={{
                    display: {
                      sm: 'none',
                      xs: 'none',
                      md: 'block',
                    },
                  }}
                />
                <Controller
                  name="a"
                  control={control}
                  render={({ field: { ref, ...rest } }: FieldValues) => (
                    <ClassicTextField
                      id={'design-a'}
                      placeholder={t('orders.design_code')}
                      error={errors.a ? true : false}
                      sx={{ width: { xs: 80, md: 120 }, mr: 1 }}
                      helperText={errors.a ? errors.a.message : ''}
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name="b"
                  control={control}
                  render={({ field: { ref, ...rest } }: FieldValues) => (
                    <ClassicTextField
                      placeholder={t('orders.design_code')}
                      id={'design-b'}
                      sx={{ width: { xs: 80, md: 120 }, mr: 1 }}
                      inputRef={ref}
                      error={errors.b ? true : false}
                      helperText={errors.b ? errors.b.message : ''}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name="c"
                  control={control}
                  render={({ field: { ref, ...rest } }: FieldValues) => (
                    <ClassicTextField
                      placeholder={t('orders.design_code')}
                      id={'design-c'}
                      sx={{ width: { xs: 80, md: 120 }, mr: 1 }}
                      inputRef={ref}
                      error={errors.c ? true : false}
                      helperText={errors.c ? errors.c.message : ''}
                      {...rest}
                    />
                  )}
                />
                <Button variant="contained" type="submit">
                  比較
                </Button>
              </form>
            </Stack>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              pb: 1,
              position: 'relative',
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                border: '1px solid #ccc',
                width: 210,
                margin: '0 auto',
              }}
            >
              <ButtonGroup sx={{ p: 0.5, background: '#fff' }}>
                <Button
                  onClick={() => setSide(Side.Front)}
                  disableRipple
                  className={side === Side.Front ? 'is-active' : ''}
                  variant="outlined"
                  sx={{
                    color: '#222',
                    borderColor: '#999',
                    border: '0 none',
                    width: 100,
                    '&:hover': {
                      border: '0 none',
                    },
                    '&.is-active': {
                      color: '#fff',
                      background: ThemeColors.primary,
                    },
                  }}
                >
                  {i18n.t('SYSCOMMON.front')}
                </Button>
                <Button
                  onClick={() => setSide(Side.Back)}
                  disableRipple
                  className={side === Side.Back ? 'is-active' : ''}
                  sx={{
                    color: '#222',
                    border: '0 none',
                    width: 100,
                    '&.is-active': {
                      color: '#fff',
                      background: ThemeColors.primary,
                    },
                    '&:hover': {
                      border: '0 none',
                    },
                  }}
                >
                  {i18n.t('SYSCOMMON.back')}
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: 1 }}>
          <Swiper
            slidesPerView={3}
            modules={[Navigation]}
            breakpoints={{
              600: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              1200: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={16}
            // onSlideChange={(s) => setActive(s.activeIndex)}
            navigation
            className="navSwiper"
          >
            {!_.isEmpty(orderCompareList) &&
              orderCompareList.map((p, i) => {
                return (
                  <SwiperSlide key={i} id={`id-${p.id}`}>
                    {renderRow(p, i)}
                  </SwiperSlide>
                )
              })}
          </Swiper>
          {!array.every((element) => element === '') ? (
            <></>
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                minHeight: 500,
              }}
            >
              {i18n.t('SYSCOMMON.enter_design_code')}
            </Box>
          )}
        </Box>
      </Paper>
      <FullScreenModal
        content={renderContent()}
        open={open ? true : false}
        onClose={() => setOpen(null)}
      />
    </Box>
  )
}

export default CompareContainer
