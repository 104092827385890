import ClassicSelect from '@components/@material-extend/ClassicSelect'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { GenreItem } from '@services/genre.services'
import { Item, ItemCreateParams } from '@services/item.services'
import { Meta } from '@store/metadata/actions/types'
import React, { useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFormCreate from './useFormCreate'
import _ from 'lodash'
import ClassicInputLabel from '@components/@material-extend/ClassInputLabel'

interface FilterFormProps {
  onCreate: (data: ItemCreateParams) => void
  genre?: GenreItem[]
  meta: Meta
  items: Item[]
  close: () => void
}

const CreateForm: React.FC<FilterFormProps> = (props) => {
  const { onCreate, items, genre, meta, close } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormCreate()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
    // reset,
  } = methods

  const copyItem = watch('copy_id')

  useEffect(() => {
    if (copyItem) {
      const genre_id = _.get(copyItem, 'attributes.genre_id', undefined)
      if (genre_id) {
        setValue('genre_id', genre_id)
        trigger('genre_id')
      }
    }
  }, [copyItem])

  const onSubmit = (values: any) => {
    let params
    if (values.copy_id !== null) {
      params = {
        name: values.name,
        code: values.code,
        genre_id: values.genre_id,
        copy_id: Number(values.copy_id.id),
      }
    } else {
      params = {
        name: values.name,
        code: values.code,
        genre_id: values.genre_id,
      }
    }
    onCreate(params)
  }

  return (
    <Stack sx={{ width: { xs: '100%', sm: '100%', md: 400 } }}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Box sx={{ width: '100%', height: 75 }}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                required
                label={t('SYSCOMMON.item_name')}
                placeholder={t('SYSCOMMON.item_name')}
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name.message : ''}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', height: 75 }}>
          <Controller
            control={control}
            name="code"
            render={({ field: { ref, ...rest } }) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                required
                label={t('SYSCOMMON.item_code')}
                placeholder={t('SYSCOMMON.item_code')}
                error={errors.code ? true : false}
                helperText={errors.code ? errors.code.message : ''}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', height: 75 }}>
          <Controller
            name="copy_id"
            control={control}
            render={({ field: { onChange, value } }: FieldValues) => (
              <>
                <ClassicInputLabel
                  id="copy_id"
                  label={t('SYSCOMMON.template_item')}
                />
                <Autocomplete
                  noOptionsText={t('SYSCOMMON.no_options')}
                  ChipProps={{
                    size: 'small',
                    color: 'info',
                    sx: { fontSize: 11 },
                  }}
                  sx={{
                    '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                      p: '4px',
                    },
                  }}
                  options={items ? items : []}
                  value={value}
                  onChange={(event, value) => {
                    // eslint-disable-next-line no-console
                    onChange(value)
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.attributes.name}
                    </Box>
                  )}
                  getOptionLabel={(option: Item) => option.attributes?.name}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      placeholder={t('SYSCOMMON.template_item')}
                      {...params}
                    />
                  )}
                />
              </>
            )}
          />
        </Box>
        <Box sx={{ width: '100%', height: 75 }}>
          <Controller
            name="genre_id"
            control={control}
            render={({ field: { onChange, value } }: FieldValues) => (
              <ClassicSelect
                label={t('SYSCOMMON.genre')}
                value={value}
                required
                onChange={onChange}
                error={errors.genre_id ? true : false}
                helperText={errors.genre_id ? errors.genre_id.message : ''}
              >
                {genre
                  ? genre.map((item, k) => (
                      <MenuItem key={k} value={item.id}>
                        <ListItemText primary={item.attributes.name} />
                      </MenuItem>
                    ))
                  : 'Loading'}
              </ClassicSelect>
            )}
          />
        </Box>

        {meta.error && (
          <Box sx={{ mb: 1 }}>
            <Alert severity="warning">
              {t(`ERROR.${meta.error.error?.message}`)}
            </Alert>
          </Box>
        )}
        <Stack justifyContent="space-between" direction={'row'} spacing={2}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              close()
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{ flex: 1 }}
            disabled={!isValid || meta.pending}
            loading={meta.pending}
            type="submit"
          >
            {t('SYSCOMMON.register')}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  )
}

export default CreateForm
