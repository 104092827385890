/* eslint-disable no-console */
import ChipArray from '@components/@admin/ChipArray'
import { useUpload } from '@components/@admin/FileUpload'
import TextEditor from '@components/@admin/TextEditor'
import ClassicSelect from '@components/@material-extend/ClassicSelect'
import ClassicTextField, {
  Label,
} from '@components/@material-extend/ClassicTextField'
import Spinner from '@components/@material-extend/Spinner'
import { useConfirm } from '@components/Confirm'
import CurrencyInput from '@components/CurrencyInput'
import PaperHeader from '@components/PaperHeader'
import { PROCESS_STATUS } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import useFormItemEdit from '@containers/Items/useFormItemEdit'
import useItemEdit from '@containers/Items/useItemEdit'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { AddAPhoto, ArrowForwardIos, Close, Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  Stack,
  Paper,
  Grid,
  CardMedia,
  Card,
  IconButton,
} from '@mui/material'
import { getPresignedUrlResponse } from '@services/common.service'
import { ImageItem } from '@services/item.services'
import { ThemeColors } from '@theme/colors'
import customHistory from 'customHistory'
import _ from 'lodash'
import { useEffect } from 'react'

import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
import i18n from '@locales/i18n'

const ItemEdit = () => {
  const { id } = useParams()
  const {
    data,
    fetch,
    genre,
    fetchGenre,
    update,
    imgs,
    imgMeta,
    meta,
    updateMeta,
    fetchImages,
    deleteImage,
    productImage,
    createImage,
    listImageMeta,
    getImages,
    updateErrors,
  } = useItemEdit()
  const { Controller, methods } = useFormItemEdit()

  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods

  const upload = useUpload()
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)
  useEffect(() => {
    if (id) {
      fetch(id)
      fetchGenre()
      getImages(id)
    }
  }, [])

  const handleThumbnail = () => {
    if (id) {
      upload({
        upload_uri: URI.UPLOAD_THUMB.replace(/:id/gi, id),
      })
        .then((res: getPresignedUrlResponse) => {
          setValue('thumbnail', res.key, { shouldDirty: true })
          setValue('thumb_url', res.file_url, { shouldDirty: true })
        })
        .catch(() => null)
    }
  }

  const handleProductImage = () => {
    if (id) {
      upload({
        upload_uri: URI.UPLOAD_CONTENT.replace(/:id/gi, id),
      })
        .then((res: getPresignedUrlResponse) => {
          createImage({
            id: id,
            values: {
              path: res.key,
            },
          })
        })
        .catch(() => null)
    }
  }

  const handleImageDelete = (img: ImageItem) => {
    confirm({
      title: t('SYSCOMMON.delete_confirm_title'),
      description: t('SYSCOMMON.delete_confirm_desc'),
    })
      .then(() => {
        if (id) {
          deleteImage({ id: id, image_id: img.id })
        }
      })
      .catch(() => null)
  }

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line no-console
      const value = data.attributes
      reset({
        name: value.name,
        price: value.price,
        descriptions: value.descriptions ? value.descriptions : '',
        article: value.article ? value.article : '',
        size: value.size ? value.size : ([] as any),
        code: value.code,
        genre_id: value.genre_id as any,
        thumb_url: value.thumb_url,
      })
    }
  }, [data])

  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])

  const onSubmit = (values: any) => {
    if (data) {
      update({
        id: data.id,
        params: _.omit(values, 'thumb_url'),
      })
    }
  }

  const renderImages = () => {
    if (!_.isEmpty(productImage) && productImage) {
      return productImage.map((img) => {
        return (
          <Grid key={img.id} item xs={6} sm={6} md={2}>
            <Card sx={{ position: 'relative', overflow: 'inherit' }}>
              <IconButton
                onClick={() => handleImageDelete(img)}
                sx={{
                  border: '0 none',
                  borderRadius: 100,
                  boxShadow: 1,
                  position: 'absolute',
                  background: '#fff',
                  right: '-8px',
                  top: '-8px',
                  zIndex: 100,
                }}
              >
                <Close sx={{ fontSize: 14 }} />
              </IconButton>
              <CardMedia
                sx={{ paddingTop: '100%' }}
                image={img.attributes.url}
              />
            </Card>
          </Grid>
        )
      })
    }
  }

  const renderLoader = () => {
    if (listImageMeta.pending) {
      return (
        <Box
          display="flex"
          sx={{ width: '100%', height: 100 }}
          alignItems="center"
          justifyContent="center"
        >
          <Spinner loading={listImageMeta.pending} />
        </Box>
      )
    }
    return null
  }
  const errorExitsFromBE = (code: string) => {
    if (updateErrors && updateErrors.error && updateErrors.error.message) {
      if (code == updateErrors.error.message) {
        return true
      }
    }
    return false
  }

  const errorMsgFromBE = (code: string) => {
    if (updateErrors && updateErrors.error && updateErrors.error.message) {
      if (code == updateErrors.error.message) {
        return t('ERROR.' + code)
      }
    }
    return ''
  }

  const getCodeError = () => {
    return errors.code ? errors.code.message : errorMsgFromBE('BE000002')
  }

  const isCodeError = () => {
    return errors.code ? true : errorExitsFromBE('BE000002')
  }

  const getNameError = () => {
    return errors.code ? errors.code.message : errorMsgFromBE('BE000001')
  }

  const isNameError = () => {
    return errors.name ? true : errorExitsFromBE('BE000001')
  }

  return (
    <ContentBox maxWidth={1200}>
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {meta.pending ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: 'rgba(255,255,255, 0.6)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 102,
            }}
          >
            <Spinner loading={meta.pending} />
          </Box>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          <Paper>
            <PaperHeader title={t('SYSCOMMON.basic_info')} />

            <Box sx={{ p: 2 }}>
              <Grid spacing={2} container>
                <Grid xs={12} md={6} item>
                  <Box sx={{ width: '100%', height: 75 }}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { ref, ...rest } }: FieldValues) => (
                        <ClassicTextField
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          label={t('SYSCOMMON.item_name')}
                          placeholder={t('SYSCOMMON.item_name')}
                          error={isNameError()}
                          helperText={getNameError()}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Box sx={{ width: '100%', height: 75 }}>
                    <Controller
                      control={control}
                      name="code"
                      render={({ field: { ref, ...rest } }) => (
                        <ClassicTextField
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          label={t('SYSCOMMON.item_code')}
                          placeholder={t('SYSCOMMON.item_code')}
                          error={isCodeError()}
                          helperText={getCodeError()}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid spacing={2} container>
                <Grid xs={12} md={6} item>
                  <Box sx={{ width: '100%', height: 75 }}>
                    {data && genre && (
                      <Controller
                        name="genre_id"
                        control={control}
                        render={({
                          field: { onChange, value },
                        }: FieldValues) => (
                          <ClassicSelect
                            label={t('SYSCOMMON.genre')}
                            value={value}
                            onChange={onChange}
                            disabled={
                              data?.attributes.status ===
                                PROCESS_STATUS.ITEM_STATUS_PUBLISHED ||
                              data?.attributes.status ===
                                PROCESS_STATUS.ITEM_STATUS_DRAFT
                            }
                            error={errors.genre_id ? true : false}
                            helperText={
                              errors.genre_id ? errors.genre_id.message : ''
                            }
                          >
                            {/* <MenuItem key={-1} value={''}>
                              <ListItemText primary={'None'} />
                            </MenuItem> */}
                            {genre
                              ? genre.map((item, k) => (
                                  <MenuItem key={k} value={item.id}>
                                    <ListItemText
                                      primary={item.attributes.name}
                                    />
                                  </MenuItem>
                                ))
                              : 'Loading'}
                          </ClassicSelect>
                        )}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Box sx={{ width: '100%', height: 75 }}>
                    <Controller
                      control={control}
                      name="price"
                      render={({
                        field: { ref, value, onChange, ...rest },
                      }) => (
                        <CurrencyInput
                          {...rest}
                          customInput={ClassicTextField}
                          ref={ref}
                          label={t('SYSCOMMON.price')}
                          inputProps={{ sx: { textAlign: 'right', pr: 1 } }}
                          placeholder={t('SYSCOMMON.price')}
                          allowNegativeValue={false}
                          value={value}
                          onValueChange={onChange}
                          endAdornment={<Box>円</Box>}
                          fullWidth
                          error={errors.price ? true : false}
                          helperText={errors.price ? errors.price.message : ''}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid spacing={2} container>
                <Grid item xs={12}>
                  <Box>
                    <Controller
                      control={control}
                      name="size"
                      render={({ field: { value, onChange } }) => (
                        <ChipArray
                          label={t('SYSCOMMON.size')}
                          value={value}
                          disabled={data?.attributes.has_design === true}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Controller
                      control={control}
                      name="descriptions"
                      render={({ field: { ref, ...rest } }) => (
                        <ClassicTextField
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          multiline
                          maxRows={8}
                          minRows={7}
                          label={t('SYSCOMMON.description')}
                          placeholder={t('SYSCOMMON.description')}
                          error={errors.price ? true : false}
                          helperText={errors.price ? errors.price.message : ''}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="thumbnail"
                      render={({ field: { ref, value } }) => (
                        <ClassicTextField
                          sx={{ display: 'none' }}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      control={control}
                      name="thumb_url"
                      render={({ field: { value } }) => (
                        <Box>
                          <Label>{t('SYSCOMMON.thumbnail')}</Label>
                          <Box
                            onClick={() => handleThumbnail()}
                            sx={{
                              width: '100%',
                              paddingTop: '76%',
                              cursor: 'pointer',
                              position: 'relative',
                              mt: 0.5,
                              border: `1px solid ${ThemeColors.primary}`,
                              background: `url('${value}') #eee`,
                              backgroundSize: 'cover',
                            }}
                          >
                            {value === null ? (
                              <AddAPhoto
                                sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  left: 0,
                                  right: 0,
                                  margin: '0 auto',
                                }}
                              />
                            ) : (
                              <Edit
                                sx={{
                                  position: 'absolute',
                                  top: '10px',
                                  right: '10px',
                                  fontSize: 14,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ mt: 2 }}>
            <PaperHeader title={t('SYSCOMMON.article')} />
            <Box sx={{ p: 2 }}>
              <Controller
                control={control}
                name="article"
                render={({ field: { value, onChange } }) => (
                  <TextEditor
                    imgMeta={imgMeta}
                    onImageOpen={() => fetchImages()}
                    imgList={imgs}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          </Paper>
          <Paper sx={{ mt: 2 }}>
            <PaperHeader title={t('SYSCOMMON.product_image')} />
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {renderImages()}
                {renderLoader()}
              </Grid>
              <Button
                sx={{ mt: 2 }}
                onClick={() => handleProductImage()}
                variant="outlined"
              >
                {t('SYSCOMMON.upload')}
              </Button>
            </Box>
          </Paper>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 2,
              display: 'flex',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                customHistory.push('/admin/items')
              }}
            >
              {t('SYSCOMMON.cancel')}
            </Button>
            <Stack direction="row" spacing={1}>
              <LoadingButton
                disabled={!isValid || !isDirty || updateMeta.pending}
                type="submit"
                loading={updateMeta.pending}
                variant="contained"
                color="secondary"
              >
                {t('SYSCOMMON.save')}
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={() => customHistory.push(`/admin/items/${id}/step/1`)}
              >
                {t('SYSCOMMON.next')}
                <ArrowForwardIos sx={{ fontSize: 14, ml: 1 }} />
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </ContentBox>
  )
}

export default ItemEdit
