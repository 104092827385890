import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/shops.services'
import { SHOPS_ACTION_TYPE } from './types'

export const shopList = createAsyncThunk<
  services.ShopsResponse,
  services.ShopsParams
>(SHOPS_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.list(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shopCreate = createAsyncThunk<
  services.ShopDetail,
  services.ShopCreateParams
>(SHOPS_ACTION_TYPE.CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shopUpdate = createAsyncThunk<
  services.ShopDetail,
  services.ShopUpdateParams
>(SHOPS_ACTION_TYPE.UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.update(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shopDetail = createAsyncThunk<services.ShopDetail, string>(
  SHOPS_ACTION_TYPE.DETAIL,
  async (id, { rejectWithValue }) => {
    try {
      const res = await services.detail(id)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const shopDelete = createAsyncThunk<
  services.ShopsResponse,
  services.ShopDeleteParams
>(SHOPS_ACTION_TYPE.DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteShop(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shopStatus = createAsyncThunk<
  services.ShopStatusResponse,
  services.ShopStatusParams
>(SHOPS_ACTION_TYPE.STATUS, async (params, { rejectWithValue }) => {
  try {
    const res = await services.status(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const shopListPublic = createAsyncThunk<
  services.ShopsResponse,
  services.ShopsParams
>(SHOPS_ACTION_TYPE.PUBLIC_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.listPublic(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchShopDetail =
  (id: string) =>
  async (dispatch: any): Promise<any> => {
    Promise.resolve(dispatch(resetDetail())).then(() =>
      dispatch(shopDetail(id))
    )
  }

export const resetDetail = createAction('shops/resetDetail')
