/* eslint-disable no-console */
import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import {
  Button,
  Checkbox,
  Pagination,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material'
import { Create, Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useAdmins from '@containers/Admins/useAdmins'
import CreateForm from '@containers/Admins/CreateForm'
import { useConfirm } from '@components/Confirm'
import DialogBase from '@components/@material-extend/DialogBase'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useEffect, useState } from 'react'
import FilterForm, { FilterParams } from '@containers/Admins/AdminsFilter'
import { AdminItem, AdminCreateParams } from '@services/admins.services'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import useAuth from 'utils/useAuth'
const Users = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const {
    paging,
    data,
    meta,
    metaCreate,
    fetch,
    deleteAdmin,
    create,
    clearCreateMeta,
  } = useAdmins()
  const confirm = useConfirm()
  const [filter, setFilter] = useState<any>({
    status: undefined,
  })
  const { user } = useAuth()
  const getSearchParams = () => {
    let searchParams = {}
    if (paging) {
      searchParams = {
        page: paging.current_page,
        per_page: paging.per_page,
        ...filter,
      }
    } else {
      searchParams = {
        page: 1,
        per_page: 20,
        ...filter,
      }
    }
    return searchParams
  }
  useEffect(() => {
    fetch(getSearchParams())
  }, [filter])

  useEffect(() => {
    clearCreateMeta()
  }, [open])

  const navigate = useNavigate()
  const isAllSelected = () => {
    if (
      data?.length &&
      data?.length > 0 &&
      selected.length > 0 &&
      selected.length == data?.length - 1 &&
      user
    ) {
      return !_.includes(selected, user.id)
    }
    return false
  }

  const handleSelectAll = () => {
    if (data?.length && data?.length > 0) {
      const allSelected = selected.length === data?.length - 1
      if (allSelected) {
        setSelected([])
      } else {
        const filtered: any = _.filter(data, (d) => {
          if (user && user.id == Number(d.id)) {
            return
          }
          return d
        })
        setSelected(_.map(filtered, (d) => Number(d.id)))
      }
    }
  }

  const handleChecked = (id: number) => {
    const exist = !!_.find(selected, (i) => i === id)
    if (exist) {
      setSelected(_.filter(selected, (s) => s !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const handleFilter = (data: FilterParams) => {
    setFilter(data)
  }

  const deleteAdmins = () => {
    confirm({
      title: t('admins.delete_confirm_title'),
      description: t('admins.delete_confirm_desc'),
    })
      .then(() => {
        const searchParams = getSearchParams()
        const currentSelected = [...selected]
        deleteAdmin({
          ids: currentSelected,
          ...searchParams,
        })
        setSelected([])
      })
      .catch(() => null)
  }

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
      ...filter,
    })
  }

  useEffect(() => {
    if (metaCreate.loaded && !metaCreate.pending && !metaCreate.error) {
      setOpen(false)
    }
  }, [metaCreate])

  const handleCreate = (params: AdminCreateParams) => {
    create(params)
  }

  const renderRow = (item: AdminItem, index: number) => {
    const data = item.attributes
    return (
      <TableRow key={index}>
        <TableCell>
          {user && user.id == Number(item.id) ? (
            ''
          ) : (
            <Checkbox
              onClick={() => handleChecked(Number(item.id))}
              checked={!!_.find(selected, (id) => id === Number(item.id))}
            />
          )}
        </TableCell>
        <TableCell
          sx={{ minWidth: 70, maxWidth: 150, wordBreak: 'break-word' }}
        >
          {data.name}
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            position: 'relative',
            minWidth: 70,
            maxWidth: 150,
            wordBreak: 'break-word',
          }}
        >
          {data.email}
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
            {user && user.id == Number(item.id) ? (
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  navigate(`/admin/users/${item.id}/password-change`)
                }
              >
                {t('admins.change_password')}
              </Button>
            ) : null}
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate(`/admin/users/${item.id}/edit`)}
            >
              {t('button.edit')}
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <DialogBase
        onClose={() => setOpen(false)}
        open={open}
        title={t('SYSCOMMON.register')}
        content={
          <CreateForm
            meta={metaCreate}
            onCreate={handleCreate}
            close={() => setOpen(false)}
          />
        }
      />
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={meta.pending}
        pagintionBox={{ sx: { pt: 2 } }}
        pagination={
          paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )
        }
        headSection={
          <TableHeadSection
            title={t('SYSCOMMON.admins_management')}
            total={paging?.total_count}
            actions={<FilterForm onFilter={handleFilter} />}
          />
        }
        filter={
          <Stack justifyContent="flex-start" direction={'row'} spacing={1}>
            <Button
              startIcon={<Delete />}
              disabled={_.isEmpty(selected) ? true : false}
              color="primary"
              variant="outlined"
              onClick={() => deleteAdmins()}
            >
              {t('SYSCOMMON.delete')}
            </Button>
            <Button
              startIcon={<Create />}
              color="secondary"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              {t('SYSCOMMON.register')}
            </Button>
          </Stack>
        }
        head={
          <TableRow>
            <TableCell width={40} align="center">
              <Checkbox checked={isAllSelected()} onClick={handleSelectAll} />
            </TableCell>
            <TableCell sx={{ minWidth: 70, maxWidth: 200 }}>
              {t('admins.name')}
            </TableCell>
            <TableCell sx={{ minWidth: 70, maxWidth: 200 }}>
              {t('admins.email')}
            </TableCell>
            <TableCell
              sx={{ minWidth: 200, width: '200px' }}
              align="right"
            ></TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default Users
