import { FieldValues } from 'react-hook-form'
import ClassicSelect from '@components/@material-extend/ClassicSelect'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { SexOptions, GenreTypeOptions } from '@constants/common.constants'
import { Button, ListItemText, Grid, MenuItem } from '@mui/material'
import React from 'react'
import i18n from '@locales/i18n'
import useFormGenre from './useFormGenre'
import { useTranslation } from 'react-i18next'
import { GenreCreateParams, GenreItem } from '@services/genre.services'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { addToast } from '@store/common/actions'
import { AlertColor } from '@mui/material'
interface AddFormProps {
  sex: any
  list: GenreItem[] | undefined
  submit: (params: GenreCreateParams) => void
}

const AddGenre: React.FC<AddFormProps> = (props) => {
  const dispatch = useDispatch()
  const { sex, list, submit } = props
  const { t } = useTranslation()
  const { Controller, methods } = useFormGenre()
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = methods

  const isExist = (name: string) => {
    if (list) {
      const filtered = _.filter(list, (o) => o.attributes.name === name)
      return filtered.length > 0
    }
    return false
  }

  const onSubmit = (values: any) => {
    const formData = { ...values }
    if (isExist(formData.name)) {
      setError('name', { type: 'custom' })
      dispatch(
        addToast({
          message: i18n.t('ERROR.BE000011'),
          severity: 'warning' as AlertColor,
        })
      )
      return
    }
    formData.filter = sex
    submit(formData)
    reset()
  }

  return (
    <Grid spacing={1} container sx={{ width: '100%' }}>
      <Grid xs={3} item>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <ClassicTextField
              {...rest}
              fullWidth
              inputRef={ref}
              placeholder={t('genre.genre_name')}
              error={errors.name ? true : false}
            />
          )}
        />
      </Grid>
      <Grid xs={3} item>
        <Controller
          control={control}
          name="sex"
          render={({ field: { onChange, value } }) => (
            <ClassicSelect value={value} onChange={onChange} fullWidth>
              {SexOptions.filter(function (el) {
                return el.value != ''
              }).map((item, k) => (
                <MenuItem key={k} value={item.value}>
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </ClassicSelect>
          )}
        />
      </Grid>
      <Grid xs={3} item>
        <Controller
          control={control}
          name="genre_type"
          render={({ field: { onChange, value } }) => (
            <ClassicSelect
              value={value}
              onChange={onChange}
              fullWidth
              required={true}
            >
              {GenreTypeOptions.map((item, k) => (
                <MenuItem key={k} value={item.value}>
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </ClassicSelect>
          )}
        />
      </Grid>
      <Grid xs={3} item>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {i18n.t('button.create')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddGenre
