import * as React from 'react'
import { PartItem } from '@services/item.services'
import {
  Stack,
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Box,
  Button,
  ListItemAvatar,
  ListItem,
  PaperProps,
} from '@mui/material'
import i18n from '@locales/i18n'
import _ from 'lodash'
import { Visibility } from '@mui/icons-material'
import DraggHandle from './DraggHandle'
import { ThemeColors } from '@theme/colors'

interface PartProps extends PaperProps {
  data: PartItem
  onDelete?: (id: string) => void
  onEdit?: (item: PartItem) => void
  dragHandleProps: any
  onView?: (svg: string) => void
  isEdit: boolean
  showEditable: boolean
}

const Part: React.FC<PartProps> = ({
  data,
  onDelete,
  onEdit,
  onView,
  showEditable,
  isEdit,
  dragHandleProps,
  ...rest
}) => {
  const { attributes } = data
  return (
    <Box
      sx={{
        listStyle: 'none',
        boxShadow: 1,
        border: isEdit ? `1px solid ${ThemeColors.secondary}` : '0 none',
        boxSizing: 'border-box',
        mb: 1,
      }}
      {...rest}
    >
      <ListItem sx={{ padding: 1, listStyle: 'none', listStyleType: 'none' }}>
        <ListItemAvatar>
          <DraggHandle {...dragHandleProps} />
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            <Typography
              variant="body1"
              sx={{ mr: 1, top: -1, position: 'relative', color: '#666' }}
              component="span"
            >
              #{attributes.part_order}
            </Typography>
            {attributes.name}
          </Typography>
          <Stack direction="row" spacing={2}>
            {!_.isEmpty(attributes.front_url) ? (
              <Chip
                size="small"
                icon={<Visibility />}
                sx={{ fontSize: 11 }}
                color="primary"
                onClick={() =>
                  onView && attributes.front_url && onView(attributes.front_url)
                }
                label={i18n.t('SYSCOMMON.front_path')}
              />
            ) : (
              <Chip
                size="small"
                icon={<Visibility />}
                sx={{ fontSize: 11, opacity: 0 }}
                color="primary"
                label={i18n.t('SYSCOMMON.front_path')}
              />
            )}
            {!_.isEmpty(attributes.back_url) ? (
              <Chip
                icon={<Visibility />}
                sx={{ fontSize: 11 }}
                size="small"
                color="secondary"
                onClick={() =>
                  onView && attributes.back_url && onView(attributes.back_url)
                }
                label={i18n.t('SYSCOMMON.back_path')}
              />
            ) : (
              <Chip
                size="small"
                icon={<Visibility />}
                sx={{ fontSize: 11, opacity: 0 }}
                color="primary"
                label={i18n.t('SYSCOMMON.back_path')}
              />
            )}
            {attributes.is_editable ? (
              <Chip
                sx={{ fontSize: 11 }}
                size="small"
                color="info"
                label={i18n.t('part.is_editable')}
              />
            ) : (
              <Chip
                sx={{ fontSize: 11, opacity: 0 }}
                size="small"
                color="info"
                label={i18n.t('part.is_editable')}
              />
            )}
          </Stack>
        </ListItemText>
        {showEditable && (
          <ListItemSecondaryAction>
            <Stack direction="row" spacing={1}>
              {!isEdit ? (
                <Button
                  variant="outlined"
                  onClick={() => onEdit && onEdit(data)}
                >
                  <Typography>{i18n.t('SYSCOMMON.edit')}</Typography>
                </Button>
              ) : null}
              <Button
                variant="outlined"
                color="error"
                onClick={() => onDelete && onDelete(data.id)}
              >
                <Typography color={'error'}>
                  {i18n.t('SYSCOMMON.delete')}
                </Typography>
              </Button>
            </Stack>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </Box>
  )
}

export default Part
