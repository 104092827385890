import { Meta } from '@store/metadata/actions/types'
import { FontItem, FontUpdateParams } from '@services/fonts.services'
import { fontUpdate, fontDetail, fontFilesUpdate } from '@store/fonts/actions'
import { detail } from '@store/fonts/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  data?: FontItem
  metaUpdate: Meta
  fetch: (id: string) => void
  update: (params: FontUpdateParams) => void
  updateFiles: (params: FontUpdateParams) => void
}

const useFontEdit = (): ReturnType => {
  const data = useAppSelector(detail)
  const dispatch = useDispatch()
  const fetch = (id: string) => {
    dispatch(fontDetail(id))
  }

  const update = (params: FontUpdateParams) => {
    dispatch(fontUpdate(params))
  }

  const updateFiles = (params: FontUpdateParams) => {
    dispatch(fontFilesUpdate(params))
  }

  const _metaCreate = createMetaSelector(fontUpdate)
  const metaUpdate = useAppSelector(_metaCreate)

  return {
    data,
    metaUpdate,
    fetch,
    update,
    updateFiles,
  }
}

export default useFontEdit
