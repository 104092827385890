import { useFieldArray, useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { MarkingItem } from '@services/item.services'
import { DesignDetailItem } from '@services/design.services'
import i18n from '@locales/i18n'
import { MARKING_TYPE } from '@constants/common.constants'

export const initialValues = {
  orders: {
    tops_size: '',
    bottoms_size: '',
    marking_values: [],
  },
}

const isNumber = (value: string): boolean => {
  if (typeof value === 'string') {
    return !isNaN(value as any)
  }
  return false
}

const useDesignForm = (
  top?: DesignDetailItem,
  bottom?: DesignDetailItem,
  allMarkings?: MarkingItem[]
) => {
  const validationSchema = Yup.object().shape({
    orders: Yup.array().of(
      Yup.object().shape({
        tops_size: Yup.string().when([], {
          is: () => !!top === true && !_.isEmpty(top?.attributes.size),
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        bottoms_size: Yup.string().when([], {
          is: () => !!bottom === true && !_.isEmpty(bottom?.attributes.size),
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        marking_values: Yup.array()
          .nullable()
          .test('max_size', i18n.t('ERROR.max_reached'), (value: any): any => {
            let isValid: any = true
            value?.forEach(
              (element: {
                id: number
                value: string
                item_marking_id: number
              }) => {
                const find = _.find(allMarkings, {
                  id: element.item_marking_id?.toString(),
                })
                // eslint-disable-next-line no-console

                if (find && element) {
                  // eslint-disable-next-line no-console
                  if (find.attributes.max_size >= element.value.length) {
                    isValid = true
                  } else {
                    isValid = false
                  }
                } else {
                  isValid = true
                }
              }
            )
            return isValid
          })
          .test(
            'input_type',
            i18n.t('ERROR.should_be_number'),
            (value: any): any => {
              let isValid: any = true
              value?.forEach(
                (element: {
                  id: number
                  value: string
                  item_marking_id: number
                }) => {
                  const find = _.find(allMarkings, {
                    id: element.item_marking_id?.toString(),
                  })

                  if (find && element) {
                    // eslint-disable-next-line no-console
                    if (
                      find.attributes.marking_type === MARKING_TYPE.NUMBER &&
                      !isNumber(element.value)
                    ) {
                      isValid = false
                    } else {
                      isValid = true
                    }
                  } else {
                    isValid = true
                  }
                }
              )
              return isValid
            }
          ),
      })
    ),
  })
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  }

  // functions to build form returned by useForm() and useFieldArray() hooks
  const { register, control, handleSubmit, reset, formState, watch, trigger } =
    useForm(formOptions as any)
  const { errors } = formState as any
  const { fields, append, remove } = useFieldArray({ name: 'orders', control })

  return {
    fields,
    append,
    remove,
    Controller,
    errors,
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    watch,
  }
}

export default useDesignForm
