import Snackbar from '@mui/material/Snackbar'
import { AlertColor } from '@mui/material/Alert'
import React from 'react'
import { getToasts } from '@store/common/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { removeToast } from '@store/common/actions'
import MuiAlert from '@mui/material/Alert'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import customHistory from 'customHistory'
import { CheckCircleOutline } from '@mui/icons-material'

export type toastProps = {
  message: string
  severity: AlertColor | undefined
  uuid: string
  isDialog?:
    | {
        url?: string
      }
    | undefined
}

const SnackBarProvider: React.FC = () => {
  const dispatch = useDispatch()
  const toasts = useSelector(getToasts)
  const remove = (uuid: string) => dispatch(removeToast(uuid))
  return (
    <>
      {toasts &&
        toasts.map((t: toastProps, idx: number) => {
          return t.isDialog === undefined ? (
            <Snackbar
              key={idx}
              open={true}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={4000}
              onClose={() => remove(t.uuid)}
            >
              <MuiAlert
                elevation={2}
                sx={{
                  lineHeight: 1.8,
                  '& .MuiButtonBase-root': {
                    border: '0 none',
                  },
                }}
                onClose={() => remove(t.uuid)}
                severity={t.severity}
              >
                {t.message}
              </MuiAlert>
            </Snackbar>
          ) : (
            <Dialog
              key={t.uuid}
              open={true}
              onClose={() => remove(t.uuid)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent sx={{ minWidth: '260px', width: '100%' }}>
                <Box sx={{ textAlign: 'center', py: 1, mb: 1 }}>
                  <CheckCircleOutline sx={{ fontSize: 50 }} color="success" />
                </Box>
                <DialogContentText
                  textAlign={'center'}
                  id="alert-dialog-description"
                  variant="h5"
                >
                  {t.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    remove(t.uuid)
                    if (t.isDialog && t.isDialog.url) {
                      customHistory.push(`${t.isDialog.url}`)
                    }
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )
        })}
    </>
  )
}

export { SnackBarProvider }
