import { LayoutContants } from '@constants/layout.constants'
import { mainmenu } from '@constants/menu.constants'
import { Box } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import Footer from './elements/Footer'
import Header from './elements/Header'
import SideDrawer from './elements/SideDrawer'

export type MainLayoutProps = {
  children: ReactNode
  headerShow?: boolean
  isFooter?: boolean
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  headerShow = true,
  isFooter = true,
}) => {
  const [drawer, setDrawer] = useState<boolean>(false)
  const height = use100vh()
  const innerHeight = height ? height - 60 : '100vh'
  return (
    <Box>
      {headerShow && (
        <Header open={drawer} setOpen={setDrawer} data={mainmenu} />
      )}
      <Box
        component="main"
        sx={{
          minHeight: `${innerHeight}px`,
          height: '100%',
          width: '100%',
          borderRadius: 0,
          paddingTop: {
            md: `${LayoutContants.appbarHeight}px`,
            sm: `${LayoutContants.appbarHeight + 98}px`,
            xs: `${LayoutContants.appbarHeight + 98}px`,
          },
        }}
      >
        {children}
      </Box>
      {isFooter ? <Footer /> : ''}
      <SideDrawer open={drawer} onClose={() => setDrawer(false)} />
    </Box>
  )
}

export default MainLayout
