import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  last_name: '',
  first_name: '',
  email: '',
  phone: '',
  company_name: '',
  body: '',
  captchaToken: '',
  confirm: false,
}

const useInquiryForm = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        last_name: Yup.string()
          .max(
            255,
            t('ERROR.E000002', {
              field: t('inquiry.first_name'),
              character: 50,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('inquiry.first_name'),
            })
          ),
        first_name: Yup.string()
          .max(
            255,
            t('ERROR.E000002', {
              field: t('inquiry.last_name'),
              character: 50,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('inquiry.last_name'),
            })
          ),
        email: Yup.string()
          .email(t('ERROR.E000004'))
          .max(
            255,
            t('ERROR.E000002', {
              field: t('inquiry.email'),
              character: 100,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('SYSCOMMON.email'),
            })
          ),
        phone: Yup.string()
          .max(
            50,
            t('ERROR.E000002', {
              field: t('inquiry.phone'),
              character: 50,
            })
          )
          .nullable(),
        company_name: Yup.string()
          .max(
            100,
            t('ERROR.E000002', {
              field: t('inquiry.company_name'),
              character: 100,
            })
          )
          .nullable(),
        body: Yup.string()
          .max(
            255,
            t('ERROR.E000002', {
              field: t('inquiry.body'),
              character: 255,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('inquiry.body'),
            })
          ),
        captchaToken: Yup.string().required(
          t('ERROR.E000001', {
            field: t('SYSCOMMON.captcha'),
          })
        ),
        confirm: Yup.boolean()
          .required(
            t('ERROR.E000001', {
              field: t('SYSCOMMON.reqiured'),
            })
          )
          .oneOf([true], t('SYSCOMMON.reqiured')),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useInquiryForm
