import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'
import {
  AdminItem,
  AdminCreateParams,
  AdminUpdateParams,
  AdminsParams,
  AdminDeleteParams,
} from '@services/admins.services'
import {
  adminList,
  adminCreate,
  adminUpdate,
  adminDelete,
} from '@store/admins/actions'
import { ADMINS_ACTION_TYPE } from '@store/admins/actions/types'
import { list, pagemeta } from '@store/admins/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
import { clearMetaData } from '@store/metadata/actions'
interface ReturnType {
  data?: AdminItem[]
  paging: MetaPage
  meta: Meta
  metaCreate: Meta
  fetch: (params: AdminsParams) => void
  create: (params: AdminCreateParams) => void
  update: (params: AdminUpdateParams) => void
  deleteAdmin: (params: AdminDeleteParams) => void
  clearCreateMeta: () => void
}

const useAdmins = (): ReturnType => {
  const data = useAppSelector(list)
  const paging = useAppSelector(pagemeta)
  const dispatch = useDispatch()
  const fetch = (params: AdminsParams) => {
    dispatch(adminList(params))
  }
  const create = (params: AdminCreateParams) => {
    dispatch(adminCreate(params))
  }
  const update = (params: AdminUpdateParams) => {
    dispatch(adminUpdate(params))
  }

  const deleteAdmin = (params: AdminDeleteParams) => {
    dispatch(adminDelete(params))
  }

  const _meta = createMetaSelector(adminList)
  const meta = useAppSelector(_meta)
  const _metaCreate = createMetaSelector(adminCreate)
  const metaCreate = useAppSelector(_metaCreate)
  const clearCreateMeta = () => {
    dispatch(clearMetaData(ADMINS_ACTION_TYPE.CREATE))
  }
  return {
    fetch,
    paging,
    meta,
    data,
    create,
    update,
    deleteAdmin,
    metaCreate,
    clearCreateMeta,
  }
}

export default useAdmins
