import PartPreview from '@components/@admin/Part/PartPreview'
import Spinner from '@components/@material-extend/Spinner'
import { useConfirm } from '@components/Confirm'
import { MARKING_TYPE } from '@constants/common.constants'
import { Side } from '@constants/editor.constants'
import useFonts from '@containers/Fonts/useFonts'
import useItemList from '@containers/Items/useItemList'
import MarkingUpsert, { ClearForm } from '@containers/Markings/MarkingUpsert'
import useMarkingForm from '@containers/Markings/useMarkingForm'
import StepTitle from '@containers/Parts/StepTitle'
import useParts from '@containers/Parts/useParts'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import { Add } from '@mui/icons-material'
import {
  Box,
  ListItem,
  ListItemText,
  Stack,
  Button,
  ButtonGroup,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { MarkingEdit, MarkingItem, PositionItem } from '@services/item.services'
import { ThemeColors } from '@theme/colors'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
import _ from 'lodash'

import { useEffect, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const StepThree = () => {
  const [maxSize, setMaxSize] = useState<number>(10)

  const { methods, Controller } = useMarkingForm(maxSize)

  const {
    control,
    trigger,
    watch,
    formState: { isDirty },
  } = methods

  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)

  const maxSizeChange = watch('max_size')

  useEffect(() => {
    setMaxSize(maxSizeChange)
  }, [maxSizeChange])

  useEffect(() => {
    trigger('sample_text')
  }, [maxSize])

  const watchFields: any = useWatch({
    control,
    name: [
      'sample_text',
      'font_size',
      'colors',
      'marking_type',
      'fill',
      'family',
      'positions',
      'side',
    ],
  })

  const { id } = useParams()
  const [side, onChangeSide] = useState(Side.Front)
  const [color, onChange] = useState<string>('')
  const [selected, setSelected] = useState<MarkingItem | null>(null)
  const [mode, setMode] = useState<'create' | 'edit' | 'hidden'>('create')
  const [position, setPositionChange] = useState<
    Omit<PositionItem, 'id' | 'side'>
  >({
    x: 0,
    y: 0,
    isActive: true,
  })
  const [data, setData] = useState<MarkingItem[] | undefined>(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])

  const sideField = watch('side')

  useEffect(() => {
    onChangeSide(sideField as Side)
  }, [sideField])

  const selectedMarking =
    selected && mode === 'edit' && data
      ? _.filter(data, { id: selected.id })
      : data

  useEffect(() => {
    if (selected && mode === 'edit') {
      onChange(_.get(selected, 'attributes.settings.color.fill', ''))
    } else {
      onChange('')
    }
  }, [selected])

  useEffect(() => {
    onChangeSide(sideField as Side)
  }, [sideField])

  const childRef = useRef<ClearForm>(null)
  const { complete } = useItemList()
  const {
    fetch,
    item,
    fetchMarkings,
    fetchDetail,
    markings,
    list,
    createMarking,
    updateMarking,
    meta,
    metaItem,
    deleteMarking,
  } = useParts()
  const { fetch: fetchFonts, data: fonts } = useFonts()
  const showEditable = !item?.attributes?.has_design

  useEffect(() => {
    setData(markings)
    // eslint-disable-next-line no-console

    if (!showEditable) {
      if (markings && markings.length > 0) {
        setSelected(markings[0])
        setMode('edit')
      } else {
        setMode('hidden')
      }
    }
  }, [markings])

  useEffect(() => {
    if (id) {
      fetch(id)
      fetchDetail(id)
      fetchMarkings(id)
      fetchFonts({ per_page: 500 })
    }
  }, [])

  const handleCreate = () => {
    if (isDirty) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          setMode('create')
          setSelected(null)
        })
        .catch(() => null)
    } else {
      setMode('create')
      setSelected(null)
    }
  }

  const confirm = useConfirm()

  useEffect(() => {
    if (mode === 'edit' && data && selected) {
      if (_.get(watchFields, '[0]', '') !== MARKING_TYPE.LOGO) {
        setData(
          _.map(data, (d) =>
            d.id === selected?.id
              ? {
                  ...d,
                  attributes: {
                    ...d.attributes,
                    marking_type: Number(watchFields[3]),
                    settings: {
                      ...d.attributes.settings,
                      sample_text: watchFields[0],
                      color: {
                        values: watchFields[2],
                        fill: watchFields[4],
                      },
                      positions: {
                        values: watchFields[6],
                        side: watchFields[7] as Side,
                      },
                      font_size: watchFields[1],
                      family: watchFields[5],
                    },
                  },
                }
              : d
          )
        )
      }
    } else if (mode === 'create' && data) {
      const newItem: MarkingItem = {
        id: 'temp',
        type: 'item_marking',
        attributes: {
          name: 'temp',
          item_id: Number(id),
          marking_order: 1,
          is_common: false,
          additional_price: 0,
          max_size: 1000,
          marking_type: Number(watchFields[3]),
          settings: {
            sample_text: watchFields[0],
            color: {
              values: watchFields[2],
              fill: watchFields[4],
            },
            font_size: watchFields[1],
            positions: {
              values: watchFields[6],
              side: watchFields[7] as Side,
            },
            family: watchFields[5],
          },
        },
      }
      setData(
        _.isEmpty(data)
          ? [newItem]
          : _.find(data, { id: 'temp' })
          ? _.map(data, (d) => (d.id === 'temp' ? newItem : d))
          : [...data, newItem]
      )
    }
  }, [watchFields])

  const handleSubmit = (values: any) => {
    let params: MarkingEdit
    if (Number(values.marking_type) === MARKING_TYPE.LOGO) {
      params = {
        name: values.name,
        additional_price: values.additional_price,
        is_common: Number(values.is_common),
        max_size: values.max_size,
        marking_type: Number(values.marking_type),
        settings: {
          positions: {
            values: values.positions,
            side: values.side,
          },
        },
      }
    } else {
      params = {
        name: values.name,
        additional_price: values.additional_price,
        is_common: Number(values.is_common),
        max_size: values.max_size,
        marking_type: Number(values.marking_type),
        settings: {
          positions: {
            values: values.positions,
            side: values.side,
          },
          fonts: values.fonts,
          family: values.family,
          sample_text: values.sample_text,
          color: {
            fill: values.fill,
            values: values.colors,
          },
          font_size: values.font_size,
        },
      }
    }

    if (mode === 'create') {
      if (id) {
        createMarking({
          id: id,
          values: params,
        })
        childRef.current && childRef.current.resetForm()
      }
    } else {
      if (id && selected) {
        updateMarking({
          id: id,
          values: params,
          marking_id: selected.id,
        })
      }
    }
  }

  const handleSelectionChange = (p: MarkingItem) => {
    if (isDirty) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          setSelected(p)
          setMode('edit')
        })
        .catch(() => null)
    } else {
      setSelected(p)
      setMode('edit')
    }
  }

  const handleDelete = (marking_id: string) => {
    confirm({
      title: i18n.t('marking.delete_confirm_title'),
      description: i18n.t('marking.delete_confirm_desc'),
    })
      .then(() => {
        if (id) {
          deleteMarking({
            id: id,
            marking_id: marking_id,
          })
          setSelected(null)
          setMode('create')
        }
      })
      .catch(() => null)
  }

  const handleBack = () => {
    navigate(`/admin/items/${id}/step/2`)
  }

  const handleSave = () => {
    if (isDirty) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          navigate(`/admin/items`)
        })
        .catch(() => null)
    } else {
      navigate(`/admin/items`)
    }
    if (id) complete(id)
  }

  const renderLoader = () => {
    if (metaItem.pending && !item && !metaItem.error && !metaItem.loaded) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 600,
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  const renderLoaderParts = () => {
    if (meta.pending && !metaItem.error && !metaItem.loaded) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 400,
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  const positionInputFill = (x: number, y: number) => {
    setPositionChange({ ...position, x, y })
  }

  return (
    <ContentBox>
      {renderLoader()}
      {id && item && <StepTitle data={item} id={id} step={3} />}
      {item && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            spacing={2}
          >
            <Box sx={{ minWidth: '350px' }}>
              <Box>
                {renderLoaderParts()}
                {list && (
                  <>
                    <PartPreview
                      fonts={fonts}
                      data={list}
                      mode={mode}
                      activePosition={position}
                      onPositionSelect={positionInputFill}
                      positionMode={true}
                      side={side}
                      activeColorMarking={_.isEmpty(color) ? null : color}
                      marking={selectedMarking}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={() => onChangeSide(Side.Front)}
                          disableRipple
                          className={side === Side.Front ? 'is-active' : ''}
                          variant="outlined"
                          sx={{
                            color: '#111',
                            borderColor: '#999',
                            '&:hover': {
                              borderColor: '#aaa',
                            },
                            '&.is-active': {
                              color: ThemeColors.secondary,
                            },
                          }}
                        >
                          {i18n.t('SYSCOMMON.front')}
                        </Button>
                        <Button
                          onClick={() => onChangeSide(Side.Back)}
                          disableRipple
                          className={side === Side.Back ? 'is-active' : ''}
                          sx={{
                            color: '#111',
                            borderColor: '#999',
                            '&.is-active': {
                              color: ThemeColors.secondary,
                            },
                            '&:hover': {
                              borderColor: '#aaa',
                            },
                          }}
                          variant="outlined"
                        >
                          {i18n.t('SYSCOMMON.back')}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'column',
                  xl: 'row',
                }}
                spacing={2}
              >
                <Box sx={{ minWidth: 200 }}>
                  {showEditable && (
                    <ListItem
                      className={mode === 'create' ? 'is-active' : ''}
                      onClick={() => handleCreate()}
                      sx={{
                        boxShadow: 1,
                        cursor: 'pointer',
                        '&.is-active': {
                          background: ThemeColors.secondary,
                          color: '#fff',
                        },
                        '&.is-active .MuiListItemIcon-root': {
                          color: '#fff',
                        },
                      }}
                    >
                      <ListItemText>{i18n.t('SYSCOMMON.create')}</ListItemText>
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <Add />
                      </ListItemIcon>
                    </ListItem>
                  )}
                  {markings &&
                    markings.map((p: MarkingItem, i: number) => {
                      return (
                        <ListItem
                          className={p.id === selected?.id ? 'is-active' : ''}
                          onClick={() => {
                            handleSelectionChange(p)
                          }}
                          sx={{
                            boxShadow: 1,
                            cursor: 'pointer',
                            '&.is-active': {
                              background: '#efefef',
                            },
                          }}
                          key={i}
                        >
                          <ListItemText>
                            <Typography noWrap>{p.attributes.name}</Typography>
                          </ListItemText>
                        </ListItem>
                      )
                    })}
                </Box>
                <Box sx={{ width: '100%' }}>
                  {mode !== 'hidden' && (
                    <MarkingUpsert
                      position={position}
                      onPositionChange={setPositionChange}
                      ref={childRef}
                      setColor={onChange}
                      color={color}
                      methods={methods}
                      Controller={Controller}
                      onDelete={handleDelete}
                      onSubmit={handleSubmit}
                      showEditable={showEditable}
                      mode={mode}
                      data={
                        selected ? _.find(markings, { id: selected.id }) : null
                      }
                      fonts={fonts}
                    />
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>

          <Stack
            direction="row"
            justifyContent={'space-between'}
            sx={{ width: '100%', mt: 2 }}
          >
            <Button variant="outlined" onClick={() => handleBack()}>
              {i18n.t('part.back_step')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSave()}
            >
              {i18n.t(showEditable ? 'part.save_and_finish' : 'part.finish')}
            </Button>
          </Stack>
        </>
      )}
    </ContentBox>
  )
}

export default StepThree
