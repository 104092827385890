import { SettingItem, SettingCreateParams } from '@services/settings.services'
import { setting, settingsSave } from '@store/settings/actions'
import { settingsData } from '@store/settings/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'

interface ReturnType {
  data?: SettingItem
  fetch: () => void
  save: (params: SettingCreateParams) => void
}

const useSystemSettings = (): ReturnType => {
  const data = useAppSelector(settingsData)
  const dispatch = useDispatch()
  const fetch = () => {
    dispatch(setting())
  }
  const save = (params: SettingCreateParams) => {
    dispatch(settingsSave(params))
  }

  return {
    fetch,
    data,
    save,
  }
}

export default useSystemSettings
