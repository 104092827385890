export enum FONTS_ACTION_TYPE {
  LIST = 'fonts/list',
  ACTIVE_LIST = 'fonts/active/list',
  CREATE = 'fonts/create',
  UPDATE = 'fonts/update',
  UPDATE_FILES = 'fonts/update/files',
  DETAIL = 'fonts/detail',
  DELETE = 'fonts/delete',
  STATUS = 'fonts/status',
}
