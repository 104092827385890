import { Box, Card, CardContent, Stack, Typography } from '@mui/material/'
import { ThemeColors } from '@theme/colors'

export type CategoryItem = {
  label: string
  id: number
  image: string
}

export type CategorySelectorProps = {
  value?: number
  onChange: (id: number) => void
  data: CategoryItem[]
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  onChange,
  value,
  data,
}) => {
  return (
    <Stack direction={'row'} spacing={2}>
      {data?.map((c, i) => {
        return (
          <Card
            className={`${value === c.id ? 'selected' : ''} `}
            key={i}
            sx={{
              maxWidth: '100%',
              width: '100%',
              '&.selected': {
                boxShadow: 3,
                border: `2px solid ${ThemeColors.primary}`,
                overflow: 'hidden',
              },
            }}
            onClick={() => onChange(c.id)}
          >
            <CardContent
              sx={{
                position: 'relative',
                height: 95,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  display: {
                    lg: 'block',
                    md: 'none',
                    sm: 'none',
                    xs: 'none',
                  },
                  position: 'absolute',
                  left: 30,
                  overflow: 'hidden',
                  bottom: -5,
                }}
              >
                <Box
                  component="img"
                  src={c.image}
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Box>
              <Typography
                variant="h1"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  mt: {
                    lg: 2,
                    sm: 0,
                    md: 0,
                    xs: 0,
                  },
                  mb: {
                    lg: 2,
                    sm: 0,
                    md: 0,
                    xs: 0,
                  },
                }}
                component="div"
              >
                {c.label}
              </Typography>
            </CardContent>
          </Card>
        )
      })}
    </Stack>
  )
}

export default CategorySelector
