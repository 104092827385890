/* eslint-disable no-console */
import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { ImageItem, Item, MarkingItem, PartItem } from '@services/item.services'
import { MetaPage } from '@constants/common.constants'
import _ from 'lodash'

type StateType = {
  list?: Item[]
  meta?: MetaPage
  detail?: Item
  imageList?: ImageItem[]
  partList?: PartItem[]
  markingList?: MarkingItem[]
  tops?: Item[]
  bottoms?: Item[]
  publicDetail?: Item
  deniedIds?: number[]
  updateError?: any
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  detail: undefined,
  imageList: undefined,
  partList: undefined,
  markingList: undefined,
  tops: undefined,
  bottoms: undefined,
  publicDetail: undefined,
  updateError: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.itemList.fulfilled, (state, action) => {
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.itemDetail.fulfilled, (state, action) => {
    state.detail = action.payload.data
    state.updateError = undefined
  })
  builder.addCase(actions.itemUpdate.fulfilled, (state, action) => {
    state.detail = action.payload.data
    state.updateError = undefined
  })
  builder.addCase(actions.itemUpdate.rejected, (state, action) => {
    state.updateError = action.payload
  })
  builder.addCase(actions.resetDetail, (state) => {
    state.detail = undefined
    state.publicDetail = undefined
  })
  builder.addCase(actions.imageList.fulfilled, (state, action) => {
    state.imageList = action.payload.data
  })
  builder.addCase(actions.imageDelete.fulfilled, (state, action) => {
    state.imageList = _.filter(
      state.imageList,
      (i) => i.id !== action.meta.arg.image_id
    )
  })
  builder.addCase(actions.imageCreate.fulfilled, (state, action) => {
    state.imageList = state.imageList
      ? [...state.imageList, action.payload.data]
      : [action.payload.data]
  })
  builder.addCase(actions.partList.fulfilled, (state, action) => {
    state.partList = action.payload.data
  })
  builder.addCase(actions.partCreate.fulfilled, (state, action) => {
    state.partList = action.payload.data
  })
  builder.addCase(actions.partCreate.pending, (state) => {
    state.detail = state.detail
      ? {
          ...state.detail,
          attributes: {
            ...state.detail?.attributes,
            current_step:
              state.detail.attributes.current_step === 1
                ? 2
                : state.detail.attributes.current_step,
          },
        }
      : undefined
  })
  builder.addCase(actions.partDelete.fulfilled, (state, action) => {
    state.partList = state.partList
      ? _.filter(state.partList, (p) => p.id != action.meta.arg.part_id)
      : []
  })
  builder.addCase(actions.partOrderChange.fulfilled, (state, action) => {
    state.partList = state.partList ? action.payload.data : state.partList
  })
  builder.addCase(actions.partUpdate.fulfilled, (state, action) => {
    state.partList = state.partList
      ? _.map(state.partList, (p) =>
          p.id === action.payload.data.id ? action.payload.data : p
        )
      : state.partList
  })
  builder.addCase(actions.markingList.fulfilled, (state, action) => {
    state.markingList = action.payload.data
  })
  builder.addCase(actions.markingCreate.fulfilled, (state, action) => {
    state.markingList = state.markingList
      ? [...state.markingList, action.payload.data]
      : [action.payload.data]
  })
  builder.addCase(actions.markingDelete.fulfilled, (state, action) => {
    state.markingList = state.markingList
      ? _.filter(state.markingList, (p) => p.id != action.meta.arg.marking_id)
      : []
  })
  builder.addCase(actions.markingUpdate.fulfilled, (state, action) => {
    state.markingList = state.markingList
      ? _.map(state.markingList, (p) =>
          p.id === action.payload.data.id ? action.payload.data : p
        )
      : state.markingList
  })
  // builder.addCase(actions.itemStatus.fulfilled, (state, action) => {
  //   state.list =
  //     state.list !== undefined
  //       ? _.map(state.list, (obj: Item) => {
  //           if (action.meta.arg.ids.includes(Number(obj.id))) {
  //             return {
  //               ...obj,
  //               attributes: {
  //                 ...obj.attributes,
  //                 status: action.meta.arg.status,
  //               },
  //             }
  //           } else {
  //             return obj
  //           }
  //         })
  //       : undefined
  // })

  builder.addCase(actions.fetchTopList.fulfilled, (state, action) => {
    state.tops = action.payload.tops.data
    state.bottoms = action.payload.bottoms.data
  })

  builder.addCase(actions.publicItem.fulfilled, (state, action) => {
    state.publicDetail = action.payload.data
  })
  builder.addCase(actions.itemStatus.fulfilled, (state, action) => {
    state.deniedIds = action.payload.denied_ids
  })
  builder.addCase(actions.resetDeniedIds, (state) => {
    state.deniedIds = undefined
  })
})
