import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import React, { useState } from 'react'
import { Box, Checkbox, Stack, CircularProgress, Paper } from '@mui/material'
import { t } from 'i18next'

const CaptchaButton = ({
  onVerifyCaptcha,
  onError,
}: {
  onVerifyCaptcha: (token: string) => void
  onError?: (err: any) => void
}) => {
  const [valid, setValid] = useState<{
    loading: boolean
    verify: boolean
  }>({
    loading: false,
    verify: false,
  })
  const { executeRecaptcha } = useGoogleReCaptcha()

  const clickHandler = async () => {
    if (!executeRecaptcha || valid.verify) {
      return
    }
    setValid({ loading: true, verify: false })
    try {
      const token = await executeRecaptcha('contact')
      onVerifyCaptcha(token)
      if (token) setValid({ loading: false, verify: true })
    } catch (err) {
      setValid({
        loading: false,
        verify: false,
      })
      onError && onError(err)
    }
  }

  return (
    <Paper>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems={'center'}
        pr={1}
      >
        {!valid.loading ? (
          <Checkbox
            checked={valid.verify}
            readOnly={valid.verify}
            color={'success'}
            onClick={clickHandler}
          />
        ) : (
          <CircularProgress size={14} sx={{ ml: 1 }} />
        )}
        {t('SYSCOMMON.im_not_robot')}
        <Box sx={{ width: 40 }}>
          <img style={{ width: '100%', height: 'auto' }} src="/recaptcha.png" />
        </Box>
      </Stack>
    </Paper>
  )
}

interface ReCaptchaProps {
  onVerifyCaptcha: (token: string) => void
}

export const ReCaptcha: React.FC<ReCaptchaProps> = ({ onVerifyCaptcha }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={
      process.env.REACT_APP_GOOGLE_RECAPTCH_V3
        ? process.env.REACT_APP_GOOGLE_RECAPTCH_V3
        : '6LdCg3gkAAAAAEumtfVNML3PnG1AKFEQKAhLCj8o'
    }
    language="ja"
  >
    <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
  </GoogleReCaptchaProvider>
)
