import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { GenreItem } from '@services/genre.services'

type StateType = {
  list?: GenreItem[]
  meta?: MetaPage
}

const initialState: StateType = {
  list: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.genreList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
  })
  builder.addCase(actions.genreCreate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
  })
  builder.addCase(actions.genreUpdate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
  })
  builder.addCase(actions.genreDelete.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
  })
})
