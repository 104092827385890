import ClassicTextField from '@components/@material-extend/ClassicTextField'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useParams } from 'react-router-dom'
import { FieldValues } from 'react-hook-form'
import useAdminEdit from '@containers/Admins/useAdminEdit'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Stack,
  Button,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material'
import PaperHeader from '@components/PaperHeader'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useConfirm } from '@components/Confirm'
import customHistory from 'customHistory'
import _ from 'lodash'
import useAuth from '@utils/useAuth'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import useFormPasswordChange from '@containers/Admins/useFormPasswordChange'
import i18n from '@locales/i18n'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
const PasswordChange = () => {
  const { id } = useParams()
  const { update, metaUpdate } = useAdminEdit()
  const { Controller, methods } = useFormPasswordChange()
  const [isMe, setIsMe] = useState<boolean>(false)
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)
  const { user } = useAuth()

  useEffect(() => {
    reset({
      new_password: '',
      new_password_confirm: '',
    })
  }, [])

  useEffect(() => {
    if (Number(id) === user.id) {
      setIsMe(true)
    }
  }, [user])
  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])

  const onSubmit = (values: any) => {
    update({
      id: user.id.toString(),
      name: user.name,
      email: user.email,
      new_password: values.new_password,
      new_password_confirm: values.new_password_confirm,
    })
  }

  const [showPassword, setShowpassword] = useState<boolean>(false)
  const [showPassword2, setShowpassword2] = useState<boolean>(false)
  const handleClickShowPassword = () => {
    setShowpassword(!showPassword)
  }
  const handleClickShowPassword2 = () => {
    setShowpassword2(!showPassword2)
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }
  return (
    <ContentBox maxWidth={1200}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        autoComplete={'off'}
      >
        <Paper>
          <PaperHeader title={t('admins.password_change_title')} />
          <Box sx={{ p: 2 }}>
            {metaUpdate.error && (
              <Box>
                <Alert severity="warning">
                  {t('ERROR.' + _.get(metaUpdate, 'error.error.message'))}
                </Alert>
              </Box>
            )}

            {isMe && (
              <>
                <Box sx={{ width: '100%', height: 80 }}>
                  <Controller
                    name="new_password"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <Box sx={{ mt: 1, mb: 2, height: 80 }}>
                        <ClassicTextField
                          {...rest}
                          fullWidth
                          inputRef={ref}
                          autoComplete={'new-password'}
                          name="new_password"
                          label={t('SYSCOMMON.new_password')}
                          type={showPassword ? 'text' : 'password'}
                          placeholder={t('SYSCOMMON.new_password')}
                          error={errors.new_password ? true : false}
                          helperText={
                            errors.new_password
                              ? errors.new_password.message
                              : ''
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff
                                    style={{ fontSize: 20, marginRight: 5 }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{ fontSize: 20, marginRight: 5 }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    )}
                  />
                </Box>
                <Box sx={{ width: '100%', height: 80 }}>
                  <Controller
                    name="new_password_confirm"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <Box sx={{ mt: 1, mb: 2, height: 80 }}>
                        <ClassicTextField
                          {...rest}
                          fullWidth
                          inputRef={ref}
                          autoComplete={'new-password'}
                          name="new_password_confirm"
                          label={t('SYSCOMMON.password_confirm')}
                          type={showPassword2 ? 'text' : 'password'}
                          placeholder={t('SYSCOMMON.password_confirm')}
                          error={errors.new_password_confirm ? true : false}
                          helperText={
                            errors.new_password_confirm
                              ? errors.new_password_confirm.message
                              : ''
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword2 ? (
                                  <VisibilityOff
                                    style={{ fontSize: 20, marginRight: 5 }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{ fontSize: 20, marginRight: 5 }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    )}
                  />
                </Box>
              </>
            )}
          </Box>
        </Paper>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              customHistory.push('/admin/users')
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>

          <Stack direction="row" spacing={1}>
            <LoadingButton
              disabled={!isValid || !isDirty || metaUpdate.pending}
              type="submit"
              loading={metaUpdate.pending}
              variant="contained"
              color="secondary"
            >
              {t('SYSCOMMON.save')}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </ContentBox>
  )
}

export default PasswordChange
