import { getPresignedUrlResponse } from '@services/common.service'
import ClassicTextField, {
  Label,
} from '@components/@material-extend/ClassicTextField'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useParams } from 'react-router-dom'
import { FieldValues } from 'react-hook-form'
import useShopEdit from '@containers/Shops/useShopEdit'
import useFormShopEdit from '@containers/Shops/useFormShopEdit'
import { useEffect } from 'react'
import { Grid, Box, Paper, Stack, Button } from '@mui/material'
import PaperHeader from '@components/PaperHeader'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useConfirm } from '@components/Confirm'
import { useUpload } from '@components/@admin/FileUpload'
import { ThemeColors } from '@theme/colors'
import customHistory from 'customHistory'
import { URI } from '@constants/uri.constants'
import { AddAPhoto, Edit } from '@mui/icons-material'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
import i18n from '@locales/i18n'
const ShopEdit = () => {
  const { id } = useParams()
  const { data, fetch, update, metaUpdate, clearDetail } = useShopEdit()
  const { Controller, methods } = useFormShopEdit()
  const { t } = useTranslation()
  const upload = useUpload()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)
  useEffect(() => {
    if (id) {
      fetch(id)
    }
  }, [id])

  useEffect(() => {
    return () => {
      clearDetail()
    }
  }, [])

  useEffect(() => {
    if (data && data.id === id) {
      const value = data.attributes
      reset({
        name: value.name,
        image: value.image,
        address: value.address,
        map_url: value.map_url,
        access_info: value.access_info,
        phone: value.phone,
        fax: value.fax,
        manager: value.manager,
        time_table: value.time_table,
        descriptions: value.descriptions,
      })
    }
  }, [data])

  const handleThumbnail = () => {
    if (id) {
      upload({
        upload_uri: URI.UPLOAD_CONTENT,
      })
        .then((res: getPresignedUrlResponse) => {
          update({
            id: id,
            image: res.key,
          })
        })
        .catch(() => null)
    }
  }

  const onSubmit = (values: any) => {
    if (data) {
      update({
        id: data.id.toString(),
        name: values.name,
        manager: values.manager,
        address: values.address,
        map_url: values.map_url,
        access_info: values.access_info,
        phone: values.phone,
        fax: values.fax,
        time_table: values.time_table,
        descriptions: values.descriptions,
      })
    }
  }
  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])
  return (
    <ContentBox maxWidth={1200}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Paper>
          <PaperHeader title={t('shops.edit_title')} />
          <Box sx={{ p: 2 }}>
            <Box sx={{ width: '100%', height: 80 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.name')}
                        placeholder={t('shops.name')}
                        error={errors.name ? true : false}
                        helperText={errors.name ? errors.name.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="manager"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.manager')}
                        placeholder={t('shops.manager')}
                        error={errors.manager ? true : false}
                        helperText={
                          errors.manager ? errors.manager.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '100%', height: 80 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.phone')}
                        placeholder={t('shops.phone')}
                        error={errors.phone ? true : false}
                        helperText={errors.phone ? errors.phone.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="fax"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.fax')}
                        placeholder={t('shops.fax')}
                        error={errors.fax ? true : false}
                        helperText={errors.fax ? errors.fax.message : ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '100%', height: 80 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.address')}
                        placeholder={t('shops.address')}
                        error={errors.address ? true : false}
                        helperText={
                          errors.address ? errors.address.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="map_url"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        label={t('shops.map_url')}
                        placeholder={t('shops.map_url')}
                        error={errors.map_url ? true : false}
                        helperText={
                          errors.map_url ? errors.map_url.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="access_info"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        multiline
                        maxRows={8}
                        minRows={8}
                        label={t('shops.access_info')}
                        placeholder={t('shops.access_info')}
                        error={errors.access_info ? true : false}
                        helperText={
                          errors.access_info ? errors.access_info.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="time_table"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        multiline
                        maxRows={8}
                        minRows={8}
                        label={t('shops.time_table')}
                        placeholder={t('shops.time_table')}
                        error={errors.time_table ? true : false}
                        helperText={
                          errors.time_table ? errors.time_table.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  <Controller
                    name="descriptions"
                    control={control}
                    render={({ field: { ref, ...rest } }: FieldValues) => (
                      <ClassicTextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        multiline
                        maxRows={8}
                        minRows={8}
                        label={t('shops.descriptions')}
                        placeholder={t('shops.descriptions')}
                        error={errors.descriptions ? true : false}
                        helperText={
                          errors.descriptions ? errors.descriptions.message : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Box>
                    <Label>{t('SYSCOMMON.thumbnail')}</Label>
                    <Box
                      onClick={() => handleThumbnail()}
                      sx={{
                        width: '100%',
                        paddingTop: '80%',
                        cursor: 'pointer',
                        position: 'relative',
                        mt: 0.5,
                        border: `1px solid ${ThemeColors.primary}`,
                        background: `url('${data?.attributes.image}') #eee`,
                        backgroundSize: 'cover',
                      }}
                    >
                      {data?.attributes.image === null ? (
                        <AddAPhoto
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                          }}
                        />
                      ) : (
                        <Edit
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            fontSize: 14,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              customHistory.push('/admin/shops')
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              disabled={!isValid || !isDirty || metaUpdate.pending}
              type="submit"
              loading={metaUpdate.pending}
              variant="contained"
              color="secondary"
            >
              {t('SYSCOMMON.save')}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </ContentBox>
  )
}

export default ShopEdit
