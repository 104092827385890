import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  email: '',
  new_password: '',
}

const useFormAdminEdit = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(
            50,
            t('ERROR.E000002', {
              field: t('admins.name'),
              character: 50,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('admins.name'),
            })
          ),
        email: Yup.string()
          .email(t('ERROR.E000004'))
          .max(
            100,
            t('ERROR.E000002', {
              field: t('admins.email'),
              character: 100,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('admins.email'),
            })
          ),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormAdminEdit
