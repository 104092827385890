import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'

export const initialValues = {
  color: '',
}

const useColorAddForm = (colors?: string[]) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape(
    {
      color: Yup.string()
        .test('color', t('ERROR.E000021'), (value: any) => {
          const isValid = /^#[0-9A-F]{6}$/i.test(value) ? true : false
          return isValid
        })
        .test(
          'color',
          t('ERROR.E000011', { field: t('part.color') }),
          (value: any) => {
            const isValid = colors ? !_.includes(colors, value) : true
            return isValid
          }
        )
        .required(
          t('ERROR.E000001', {
            field: t('part.color'),
          })
        ),
    },
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useColorAddForm
