import { SEX } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'

export type GenreItem = {
  id: string
  type: 'genre_list'
  attributes: {
    name: string
    sex: SEX
    genre_type: number
    item_count?: null | number
  }
}

export interface GenreUpdateParams {
  id: number
  name: string
}

export interface GenreCreateParams {
  name: string
  sex: number
  genre_type: number
}

export interface GenreDeleteParams {
  id: number
  sex: number
}

export type GenreListResponse = {
  data: GenreItem[]
}

export const list = async (): Promise<GenreListResponse> => {
  const { data } = await api.get<GenreListResponse>(URI.GENRES)
  return data
}

export const listWithFilter = async (
  sex: number
): Promise<GenreListResponse> => {
  const { data } = await api.get<GenreListResponse>(URI.GENRES, {
    params: { filter: sex },
  })
  return data
}

export const create = async (
  params: GenreCreateParams
): Promise<GenreListResponse> => {
  const { data } = await api.post<GenreListResponse>(URI.GENRES, params)
  return data
}

export const update = async (
  params: GenreUpdateParams
): Promise<GenreListResponse> => {
  const { data } = await api.put<GenreListResponse>(
    URI.GENRES + '/' + params.id,
    params
  )
  return data
}

export const deleteGenre = async (
  params: GenreDeleteParams
): Promise<GenreListResponse> => {
  const { data } = await api.delete<GenreListResponse>(
    URI.GENRES + '/' + params.id,
    {
      params: { filter: params.sex },
    }
  )
  return data
}
