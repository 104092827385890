import i18n from '@locales/i18n'
import { Box, Typography, Container, Paper } from '@mui/material'

const Terms = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center">
          {i18n.t('SYSCOMMON.terms')}
        </Typography>
        <Paper sx={{ p: 2 }}>{'no content'}</Paper>
      </Container>
    </Box>
  )
}

export default Terms
