import Spinner from '@components/@material-extend/Spinner'
import ShopCard from '@components/Shop/ShopCard'
import useShopPublic from '@containers/Shops/useShopPublic'
import { Box, Container, Pagination } from '@mui/material'
import Typography from '@mui/material/Typography'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ShopsPublic = () => {
  const { data, fetch, paging, meta } = useShopPublic()
  const { t } = useTranslation()

  useEffect(() => {
    fetch({
      page: 1,
      per_page: 20,
    })
  }, [])

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
    })
  }

  const renderLoader = () => {
    if (meta.pending && !meta.error && !meta.loaded) {
      return (
        <Box sx={{ width: '100%', height: 500 }}>
          <Spinner loading={true} />
        </Box>
      )
    }
  }

  return (
    <Container
      maxWidth={'lg'}
      sx={{ maxWidth: '100%', margin: '0 auto', mt: 1 }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="h2" align="center" sx={{ mt: 3, mb: 3 }}>
          {t('shops.shop_list')}
        </Typography>
        <Box>
          {renderLoader()}
          {data &&
            data.map((shop, index) => {
              return <ShopCard data={shop} key={index} />
            })}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            pb: 2,
          }}
        >
          {paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default ShopsPublic
