import { FONT_STATUS, MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'

export type FontItem = {
  id: string
  type: 'font'
  attributes: {
    name: string
    font_family?: string
    woff_path?: string
    woff2_path?: string
    style_path?: string
    thumb?: string
    status: number
    replace_uri?: string
  }
}
export type FontDetail = {
  data: FontItem
}

export type FontsParams = {
  page?: number
  per_page?: number
  status?: number | null
  name?: string | null
}

export interface FontCreateParams {
  name: string
}

export interface FontUpdateParams {
  id: string
  name?: string
  font_family?: string
  woff_path?: string
  woff2_path?: string
  style_path?: string
  thumb?: string
  status?: number
}

export type FontsResponse = {
  data: FontItem[]
  meta: MetaPage
}

export type FontStatusParams = {
  ids: number[]
  status: FONT_STATUS
}

export type FontDeleteParams = {
  ids: number[]
}

export type FontStatusResponse = {
  data: string
}

export const list = async (params: FontsParams): Promise<FontsResponse> => {
  const { data } = await api.get<FontsResponse>(URI.FONTS, { params })
  return data
}

export const activeList = async (): Promise<FontsResponse> => {
  const { data } = await api.get<FontsResponse>(URI.FONTS + '/active')
  return data
}

export const create = async (params: FontCreateParams): Promise<FontDetail> => {
  const { data } = await api.post<FontDetail>(URI.FONTS, params)
  return data
}

export const update = async (params: FontUpdateParams): Promise<FontDetail> => {
  const { data } = await api.put<FontDetail>(
    URI.FONTS + '/' + params.id,
    params
  )
  return data
}

export const deleteFont = async (
  params: FontDeleteParams
): Promise<FontsResponse> => {
  const { data } = await api.delete<FontsResponse>(URI.FONTS + '/delete', {
    params,
  })
  return data
}

export const status = async (
  params: FontStatusParams
): Promise<FontStatusResponse> => {
  const { data } = await api.post<FontStatusResponse>(
    `${URI.FONTS}/update/status/`,
    params
  )
  return data
}

export const detail = async (id: string): Promise<FontDetail> => {
  const { data } = await api.get<FontDetail>(`${URI.FONTS}/${id}`)
  return data
}
