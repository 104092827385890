import PartPreview from '@components/@admin/Part/PartPreview'
import Spinner from '@components/@material-extend/Spinner'
import { useConfirm } from '@components/Confirm'
import { Side } from '@constants/editor.constants'
import ColorConfigration from '@containers/Parts/ColorConfigration'
import StepTitle from '@containers/Parts/StepTitle'
import useParts from '@containers/Parts/useParts'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import {
  Box,
  ListItem,
  ListItemText,
  Stack,
  Button,
  ButtonGroup,
  Alert,
} from '@mui/material'
import { PartItem } from '@services/item.services'
import { ThemeColors } from '@theme/colors'
import customHistory from 'customHistory'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCallbackPrompt } from '@utils/useCallBackPromt'

const StepTwo = () => {
  const { id } = useParams()
  const [side, onChangeSide] = useState(Side.Front)
  const [color, onChange] = useState<string>('')
  const [selected, setSelected] = useState<PartItem | null>(null)
  const { fetch, list, item, metaItem, meta, fetchDetail, editPart } =
    useParts()
  const [parts, setParts] = useState<PartItem[] | undefined>(undefined)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const editableList = _.filter(list, (l) => l.attributes.is_editable)
  const showEditable = !item?.attributes?.has_design
  const isAllColorValid = _.every(
    editableList,
    (l) => !_.isEmpty(l.attributes.colors?.values)
  )

  // eslint-disable-next-line no-console
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)

  useEffect(() => {
    if (id) {
      fetch(id)
      fetchDetail(id)
    }
  }, [])

  useEffect(() => {
    if (isAllColorValid && id) {
      fetchDetail(id)
    }
  }, [isAllColorValid])

  useEffect(() => {
    if (list) {
      setParts(list)
      if (selected === null) setSelected(list[0])
    }
  }, [list])

  useEffect(() => {
    setParts(list)
    if (selected) {
      const selectedData = _.find(list, (v) => v.id === selected.id)
      const isFilled = _.get(selectedData, 'attributes.colors.fill', '')
      onChange(isFilled)
    }
  }, [selected])

  useEffect(() => {
    if (selected) {
      setParts(
        _.map(parts, (p) =>
          p.id === selected.id
            ? {
                ...p,
                attributes: {
                  ...p.attributes,
                  colors: {
                    values: _.get(p, 'attributes.colors.values', []),
                    fill: color,
                  },
                },
              }
            : p
        )
      )
    }
  }, [color])

  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])

  const handleGoPrev = () => {
    customHistory.push(`/admin/items/${id}/step/1`)
  }

  const handleGoNext = () => {
    customHistory.push(`/admin/items/${id}/step/3`)
  }

  const handleSelect = (p: PartItem) => {
    if (isDirty) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          setSelected(p)
        })
        .catch(() => null)
    } else {
      setSelected(p)
    }
  }

  const renderLoader = () => {
    if (metaItem.pending && !item && !metaItem.error && !metaItem.loaded) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 600,
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  const renderLoaderParts = () => {
    if (meta.pending && !metaItem.error && !metaItem.loaded) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 400,
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  return (
    <ContentBox>
      {renderLoader()}
      {id && item && <StepTitle data={item} id={id} step={2} />}
      {item && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Box>
              {parts && <PartPreview data={parts ? parts : []} side={side} />}
              {renderLoaderParts()}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonGroup>
                  <Button
                    onClick={() => onChangeSide(Side.Front)}
                    disableRipple
                    className={side === Side.Front ? 'is-active' : ''}
                    variant="outlined"
                    sx={{
                      color: '#111',
                      borderColor: '#999',
                      '&:hover': {
                        borderColor: '#aaa',
                      },
                      '&.is-active': {
                        color: ThemeColors.secondary,
                      },
                    }}
                  >
                    {i18n.t('SYSCOMMON.front')}
                  </Button>
                  <Button
                    onClick={() => onChangeSide(Side.Back)}
                    disableRipple
                    className={side === Side.Back ? 'is-active' : ''}
                    sx={{
                      color: '#111',
                      borderColor: '#999',
                      '&.is-active': {
                        color: ThemeColors.secondary,
                      },
                      '&:hover': {
                        borderColor: '#aaa',
                      },
                    }}
                    variant="outlined"
                  >
                    {i18n.t('SYSCOMMON.back')}
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                spacing={2}
              >
                <Box sx={{ width: 300 }}>
                  {list &&
                    list.map((p: PartItem, i: number) => {
                      if (p.attributes.is_editable) {
                        return (
                          <ListItem
                            className={p.id === selected?.id ? 'is-active' : ''}
                            onClick={() => {
                              handleSelect(p)
                            }}
                            sx={{
                              boxShadow: 1,
                              cursor: 'pointer',
                              '&.is-active': {
                                background: ThemeColors.secondary,
                                color: '#fff',
                              },
                            }}
                            key={i}
                          >
                            <ListItemText>{p.attributes.name}</ListItemText>
                          </ListItem>
                        )
                      }
                    })}
                </Box>
                <Box sx={{ width: '100%' }}>
                  {id && list && (
                    <ColorConfigration
                      onIsDirtyChange={setIsDirty}
                      color={color}
                      onChange={onChange}
                      showEditable={showEditable}
                      id={id}
                      list={list}
                      selectedId={selected?.id}
                      onChangeColor={editPart}
                    />
                  )}
                  {!isAllColorValid && (
                    <Box mt={2}>
                      <Alert severity="warning">
                        {i18n.t('ERROR.E000014')}
                      </Alert>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent={'space-between'}
            sx={{ width: '100%', mt: 2 }}
          >
            <Button variant="outlined" onClick={() => handleGoPrev()}>
              {i18n.t('part.back_step')}
            </Button>
            <Button
              variant="contained"
              disabled={!isAllColorValid}
              color="secondary"
              onClick={() => handleGoNext()}
            >
              {i18n.t('part.next_step3')}
            </Button>
          </Stack>
        </>
      )}
    </ContentBox>
  )
}

export default StepTwo
