import { ShopItem, ShopsParams } from '@services/shops.services'
import { shopListPublic } from '@store/shops/actions'
import { listPublic, pagemetaPublic } from '@store/shops/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'

interface ReturnType {
  data?: ShopItem[]
  paging?: MetaPage
  fetch: (params: ShopsParams) => void
  meta: Meta
}

const useShopPublic = (): ReturnType => {
  const data = useAppSelector(listPublic)
  const dispatch = useDispatch()
  const fetch = (params: ShopsParams) => {
    dispatch(shopListPublic(params))
  }
  const paging = useAppSelector(pagemetaPublic)

  const _meta = createMetaSelector(shopListPublic)
  const meta = useAppSelector(_meta)

  return {
    data,
    fetch,
    meta,
    paging,
  }
}

export default useShopPublic
