import { Box } from '@mui/material/'
import PriceRow from '@components/@editor-components/DetailPrice/PriceRow'
import { DesignItem } from '@services/design.services'
import i18n from '@locales/i18n'
import Typography from '@mui/material/Typography'
import { MarkingItem } from '@services/item.services'
import { currency } from 'utils/helper/common.helpers'
import { ThemeColors } from '@theme/colors'

export type PriceContentProps = {
  bottomData?: DesignItem
  topData?: DesignItem
  markingsTop?: MarkingItem[]
  markingsBottom?: MarkingItem[]
  markingPriceTop: number
  markingPriceBottom: number
}

const PriceContent: React.FC<PriceContentProps> = ({
  bottomData,
  topData,
  markingsTop,
  markingsBottom,
  markingPriceTop,
  markingPriceBottom,
}) => {
  return (
    <Box sx={{ pb: 2, background: '#fff' }}>
      {topData ? (
        <>
          <PriceRow
            title={i18n.t('SYSCOMMON.tops')}
            productPrice={topData.attributes.price}
            product={topData.attributes.name}
            markingsPrice={markingPriceTop}
          />
          <Box sx={{ pl: 2, textAlign: 'left' }}>
            {markingsTop?.map((m, i) => {
              return (
                <Typography variant="body1" key={i}>
                  - {m.attributes.name}
                  <Box
                    component="span"
                    sx={{ color: ThemeColors.secondary, ml: 1 }}
                  >
                    {currency(m?.attributes.additional_price)}
                  </Box>
                </Typography>
              )
            })}
          </Box>
        </>
      ) : null}
      {bottomData ? (
        <>
          <PriceRow
            title={i18n.t('SYSCOMMON.bottoms')}
            productPrice={bottomData.attributes.price}
            product={bottomData.attributes.name}
            markingsPrice={markingPriceBottom}
          />
          <Box sx={{ textAlign: 'left' }}>
            {markingsBottom?.map((m, i) => {
              return (
                <Typography variant="body2" key={i}>
                  - {m.attributes.name}
                  <Box
                    component="span"
                    sx={{ color: ThemeColors.secondary, ml: 1 }}
                  >
                    {currency(m?.attributes.additional_price)}
                  </Box>
                </Typography>
              )
            })}
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default PriceContent
