import React, { ReactNode } from 'react'
import Table, { TableProps } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  PaperProps,
  TableCell,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BoxProps } from '@mui/system'

export interface TableLayoutProps {
  renderRow: (data: any, index: number) => void
  data: any[]
  head: ReactNode
  tableProps?: TableProps
  isLoading?: boolean
  filter?: ReactNode
  pagination?: ReactNode
  headSection?: ReactNode
  settings?: ReactNode
  pagintionBox?: BoxProps
  footer?: ReactNode
  paperProps?: PaperProps
  minWidth?: number | string
}

const TableLayout: React.FC<TableLayoutProps> = (props) => {
  const {
    data,
    renderRow,
    head,
    tableProps,
    isLoading,
    paperProps,
    filter,
    pagination,
    headSection,
    settings,
    pagintionBox,
    footer,
    minWidth = 900,
  } = props
  const { t } = useTranslation()
  return (
    <Paper {...paperProps}>
      {headSection && (
        <Box sx={{ padding: 2, borderBottom: '1px solid #ddd' }}>
          {headSection}
          {filter || pagination ? (
            <Grid
              container
              alignItems={'center'}
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid xs={12} sm={12} md={7} lg={7} alignItems={'center'} item>
                {filter && filter}
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={5}
                lg={5}
                alignItems={'center'}
                height={'100%'}
                item
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems={'center'}
                  {...pagintionBox}
                >
                  {pagination && pagination}
                  {settings && settings}
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      )}

      <TableContainer>
        <Table
          sx={{
            minWidth: minWidth,
            '& .MuiTableCell-root': {
              p: '4px 4px',
              fontSize: 14,
            },
          }}
          {...tableProps}
        >
          <TableHead>{head}</TableHead>
          <TableBody sx={{ position: 'relative' }}>
            {isLoading ? (
              <TableRow>
                <TableCell>
                  <Box
                    component="span"
                    sx={{
                      position: 'absolute',
                      left: 0,
                      background: 'rgba(255,255,255,0.6)',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}

            {data.length > 0 ? (
              data.map((item, index) => renderRow(item, index))
            ) : (
              <TableRow sx={{ position: 'relative', p: 2, height: 50 }}>
                <TableCell>
                  <Box
                    component="span"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  >
                    {t('SYSCOMMON.no_data')}
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {footer}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default TableLayout
