import React, { ReactNode } from 'react'
import map from 'lodash/map'
import { Box, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'
import tinycolor from 'tinycolor2'
import _ from 'lodash'
import i18n from '@locales/i18n'

interface ColorPickerProps {
  colors: string[]
  onChangeComplete: (color: string) => void
  value: string
  perRow: number
  emptyValue?: boolean
  emptyPlaceholder?: ReactNode
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  value,
  onChangeComplete,
  perRow,
  emptyValue = false,
  emptyPlaceholder,
}) => {
  const handleChange = (color: string) => onChangeComplete(color)
  const tinyColor = tinycolor(value)

  return (
    <Stack
      sx={{ width: '100%' }}
      flexDirection="row"
      display="flex"
      flexWrap={'wrap'}
      justifyContent="flex-start"
    >
      {emptyValue ? (
        <Box
          key={'none'}
          className={value === '' || value === null ? 'active' : ''}
          onClick={() => handleChange('')}
          sx={{
            backgroundSize: '10px 10px',
            backgroundColor: 'transparent',
            backgroundPosition: '0px 0px, 0px 15px, 15px -15px, -15px 0px',
            backgroundImage:
              'linear-gradient(45deg, rgb(230, 230, 230) 25%, transparent 25%), linear-gradient(-45deg, rgb(230, 230, 230) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(230, 230, 230) 75%), linear-gradient(-45deg, transparent 75%, rgb(230, 230, 230) 75%)',
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            justifyContent: 'center',
            width: `${100 / perRow}%`,
            cursor: 'pointer',
            '&.active': {
              outline: 'rgb(255, 255, 255) solid 2px',
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 2px',
              position: 'relative',
              zIndex: 2,
            },
            '&:hover': {
              outline: 'rgb(255, 255, 255) solid 2px',
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 2px',
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          {value === '' || value === null ? (
            <Check sx={{ color: '#fff' }} />
          ) : (
            ''
          )}
        </Box>
      ) : null}
      {!_.isEmpty(colors) ? (
        map(colors, (c, i) => (
          <Box
            key={i}
            className={c === value ? 'active' : ''}
            onClick={() => handleChange(c)}
            sx={{
              background: c,
              display: 'flex',
              alignItems: 'center',
              height: '50px',
              justifyContent: 'center',
              width: `${100 / perRow}%`,
              cursor: 'pointer',
              border: '1px solid #ccc',
              '&.active': {
                outline: 'rgb(255, 255, 255) solid 1px',
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 2px',
                position: 'relative',
                zIndex: 2,
                border: '0 none',
              },
              '&:hover': {
                outline: 'rgb(255, 255, 255) solid 1px',
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 2px',
                position: 'relative',
                zIndex: 2,
              },
            }}
          >
            {c === value ? (
              <Check sx={{ color: tinyColor.isLight() ? '#000' : '#fff' }} />
            ) : (
              ''
            )}
          </Box>
        ))
      ) : emptyPlaceholder ? (
        emptyPlaceholder
      ) : (
        <Box sx={{ height: '50px' }}>{i18n.t('SYSCOMMON.no_data')}</Box>
      )}
    </Stack>
  )
}

export default ColorPicker
