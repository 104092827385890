import { store } from '@store/store'
import { authorizationProvider } from '@services/interceptor'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AuthLayout from 'layouts/AuthLayout'
import MainLayout from 'layouts/MainLayout'
import '@locales/i18n'
import moment from 'moment'
import { CustomRouter } from 'customRouter'
import customHistory from 'customHistory'
// import ThemeClient from 'pages/home'
import '@theme/global.css'
import '@theme/editor.css'
import HomePage from '@pages/homepage'
import 'swiper/css'
import 'swiper/css/pagination'
import EditorPage from '@pages/editor'
import FullScreenLayout from 'layouts/FullScreenLayout'
import Design from '@pages/design'
import Compare from '@pages/compare'
import ShopsPublic from '@pages/shops'
import Order from '@pages/order'
import Login from '@pages/login'
import PasswordForgot from '@pages/passwordForgot'
import SentLink from '@pages/passwordForgot/SentLink'
import PasswordReset from '@pages/passwordReset'
import useAuth from 'utils/useAuth'
import AdminLayout from '@layouts/AdminLayout'
import ItemEdit from '@pages/admin/items/edit'
import ItemDetail from '@pages/admin/items/detail'
import ItemList from '@pages/admin/items'
import Genres from '@pages/admin/genres'
import SystemSettings from '@pages/admin/system_settings'
import Fonts from '@pages/admin/fonts'
import FontEdit from '@pages/admin/fonts/edit'
import Shops from '@pages/admin/shops'
import ShopEdit from '@pages/admin/shops/edit'
import Orders from '@pages/admin/orders'
import Designs from '@pages/admin/designs'
import Users from '@pages/admin/users'
import UserEdit from '@pages/admin/users/edit'
import i18n from '@locales/i18n'
import StepOne from '@pages/admin/items/step/One'
import StepTwo from '@pages/admin/items/step/Two'
import StepThree from '@pages/admin/items/step/Three'
import Page404 from '@pages/404'
import Terms from '@pages/terms'
import Privacy from '@pages/privacy'
import HowTo from '@pages/howto'
import Inquiry from '@pages/inquiry'
import PasswordChange from '@pages/admin/users/passwordChange'
moment.locale('jp')

function App() {
  authorizationProvider(store)

  // Automatically scrolls to top whenever pathname changes

  return (
    <CustomRouter history={customHistory}>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <MainLayout>
                <HomePage />
              </MainLayout>
            }
          />
          <Route
            path="editor"
            element={
              <FullScreenLayout>
                <EditorPage />
              </FullScreenLayout>
            }
          />
          <Route path="design">
            <Route
              path=":id"
              element={
                <MainLayout isFooter={false}>
                  <Design />
                </MainLayout>
              }
            />
          </Route>
          <Route path="order">
            <Route
              path=":id"
              element={
                <MainLayout>
                  <Order />
                </MainLayout>
              }
            />
          </Route>
          <Route
            path="compare"
            element={
              <MainLayout>
                <Compare />
              </MainLayout>
            }
          />
          <Route
            path="privacy"
            element={
              <MainLayout>
                <Privacy />
              </MainLayout>
            }
          />
          <Route
            path="terms"
            element={
              <MainLayout>
                <Terms />
              </MainLayout>
            }
          />
          <Route
            path="howto"
            element={
              <MainLayout>
                <HowTo />
              </MainLayout>
            }
          />
          <Route
            path="inquiry"
            element={
              <MainLayout>
                <Inquiry />
              </MainLayout>
            }
          ></Route>
          <Route
            path="shops"
            element={
              <MainLayout>
                <ShopsPublic />
              </MainLayout>
            }
          />
        </Route>
        <Route element={<AuthOutlet />} path="/login">
          <Route
            index
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
        </Route>

        <Route element={<AuthOutlet />} path="/password">
          <Route
            path="forgot"
            element={
              <AuthLayout>
                <PasswordForgot />
              </AuthLayout>
            }
          />
          <Route
            path="forgot/sent"
            element={
              <AuthLayout>
                <SentLink />
              </AuthLayout>
            }
          />
          <Route
            path="reset/:reset_password_token"
            element={
              <AuthLayout>
                <PasswordReset />
              </AuthLayout>
            }
          />
        </Route>

        <Route element={<PrivateOutlet />} path="/admin">
          <Route index element={<Navigate to={'/admin/items'} />} />
          <Route path="items">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.merchandise')}>
                  <ItemList />
                </AdminLayout>
              }
            />
            <Route
              path=":id"
              element={
                <AdminLayout>
                  <ItemDetail />
                </AdminLayout>
              }
            />
            <Route path=":id/edit">
              <Route
                index
                element={
                  <AdminLayout>
                    <ItemEdit />
                  </AdminLayout>
                }
              />
            </Route>
            <Route path=":id/step">
              <Route
                path="1"
                element={
                  <AdminLayout>
                    <StepOne />
                  </AdminLayout>
                }
              />
              <Route
                path="2"
                element={
                  <AdminLayout>
                    <StepTwo />
                  </AdminLayout>
                }
              />
              <Route
                path="3"
                element={
                  <AdminLayout>
                    <StepThree />
                  </AdminLayout>
                }
              />
            </Route>
          </Route>

          <Route
            path="genres"
            element={
              <AdminLayout title={i18n.t('SYSCOMMON.genre_management')}>
                <Genres />
              </AdminLayout>
            }
          />

          <Route path="fonts">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.fonts_management')}>
                  <Fonts />
                </AdminLayout>
              }
            />
            <Route path=":id/edit">
              <Route
                index
                element={
                  <AdminLayout>
                    <FontEdit />
                  </AdminLayout>
                }
              />
            </Route>
          </Route>

          <Route path="shops">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.shop_management')}>
                  <Shops />
                </AdminLayout>
              }
            />
            <Route path=":id/edit">
              <Route
                index
                element={
                  <AdminLayout>
                    <ShopEdit />
                  </AdminLayout>
                }
              />
            </Route>
          </Route>

          <Route path="orders">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.orders')}>
                  <Orders />
                </AdminLayout>
              }
            />
          </Route>

          <Route path="designs">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.designs')}>
                  <Designs />
                </AdminLayout>
              }
            />
          </Route>

          <Route
            path="system_settings"
            element={
              <AdminLayout title={i18n.t('SYSCOMMON.system_settings')}>
                <SystemSettings />
              </AdminLayout>
            }
          />

          <Route path="users">
            <Route
              index
              element={
                <AdminLayout title={i18n.t('SYSCOMMON.admins_management')}>
                  <Users />
                </AdminLayout>
              }
            />
            <Route path=":id/edit">
              <Route
                index
                element={
                  <AdminLayout>
                    <UserEdit />
                  </AdminLayout>
                }
              />
            </Route>
            <Route path=":id/password-change">
              <Route
                index
                element={
                  <AdminLayout>
                    <PasswordChange />
                  </AdminLayout>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route
          path="*"
          element={
            <MainLayout>
              <Page404 />
            </MainLayout>
          }
        />
      </Routes>
    </CustomRouter>
  )
}

const PrivateOutlet = () => {
  const { isLoggedIn } = useAuth()
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

const AuthOutlet = () => {
  const { isLoggedIn } = useAuth()
  return isLoggedIn ? <Navigate to="/admin" /> : <Outlet />
}

export default App
