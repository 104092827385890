import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
const SentMail: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <br />
      <Typography variant="body1" color="text.secondary">
        {t('SYSCOMMON.sent_reset_link_to_your_email')}
      </Typography>
      <br />
      <Link to="/login">{t('SYSCOMMON.back_to_login')}</Link>
    </Box>
  )
}

export default SentMail
