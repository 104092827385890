import { URI } from '@constants/uri.constants'
import api from './api'

export type SettingItem = {
  id: string | null
  type: 'system_setting'
  attributes: {
    design_expire_month: number
    order_expire_month: number
  }
}

export interface SettingCreateParams {
  design_expire_month: number
  order_expire_month: number
}

export interface SettingUpdateParams {
  id: number
  design_expire_month: number
  order_expire_month: number
}

export type SettingsResponse = {
  data: SettingItem
}

export const getSettings = async (): Promise<SettingsResponse> => {
  const { data } = await api.get<SettingsResponse>(URI.SETTINGS)
  return data
}

export const create = async (
  params: SettingCreateParams
): Promise<SettingsResponse> => {
  const { data } = await api.post<SettingsResponse>(URI.SETTINGS, params)
  return data
}
