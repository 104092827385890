import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { Order, OrderCreateDetail } from '@services/order.services'

type StateType = {
  list?: Order[]
  meta?: MetaPage
  detail?: OrderCreateDetail
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  detail: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.orderList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })

  builder.addCase(actions.showByCode.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })

  builder.addCase(actions.resetDetail, (state) => {
    // eslint-disable-next-line no-console
    state.detail = undefined
  })
})
