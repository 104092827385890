import * as React from 'react'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { Box, Button, Stack } from '@mui/material'
import i18n from '@locales/i18n'
import { useState } from 'react'
import { Label } from '@components/@material-extend/ClassInputLabel'
import _ from 'lodash'

interface ChipArrayProps {
  onChange: (data: string[]) => void
  value: string[]
  addText?: string
  label: string
  disabled?: boolean
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

const ChipArray: React.FC<ChipArrayProps> = ({
  value,
  onChange,
  label,
  disabled,
  addText = i18n.t('SYSCOMMON.add_size'),
}) => {
  const [text, setText] = useState<string>('')

  const handleAdd = (added: string) => {
    onChange([...value, added])
    setText('')
  }
  const handleDelete = (deleted: string) => {
    onChange(value.filter((chip) => chip !== deleted))
  }

  return (
    <>
      <Label>{label}</Label>
      <Stack direction="row" spacing={1}>
        <Box>
          <Stack direction="row" spacing={1}>
            <ClassicTextField
              disabled={disabled}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <Button
              variant="outlined"
              disabled={_.isEmpty(text)}
              onClick={() => handleAdd(text)}
            >
              {addText}
            </Button>
          </Stack>
        </Box>
        <Box
          component="ul"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0,
            m: 0,
          }}
        >
          {value.map((data, k) => {
            return (
              <ListItem key={k}>
                <Chip
                  label={data}
                  sx={{
                    fontSize: 12,
                    p: 0,
                    height: 28,
                    '& .MuiSvgIcon-root': {
                      display: disabled ? 'none' : 'inline-block',
                    },
                  }}
                  onDelete={() => !disabled && handleDelete(data)}
                />
              </ListItem>
            )
          })}
        </Box>
      </Stack>
    </>
  )
}

export default ChipArray
