import { applyMiddleware, createStore } from '@reduxjs/toolkit'
import reducer from './reducers'
import { persistReducer } from 'redux-persist'
import storage from './storage'
import thunk from 'redux-thunk'
import { globalMiddleware } from './globalMiddleware'

/**
 * initStore
 * Initialise and export redux store
 */
const initStore = () => {
  const persistConfig = {
    key: 'auth',
    whitelist: ['auth', 'common'],
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, reducer)
  const store = createStore(
    persistedReducer,
    applyMiddleware(thunk, globalMiddleware)
  )

  return store
}

export const store = initStore()
export type StoreType = ReturnType<typeof initStore>
export type RootState = ReturnType<StoreType['getState']>
export type AppDispatch = StoreType['dispatch']
