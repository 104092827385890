import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { WarningRounded } from '@mui/icons-material'
import { ConfirmOptions } from '@components/Confirm/types'
import useDesignConfirmForm from './useDesignConfirmForm'
import { FieldValues } from 'react-hook-form'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

interface DesignConfirmDialogProps {
  open: boolean
  options: ConfirmOptions
  onCancel: () => void
  onConfirm: (email: string | null) => void
  onClose: (event: Event, reason: string) => void
}

const DesignConfirmDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}: DesignConfirmDialogProps) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    cancellationButtonProps,
    additionalText,
    confirmationButtonProps,
  } = options

  const onSubmit = (values: any) => {
    if (_.isEmpty(values.email)) {
      onConfirm(null)
    } else {
      onConfirm(values.email)
    }
  }

  const { t } = useTranslation()

  const { Controller, methods } = useDesignConfirmForm()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={onClose}>
      <Container maxWidth={false}>
        {title && (
          <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        )}
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          {content ? (
            <DialogContent>{content}</DialogContent>
          ) : (
            <DialogContent sx={{ p: 1 }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <ClassicTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    label={t('SYSCOMMON.email_save')}
                    placeholder={t('SYSCOMMON.email_save')}
                    error={errors.email ? true : false}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
              {description && (
                <DialogContentText>{description}</DialogContentText>
              )}
            </DialogContent>
          )}
          <DialogActions>
            <Button {...cancellationButtonProps} onClick={onCancel}>
              {cancellationText}
            </Button>
            <Button {...confirmationButtonProps} type="submit">
              {confirmationText}
            </Button>
          </DialogActions>
        </form>
        {additionalText && (
          <Typography variant="body1" component="p">
            <WarningRounded fontSize="small" />
            {additionalText}
          </Typography>
        )}
      </Container>
    </Dialog>
  )
}

export default DesignConfirmDialog
