import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material/'
import { ThemeColors } from '@theme/colors'
import { Item } from '@services/item.services'
import i18n from '@locales/i18n'

export type ProductItemProps = {
  data: Item
  isSelected: boolean
  onClick: (id: number) => void
  onView: (id: string) => void
}

const ProductItem: React.FC<ProductItemProps> = ({
  data,
  isSelected,
  onClick,
  onView,
}) => {
  const { attributes } = data

  return (
    <Box
      className="sample-selector"
      sx={{
        mb: 3,
        width: '100%',
        p: '2px',
      }}
    >
      <Card
        className={`${isSelected ? 'active' : ''} `}
        sx={{
          maxWidth: '100%',
          width: '100%',
          border: '2px solid transparent',
          boxSizing: 'border-box',
          pt: 4,
          pb: 4,
          p: 2,
          '&.active': {
            boxShadow: 3,
            border: `2px solid ${ThemeColors.primary}`,
            overflow: 'hidden',
          },
        }}
        onClick={(e) => {
          e.stopPropagation()
          onClick(Number(data.id))
        }}
      >
        <CardMedia
          component="div"
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            paddingTop: '120%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              background: `url('${data.attributes.thumb_url}') center center no-repeat`,
              backgroundSize: 'contain',
              right: 0,
            }}
          />
        </CardMedia>
      </Card>
      <CardContent
        onClick={() => onView(data.id)}
        sx={{
          p: 1,
          '&:last-child': { paddingBottom: 1 },
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              display: '-webkit-box',
              margin: '0 auto',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textDecoration: 'underline',
              textAlign: 'center',
              height: 40,
              fontWeight: 500,
              cursor: 'pointer',
              '&:hover': {
                transition: 'all 0.3s ease',
                color: ThemeColors.secondary,
              },
            }}
            component="div"
          >
            {attributes.name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 400 }}>
            {`${attributes.price}`}
            <Box
              component="span"
              sx={{
                ml: 0.2,
                fontSize: 10,
                top: '-2px',
                position: 'relative',
              }}
            >
              {i18n.t('SYSCOMMON.yen')}
              {attributes.has_additional_price ? '〜' : ''}
            </Box>
          </Typography>
        </Box>
      </CardContent>
    </Box>
  )
}

export default ProductItem
