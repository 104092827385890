import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'
import { Order, OrderFilterParams } from '@services/order.services'
import { orderList } from '@store/order/actions'
import { list, pagemeta } from '@store/order/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  paging: MetaPage
  meta: Meta
  data?: Order[]
  fetch: (params: OrderFilterParams) => void
}

const useOrderList = (): ReturnType => {
  const paging = useAppSelector(pagemeta)
  const data = useAppSelector(list)
  const dispatch = useDispatch()
  const fetch = (params: OrderFilterParams) => {
    dispatch(orderList(params))
  }

  const _meta = createMetaSelector(orderList)
  const meta = useAppSelector(_meta)

  return {
    paging,
    meta,
    fetch,
    data,
  }
}

export default useOrderList
