import ClassicSelect from '@components/@material-extend/ClassicSelect'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { Search } from '@mui/icons-material'
import {
  IconButton,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from '@mui/material/'
import _ from 'lodash'

export type SearchOptionItem = {
  id: number
  label: string
}

export type SelectWithOptionProps = {
  options: SearchOptionItem[]
  onChange: (value: string) => void
  value: string
  optionWidth?: number
  selectedValue: SearchOptionItem
  onSearch: () => void
  setSelected: (selected: SearchOptionItem) => void
}

const SelectWithOption: React.FC<SelectWithOptionProps> = ({
  options,
  value,
  onChange,
  onSearch,
  setSelected,
  selectedValue,
  optionWidth,
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = _.find(options, ['label', event.target.value])
    setSelected(value ? value : options[0])
  }

  return (
    <>
      {
        <ClassicTextField
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !_.isEmpty(value)) {
              onSearch()
            }
          }}
          sx={{
            border: '0 none',
            '&.Mui-focused': {
              boxShadow: 'none',
            },
          }}
          inputProps={{
            sx: {
              p: 1,
              border: '1px solid #999',
              borderRadius: '2px',
              borderTopRightRadius: '0px !important',
              borderBottomRightRadius: '0px !important',
              borderRight: '0 none !important',
            },
          }}
          startAdornment={
            <Stack direction="row" spacing={0.5}>
              <ClassicSelect
                value={selectedValue.label}
                placeholder={'Search...'}
                name="search"
                sx={{
                  border: '0 none',
                  width: optionWidth ? optionWidth : 120,
                }}
                onChange={handleChange}
              >
                {options.map((item: SearchOptionItem, k: number) => (
                  <MenuItem key={k} value={item.label}>
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </ClassicSelect>
            </Stack>
          }
          endAdornment={
            <IconButton
              type="submit"
              disabled={_.isEmpty(value)}
              onClick={() => onSearch()}
              sx={{
                marginRight: ' 4px',
                border: '1px solid #999 !important',
                borderLeft: '0 none',
                padding: '5px',
                borderRadius: '0',
                borderTopRightRadius: '2px !important',
                borderBottomRightRadius: '2px !important',
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              <Search />
            </IconButton>
          }
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      }
    </>
  )
}

export default SelectWithOption
