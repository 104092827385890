import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import React from 'react'

interface DateTimePickerProps {
  onChange: (date: any) => void
  value: string
  title: string
  views?: string[] | any
  inputFormat?: string
  mask?: string
  required?: boolean
  error?: boolean
  helperText?: string
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  title,
  onChange,
  value,
  required,
  helperText,
  error,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        inputFormat="YYYY/MM/DD"
        onChange={(newValue) => {
          onChange(newValue)
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <>
            <ClassicTextField
              label={title}
              sx={{ position: 'relative' }}
              helperText={helperText}
              error={error}
              required={required}
              inputProps={{ ref: inputRef, ...inputProps }}
              endAdornment={InputProps?.endAdornment}
            />
          </>
        )}
      />
    </LocalizationProvider>
  )
}

export default DateTimePicker
