import i18n from '@locales/i18n'

export const LayoutContants = {
  appbarHeight: 54,
}

export enum SEARCH_TYPE {
  DESIGN = 1,
  ORDER = 2,
}

export const SearchOptions = [
  {
    id: 1,
    label: i18n.t('SYSCOMMON.design_proposal_search'),
  },
  {
    id: 2,
    label: i18n.t('SYSCOMMON.order_information_search'),
  },
]
