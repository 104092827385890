import * as React from 'react'
import { Box } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import { useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { GalleryItem } from '@services/item.services'

export type SliderProps = {
  data: GalleryItem[]
}

const Slider: React.FC<SliderProps> = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  return (
    <Box className="gallery-slider">
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
        style={
          {
            '--swiper-navigation-color': '#444',
            '--swiper-pagination-color': '#444',
          } as any
        }
      >
        {data?.map((c, i) => {
          return (
            <SwiperSlide key={i}>
              <Box
                sx={{
                  background: `url(${c.attributes.url}) no-repeat center center`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundSize: 'contain',
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={6}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper3"
      >
        {data?.map((c, i) => {
          return (
            <SwiperSlide key={i}>
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    background: `url(${c.attributes.url}) no-repeat center center`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundSize: 'contain',
                  }}
                />
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default Slider
