import { MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'
import { FontItem } from './fonts.services'
import { Item, MarkingItem, PartItem } from './item.services'

export type Design = {
  id: string
  type: 'design_list'
  attributes: {
    created_ip: string
    code: string
    price: number
    last_access: string
    expire_at: string
    created_at: string
    genre_types: string
    images: string
    is_expired: boolean
    email: string
  }
}

export type DesignFilterParams = {
  page?: number
  per_page?: number
  start_at?: string
  end_at?: string
  item_id?: string
  email?: string
}

export type DesignListResponse = {
  data: Design[]
  meta: MetaPage
}

export type NewParams = {
  top_id?: number
  bottom_id?: number
}

export type DesignItem = Item & {
  attributes: {
    item_parts: PartItem[]
    item_markings: MarkingItem[]
  }
}

export type NewDesignResponse = {
  top?: {
    data: DesignItem
  }
  bottom?: {
    data: DesignItem
  }
  sex?: number
}

export type DesignCreate = {
  items: DesignConfigItem[]
}

export interface DesignConfigItem {
  id: number
  parts: PartConfig[]
  marks: MarkConfig[]
}

export interface PartConfig {
  id: string
  color: string
}

export interface MarkConfig {
  id: string
  input_data: string
  is_common: number
  settings: {
    font_size: number
    position_id: string
    image?: string
    family: number
    fill: string
  }
}

export type ClientFontResponse = {
  data: FontItem[]
}

export interface DesignCreateItem extends DesignConfigItem {
  front_image: string
  back_image: string
}

export type CreateDesignParams = {
  items: DesignConfigItem[]
  email: string | null
}

export type CreateDesignResponse = {
  status: string
  code: string
}

export type DesignPart = {
  id: string
  type: 'design_part'
  attributes: {
    item_part_id: number
    color: string
  }
}

export type DesignMarking = {
  id: string
  type: 'design_marking'
  attributes: {
    item_marking_id: number
    is_common: boolean
    input_data: string
    settings: {
      font_size: number
      position_id: string
      family: number
      fill: string
    }
  }
}

export type DesignDetailItem = {
  id: string
  type: 'design_item'
  attributes: {
    item_id: number
    genre_type: number
    name: string
    price?: number
    size: []
    item_markings: MarkingItem[]
    design_parts: DesignPart[]
    design_markings: DesignMarking[]
    front_url: string
    back_url: string
  }
}

export interface DesignDetail {
  id: string
  type: 'design'
  attributes: {
    is_active: boolean
    is_expired: boolean
    code: string
    price: number
    design_items: DesignDetailItem[]
  }
}

export type DesignShareParams = {
  code: string
  email: string
}

export type CompareParams = {
  codes: string[]
}

export type DesignDetailResponse = {
  data: DesignDetail
}

export type DesignCompareResponse = {
  data: DesignDetail[]
}

export type DesignShareResponse = {
  status: 'ok'
}

export type DesignCopyParams = {
  code: string
}

export type DesignCopyResponse = {
  data: DesignCopyDetail
}

export type DesignDeleteResponse = {
  data: any
}

export type DesignCopyDetail = {
  id: string
  type: 'design_copy'
  attributes: {
    code: string
    price: number
    items: CopyItemDetail[]
    design_items: DesignDetailItem[]
  }
}

export type CopyItemDetail = {
  id: string
  type: 'item_with_parts_for_design'
  attributes: {
    genre_id: number
    name: string
    code: string
    price: number
    status: number
    has_additional_price: boolean
    size: string[]
    thumb_url: string
    item_parts: PartItem[]
    item_markings: MarkingItem[]
  }
}

export interface DesignDeleteParams {
  id: string
  filter?: DesignFilterParams
}

export const list = async (
  params: DesignFilterParams
): Promise<DesignListResponse> => {
  const { data } = await api.get<DesignListResponse>(URI.DESIGNS, {
    params: params,
  })
  return data
}

export const newDesign = async (
  params: NewParams
): Promise<NewDesignResponse> => {
  const { data } = await api.post<NewDesignResponse>(URI.DESIGN_NEW, params)
  return data
}

export const clientFonts = async (): Promise<ClientFontResponse> => {
  const { data } = await api.get<ClientFontResponse>(URI.CLIENT_FONTS)
  return data
}

export const createDesign = async (
  params: CreateDesignParams
): Promise<CreateDesignResponse> => {
  const { data } = await api.post<CreateDesignResponse>(
    URI.DESIGN_CREATE,
    params
  )
  return data
}

export const designDetail = async (
  id: string
): Promise<DesignDetailResponse> => {
  const { data } = await api.get<DesignDetailResponse>(
    URI.DESIGN_DETAIL.replace(/:id/gi, id)
  )
  return data
}

export const compareDesign = async (
  params: CompareParams
): Promise<DesignCompareResponse> => {
  const { data } = await api.post<DesignCompareResponse>(URI.COMPARE, params)
  return data
}

export const designShare = async (
  params: DesignShareParams
): Promise<DesignShareResponse> => {
  const { data } = await api.post<DesignShareResponse>(URI.SHARE, params)
  return data
}

export const copy = async (
  params: DesignCopyParams
): Promise<DesignCopyResponse> => {
  const { data } = await api.post<DesignCopyResponse>(URI.COPY, params)
  return data
}

export const deleteDesign = async (
  params: DesignDeleteParams
): Promise<DesignListResponse> => {
  const { data } = await api.delete<DesignListResponse>(
    URI.DESIGN_ITEM.replace(/:id/gi, params.id),
    {
      params: params.filter,
    }
  )
  return data
}
