/* eslint-disable no-console */
import * as React from 'react'
import { MarkingItem, PartItem } from '@services/item.services'
import { Box } from '@mui/material'
import _ from 'lodash'
import { Side } from '@constants/editor.constants'
import { DesignConfigItem, MarkConfig } from '@services/design.services'
import { FontItem } from '@services/fonts.services'
import { MARKING_TYPE } from '@constants/common.constants'
import password from 'secure-random-password'

interface ChipArrayProps {
  data: PartItem[]
  side: Side
  settings: DesignConfigItem
  fonts: FontItem[]
  marking?: MarkingItem[]
  svgId: string
  activeMarking?: MarkingItem | null
  onPositionChoose?: (id: number) => void
}

const DesignPreview: React.FC<ChipArrayProps> = ({
  data,
  side,
  settings,
  marking,
  fonts,
  activeMarking,
  onPositionChoose,
}) => {
  const date = new Date()
  const designUUID =
    password.randomPassword({
      length: 8,
      characters: [password.digits, password.upper, password.lower],
    }) +
    '-' +
    date.getTime()

  const renderSVG = () => {
    return _.map(data, (part, i) => {
      const { attributes } = part
      const graph =
        side === Side.Front ? attributes.front_svg : attributes.back_svg
      const color = _.find(settings.parts, { id: part.id })
        ? _.find(settings.parts, { id: part.id })?.color
        : attributes.colors?.fill

      if (graph) {
        return (
          <g key={i}>
            <style>{`g#path-${part.id}-${designUUID} path {
                fill:${color} !important;
           }`}</style>
            <Box
              component="g"
              style={{
                overflow: 'hidden',
              }}
              id={`path-${part.id}-${designUUID}`}
              dangerouslySetInnerHTML={{
                __html: graph,
              }}
            ></Box>
          </g>
        )
      } else {
        return null
      }
    })
  }

  /**
   * Renders active settings markings
   */
  const renderMarkings = () => {
    if (!_.isEmpty(settings.marks)) {
      return _.map(settings.marks, (p, i) => {
        return renderMarkingItem(p, i)
      })
    }
    return null
  }

  /**
   * Renders current activeMarking preview
   */
  const renderEditPreview = () => {
    const item = activeMarking
    const settingsItem = _.find(settings.marks, { id: item?.id })
    if (activeMarking) {
      const position = _.get(item, 'attributes.settings.positions', undefined)
      const isPositionSet = settingsItem?.settings.position_id ? true : false
      const activePosition = _.find(position.values, {
        id: settingsItem?.settings.position_id,
      })

      if (
        isPositionSet &&
        _.get(item, 'attributes.marking_type') !== MARKING_TYPE.LOGO &&
        settingsItem
      ) {
        // this block renders when user choosen position editing text or number
        const currentFont = _.find(
          fonts,
          (f) => Number(f.id) === settingsItem?.settings.family
        )
        const currentSettings = _.find(settings.marks, { id: item?.id })

        return activePosition && position.side === side ? (
          <Box component="g" id={`text-${position.id}`}>
            <text
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                transform: `translate(${activePosition.x}px, ${activePosition.y}px )`,
                fontSize: _.get(currentSettings, 'settings.font_size', 0),
                fill: _.get(currentSettings, 'settings.fill', ''),
                fontFamily: currentFont?.attributes.font_family,
              }}
            >
              {_.get(currentSettings, 'input_data', '')}
            </text>
          </Box>
        ) : null
      } else if (!isPositionSet && item) {
        // this block renders when user not choose position
        return position.side === side
          ? _.map(
              _.get(item, 'attributes.settings.positions.values', []),
              (p, i) => {
                return (
                  <Box
                    key={i}
                    component="g"
                    sx={{
                      '& circle': {
                        opacity: 1,
                        cursor: 'pointer',
                      },
                    }}
                    id={`shape-${i}`}
                  >
                    <circle
                      stroke="white"
                      strokeWidth="2"
                      onClick={() => onPositionChoose && onPositionChoose(p.id)}
                      cx={p.x}
                      cy={p.y}
                      r="10"
                      fill="red"
                    />
                  </Box>
                )
              }
            )
          : null
      } else if (
        isPositionSet &&
        _.get(item, 'attributes.marking_type') === MARKING_TYPE.LOGO &&
        settingsItem
      ) {
        // this block renders when user choosen position editing logo marking
        const activePosition = _.find(position.values, {
          id: settingsItem?.settings.position_id,
        })

        return activePosition && position.side === side ? (
          <Box
            component="g"
            sx={{
              '& circle': {
                opacity: 0.5,
                cursor: 'pointer',
              },
            }}
            id={`shape-${activePosition.id}`}
          >
            <image
              style={{
                transform: `translate(${activePosition.x - 25}px, ${
                  activePosition.y - 25
                }px )`,
              }}
              width="50"
              height="50"
              href={settingsItem?.settings?.image}
            />
          </Box>
        ) : null
      }
    }
    return null
  }

  const renderFontImport = () => {
    if (settings) {
      return (
        <style>
          {_.map(settings.marks, (m: MarkConfig) => {
            const family = _.get(m, 'settings.family', '')
            const currentFont = _.find(fonts, { id: family.toString() })
            const url = _.get(currentFont, 'attributes.style_path', undefined)
            if (url) {
              return `@import url(${url});`
            }
            return ''
          })}
        </style>
      )
    }
    return ''
  }

  const renderMarkingItem = (setting: MarkConfig, index: number) => {
    const item = _.find(marking, { id: setting.id })

    const position = _.get(item, 'attributes.settings.positions', undefined)

    const activePosition = position
      ? _.find(position.values, { id: setting.settings.position_id })
      : undefined

    if (_.get(item, 'attributes.marking_type') !== MARKING_TYPE.LOGO) {
      const currentFont = _.find(
        fonts,
        (f) => Number(f.id) === setting.settings.family
      )

      return activePosition && position.side === side ? (
        <Box key={index} component="g">
          <text
            id={`id=${setting.id}`}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              transform: `translate(${activePosition.x}px, ${activePosition.y}px )`,
              fontSize: _.get(setting, 'settings.font_size', 0),
              fill: _.get(setting, 'settings.fill', ''),
              fontFamily: currentFont?.attributes.font_family,
            }}
          >
            {_.get(setting, 'input_data', '')}
          </text>
        </Box>
      ) : null
    } else {
      return activePosition && position.side === side ? (
        <Box
          key={index}
          component="g"
          sx={{
            '& circle': {
              opacity: 0.5,
              cursor: 'pointer',
            },
          }}
          id={`shape-${activePosition.id}`}
        >
          <image
            style={{
              transform: `translate(${activePosition.x - 25}px, ${
                activePosition.y - 25
              }px )`,
            }}
            width="50"
            height="50"
            href={_.get(setting, 'settings.image', '')}
          />
        </Box>
      ) : null
    }

    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 350 450"
      width="100%"
      height="100%"
      xmlSpace="preserve"
      style={{ filter: ' drop-shadow(rgba(0, 0, 0, 0.2) 1px 3px 7px)' }}
    >
      {/* {renderFontImport()} */}
      {renderSVG()}
      {renderEditPreview()}
      {renderFontImport()}
      {renderMarkings()}
    </svg>
  )
}

export default DesignPreview
