import { helperHide, helperShow } from '@store/common/actions'
import { helper as helperState } from '@store/common/selectors'
import { useDispatch, useSelector } from 'react-redux'

interface ReturnType {
  hide: () => void
  show: () => void
  helper: boolean
}

const useHelper = (): ReturnType => {
  const helper = useSelector(helperState)
  const dispatch = useDispatch()
  const hide = () => dispatch(helperHide())
  const show = () => dispatch(helperShow())

  return {
    helper,
    hide,
    show,
  }
}

export default useHelper
