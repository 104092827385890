import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  phone: '',
  fax: '',
  manager: '',
  status: '',
}

const useFormFilter = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().max(
          100,
          t('SYSCOMMON.ERROR.E000007', {
            field: t('SYSCOMMON.keyword'),
            character: 100,
          })
        ),
        manager: Yup.string().max(
          20,
          t('SYSCOMMON.ERROR.E000007', {
            field: t('SYSCOMMON.keyword'),
            character: 20,
          })
        ),
        phone: Yup.string().max(
          20,
          t('SYSCOMMON.ERROR.E000007', {
            field: t('SYSCOMMON.keyword'),
            character: 20,
          })
        ),
        fax: Yup.string().max(
          20,
          t('SYSCOMMON.ERROR.E000007', {
            field: t('SYSCOMMON.keyword'),
            character: 20,
          })
        ),
        status: Yup.string().nullable(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormFilter
