import Spinner from '@components/@material-extend/Spinner'
import InquiryForm from '@containers/Inquiry/InquiryForm'
import i18n from '@locales/i18n'
import { CheckCircleOutlined } from '@mui/icons-material'
import { Box, Typography, Container, Paper, Alert } from '@mui/material'
import { InquiryParams } from '@services/common.service'
import { sendInquiry } from '@store/common/actions'
import { useAppSelector } from '@store/hooks'
import { createMetaSelector } from '@store/metadata/selectors'
import { ThemeColors } from '@theme/colors'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const Inquiry = () => {
  const dispatch = useDispatch()
  const [sent, setSent] = useState<boolean>(false)

  const _meta = createMetaSelector(sendInquiry)
  const meta = useAppSelector(_meta)

  const handleSubmit = (params: InquiryParams) => {
    dispatch(sendInquiry(params))
  }

  useEffect(() => {
    if (meta.loaded && !meta.pending && !meta.error) {
      setSent(true)
    }
  }, [meta])

  const renderLoader = () => {
    if (meta.pending && !meta.loaded && !meta.error) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1000,
            alignItems: 'center',
          }}
        >
          <Spinner size={14} loading={true} />
        </Box>
      )
    }
    return null
  }

  const renderSent = () => {
    if (sent) {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            {' '}
            {i18n.t('SYSCOMMON.success')}
          </Typography>
          <Box>
            <CheckCircleOutlined
              sx={{ color: ThemeColors.green, fontSize: 40 }}
            />
          </Box>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {i18n.t('SYSCOMMON.email_sent')}
          </Typography>
        </Box>
      )
    }
    return null
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Container maxWidth="md">
        <Typography variant="h2" sx={{ mt: 3, mb: 3 }} align="center">
          {i18n.t('SYSCOMMON.inquiry')}
        </Typography>
        <Paper
          sx={{
            p: 2,
            width: '100%',
            position: 'relative',
            minHeight: 200,
            height: '100%',
            mb: 4,
          }}
        >
          {meta.error && (
            <Box>
              <Alert severity="warning">{i18n.t('ERROR.E000003')}</Alert>
            </Box>
          )}
          {renderLoader()}
          {renderSent()}
          <Box sx={{ zIndex: 100 }}>
            {!sent ? <InquiryForm onSubmit={handleSubmit} meta={meta} /> : null}
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}

export default Inquiry
