import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import password from 'secure-random-password'
import { ContentImageListResponse } from '@services/common.service'

type StateType = {
  toasts: {
    uuid: string
    message: string
    severity: 'success' | 'error' | 'warning' | 'info'
    isDialog?: { url?: string }
  }[]
  redirect?: string
  imgs?: ContentImageListResponse
  helper: boolean
}

const initialState: StateType = {
  toasts: [],
  redirect: undefined,
  imgs: undefined,
  helper: true,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.addToast, (state, action) => {
    // eslint-disable-next-line no-console
    state.toasts = [
      {
        message: action.payload.message,
        severity: action.payload.severity ? action.payload.severity : 'success',
        uuid: password.randomPassword({
          length: 12,
          characters: [
            password.lower,
            password.upper,
            password.digits,
            password.symbols,
          ],
        }),
        isDialog: action.payload.isDialog,
      },
      ...state.toasts,
    ]
  })
  builder.addCase(actions.removeToast, (state, action) => {
    state.toasts = state.toasts.filter((t) => t.uuid !== action.payload)
  })
  builder.addCase(actions.cleanToasts, (state) => {
    state.toasts = []
  })
  builder.addCase(actions.previousRoute, (state, action) => {
    state.redirect = action.payload.path
  })
  builder.addCase(actions.contentImageList.fulfilled, (state, action) => {
    state.imgs = action.payload
  })
  builder.addCase(actions.helperShow, (state) => {
    state.helper = true
  })
  builder.addCase(actions.helperHide, (state) => {
    state.helper = false
  })
})
