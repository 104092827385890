import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from '@theme/index'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { store } from '@store/store'
import { Provider } from 'react-redux'
import ConfirmProvider from '@components/Confirm/ConfirmProvider'
import {
  defaultConfirmationOptions,
  defaultUploadOptions,
} from '@constants/common.constants'
import { SnackBarProvider } from '@components/Toast'
import { UploaderProvider } from '@components/@admin/FileUpload'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<>Loading</>} persistor={persistStore(store)}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider defaultOptions={defaultConfirmationOptions}>
          <UploaderProvider defaultOptions={defaultUploadOptions}>
            <SnackBarProvider />
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
          </UploaderProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
