import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'
import {
  FontItem,
  FontCreateParams,
  FontUpdateParams,
  FontsParams,
  FontStatusParams,
  FontDeleteParams,
} from '@services/fonts.services'
import {
  fontList,
  fontActiveList,
  fontCreate,
  fontUpdate,
  fontDelete,
  fontStatus,
} from '@store/fonts/actions'
import { list, pagemeta } from '@store/fonts/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  data?: FontItem[]
  paging: MetaPage
  meta: Meta
  metaCreate: Meta
  fetch: (params: FontsParams) => void
  activeFontsFetch: () => void
  create: (params: FontCreateParams) => void
  update: (params: FontUpdateParams) => void
  changeStatus: (params: FontStatusParams) => void
  deleteFont: (params: FontDeleteParams) => void
}

const useFonts = (): ReturnType => {
  const data = useAppSelector(list)
  const paging = useAppSelector(pagemeta)
  const dispatch = useDispatch()
  const fetch = (params: FontsParams) => {
    dispatch(fontList(params))
  }
  const activeFontsFetch = () => {
    dispatch(fontActiveList())
  }
  const create = (params: FontCreateParams) => {
    dispatch(fontCreate(params))
  }
  const update = (params: FontUpdateParams) => {
    dispatch(fontUpdate(params))
  }

  const changeStatus = (params: FontStatusParams) => {
    dispatch(fontStatus(params))
  }

  const deleteFont = (params: FontDeleteParams) => {
    dispatch(fontDelete(params))
  }

  const _meta = createMetaSelector(fontList)
  const meta = useAppSelector(_meta)
  const _metaCreate = createMetaSelector(fontCreate)
  const metaCreate = useAppSelector(_metaCreate)

  return {
    fetch,
    activeFontsFetch,
    paging,
    meta,
    data,
    create,
    update,
    deleteFont,
    metaCreate,
    changeStatus,
  }
}

export default useFonts
