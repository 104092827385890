import { Box, Stack } from '@mui/material'
import * as React from 'react'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { PositionItem } from '@services/item.services'
import _ from 'lodash'

interface PositionEditorProps {
  value: Omit<PositionItem, 'id' | 'side'>
  onChange: (values: Omit<PositionItem, 'id' | 'side'>) => void
  onSubmit: (value: Omit<PositionItem, 'id' | 'side'>) => void
}

const PositionEditor: React.FC<PositionEditorProps> = ({
  onChange,
  value,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'x' | 'y'
  ) => {
    const inputValue = Number(e.target.value)
    const transform =
      isNaN(inputValue) ||
      inputValue === null ||
      inputValue === undefined ||
      inputValue > 450
        ? 0
        : inputValue
    if (type === 'x') {
      onChange({ ...value, x: transform })
    } else {
      onChange({ ...value, y: transform })
    }
  }

  const isValid =
    value.x > 0 &&
    value.y &&
    _.isNumber(Number(value.x)) &&
    _.isNumber(Number(value.y))

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%', mt: 1 }}>
      <Box sx={{ height: 80, width: 200 }}>
        <ClassicTextField
          inputProps={{ sx: { textTransform: 'uppercase' } }}
          value={value.x}
          onChange={(e) => handleChange(e, 'x')}
          fullWidth
          label={t('marking.x')}
          placeholder={t('marking.x')}
        />
      </Box>
      <Box sx={{ height: 80, width: 200 }}>
        <ClassicTextField
          inputProps={{ sx: { textTransform: 'uppercase' } }}
          value={value.y}
          onChange={(e) => handleChange(e, 'y')}
          fullWidth
          label={t('marking.y')}
          placeholder={t('marking.y')}
        />
      </Box>
      <LoadingButton
        variant="contained"
        disabled={!isValid}
        color="secondary"
        onClick={() => {
          onSubmit(value)
          onChange({ x: 0, y: 0, isActive: false })
        }}
        sx={{ top: 24, position: 'relative', height: 36 }}
      >
        {t('marking.add_position')}
      </LoadingButton>
    </Stack>
  )
}

export default PositionEditor
