import { Meta } from '@store/metadata/actions/types'
import {
  CompareParams,
  CreateDesignParams,
  DesignCopyDetail,
  DesignCopyParams,
  DesignDetail,
  DesignDetailItem,
  DesignItem,
  DesignShareParams,
  NewParams,
} from '@services/design.services'
import {
  createDesign,
  createNew,
  fontListPublic,
  detail as fetchDetail,
  compareDesign,
  shareDesign,
  copyDesign,
  resetCopy,
} from '@store/design/actions'
import {
  bottom,
  compareItems,
  copyBottom,
  copyDetail,
  copyTop,
  detail,
  fonts,
  sex,
  top,
} from '@store/design/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
import { FontItem } from '@services/fonts.services'
import { addToast, toastParams } from '@store/common/actions'

interface ReturnType {
  meta: Meta
  topData: DesignItem
  bottomData: DesignItem
  fetch: (params: NewParams) => void
  fetchFonts: () => void
  fontsData?: FontItem[]
  create: (params: CreateDesignParams) => void
  data?: DesignDetail
  showDetail: (id: string) => void
  metaDetail: Meta
  compareCodes: (params: CompareParams) => void
  compareList: DesignDetail[]
  toast: (params: toastParams) => void
  share: (params: DesignShareParams) => void
  metaShare: Meta
  fetchCopy: (params: DesignCopyParams) => void
  copy: DesignCopyDetail
  cTop?: DesignDetailItem
  sexData?: number
  cBottom?: DesignDetailItem
  copyRemove: () => void
  metaCreate: Meta
}

const useDesign = (): ReturnType => {
  const toast = (params: toastParams) => {
    dispatch(addToast(params))
  }
  const topData = useAppSelector(top)
  const bottomData = useAppSelector(bottom)
  const data = useAppSelector(detail)
  const dispatch = useDispatch()
  const fetch = (params: NewParams) => {
    dispatch(createNew(params))
  }
  const showDetail = (params: string) => {
    dispatch(fetchDetail(params))
  }
  const compareCodes = (params: CompareParams) => {
    dispatch(compareDesign(params))
  }
  const compareList = useAppSelector(compareItems)
  const fetchFonts = () => dispatch(fontListPublic())
  const fontsData = useAppSelector(fonts)
  const create = (params: CreateDesignParams) => dispatch(createDesign(params))
  const share = (params: DesignShareParams) => dispatch(shareDesign(params))
  const copy = useAppSelector(copyDetail)
  const cBottom = useAppSelector(copyBottom)
  const cTop = useAppSelector(copyTop)
  const fetchCopy = (params: DesignCopyParams) => {
    dispatch(copyDesign(params))
  }
  const sexData = useAppSelector(sex)

  const _meta = createMetaSelector(createNew)
  const meta = useAppSelector(_meta)
  const _metaDetail = createMetaSelector(fetchDetail)
  const metaDetail = useAppSelector(_metaDetail)
  const _metaShare = createMetaSelector(shareDesign)
  const metaShare = useAppSelector(_metaShare)
  const _metaCreate = createMetaSelector(createDesign)
  const metaCreate = useAppSelector(_metaCreate)

  const copyRemove = () => dispatch(resetCopy())

  return {
    meta,
    fetch,
    copyRemove,
    metaDetail,
    copy,
    cBottom,
    cTop,
    metaCreate,
    fetchCopy,
    topData,
    compareList,
    sexData,
    share,
    data,
    compareCodes,
    metaShare,
    toast,
    showDetail,
    bottomData,
    create,
    fetchFonts,
    fontsData,
  }
}

export default useDesign
