import { combineReducers, Reducer } from 'redux'
import metadata from '@store/metadata'
import auth from '@store/auth'
import admins from '@store/admins'
import items from '@store/items'
import common from '@store/common'
import genre from '@store/genre'
import settings from '@store/settings'
import fonts from '@store/fonts'
import shops from '@store/shops'
import order from '@store/order'
import design from '@store/design'
export interface AppState {
  metadata: any
  auth: any
  admins: any
  items: any
  common: any
  genre: any
  settings: any
  fonts: any
  shops: any
  order: any
  design: any
}

const combinedReducers = combineReducers({
  metadata: metadata.reducer,
  auth: auth.reducers,
  admins: admins.reducers,
  common: common.reducers,
  items: items.reducers,
  genre: genre.reducers,
  settings: settings.reducers,
  fonts: fonts.reducers,
  shops: shops.reducers,
  order: order.reducers,
  design: design.reducers,
})

const reducer: Reducer<any> = (state, action) => {
  return combinedReducers(state, action)
}

export default reducer

export type RootState = ReturnType<typeof reducer>
