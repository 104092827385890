import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { Box, Button, Grid } from '@mui/material'
import { AdminsParams } from '@services/admins.services'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFormFilter from './useFormFilter'

export type FilterParams = Omit<AdminsParams, 'per_page' | 'page'>

interface FilterFormProps {
  onFilter: (data: FilterParams) => void
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const { onFilter } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormFilter()
  const { control, handleSubmit } = methods

  const onSubmit = (values: any) => {
    onFilter({
      name: values.name,
      email: values.email,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 1 }} pb={2}>
        <Grid item xs={6}></Grid>
        <Grid item xs={2}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('admins.name')}
                placeholder={t('admins.name')}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="email"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('admins.email')}
                placeholder={t('admins.email')}
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              type="submit"
            >
              {t('SYSCOMMON.search')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default FilterForm
