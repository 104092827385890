import { Box, Typography } from '@mui/material/'
import { BoxProps } from '@mui/system'
import { ReactNode } from 'react'

export type PaperHeaderProps = {
  actions?: ReactNode
  title: string
}

const PaperHeaderReverse: React.FC<BoxProps & PaperHeaderProps> = ({
  actions,
  title,
  ...rest
}) => {
  return (
    <Box
      sx={{
        padding: {
          xs: '2px 8px',
          sm: '2px 8px',
          md: '8px 16px',
        },
        background: ' #fff',
        borderBottom: '1px solid  #eaeded',
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          minHeight: 40,
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 100 }}>
          {actions && actions}
        </Box>
        <Box
          sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PaperHeaderReverse
