import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  status: false,
  woff_path: '',
  woff2_path: '',
  style_path: '',
  thumb: '',
}

const useFormFontEdit = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(
            50,
            t('ERROR.E000002', {
              field: t('fonts.name'),
              character: 100,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('fonts.name'),
            })
          ),
        woff_path: Yup.string().nullable(),
        woff2_path: Yup.string().nullable(),
        style_path: Yup.string().nullable(),
        thumb: Yup.string().nullable(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormFontEdit
