import { Box, ListItem, ListItemText } from '@mui/material'
import { DesignConfigItem, DesignItem } from '@services/design.services'
import * as React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { MarkingItem, PartItem } from '@services/item.services'
import _ from 'lodash'
// import { Done } from '@mui/icons-material'
// import { MARKING_TYPE } from '@constants/common.constants'
import i18n from '@locales/i18n'
import { ThemeColors } from '@theme/colors'

interface ChipArrayProps {
  data: DesignItem
  onClickPart: (value: PartItem) => void
  onClickMarking: (value: MarkingItem) => void
  settings: DesignConfigItem | null
}

const PanelList: React.FC<ChipArrayProps> = ({
  data,
  onClickPart,
  settings,
  onClickMarking,
}) => {
  // const renderDone = (item: MarkingItem) => {
  //   if (
  //     settings &&
  //     _.find(settings.marks, { id: item.id }) &&
  //     _.find(settings.marks, { id: item.id })?.settings.position_id !== '' &&
  //     item.attributes.marking_type !== MARKING_TYPE.LOGO
  //   ) {
  //     return <Done sx={{ mr: 1, fontSize: 14 }} />
  //   } else if (
  //     settings &&
  //     _.find(settings.marks, { id: item.id }) &&
  //     _.find(settings.marks, { id: item.id })?.settings.position_id !== '' &&
  //     item.attributes.marking_type === MARKING_TYPE.LOGO &&
  //     !_.isEmpty(_.find(settings.marks, { id: item.id })?.settings.image)
  //   ) {
  //     return <Done sx={{ mr: 1, fontSize: 14 }} />
  //   }
  //   return null
  // }
  return (
    <>
      <Box
        sx={{
          background: ThemeColors.primary,
          color: '#fff',
          textAlign: 'center',
          fontSize: 14,
          p: 1,
        }}
      >
        {i18n.t('SYSCOMMON.panel_list_text')}
      </Box>
      {!_.isEmpty(data.attributes.item_parts) &&
        data.attributes.item_parts.map((t, i) => {
          if (t.attributes.is_editable) {
            return (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                <ListItem
                  sx={{
                    maxWidth: 600,
                    '&:hover': {
                      background: '#f9f9f9',
                    },
                  }}
                  onClick={() => onClickPart(t)}
                  secondaryAction={<KeyboardArrowRightIcon />}
                >
                  {settings && _.find(settings.parts, { id: t.id }) ? (
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        border: '1px solid #eee',
                        borderRadius: 20,
                        backgroundColor: _.find(settings.parts, { id: t.id })
                          ?.color,

                        mr: 1,
                      }}
                    ></Box>
                  ) : (
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        border: '1px solid #eee',
                        backgroundColor: t.attributes.colors?.fill,

                        mr: 1,
                      }}
                    ></Box>
                  )}

                  <ListItemText
                    sx={{
                      cursor: 'pointer',
                      width: 100,
                      '& .MuiListItemText-primary': {
                        fontSize: 16,
                        fontWeight: 500,
                        pr: 1,
                      },
                    }}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                    primary={t.attributes.name}
                  />
                  <ListItemText
                    sx={{
                      cursor: 'pointer',
                      '& .MuiListItemText-primary': {
                        fontSize: 14,
                        fontWeight: 500,
                      },
                    }}
                    primary={i18n.t('SYSCOMMON.part_list_text')}
                  />
                </ListItem>
              </Box>
            )
          }
        })}
      {!_.isEmpty(data.attributes.item_markings) &&
        data.attributes.item_markings.map((t, i) => {
          return (
            <Box
              key={i}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                borderBottom: '1px solid #ddd',
              }}
            >
              <ListItem
                sx={{
                  maxWidth: 600,
                  '&:hover': {
                    background: '#f9f9f9',
                  },
                }}
                divider
                onClick={() => {
                  onClickMarking(t)
                }}
                secondaryAction={<KeyboardArrowRightIcon />}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    border: '1px solid #eee',
                    background:
                      "url('images/marking_icon.png') no-repeat center center",
                    backgroundSize: 'contain',
                    mr: 1,
                  }}
                ></Box>
                <ListItemText
                  sx={{
                    cursor: 'pointer',
                    width: 100,
                    '& .MuiListItemText-primary': {
                      fontSize: 16,
                      fontWeight: 500,
                    },
                  }}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    style: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  primary={t.attributes.name}
                />
                <ListItemText
                  sx={{
                    cursor: 'pointer',
                    pl: 6,
                    '& .MuiListItemText-primary': {
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  }}
                  primary={i18n.t('SYSCOMMON.marking_list_text')}
                />
              </ListItem>
            </Box>
          )
        })}
    </>
  )
}

export default PanelList
