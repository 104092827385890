import EditorLayout from '@layouts/EditorLayout'
import _ from 'lodash'
import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { LayoutContants } from '@constants/layout.constants'
import i18n from '@locales/i18n'
import { Side, Variant } from '@constants/editor.constants'
import Controller from '@components/@editor-components/Controller'
import { ThemeColors } from '@theme/colors'
import FullScreenModal from '@components/FullScreenModal'
import DetailPrice from '@components/@editor-components/DetailPrice'
import useDesign from './useDesign'
import PreviewLayout from '@components/@editor-components/PreviewLayout'
import usePublicItem from '@containers/Items/usePublicItem'
import Detail from '@components/Detail'
import {
  DesignConfigItem,
  DesignItem,
  MarkConfig,
  PartConfig,
} from '@services/design.services'
import { MarkingItem, PartItem } from '@services/item.services'
import { MARKING_TYPE, SEX } from '@constants/common.constants'
import { Buffer } from 'buffer'
import { useConfirm } from '@components/Confirm'
import PriceContent from '@components/PriceContent'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { transformAttr } from '@utils/helper/common.helpers'
import useHelper from './useHelper'
import { HelperTextType } from '@components/HelperText'
import DesignConfirmDialog from './DesignConfirmDialog'
import { DEFAULT_OPTIONS } from '@components/Confirm/ConfirmProvider'

export type Catalogy = {
  id?: string
  variant: Variant
}

const EditorContainer: React.FC = () => {
  const [search, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState<string | null>(null)
  const [catalogy, setCatalogy] = useState<Catalogy | null>(null)
  const [side, setSide] = useState<Side>(Side.Front)
  const [active, setActive] = useState<Variant>(Variant.Top)
  const [topSettings, setSettingsTops] = useState<DesignConfigItem | null>(null)
  const [bottomSettings, setSettingsBottoms] =
    useState<DesignConfigItem | null>(null)
  const [preview, setPreview] = useState<boolean>(true)
  const [save, setSave] = useState<boolean>(false)

  const [activeColor, setActiveColor] = useState<string>('')

  const [activeItem, setActiveItem] = useState<PartItem | null>(null)
  const [activeMarking, setMarkingsPanel] = useState<MarkingItem | null>(null)

  const childRef = useRef<any>(null)
  const confirm = useConfirm()
  const { helper, hide, show } = useHelper()

  const ref = useRef<any>(null)

  useEffect(() => {
    if (activeMarking) {
      setSide(activeMarking.attributes.settings.positions.side)
    } else {
      setSide(Side.Front)
    }
  }, [activeMarking])

  useEffect(() => {
    setActiveItem(null)
    setMarkingsPanel(null)
  }, [active])

  const {
    fetch,
    topData,
    bottomData,
    fetchFonts,
    fontsData,
    create,
    metaCreate,
    meta,
    sexData,
    fetchCopy,
    cTop,
    cBottom,
    copyRemove,
    copy,
  } = useDesign()

  useEffect(() => {
    // when item changes reset settings and close panel
    if (topData) {
      // initialize settings
      if (!copy && Number(topData.id) !== topSettings?.id) {
        setSettingsTops({ id: Number(topData.id), parts: [], marks: [] })
      }
      setMarkingsPanel(null)
      setActiveItem(null)
      setActive(Variant.Top)
    }
    if (bottomData) {
      if (!copy && Number(bottomData.id) !== bottomSettings?.id) {
        setSettingsBottoms({ id: Number(bottomData.id), parts: [], marks: [] })
      }
      // initialize settings
      setMarkingsPanel(null)
      setActiveItem(null)
    }
    if (!topData && bottomData) {
      setActive(Variant.Bottom)
    }
  }, [bottomData, topData])

  useEffect(() => {
    if (meta.loaded) {
      if (active === Variant.Top && topData === undefined) {
        setCatalogy({ variant: Variant.Top })
      } else if (active === Variant.Bottom && bottomData === undefined) {
        setCatalogy({ variant: Variant.Bottom })
      } else {
        setCatalogy(null)
      }
    }
  }, [active, bottomData, topData])

  const activeMarkingsTopIds = topSettings
    ? _.map(
        _.filter(
          topSettings?.marks,
          (m) =>
            !_.isEmpty(m.settings.position_id) && Boolean(m.is_common) === false
        ),
        (m) => m.id
      )
    : []

  const selectedMarkingTop = topData
    ? _.filter(topData?.attributes?.item_markings, (t) => {
        return activeMarkingsTopIds.includes(t.id)
      })
    : []

  const activeMarkingsBottomIds = bottomSettings
    ? _.map(
        _.filter(
          bottomSettings?.marks,
          (m) =>
            !_.isEmpty(m.settings.position_id) && Boolean(m.is_common) === false
        ),
        (m) => m.id
      )
    : []

  const selectedMarkingBottom = bottomData
    ? _.filter(bottomData?.attributes?.item_markings, (t) => {
        return activeMarkingsBottomIds.includes(t.id)
      })
    : []

  const markingTopTotalPrice = selectedMarkingTop
    ? _.sumBy(selectedMarkingTop, function (o) {
        return o.attributes.additional_price
      })
    : 0
  const markingBottomTotalPrice = selectedMarkingBottom
    ? _.sumBy(selectedMarkingBottom, function (o) {
        return o.attributes.additional_price
      })
    : 0

  const totalPrice =
    (topData ? topData.attributes.price : 0) +
    (bottomData ? bottomData.attributes.price : 0) +
    markingTopTotalPrice +
    markingBottomTotalPrice

  const validateDataArray = (
    designData: DesignItem,
    dataArray?: MarkConfig[]
  ): boolean => {
    // Check if dataArray is an array and empty if there is nothing to validate just return true
    if (!dataArray) {
      return true
    }

    if (_.isArray(dataArray) && _.isEmpty(dataArray)) {
      return true
    }

    // If markings exist Iterate over each item in the array
    for (const item of dataArray) {
      const findMarking = _.find(designData.attributes.item_markings, {
        id: item.id,
      })
      const type = findMarking?.attributes.marking_type

      // Check if the item is an object
      if (
        _.isEmpty(item.input_data) &&
        type !== MARKING_TYPE.LOGO &&
        !_.isEmpty(item.settings.position_id)
      ) {
        return false
      }

      if (
        _.isEmpty(item.settings.image) &&
        type === MARKING_TYPE.LOGO &&
        !_.isEmpty(item.settings.position_id)
      ) {
        return false
      }
    }

    // All checks passed, data array is valid
    return true
  }

  const validateTop = validateDataArray(topData, topSettings?.marks)
  const validateBottom = validateDataArray(bottomData, bottomSettings?.marks)

  const isValid = validateTop && validateBottom

  const {
    detail,
    data,
    tops: topList,
    bottoms: bottomList,
    fetch: fetchList,
  } = usePublicItem()

  const products = catalogy
    ? catalogy?.variant === Variant.Top
      ? _.filter(topList, (t) => t.id !== search.get('t'))
      : _.filter(bottomList, (t) => t.id !== search.get('b'))
    : []

  useEffect(() => {
    if (search.get('t') || search.get('b')) {
      fetch({
        top_id: !_.isEmpty(search.get('t'))
          ? Number(search.get('t'))
          : undefined,
        bottom_id: !_.isEmpty(search.get('b'))
          ? Number(search.get('b'))
          : undefined,
      })
      fetchFonts()
    }
  }, [search])

  useEffect(() => {
    if (search.get('copy')) {
      fetchCopy({ code: search.get('copy') as string })
    }
  }, [search])

  useEffect(() => {
    if (copy) {
      if (cBottom || cTop) {
        setSearchParams({
          b: cBottom ? cBottom.attributes.item_id.toString() : '',
          t: cTop ? cTop.attributes.item_id.toString() : '',
          copy: search.get('copy') as string,
        })
      }
    }
  }, [copy])

  useEffect(() => {
    return () => {
      copyRemove()
    }
  }, [])

  useEffect(() => {
    const settings = active === Variant.Top ? topSettings : bottomSettings
    if (activeItem && settings) {
      const currentColor = _.find(settings.parts, { id: activeItem.id })
        ? _.find(settings.parts, { id: activeItem.id })?.color
        : activeItem.attributes.colors?.fill
      setActiveColor(currentColor as string)
    }
  }, [activeItem])

  useEffect(() => {
    // when item changes reset settings and close panel
    if (cTop && topData) {
      // eslint-disable-next-line no-console
      setSettingsTops({
        id: Number(topData.id),
        parts: _.map(cTop?.attributes.design_parts, (p) => {
          return {
            ..._.omit(p.attributes, 'item_part_id'),
            id: p.attributes.item_part_id.toString(),
          }
        }),
        marks: _.map(cTop?.attributes.design_markings, (p) => {
          const { attributes } = p
          return {
            id: attributes.item_marking_id.toString(),
            is_common: Number(attributes.is_common),
            settings: attributes.settings,
            input_data: attributes.input_data,
          }
        }),
      })
    }
    if (cBottom && bottomData) {
      setSettingsBottoms({
        id: Number(bottomData.id),
        parts: _.map(cBottom?.attributes.design_parts, (p) => {
          return {
            ..._.omit(p.attributes, 'item_part_id'),
            id: p.attributes.item_part_id.toString(),
          }
        }),
        marks: _.map(cBottom?.attributes.design_markings, (p) => {
          return {
            ..._.omit(p.attributes, ['item_marking_id', 'is_common']),
            id: p.attributes.item_marking_id.toString(),
            is_common: Number(p.attributes.is_common),
          }
        }),
      })
    }
  }, [copy, bottomData, topData])

  useEffect(() => {
    if (open) {
      detail(open)
    }
  }, [open])

  useEffect(() => {
    if (catalogy) {
      fetchList({ sex: sexData ? sexData : SEX.UNISEX })
    }
  }, [catalogy])

  const renderContent = () => {
    if (data) {
      return <Detail data={data} />
    }
  }

  const handleColorChange = (color: string, part: PartItem) => {
    setActiveColor(color)
    const newObj: PartConfig = {
      id: part.id,
      color: color,
    }
    if (active === Variant.Top && topSettings) {
      const parts = topSettings.parts
        .filter((obj) => obj.id !== newObj.id)
        .concat(newObj)
      setSettingsTops({
        ...topSettings,
        parts: parts,
      })
    } else if (active === Variant.Bottom && bottomSettings) {
      const parts = bottomSettings.parts
        .filter((obj) => obj.id !== newObj.id)
        .concat(newObj)
      setSettingsBottoms({
        ...bottomSettings,
        parts: parts,
      })
    }
  }

  const handleMarkingEdit = (value: MarkConfig) => {
    if (active === Variant.Top && topSettings) {
      const marks = topSettings.marks
        .filter((obj) => obj.id !== value.id)
        .concat(value)
      setSettingsTops({
        ...topSettings,
        marks: marks,
      })
    } else if (active === Variant.Bottom && bottomSettings) {
      const marks = bottomSettings.marks
        .filter((obj) => obj.id !== value.id)
        .concat(value)
      setSettingsBottoms({
        ...bottomSettings,
        marks: marks,
      })
    }
  }

  const renderPriceContent = () => {
    return (
      <PriceContent
        markingPriceTop={markingTopTotalPrice}
        markingPriceBottom={markingBottomTotalPrice}
        topData={topData}
        bottomData={bottomData}
        markingsTop={selectedMarkingTop}
        markingsBottom={selectedMarkingBottom}
      />
    )
  }

  const handlePositionChoose = (id: number) => {
    // setPreview(false)
    if (activeMarking) {
      childRef.current && childRef.current.setPositionValue(id)
    }
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      setActive(newAlignment as Variant)
    }
  }

  const handleReset = () => {
    if (topData && !search.get('copy')) {
      setSettingsTops({ id: Number(topData.id), parts: [], marks: [] }) // initialize settings
    } else if (copy && cTop) {
      setSettingsTops({
        id: Number(topData.id),
        parts: _.map(cTop?.attributes.design_parts, (p) => {
          return {
            ..._.omit(p.attributes, 'item_part_id'),
            id: p.attributes.item_part_id.toString(),
          }
        }),
        marks: _.map(cTop?.attributes.design_markings, (p) => {
          const { attributes } = p
          return {
            id: attributes.item_marking_id.toString(),
            is_common: Number(attributes.is_common),
            settings: attributes.settings,
            input_data: attributes.input_data,
          }
        }),
      })
    }
    if (bottomData && !search.get('copy')) {
      setSettingsBottoms({ id: Number(bottomData.id), parts: [], marks: [] }) // initialize settings
    } else if (copy && cBottom) {
      setSettingsBottoms({
        id: Number(bottomData.id),
        parts: _.map(cBottom?.attributes.design_parts, (p) => {
          return {
            ..._.omit(p.attributes, 'item_part_id'),
            id: p.attributes.item_part_id.toString(),
          }
        }),
        marks: _.map(cBottom?.attributes.design_markings, (p) => {
          return {
            ..._.omit(p.attributes, ['item_marking_id', 'is_common']),
            id: p.attributes.item_marking_id.toString(),
            is_common: Number(p.attributes.is_common),
          }
        }),
      })
    }
  }

  const handleSubmit = (email: string | null) => {
    setMarkingsPanel(null)
    const items: DesignConfigItem[] = []
    const top = topSettings
      ? {
          ...topSettings,
          marks: _.filter(
            topSettings.marks,
            (m) => !_.isEmpty(m.settings.position_id)
          ),
          front_img: getSvg('top-preview-front'),
          back_img: getSvg('top-preview-back'),
        }
      : null

    const bottom = bottomSettings
      ? {
          ...bottomSettings,
          marks: _.filter(
            bottomSettings.marks,
            (m) => !_.isEmpty(m.settings.position_id)
          ),
          front_img: getSvg('bottom-preview-front'),
          back_img: getSvg('bottom-preview-back'),
        }
      : null

    if (top && search.get('t')) {
      items.push(top)
    }
    if (bottom && search.get('b')) {
      items.push(bottom)
    }

    create({ items: items, email: email })
  }

  const getSvg = (id: string) => {
    const element = document.getElementById(id)
    const string = `${element?.innerHTML}`
    const base64 =
      'data:image/svg+xml;base64,' +
      Buffer.from(string, 'utf-8').toString('base64')

    return base64
  }

  const renderHelper = (): HelperTextType => {
    if (catalogy) {
      return {
        desktop: i18n.t('helper.active_catalogy'),
        mobile: i18n.t('helper.active_catalogy_mobile'),
      }
    }
    if (activeMarking) {
      return {
        desktop: i18n.t('helper.active_marking'),
        mobile: i18n.t('helper.active_marking_mobile'),
      }
    }
    if (activeItem) {
      return {
        desktop: i18n.t('helper.active_color'),
        mobile: i18n.t('helper.active_color_mobile'),
      }
    }
    return {
      desktop: i18n.t('helper.init'),
      mobile: i18n.t('helper.init_mobile'),
    }
  }

  const helperText = renderHelper()

  return (
    <>
      <DesignConfirmDialog
        options={{
          ...DEFAULT_OPTIONS,
          confirmationButtonProps: {
            fullWidth: true,
            variant: 'contained',
            size: 'large',
          },
          cancellationButtonProps: {
            variant: 'outlined',
            fullWidth: true,
            size: 'large',
          },
          dialogProps: {
            maxWidth: 'sm',
          },
          title: i18n.t('designs.save_confirm_title'),
        }}
        onCancel={() => setSave(false)}
        open={save}
        onConfirm={handleSubmit}
        onClose={() => setSave(false)}
      />
      <EditorLayout
        isLoading={metaCreate.pending}
        onCopyRemove={() => copyRemove()}
        onSubmit={() => setSave(true)}
        onReset={() => handleReset()}
        isValid={isValid}
        preview={preview}
        setPreview={setPreview}
        side={side}
        headerAction={
          <ToggleButtonGroup
            exclusive
            fullWidth
            onChange={handleChange}
            value={active}
            aria-label="text formatting"
            sx={{
              borderLeft: '1px solid #222',
              borderBottom: '1px solid #222',
              borderTop: '1px solid #222',
              borderRight: '1px solid #222',
              minHeight: LayoutContants.appbarHeight,
            }}
          >
            <ToggleButton
              disabled={search.get('t') === undefined}
              sx={{
                minWidth: 100,
                border: '0 none',
                color: '#fff',
                backgroundColor: '#b8b8b8',
                borderRight: '1px solid #222 !important',
                '&.MuiToggleButton-root .tab-text': {
                  color: '#fff',
                },
                '&.MuiToggleButton-root img': {
                  filter: 'brightness(0) invert(1)',
                },
                '&.MuiToggleButton-root.Mui-selected img': {
                  filter: 'none',
                },
                '&.MuiToggleButton-root.Mui-selected .tab-text': {
                  color: ThemeColors.primary,
                },
                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#fff',
                },
                '&.MuiToggleButton-root:hover': {
                  backgroundColor: '#b8b8b8',
                },
                '&.MuiToggleButton-root.Mui-selected:hover': {
                  backgroundColor: '#fff',
                },
              }}
              value={Variant.Top}
              aria-label={Variant.Top}
            >
              <Box
                component="img"
                src="/images/topsicon.svg"
                sx={{
                  height: 22,
                  mr: 1,
                }}
              />
              <Typography
                variant="h6"
                sx={{ fontSize: 14 }}
                className="tab-text"
              >
                {i18n.t('SYSCOMMON.tops')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              sx={{
                minWidth: 100,
                border: '0 none',
                color: '#fff',
                backgroundColor: '#b8b8b8',
                '&.MuiToggleButton-root .tab-text': {
                  color: '#fff',
                },
                '&.MuiToggleButton-root.Mui-selected .tab-text': {
                  color: ThemeColors.primary,
                },
                '&.MuiToggleButton-root img': {
                  filter: 'brightness(0) invert(1)',
                },
                '&.MuiToggleButton-root.Mui-selected img': {
                  filter: 'none',
                },
                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#fff',
                },
                '&.MuiToggleButton-root:hover': {
                  backgroundColor: '#b8b8b8',
                },
                '&.MuiToggleButton-root.Mui-selected:hover': {
                  backgroundColor: '#fff',
                },
                '&.MuiToggleButton-root.MuiToggleButtonGroup-grouped:not(:first-of-type)':
                  {
                    borderLeft: '1px solid #000',
                  },
              }}
              disabled={search.get('b') === undefined}
              value={Variant.Bottom}
              aria-label={Variant.Bottom}
            >
              <Box
                component="img"
                src="/images/bottomsicon.svg"
                sx={{
                  height: 22,
                  mr: 1,
                }}
              />

              <Typography
                variant="h6"
                sx={{ fontSize: 14 }}
                className="tab-text"
              >
                {i18n.t('SYSCOMMON.bottoms')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        }
        onChangeSide={(side) => setSide(side)}
        bottom={
          <DetailPrice content={renderPriceContent()} total={totalPrice} />
        }
        control={
          <>
            <Controller
              setPreview={setPreview}
              products={products ? products : []}
              fonts={fontsData}
              settings={active === Variant.Top ? topSettings : bottomSettings}
              data={activeItem}
              onClose={() => {
                setActiveItem(null)
                setPreview(false)
              }}
              setMarkingItem={(value) => {
                setMarkingsPanel(value)
                ref.current?.resetScroll()
              }}
              onCloseCatalogy={() => setCatalogy(null)}
              onChangeSide={(side) => setSide(side)}
              ref={{ position: childRef, scroll: ref } as any}
              onColorChange={handleColorChange}
              value={active}
              top={topData}
              side={side}
              onChoose={(id) => {
                confirm({
                  title: i18n.t('designs.confirm_change_title'),
                  description: i18n.t('designs.confirm_change_desc'),
                })
                  .then(() => {
                    if (catalogy?.variant === Variant.Top) {
                      setSearchParams({
                        b: search.get('b') as string,
                        t: id,
                      })
                    } else {
                      setSearchParams({ t: search.get('t') as string, b: id })
                    }
                    setCatalogy(null)
                    setPreview(true)
                  })
                  .catch(() => null)
              }}
              catalogy={catalogy}
              setActiveItem={setActiveItem}
              bottom={bottomData}
              activeMarking={activeMarking}
              onMarkingChange={handleMarkingEdit}
              onCloseMarking={() => {
                setMarkingsPanel(null)
                setPreview(false)
              }}
              color={activeColor}
            />
          </>
        }
      >
        <PreviewLayout
          isHelperActive={helper}
          helperText={helperText}
          onShowHelper={() => show()}
          activeMarking={activeMarking}
          onRemove={(v) => {
            confirm({
              title: i18n.t('designs.confirm_remove_design_title'),
              description: i18n.t('designs.confirm_remove_design_desc'),
            })
              .then(() => {
                if (v === Variant.Top) {
                  setSearchParams({
                    b: search.get('b') as string,
                  })
                } else {
                  setSearchParams({
                    t: search.get('t') as string,
                  })
                }
              })
              .catch(() => null)
          }}
          onClickChangeItem={(id, v) => {
            setCatalogy({ id: id, variant: v })
            setPreview(false)
          }}
          active={active}
          onCloseHelper={() => hide()}
          onClickDetail={setOpen}
          topDetail={topData}
          bottomDetail={bottomData}
          onPositionChoose={handlePositionChoose}
          fonts={fontsData ? fontsData : []}
          topSettings={topSettings}
          bottomSettings={bottomSettings}
          side={side}
        />
      </EditorLayout>
      <FullScreenModal
        content={renderContent()}
        open={open ? true : false}
        onClose={() => setOpen(null)}
      />
    </>
  )
}

export default EditorContainer
