import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { MainMenuItem } from '@constants/menu.constants'
import {
  Button,
  Container,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Hamburger from './Hamburger'
import {
  LayoutContants,
  SearchOptions,
  SEARCH_TYPE,
} from '@constants/layout.constants'
import { ThemeColors } from '@theme/colors'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SelectWithOption, {
  SearchOptionItem,
} from '@components/SearchWithOption'
import SelectWithOptionMobile from '@components/SearchWithOption/SelectWithOptionMobile'

export interface AdminHeaderProps {
  data: MainMenuItem[]
  setOpen: (open: boolean) => void
  open: boolean
}

export const Logo = () => {
  return (
    <Box
      component="a"
      href="/"
      sx={{
        height: 50,
        cursor: 'pointer',
        textDecoration: 'none',
        fontWeight: 700,
        fontFamily: "'Noto Sans JP'",
        color: ThemeColors.secondary,
        fontSize: 22,
        padding: '8px 0px 8px 0px',
      }}
    >
      <Box
        sx={{ height: '100%', width: 'auto' }}
        component="img"
        src="/images/pclogo.png"
      />
    </Box>
  )
}

const Header: React.FC<AdminHeaderProps> = ({ data, setOpen, open }) => {
  const [value, setValue] = useState<string>('')
  const [selected, setSelected] = useState<SearchOptionItem>(SearchOptions[0])
  const navigate = useNavigate()
  const location = useLocation()

  const handleSearch = (value: string) => {
    setValue(value)
  }
  const onSearch = () => {
    if (selected.id === SEARCH_TYPE.DESIGN) {
      navigate(`/design/${value}`, { replace: true })
    } else {
      navigate(`/order/${value}`, { replace: true })
    }
    setValue('')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-console
    console.log(location)
  }, [location])

  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <>
      <Box>
        <AppBar
          elevation={0}
          position="fixed"
          color="primary"
          sx={{
            background: '#fff',
            borderBottom: '1px solid #e3e4e5',
            boxShadow: '0 1px 3px rgb(0 0 0 / 4%)',
            '& .MuiPaper-root-MuiAppBar-root': {
              borderRadius: 0,
            },
          }}
        >
          <Toolbar
            sx={{
              '&.MuiToolbar-root': {
                minHeight: LayoutContants.appbarHeight,
                paddingLeft: 1,
                paddingRight: 1,
              },
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'none',
                  lg: 'none',
                },
              }}
            >
              <Hamburger open={open} setOpen={() => setOpen(!open)} />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: { xs: 40, sm: 40, md: 0, lg: 0 },
                top: 0,
                bottom: 0,
                p: 1,
              }}
            >
              <Logo />
            </Box>
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                    lg: 'block',
                  },
                  pl: { md: 8, lg: 8, xl: 0 },
                }}
              >
                {data.map((menu: MainMenuItem) => (
                  <Button
                    key={menu.label}
                    variant="text"
                    sx={{
                      pl: 2,
                      pr: 2,
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: ThemeColors.primary,
                      }}
                      to={menu.route}
                    >
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: ThemeColors.primary,
                          fontWeight: 600,
                          '&:hover': {
                            color: ThemeColors.secondary,
                            transition: 'all 0.4s ease',
                          },
                        }}
                      >
                        {menu.label}
                      </Typography>
                    </Link>
                  </Button>
                ))}
              </Box>
            </Container>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'flex',
                },
                position: 'absolute',
                right: 15,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 1,
                width: {
                  xs: '300px',
                  sm: '300px',
                  md: 'auto',
                  lg: 'auto',
                  xl: 'auto',
                },
              }}
            >
              <SelectWithOption
                setSelected={setSelected}
                onSearch={onSearch}
                selectedValue={selected}
                options={SearchOptions}
                value={value}
                onChange={handleSearch}
              />
            </Box>
          </Toolbar>
          {isMobile ? (
            <Box sx={{ borderTop: '1px solid #ccc', padding: '8px 16px' }}>
              <SelectWithOptionMobile
                setSelected={setSelected}
                onSearch={onSearch}
                selectedValue={selected}
                options={SearchOptions}
                value={value}
                onChange={handleSearch}
              />
            </Box>
          ) : null}
        </AppBar>
      </Box>
    </>
  )
}

export default Header
