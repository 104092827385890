import { MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'

export type AdminItem = {
  id: string
  type: 'admin'
  attributes: {
    name: string
    email: string
    password?: string
  }
}
export type AdminDetail = {
  data: AdminItem
}

export type AdminsParams = {
  page?: number
  per_page?: number
  name?: string | null
  email?: string | null
}

export interface AdminCreateParams {
  name: string
  email: string
  password: string
}

export interface AdminUpdateParams {
  id: string
  name?: string
  email?: string
  new_password?: string
  new_password_confirm?: string
}

export type AdminsResponse = {
  data: AdminItem[]
  meta: MetaPage
}

export type AdminDeleteParams = {
  ids: number[]
}

export type AdminstatusResponse = {
  data: string
}

export const list = async (params: AdminsParams): Promise<AdminsResponse> => {
  const { data } = await api.get<AdminsResponse>(URI.ADMIN_USERS, { params })
  return data
}

export const create = async (
  params: AdminCreateParams
): Promise<AdminDetail> => {
  const { data } = await api.post<AdminDetail>(URI.ADMIN_USERS, params)
  return data
}

export const update = async (
  params: AdminUpdateParams
): Promise<AdminDetail> => {
  const { data } = await api.put<AdminDetail>(
    URI.ADMIN_USERS + '/' + params.id,
    params
  )
  return data
}

export const deleteAdmin = async (
  params: AdminDeleteParams
): Promise<AdminsResponse> => {
  const { data } = await api.delete<AdminsResponse>(
    URI.ADMIN_USERS + '/delete',
    {
      params,
    }
  )
  return data
}

export const detail = async (id: string): Promise<AdminDetail> => {
  const { data } = await api.get<AdminDetail>(`${URI.ADMIN_USERS}/${id}`)
  return data
}
