import { UploadOptions } from '@components/@admin/FileUpload/types'
import { ConfirmOptions } from '@components/Confirm/types'
import i18n from '@locales/i18n'
export const SIMPLE_DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss'
export const SIMPLE_DATE_FORMAT = 'YYYY/MM/DD'
export type MetaPage = {
  current_page: number
  total_pages: number
  total_count: number
  per_page: number
}

export enum PROCESS_STATUS {
  ITEM_STATUS_DRAFT = 1,
  ITEM_STATUS_PUBLISHED = 2,
  ITEM_STATUS_DELETED = 3,
  ITEM_STATUS_IN_PROGRESS = 0,
}

export enum FONT_STATUS {
  FONT_STATUS_PUBLISHED = 1,
  FONT_STATUS_DRAFT = 2,
  FONT_STATUS_DELETED = 3,
}

export enum SHOP_STATUS {
  SHOP_STATUS_DRAFT = 1,
  SHOP_STATUS_PUBLISHED = 2,
}

export enum SEX {
  UNISEX = 1,
  FEMALE = 2,
}

export enum GENRE_TYPES {
  TOPS = 1,
  BOTTOMS = 2,
}

export const StatusOptions = [
  {
    value: '',
    name: 'None',
  },
  {
    value: PROCESS_STATUS.ITEM_STATUS_DRAFT,
    name: i18n.t('SYSCOMMON.private'),
  },
  {
    value: PROCESS_STATUS.ITEM_STATUS_PUBLISHED,
    name: i18n.t('SYSCOMMON.publish'),
  },
  {
    value: PROCESS_STATUS.ITEM_STATUS_IN_PROGRESS,
    name: i18n.t('SYSCOMMON.in_progress'),
  },
  {
    value: PROCESS_STATUS.ITEM_STATUS_DELETED,
    name: i18n.t('SYSCOMMON.delete'),
  },
]

export const SexOptions = [
  {
    value: '',
    name: 'None',
  },
  {
    value: SEX.UNISEX,
    name: 'Mens/Unisex',
  },
  {
    value: SEX.FEMALE,
    name: 'Ladies',
  },
]

export const GenreTypeOptions = [
  {
    value: GENRE_TYPES.TOPS,
    name: i18n.t('SYSCOMMON.tops'),
  },
  {
    value: GENRE_TYPES.BOTTOMS,
    name: i18n.t('SYSCOMMON.bottoms'),
  },
]

export const FontStatusOptions = [
  {
    value: '',
    name: 'None',
  },
  {
    value: FONT_STATUS.FONT_STATUS_DRAFT,
    name: i18n.t('SYSCOMMON.private'),
  },
  {
    value: FONT_STATUS.FONT_STATUS_PUBLISHED,
    name: i18n.t('SYSCOMMON.publish'),
  },
  {
    value: FONT_STATUS.FONT_STATUS_DELETED,
    name: i18n.t('SYSCOMMON.deleted'),
  },
]

export const ShopStatusOptions = [
  {
    value: '',
    name: 'None',
  },
  {
    value: SHOP_STATUS.SHOP_STATUS_DRAFT,
    name: i18n.t('SYSCOMMON.private'),
  },
  {
    value: SHOP_STATUS.SHOP_STATUS_PUBLISHED,
    name: i18n.t('SYSCOMMON.publish'),
  },
]

export const defaultConfirmationOptions: ConfirmOptions = {
  confirmationButtonProps: {
    fullWidth: true,
    variant: 'contained',
    size: 'large',
  },
  cancellationButtonProps: {
    variant: 'outlined',
    fullWidth: true,
    size: 'large',
  },
  dialogProps: {
    maxWidth: 'sm',
  },
}

export const defaultUploadOptions: UploadOptions = {
  accept: {
    'image/*': ['.jpeg', '.png', 'jpg', 'gif'],
  },
  maxSize: 4194304, // 4mb max default size
  title: i18n.t('SYSCOMMON.default_upload_title'),
}
export const defaultCssUploadOptions: UploadOptions = {
  accept: {
    'text/css': ['.css', '.scss'],
  },
  title: i18n.t('SYSCOMMON.default_upload_title'),
}

export const defaultWoffUploadOptions: UploadOptions = {
  accept: {
    'font/woff': ['.woff'],
    'font/woff2': ['.woff2'],
  },
  maxSize: 10194304, // 4mb max default size
  title: i18n.t('SYSCOMMON.default_upload_title'),
}

export const defaultPartsUploadOptions: UploadOptions = {
  accept: {
    'image/*': ['.svg'],
  },
  title: i18n.t('SYSCOMMON.default_upload_title'),
}

export enum BOOLEAN {
  TRUE = 1,
  FALSE = 0,
}

export enum MARKING_TYPE {
  TEXT = 1,
  NUMBER = 2,
  LOGO = 3,
}
