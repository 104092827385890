import {
  Item,
  ItemPartOrderParams,
  ItemPartUpdate,
  MarkingCreateParams,
  MarkingDeleteParams,
  MarkingItem,
  MarkingUpdateParams,
  MultiCreateParams,
  PartDeleteParams,
} from '@services/item.services'
import { useAppSelector } from '@store/hooks'
import {
  partCreate,
  partList,
  partDelete,
  partOrderChange,
  partUpdate,
  itemDetail,
  markingList,
  markingCreate,
  markingUpdate,
  markingDelete,
} from '@store/items/actions'
import { detail, markingData, partListData } from '@store/items/selectors'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { useDispatch, useSelector } from 'react-redux'

interface ReturnType {
  metaCreate: Meta
  create: (params: MultiCreateParams) => void
  fetch: (id: string) => void
  meta: Meta
  editPart: (params: ItemPartUpdate) => void
  list: any
  metaDragg: Meta
  deletePart: (params: PartDeleteParams) => void
  orderChange: (params: ItemPartOrderParams) => void
  item: Item
  fetchDetail: (id: string) => void
  fetchMarkings: (id: string) => void
  markings: MarkingItem[]
  createMarking: (params: MarkingCreateParams) => void
  updateMarking: (params: MarkingUpdateParams) => void
  deleteMarking: (params: MarkingDeleteParams) => void
  metaItem: Meta
}

const useParts = (): ReturnType => {
  const item = useSelector(detail)
  const list = useSelector(partListData)
  const markings = useSelector(markingData)
  const dispatch = useDispatch()
  const create = (params: MultiCreateParams) => {
    dispatch(partCreate(params))
  }
  const editPart = (params: ItemPartUpdate) => {
    dispatch(partUpdate(params))
  }
  const fetch = (params: string) => {
    dispatch(partList(params))
  }

  const createMarking = (params: MarkingCreateParams) => {
    dispatch(markingCreate(params))
  }

  const updateMarking = (params: MarkingUpdateParams) => {
    dispatch(markingUpdate(params))
  }

  const deleteMarking = (params: MarkingDeleteParams) => {
    dispatch(markingDelete(params))
  }

  const fetchMarkings = (params: string) => {
    dispatch(markingList(params))
  }
  const fetchDetail = (id: string) => {
    dispatch(itemDetail(id))
  }

  const deletePart = (params: PartDeleteParams) => {
    dispatch(partDelete(params))
  }
  const orderChange = (params: ItemPartOrderParams) => {
    dispatch(partOrderChange(params))
  }

  const _metaItem = createMetaSelector(partList)
  const metaItem = useAppSelector(_metaItem)

  const _meta = createMetaSelector(partList)
  const meta = useAppSelector(_meta)

  const _metaCreate = createMetaSelector(partCreate)
  const metaCreate = useAppSelector(_metaCreate)

  const _metaDragg = createMetaSelector(partOrderChange)
  const metaDragg = useAppSelector(_metaDragg)

  return {
    metaCreate,
    deletePart,
    fetchMarkings,
    metaDragg,
    create,
    updateMarking,
    orderChange,
    metaItem,
    createMarking,
    deleteMarking,
    editPart,
    fetch,
    markings,
    meta,
    item,
    list,
    fetchDetail,
  }
}

export default useParts
