import { MetaPage } from '@constants/common.constants'
import { GenreItem } from '@services/genre.services'
import {
  ItemListParams,
  Item,
  ItemStatusParams,
  ItemCreateParams,
} from '@services/item.services'
import { genreList } from '@store/genre/actions'
import { genreListData } from '@store/genre/selectors'
import { useAppSelector } from '@store/hooks'
import {
  completeItem,
  itemCreate,
  itemList,
  itemStatus,
  resetDeniedIds,
} from '@store/items/actions'
import { ITEM_ACTION_TYPE } from '@store/items/actions/types'
import { deniedIds, list, pagemeta } from '@store/items/selectors'
import { clearMetaData } from '@store/metadata/actions'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { useDispatch } from 'react-redux'

interface ReturnType {
  data?: Item[]
  paging: MetaPage
  meta: Meta
  metaCreate: Meta
  fetch: (params: ItemListParams) => void
  changeStatus: (params: ItemStatusParams) => void
  fetchGenre: () => void
  genre?: GenreItem[]
  create: (params: ItemCreateParams) => void
  clearCreateMeta: () => void
  complete: (id: string) => void
  denyIds?: number[]
  clear: () => void
}

const useItemList = (): ReturnType => {
  const data = useAppSelector(list)
  const genre = useAppSelector(genreListData)
  const paging = useAppSelector(pagemeta)
  const denyIds = useAppSelector(deniedIds)
  const dispatch = useDispatch()
  const fetch = (params: ItemListParams) => {
    dispatch(itemList(params))
  }
  const changeStatus = (params: ItemStatusParams) => {
    dispatch(itemStatus(params))
  }
  const complete = (id: string) => {
    dispatch(completeItem(id))
  }
  const create = (params: ItemCreateParams) => {
    dispatch(itemCreate(params))
  }
  const fetchGenre = () => {
    dispatch(genreList())
  }
  const clear = () => {
    dispatch(resetDeniedIds())
  }
  const _meta = createMetaSelector(itemList)
  const meta = useAppSelector(_meta)
  const _metaCreate = createMetaSelector(itemCreate)
  const metaCreate = useAppSelector(_metaCreate)
  const clearCreateMeta = () => {
    dispatch(clearMetaData(ITEM_ACTION_TYPE.CREATE))
  }
  return {
    paging,
    meta,
    fetch,
    changeStatus,
    fetchGenre,
    clearCreateMeta,
    metaCreate,
    data,
    genre,
    create,
    complete,
    denyIds,
    clear,
  }
}

export default useItemList
