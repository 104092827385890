import React, { ReactNode, useState } from 'react'
import {
  Box,
  Typography,
  Stack,
  Slide,
  Divider,
  useMediaQuery,
} from '@mui/material'
import { BoxProps } from '@mui/system'
import i18n from '@locales/i18n'
import { currency } from 'utils/helper/common.helpers'
import { ThemeColors } from '@theme/colors'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Close } from '@mui/icons-material'

interface DetailPriceProps extends BoxProps {
  total: number
  content: ReactNode
}

const DetailPrice: React.FC<DetailPriceProps> = ({
  total,
  content,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <>
      <Box
        sx={{
          width: '100%',
          boxShadow: '0px -2px 4px rgb(0 0 0 / 20%)',
          position: 'absolute',
          zIndex: 1000,
          bottom: isMobile ? 40 : 0,
          ...rest.sx,
        }}
      >
        <Stack
          sx={{
            borderTop: '1px solid #ccc',
            p: 1,
            background: '#fff',
            color: '#222',
          }}
          direction="row"
          justifyContent={'center'}
          alignItems="center"
        >
          <Typography variant="body1">
            {i18n.t('SYSCOMMON.total_price')}
          </Typography>
          <Typography variant="h4" sx={{ color: ThemeColors.secondary }}>
            {currency(total)}
          </Typography>
        </Stack>
        <Divider sx={{ borderColor: '#ccc' }} />
        <Box
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            p: 1,
            right: 0,
            top: '5px',
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? <Close /> : <KeyboardArrowDownIcon />}
        </Box>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit timeout={30}>
          <Box>{content}</Box>
        </Slide>
      </Box>
    </>
  )
}

export default DetailPrice
