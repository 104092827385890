import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  a: '',
  b: '',
  c: '',
}

const useCompareForm = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        a: Yup.string().nullable(),
        b: Yup.string().nullable(),
        c: Yup.string().nullable(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useCompareForm
