import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/item.services'
import { ITEM_ACTION_TYPE } from './types'

export const itemList = createAsyncThunk<
  services.ItemListResponse,
  services.ItemListParams
>(ITEM_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res: any = await services.list(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const itemCreate = createAsyncThunk<
  services.ItemCreateResponse,
  services.ItemCreateParams
>(ITEM_ACTION_TYPE.CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const itemDetail = createAsyncThunk<services.ItemCreateResponse, string>(
  ITEM_ACTION_TYPE.DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.detail(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const itemUpdate = createAsyncThunk<
  services.ItemCreateResponse,
  services.ItemUpdateParams
>(ITEM_ACTION_TYPE.UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.update(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const itemStatus = createAsyncThunk<
  services.ItemStatusResponse,
  services.ItemStatusParams
>(ITEM_ACTION_TYPE.STATUS, async (params, { rejectWithValue }) => {
  try {
    const res = await services.status(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchDetail =
  (id: string) =>
  async (dispatch: any): Promise<any> => {
    Promise.resolve(dispatch(resetDetail())).then(() =>
      dispatch(itemDetail(id))
    )
  }

export const imageList = createAsyncThunk<services.ImageListResponse, string>(
  ITEM_ACTION_TYPE.IMAGE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.listImage(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const imageCreate = createAsyncThunk<
  services.ImageCreateResponse,
  services.ImageCreateParams
>(ITEM_ACTION_TYPE.IMAGE_CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.createImage(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const imageDelete = createAsyncThunk<
  services.ImageDeleteResponse,
  services.ImageDeleteParams
>(ITEM_ACTION_TYPE.IMAGE_DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteImage(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const partCreate = createAsyncThunk<
  services.PartCreateResponse,
  services.MultiCreateParams
>(ITEM_ACTION_TYPE.PART_CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partCreate(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const partUpdate = createAsyncThunk<
  services.PartUpdateResponse,
  services.ItemPartUpdate
>(ITEM_ACTION_TYPE.PART_UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partUpdate(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const partList = createAsyncThunk<services.PartListResponse, string>(
  ITEM_ACTION_TYPE.PART_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partList(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const partDelete = createAsyncThunk<any, services.PartDeleteParams>(
  ITEM_ACTION_TYPE.PART_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.partDelete(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const partOrderChange = createAsyncThunk<
  services.PartListResponse,
  services.ItemPartOrderParams
>(ITEM_ACTION_TYPE.PART_ORDER, async (params, { rejectWithValue }) => {
  try {
    const res = await services.partOrder(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const markingCreate = createAsyncThunk<
  services.MarkingCreateResponse,
  services.MarkingCreateParams
>(ITEM_ACTION_TYPE.MARKING_CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.mCreate(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const markingUpdate = createAsyncThunk<
  services.MarkingCreateResponse,
  services.MarkingUpdateParams
>(ITEM_ACTION_TYPE.MARKING_UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.mUpdate(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const markingList = createAsyncThunk<
  services.MarkingListResponse,
  string
>(ITEM_ACTION_TYPE.MARKING_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.mList(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const markingDelete = createAsyncThunk<
  any,
  services.MarkingDeleteParams
>(ITEM_ACTION_TYPE.MARKING_DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.mDelete(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchTopList = createAsyncThunk<
  services.TopListResponse,
  services.TopListParams
>(ITEM_ACTION_TYPE.TOP_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.topList(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const publicItem = createAsyncThunk<services.PublicItemResponse, string>(
  ITEM_ACTION_TYPE.PUBLIC_ITEM,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.publicItemDetail(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const completeItem = createAsyncThunk<any, string>(
  ITEM_ACTION_TYPE.COMPLETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.complete(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetDetail = createAction('items/resetDetail')

export const fetchPublicDetail =
  (id: string) =>
  async (dispatch: any): Promise<any> => {
    Promise.resolve(dispatch(resetDetail())).then(() =>
      dispatch(publicItem(id))
    )
  }

export const resetDeniedIds = createAction('items/resetDeniedIds')
