import ClassicSelect from '@components/@material-extend/ClassicSelect'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { SexOptions, StatusOptions } from '@constants/common.constants'
import { Box, Button, Grid, ListItemText, MenuItem } from '@mui/material'
import { GenreItem } from '@services/genre.services'
import { ItemListParams } from '@services/item.services'
import _ from 'lodash'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFormFilter from './useFormFilter'

export type FilterParams = Omit<ItemListParams, 'per_page' | 'page'>

interface FilterFormProps {
  onFilter: (data: FilterParams) => void
  genre?: GenreItem[]
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const { onFilter, genre } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormFilter()
  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    // reset,
  } = methods

  const onSubmit = (values: any) => {
    onFilter({
      name: values.name,
      status: !_.isEmpty(values.status) ? values.status : null,
      sex: !_.isEmpty(values.sex) ? values.sex : [],
      genre_id: !_.isEmpty(values.genre_id) ? values.genre_id : null,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 1 }} pb={2}>
        <Grid item xs={2}>
          {' '}
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('SYSCOMMON.item_name')}
                placeholder={t('SYSCOMMON.item_name')}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ width: '100%' }}>
            <Controller
              control={control}
              name="sex"
              render={({ field: { onChange, value } }) => (
                <ClassicSelect
                  label={t('SYSCOMMON.sex')}
                  value={value}
                  onChange={onChange}
                >
                  {SexOptions.map((item, k) => (
                    <MenuItem key={k} value={item.value}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </ClassicSelect>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ width: '100%' }}>
            <Controller
              name="genre_id"
              control={control}
              render={({ field: { onChange, value } }: FieldValues) => (
                <ClassicSelect
                  label={t('SYSCOMMON.genre')}
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem key={-1} value={''}>
                    <ListItemText primary={'None'} />
                  </MenuItem>
                  {genre
                    ? genre.map((item, k) => (
                        <MenuItem key={k} value={item.id}>
                          <ListItemText primary={item.attributes.name} />
                        </MenuItem>
                      ))
                    : 'Loading'}
                </ClassicSelect>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <ClassicSelect
                  label={t('SYSCOMMON.status')}
                  value={value}
                  onChange={onChange}
                >
                  {StatusOptions.map((item, k) => (
                    <MenuItem key={k} value={item.value}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </ClassicSelect>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              type="submit"
            >
              {t('SYSCOMMON.search')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default FilterForm
