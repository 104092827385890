import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ThemeColors } from '@theme/colors'
import i18n from '@locales/i18n'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
  return (
    <Box sx={{ position: 'relative', background: ThemeColors.primary }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box
          justifyContent={'center'}
          alignItems="center"
          flexDirection={'column'}
          display="flex"
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
            }}
          >
            <Stack direction="row">
              <Link
                to="/terms"
                style={{ color: ThemeColors.text[500], textDecoration: 'none' }}
              >
                <Typography
                  sx={{
                    borderRight: '1px solid #222',
                    padding: '0 20px',
                    '&:hover': {
                      color: '#fff',
                      tranistion: 'all 0.4s ease',
                    },
                  }}
                >
                  {i18n.t('SYSCOMMON.terms')}
                </Typography>
              </Link>
              <Link
                to="/privacy"
                style={{
                  color: ThemeColors.text[500],
                  textDecoration: 'none',
                }}
              >
                <Typography
                  sx={{
                    padding: '0 10px',
                    '&:hover': {
                      color: '#fff',
                      tranistion: 'all 0.4s ease',
                    },
                  }}
                >
                  {i18n.t('SYSCOMMON.privacy')}
                </Typography>
              </Link>
              <Link
                to="/howto"
                style={{
                  color: ThemeColors.text[500],
                  textDecoration: 'none',
                }}
              >
                <Typography
                  sx={{
                    padding: '0 10px',
                    '&:hover': {
                      color: '#fff',
                      tranistion: 'all 0.4s ease',
                    },
                  }}
                >
                  {i18n.t('SYSCOMMON.howto')}
                </Typography>
              </Link>
            </Stack>
          </Box>
          <Box sx={{ p: 0.5, color: '#fff' }}>
            Copyright © Accele. All Rights Reserved
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
