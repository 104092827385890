import { Box, Stack } from '@mui/material'
import * as React from 'react'
import useColorAddForm from './useColorAddForm'
import { FieldValues } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import HorizontalTextField from '@components/@material-extend/HorizontalTextField'

interface ColorEditorProps {
  colors?: string[]
  onChange: (values: any) => void
}

const ColorEditor: React.FC<ColorEditorProps> = ({ onChange, colors }) => {
  const { Controller, methods } = useColorAddForm(colors)

  const {
    control: control2,
    formState: { errors, isValid },
    watch,
    setError,
    setValue,
  } = methods

  const color = watch('color')

  const { t } = useTranslation()

  const onSubmit = () => {
    onChange(color)
    setValue('color', '')
    setError('color', { type: 'focus' }, { shouldFocus: true })
  }

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Box sx={{ height: 60, width: 150 }}>
        <Controller
          name="color"
          control={control2}
          render={({
            field: { ref, value, onChange, ...rest },
          }: FieldValues) => (
            <>
              <HorizontalTextField
                {...rest}
                startAdornment={
                  <Box
                    sx={{
                      width: 45,
                      height: 25,
                      border: '1px solid #eee',
                      backgroundColor: value,
                      mr: 2,
                    }}
                  ></Box>
                }
                inputRef={ref}
                inputProps={{ sx: { textTransform: 'uppercase' } }}
                value={value}
                labelWidth={40}
                onChange={onChange}
                fullWidth
                label={t('part.color')}
                placeholder={t('part.color')}
                error={errors.color ? true : false}
                helperText={errors.color ? errors.color.message : ''}
              />
            </>
          )}
        />
      </Box>
      <LoadingButton
        onClick={() => onSubmit()}
        variant="contained"
        disabled={!isValid}
        color="secondary"
        sx={{ position: 'relative', height: 36 }}
      >
        {t('part.add_color')}
      </LoadingButton>
    </Stack>
  )
}

export default ColorEditor
