import { DragHandle } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'

const DraggHandle: React.FC = (props) => {
  return (
    <Box {...props}>
      <DragHandle />
    </Box>
  )
}
export default DraggHandle
