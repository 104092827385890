import React from 'react'
import { ListItem, ListItemIcon, Typography } from '@mui/material/'
import { MenuItem } from '@constants/menu.constants'
import { NavLink } from 'react-router-dom'

interface MainMenuItemProps {
  route: MenuItem
}

const MainMenuItem: React.FC<MainMenuItemProps> = ({ route }) => {
  return (
    <NavLink
      to={route.path}
      style={{ textDecoration: 'none' }}
      test-id="menu-item"
      className={({ isActive }) => (isActive ? 'active-link' : 'link')}
    >
      <ListItem
        sx={{
          paddingLeft: '20px',
          paddingRight: '20px',
          cursor: 'pointer',
          color: '#768191',
          textDecoration: 'none',
          padding: '12px 40px 12px 18px',
          position: 'relative',
          fontSize: '12px',
          fontWeight: 500,
          '&:hover .MuiSvgIcon-root': {
            color: (theme) => theme.palette.primary.main,
            transition: 'all 0.3s ease',
          },
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            transition: 'all 0.3s ease',
          },
          '& a': {
            textDecoration: 'none',
            color: '#768191',
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 30,
            '& .MuiSvgIcon-root': {
              fontSize: 18,
            },
          }}
        >
          {route.icon}
        </ListItemIcon>
        <Typography
          sx={{ fontWeight: 500, fontSize: 14, color: '#333' }}
          className="main-menu-item"
          component="div"
        >
          {route.name}
        </Typography>
      </ListItem>
    </NavLink>
  )
}

export default MainMenuItem
