import * as React from 'react'
import { ContentImageListResponse } from '@services/common.service'
import { Box, Button } from '@mui/material'
import { useState } from 'react'
import i18n from '@locales/i18n'
import { ThemeColors } from '@theme/colors'
import { use100vh } from 'react-div-100vh'
import { Meta } from '@store/metadata/actions/types'

interface ChipArrayProps {
  value?: ContentImageListResponse
  onInsert: (data: string) => void
  size?: number
  meta: Meta
}

const FileList: React.FC<ChipArrayProps> = ({
  value,
  onInsert,
  size = 100,
  meta,
}) => {
  const [selected, setSelected] = useState<string | null>(null)
  const height = use100vh()
  const maxHeight = height ? height - 600 : 400

  const renderLoader = () => {
    if (!value && meta.pending) {
      return <>...Loading</>
    }
    return null
  }

  return (
    <Box>
      <Box
        sx={{
          background: '#fefefe',
          maxHeight: maxHeight,
          overflowY: 'auto',
        }}
      >
        <Box
          flexDirection={'row'}
          flexWrap={'wrap'}
          display="flex"
          justifyContent="flex-start"
          sx={{ p: 1, border: '1px solid #eee' }}
        >
          {value &&
            value.contents.map((img) => {
              const absolute = `${value.prefix}${img}`
              return (
                <Box
                  key={img}
                  className={selected === absolute ? 'active' : ''}
                  onClick={() => setSelected(`${absolute}`)}
                  sx={{
                    width: size,
                    height: size,
                    mb: 1.5,
                    ml: 0.5,
                    mr: 0.5,
                    background: `url(${absolute})`,
                    backgroundSize: 'cover',
                    position: 'relative',
                    display: 'block',
                    '&.active': {
                      border: `2px solid ${ThemeColors.primary}`,
                    },
                  }}
                ></Box>
              )
            })}
          {renderLoader()}
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={selected === null ? true : false}
          variant={'outlined'}
          onClick={() => selected && onInsert(selected)}
        >
          {i18n.t('SYSCOMMON.insert')}
        </Button>
      </Box>
    </Box>
  )
}

export default FileList
