import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  status: '',
  genre_id: '',
  sex: '',
}

const useFormFilter = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().max(
          50,
          t('SYSCOMMON.ERROR.E000007', {
            field: t('SYSCOMMON.keyword'),
            character: 50,
          })
        ),
        status: Yup.string().nullable(),
        sex: Yup.string(),
        genre_id: Yup.string(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormFilter
