/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Label } from '@components/@material-extend/ClassInputLabel'
import _ from 'lodash'
import { AttachFile, TouchAppOutlined } from '@mui/icons-material'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { MarkingItem } from '@services/item.services'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form'
import { FontItem } from '@services/fonts.services'
import { MARKING_TYPE } from '@constants/common.constants'
import { getFileName } from '@utils/helper/fileHelper'
import { useUpload } from '@components/@admin/FileUpload'
import { URI } from '@constants/uri.constants'
import { getPresignedUrlResponse } from '@services/common.service'
import i18n from '@locales/i18n'
import { red } from '@mui/material/colors'
import BoxedPicker from '../ColorPicker/BoxedPicker'
import { ThemeColors } from '@theme/colors'
import HorizontalTextField from '@components/@material-extend/HorizontalTextField'
import CurrencyInput from '@components/CurrencyInput'

interface MarkingEditorProps {
  data: MarkingItem | undefined
  onClose: () => void
  fonts?: FontItem[]
  Controller: any
  control: any
  setValue: (field: string, value: any) => void
  setPreview: (value: boolean) => void
  errors: any
  onResetPosition: () => void
  getValues: any
}

const MarkingEditor: React.FC<MarkingEditorProps> = (props) => {
  // eslint-disable-next-line no-console
  const {
    data,
    onClose,
    fonts,
    Controller,
    control,
    setPreview,
    setValue,
    errors,
    getValues,
    onResetPosition,
  } = props

  // const myRef = useRef<HTMLDivElement>(null)

  // const executeScroll = () => {
  //   if (
  //     myRef.current &&
  //     myRef.current.parentElement &&
  //     myRef.current.parentElement.parentElement
  //   ) {
  //     myRef.current.parentElement.parentElement.scrollTop = 0
  //   }
  // }

  // executeScroll()

  const isMobile = useMediaQuery('(max-width:900px)')

  const { t } = useTranslation()

  const upload = useUpload()
  const handleUpload = () => {
    upload({
      upload_uri: URI.UPLOAD_LOGO,
    })
      .then((res: getPresignedUrlResponse) => {
        setValue('image', res.file_url)
      })
      .catch(() => null)
  }
  const getMarkingFonts = () => {
    let res: FontItem[] = []
    let markingFontIds: string[] = []
    if (data) {
      markingFontIds = _.get(data, 'attributes.settings.fonts', [])
    }
    // eslint-disable-next-line no-console

    if (!_.isEmpty(fonts) && fonts) {
      if (markingFontIds.length > 0) {
        res = fonts.filter((obj) => markingFontIds.includes(obj.id))
      } else {
        res = fonts.filter(
          (obj) => obj.attributes && obj.attributes?.status == 1
        )
      }
    }

    return res
  }

  return data ? (
    <>
      {/* <div ref={myRef}></div> */}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderBottom: '1px solid #ddd',
          alignItems: 'center',
          height: 44,
          p: 3,
        }}
      >
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              border: '1px solid #eee',
              background:
                "url('images/marking_icon.png') no-repeat center center",
              backgroundSize: 'contain',
              mr: 1,
            }}
          ></Box>
          <Typography
            variant="h5"
            sx={{ margin: 0, p: 1.4, fontSize: 16, fontWeight: 500 }}
          >
            {data.attributes.name}
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ minWidth: 160, width: '100%' }}
            onClick={() => onClose()}
          >
            {i18n.t('SYSCOMMON.marking_done')}
          </Button>
        </Box>
      </Box>

      <Box>
        <Box>
          <Controller
            name="positionId"
            control={control}
            render={({ field: { value } }: FieldValues) => (
              <FormControl
                sx={{ p: 2, pb: 0, borderBottom: '1px solid #ddd' }}
                fullWidth
              >
                <Label
                  sx={{
                    borderBottom: '1px dashed #ccc',
                    width: '100%',
                    pb: 0.5,
                    textAlign: {
                      md: 'left',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                  id="position"
                >
                  位置
                </Label>
                <Box
                  sx={{
                    mb: 1,
                    background: { md: 'inherit', xs: ThemeColors.secondary },
                    alignItems: 'center',
                    display: _.isEmpty(value)
                      ? { md: 'inherit', xs: 'flex' }
                      : 'none',
                    my: { md: 'inherit', xs: 2 },
                  }}
                >
                  <Box
                    sx={{
                      m: 1,
                      background: { md: 'inherit', xs: ThemeColors.light },
                      padding: '2px',
                    }}
                  >
                    <TouchAppOutlined
                      sx={{
                        fontSize: 18,
                        position: 'relative',
                        color: ThemeColors.secondary,
                        top: 3,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { md: 'inherit', xs: '100%' },
                      textAlign: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        cursor: !_.isEmpty(value) ? 'inherit' : 'pointer',
                        color: {
                          md: ThemeColors.secondary,
                          xs: ThemeColors.light,
                        },
                        fontWeight: '600',
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        _.isEmpty(value) && setPreview(true)
                      }}
                    >
                      {t('marking.marking_position_required')}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'pre-line',
                    position: 'relative',
                    width: '100%',
                    pb: 2,
                  }}
                >
                  {_.isEmpty(value) ? (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: {
                          sm: 'column',
                          md: 'row',
                          xs: 'column',
                        },
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">
                        {isMobile
                          ? t('marking.please_choose_position_mobile')
                          : t('marking.please_choose_position')}
                      </Typography>
                      <Box
                        sx={{
                          width: { md: 100, sm: 150, xs: 150 },
                          display: 'block',
                          margin: '0 auto',
                          position: {
                            md: 'absolute',
                            sm: 'relative',
                            xs: 'relative',
                          },
                          top: {
                            md: -40,
                            sm: 'inherit',
                            xs: 'inherit',
                          },
                          right: {
                            md: 0,
                            sm: 'inherit',
                            xs: 'inherit',
                          },
                        }}
                      >
                        <Box
                          component="img"
                          sx={{ width: '100%', height: 'auto' }}
                          src="images/choose_position.png"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      onClick={() => onResetPosition()}
                      fullWidth
                      variant="outlined"
                    >
                      {t('marking.change_location')}
                    </Button>
                  )}
                </Box>
              </FormControl>
            )}
          />
        </Box>
        {data.attributes.marking_type !== MARKING_TYPE.LOGO ? (
          <FormControl sx={{ p: 2, borderBottom: '1px solid #ddd' }} fullWidth>
            <Controller
              name="is_common"
              control={control}
              render={({ field }: FieldValues) => (
                <>
                  <Label
                    id="type"
                    sx={{
                      borderBottom: '1px dashed #ccc',
                      width: '100%',
                      pb: 0.5,
                      textAlign: {
                        md: 'left',
                        sm: 'center',
                        xs: 'center',
                      },
                    }}
                  >
                    マーキング
                  </Label>{' '}
                  <Stack
                    direction="row"
                    justifyContent={{
                      md: 'flex-start',
                      sm: 'center',
                      xs: 'center',
                    }}
                    alignItems="center"
                    spacing={{ sm: 4, xs: 4, md: 2 }}
                  >
                    <Box>
                      <RadioGroup
                        row
                        aria-labelledby="type"
                        name="row-radio-buttons-group"
                        {...field}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="全共通"
                        />
                        <FormControlLabel
                          disabled={
                            Number(data.attributes.is_common) === 1
                              ? true
                              : false
                          }
                          value={0}
                          control={<Radio />}
                          label="個別指定"
                        />
                      </RadioGroup>
                    </Box>
                    <Box sx={{ color: red[900] }}>
                      {Number(getValues('is_common')) === 0 &&
                      Number(data.attributes.additional_price) > 0
                        ? t('marking.is_common_text', {
                            price: data.attributes.additional_price,
                          })
                        : ''}
                    </Box>
                  </Stack>
                </>
              )}
            />
          </FormControl>
        ) : null}

        {data.attributes.marking_type !== MARKING_TYPE.LOGO ? (
          <>
            <FormControl
              sx={{ p: 2, borderBottom: '1px solid #ddd' }}
              fullWidth
            >
              {' '}
              <Label
                id="type"
                sx={{
                  borderBottom: '1px dashed #ccc',
                  width: '100%',
                  pb: 0.5,
                  mb: 2,
                  textAlign: {
                    md: 'left',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                {t('marking.text')}
              </Label>
              {data.attributes.marking_type === MARKING_TYPE.TEXT ? (
                <Controller
                  name="input_data"
                  control={control}
                  render={({ field: { ref, ...rest } }: FieldValues) => (
                    <ClassicTextField
                      {...rest}
                      inputRef={ref}
                      placeholder="チーム名"
                      error={errors.input_data ? true : false}
                      helperText={
                        errors.input_data
                          ? errors.input_data.message
                          : t('marking.up_to_character', {
                              limit: data.attributes.max_size,
                            })
                      }
                      inputProps={{ maxLength: data.attributes.max_size }}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="input_data"
                  control={control}
                  render={({
                    field: { onChange, value, ...rest },
                  }: FieldValues) => (
                    <CurrencyInput
                      {...rest}
                      value={isNaN(Number(value)) ? 0 : Number(value)}
                      fullWidth
                      customInput={HorizontalTextField}
                      onValueChange={onChange}
                      placeholder="チーム名"
                      disableGroupSeparators
                      allowNegativeValue={false}
                      allowDecimals={false}
                      inputProps={{
                        maxLength: data.attributes.max_size,
                      }}
                      error={errors.input_data ? true : false}
                      helperText={
                        errors.input_data
                          ? errors.input_data.message
                          : t('marking.up_to_character', {
                              limit: data.attributes.max_size,
                            })
                      }
                    />
                  )}
                />
              )}
            </FormControl>

            <FormControl
              sx={{ p: 2, borderBottom: '1px solid #ddd' }}
              fullWidth
            >
              <Controller
                name="family"
                control={control}
                render={({ field }: FieldValues) => (
                  <>
                    <Label
                      id="family"
                      sx={{
                        borderBottom: '1px dashed #ccc',
                        width: '100%',
                        pb: 0.5,
                        mb: 2,
                        textAlign: {
                          md: 'left',
                          sm: 'center',
                          xs: 'center',
                        },
                      }}
                    >
                      フォント
                    </Label>
                    <RadioGroup {...field}>
                      <Grid container spacing={1}>
                        {!_.isEmpty(fonts) &&
                          fonts &&
                          getMarkingFonts().map((f, i) => {
                            return (
                              <Grid key={i} item xs={12} md={12} lg={6}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    value={f.id}
                                    sx={{
                                      '& .MuiTypography-root': {
                                        width: 100,
                                      },
                                    }}
                                    label={f.attributes.name.split('', 10)}
                                  />
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'block',
                                      height: 40,
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={f.attributes.thumb}
                                      sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })}
                      </Grid>
                    </RadioGroup>
                  </>
                )}
              />
            </FormControl>
            <FormControl
              sx={{ p: 2, borderBottom: '1px solid #ddd' }}
              fullWidth
            >
              <Controller
                name="fill"
                control={control}
                render={({ field: { value, onChange } }: FieldValues) => (
                  <>
                    <Label
                      sx={{
                        borderBottom: '1px dashed #ccc',
                        width: '100%',
                        pb: 0.5,
                        textAlign: {
                          md: 'left',
                          sm: 'center',
                          xs: 'center',
                        },
                      }}
                      id="position"
                    >
                      フォントの色
                    </Label>
                    <BoxedPicker
                      colors={_.get(
                        data,
                        'attributes.settings.color.values',
                        []
                      )}
                      value={value}
                      size={50}
                      onChangeComplete={(c) => onChange(c)}
                    />
                  </>
                )}
              />
            </FormControl>
          </>
        ) : (
          <FormControl sx={{ p: 1 }} fullWidth>
            <Controller
              name="image"
              control={control}
              render={({
                field: { ref, value, onChange, ...rest },
              }: FieldValues) => (
                <Stack direction={'row'}>
                  <Button
                    variant="contained"
                    disabled={!_.isEmpty(value)}
                    startIcon={<AttachFile />}
                    color="secondary"
                    onClick={() => handleUpload()}
                  >
                    {t('SYSCOMMON.upload')}
                  </Button>
                  {value ? (
                    <Chip
                      sx={{ fontSize: 10, ml: 1, maxWidth: 100 }}
                      variant="outlined"
                      label={
                        <>
                          <AttachFile sx={{ fontSize: 13 }} />
                          {getFileName(value)}
                        </>
                      }
                      onDelete={() => {
                        onChange('')
                        setValue('back_svg', '')
                      }}
                    />
                  ) : null}
                  <Box sx={{ display: 'none' }}>
                    <ClassicTextField
                      {...rest}
                      sx={{ width: 120 }}
                      inputRef={ref}
                      value={value}
                      fullWidth
                      hidden={true}
                      readOnly
                    />
                  </Box>
                </Stack>
              )}
            />
          </FormControl>
        )}
      </Box>
      {/* <SlideInPanel value={open}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'row',
              borderBottom: '1px solid #ddd',
              height: 44,
            }}
          >
            <Typography
              variant="h5"
              sx={{ margin: 0, p: 1.4, fontSize: 16, fontWeight: 500 }}
            >
              {'フォント'}
            </Typography>
            <Box sx={{ borderLeft: '1px solid #ddd', p: 1, height: '100%' }}>
              <IconButton
                sx={{ border: '0 none' }}
                onClick={() => setOpenFont(false)}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            {!_.isEmpty(fonts) &&
              fonts &&
              getMarkingFonts().map((f, i) => {
                return (
                  <ListItem
                    divider
                    onClick={() => {
                      setValue('family', f.id)
                      setOpenFont(false)
                    }}
                    key={i}
                    secondaryAction={<KeyboardArrowRightIcon />}
                  >
                    <Box
                      sx={{
                        background: `url(${f.attributes.thumb}) center center`,
                        display: 'block',
                        backgroundSize: 'cover',
                        border: '1px solid #eee',
                        height: 40,
                        width: 200,
                        mr: 2,
                      }}
                    />
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          fontSize: 16,
                          fontWeight: 500,
                        },
                      }}
                      primary={f.attributes.name}
                    />
                  </ListItem>
                )
              })}
          </Box>
        </Box>
      </SlideInPanel> */}
      <Box sx={{ height: 140 }} />
    </>
  ) : null
}
export default MarkingEditor
