import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import { SexOptions, GenreTypeOptions } from '@constants/common.constants'
import useGenreList from '@containers/Genres/useGenreList'
import FilterForm from '@containers/Genres/GenresFilter'
import AddGenre from '@containers/Genres/AddGenre'
import UpdateGenre from '@containers/Genres/UpdateGenre'
import { useConfirm } from '@components/Confirm'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import { Button, Box, Stack, TableCell, TableRow } from '@mui/material'
import { GenreItem } from '@services/genre.services'
import _ from 'lodash'
import { useEffect, useState } from 'react'

const Genres = () => {
  const { data, fetch, create, update, deleteGenre } = useGenreList()
  const [selected, setSelected] = useState<string>('0')
  const [sex, setSex] = useState<number>(0)
  const confirm = useConfirm()
  useEffect(() => {
    fetch(sex)
    setSelected('0')
  }, [sex])

  useEffect(() => {
    setSelected('0')
  }, [data])

  const renderRow = (item: GenreItem, index: number) => {
    const row = item.attributes
    const deleteDisabled = row.item_count && row.item_count > 0 ? true : false
    const itemCount = row.item_count && row.item_count > 0 ? row.item_count : 0
    if (selected == item.id) {
      return (
        <UpdateGenre
          key={index}
          item={item}
          sex={sex}
          list={data}
          submit={update}
          onCancel={() => setSelected('0')}
        />
      )
    }
    return (
      <TableRow key={index}>
        <TableCell align="left">
          <Box sx={{ pl: 1.5, pr: 1.5 }}>{row.name}</Box>
        </TableCell>
        <TableCell align="center">
          {_.find(SexOptions, (s) => s.value === row.sex)?.name}
        </TableCell>
        <TableCell align="center">
          {_.find(GenreTypeOptions, (s) => s.value === row.genre_type)?.name}
        </TableCell>

        <TableCell align="center">{itemCount}</TableCell>
        <TableCell align="center">
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setSelected(item.id)
              }}
            >
              {i18n.t('button.edit')}
            </Button>
            <Button
              size="small"
              disabled={deleteDisabled}
              variant="outlined"
              color="error"
              onClick={() => {
                confirm({
                  title: i18n.t('genre.delete_confirm_title'),
                  description: i18n.t('genre.delete_confirm_desc', {
                    target: row.name,
                  }),
                })
                  .then(() => {
                    deleteGenre({ id: parseInt(item.id, 10), sex: sex })
                  })
                  .catch(() => null)
              }}
            >
              {i18n.t('button.delete')}
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={false}
        pagintionBox={{ sx: { pt: 2 } }}
        filter={<FilterForm sex={sex} onFilter={setSex} />}
        headSection={
          <TableHeadSection
            title={i18n.t('SYSCOMMON.genre_management')}
            total={data?.length}
          />
        }
        settings={<AddGenre sex={sex} submit={create} list={data} />}
        head={
          <TableRow>
            <TableCell>
              <Box sx={{ pl: 1.5, pr: 1.5 }}>{i18n.t('genre.genre_name')}</Box>
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('SYSCOMMON.sex')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('genre.genre_type')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('genre.item_count')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '170px',
                width: '170px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            ></TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default Genres
