import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  font_size: 0,
  positionId: '',
  input_data: '',
  fill: '',
  family: 0,
  image: '',
  is_common: 0,
}

const useMarkingEditor = () => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape(
    {
      input_data: Yup.string().required(
        t('ERROR.E000001', {
          field: t('marking.text'),
        })
      ),
      positionId: Yup.string().required(),
      // required when text or number
      fill: Yup.string().required(),
      font_size: Yup.number().required(),
      image: Yup.string().nullable(),
      family: Yup.number().required(),
      is_common: Yup.number().required(),
    },
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return { Controller, methods, initialValues }
}

export default useMarkingEditor
