import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/genre.services'
import { GENRE_ACTION_TYPE } from './types'

export const genreList = createAsyncThunk<services.GenreListResponse>(
  GENRE_ACTION_TYPE.LIST,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.list()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const genreListWithFilter = createAsyncThunk<
  services.GenreListResponse,
  number
>(GENRE_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.listWithFilter(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const genreCreate = createAsyncThunk<
  services.GenreListResponse,
  services.GenreCreateParams
>(GENRE_ACTION_TYPE.CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const genreUpdate = createAsyncThunk<
  services.GenreListResponse,
  services.GenreUpdateParams
>(GENRE_ACTION_TYPE.UPDATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.update(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const genreDelete = createAsyncThunk<
  services.GenreListResponse,
  services.GenreDeleteParams
>(GENRE_ACTION_TYPE.DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.deleteGenre(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
