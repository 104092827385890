import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

export const initialValues = {
  name: '',
  image: '',
  address: '',
  map_url: '',
  access_info: '',
  phone: '',
  fax: '',
  manager: '',
  time_table: '',
  descriptions: '',
}

const useFormShopEdit = () => {
  const { t } = useTranslation()
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(
            100,
            t('ERROR.E000002', {
              field: t('shops.name'),
              character: 100,
            })
          )
          .required(
            t('ERROR.E000001', {
              field: t('shops.name'),
            })
          ),
        address: Yup.string()
          .max(
            255,
            t('ERROR.E000002', {
              field: t('shops.address'),
              character: 255,
            })
          )
          .nullable(),
        map_url: Yup.string()
          .url(
            t('ERROR.E000008', {
              field: t('shops.map_url'),
            })
          )
          .max(
            255,
            t('ERROR.E000002', {
              field: t('shops.map_url'),
              character: 255,
            })
          )
          .nullable(),
        access_info: Yup.string().nullable(),
        phone: Yup.string()
          .max(
            20,
            t('ERROR.E000002', {
              field: t('shops.phone'),
              character: 20,
            })
          )
          .nullable(),
        manager: Yup.string()
          .max(
            20,
            t('ERROR.E000002', {
              field: t('shops.manager'),
              character: 20,
            })
          )
          .nullable(),
        fax: Yup.string()
          .max(
            20,
            t('ERROR.E000002', {
              field: t('shops.fax'),
              character: 20,
            })
          )
          .nullable(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormShopEdit
