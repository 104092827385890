import i18n from '@locales/i18n'
import {
  Category,
  DesignServices,
  List,
  People,
  ProductionQuantityLimits,
  Settings,
  FontDownload,
  Storefront,
} from '@mui/icons-material'
import { ReactNode } from 'react'
export type MainMenuItem = {
  label: string
  route: string
}

export type MenuItem = {
  id: number
  name: string
  exact?: boolean
  path: string
  icon?: ReactNode
}

export const mainmenu: MainMenuItem[] = [
  {
    label: i18n.t('SYSCOMMON.shop_information'),
    route: '/shops',
  },
  {
    label: i18n.t('SYSCOMMON.design_compare'),
    route: '/compare',
  },
  {
    label: i18n.t('SYSCOMMON.inquiry'),
    route: '/inquiry',
  },
]

export const AdminMainMenu: MenuItem[] = [
  {
    id: 1,
    name: i18n.t('SYSCOMMON.staff_management'),
    path: '/admin/users',
    icon: <People />,
  },
  {
    id: 2,
    name: i18n.t('SYSCOMMON.genre_management'),
    path: '/admin/genres',
    icon: <Category />,
  },
  {
    id: 3,
    name: i18n.t('SYSCOMMON.merchandise'),
    path: '/admin/items',
    icon: <ProductionQuantityLimits />,
  },
  {
    id: 4,
    name: i18n.t('SYSCOMMON.designs'),
    path: '/admin/designs',
    icon: <DesignServices />,
  },
  {
    id: 5,
    name: i18n.t('SYSCOMMON.orders'),
    path: '/admin/orders',
    icon: <List />,
  },
  {
    id: 7,
    name: i18n.t('SYSCOMMON.fonts_management'),
    path: '/admin/fonts',
    icon: <FontDownload />,
  },
  {
    id: 8,
    name: i18n.t('SYSCOMMON.shop_management'),
    path: '/admin/shops',
    icon: <Storefront />,
  },
  {
    id: 9,
    name: i18n.t('SYSCOMMON.system_settings'),
    path: '/admin/system_settings',
    icon: <Settings />,
  },
]
