import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { FontItem } from '@services/fonts.services'
import _ from 'lodash'
type StateType = {
  list?: FontItem[]
  meta?: MetaPage
  detail?: FontItem
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  detail: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fontList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.fontActiveList.fulfilled, (state, action) => {
    state.list = action.payload.data
  })
  builder.addCase(actions.fontCreate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.fontUpdate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.fontFilesUpdate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.fontStatus.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list =
      state.list !== undefined
        ? _.map(state.list, (obj: FontItem) => {
            if (action.meta.arg.ids.includes(Number(obj.id))) {
              return {
                ...obj,
                attributes: {
                  ...obj.attributes,
                  status: action.meta.arg.status,
                },
              }
            } else {
              return obj
            }
          })
        : undefined
  })
  builder.addCase(actions.fontDelete.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.fontDetail.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
})
