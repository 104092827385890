import { Meta } from '@store/metadata/actions/types'
import { ShopItem, ShopUpdateParams } from '@services/shops.services'
import { shopUpdate, fetchShopDetail, resetDetail } from '@store/shops/actions'
import { detail } from '@store/shops/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  data?: ShopItem
  metaUpdate: Meta
  fetch: (id: string) => void
  update: (params: ShopUpdateParams) => void
  clearDetail: () => void
}

const useShopEdit = (): ReturnType => {
  const data = useAppSelector(detail)
  const dispatch = useDispatch()
  const fetch = (id: string) => {
    dispatch(fetchShopDetail(id))
  }

  const update = (params: ShopUpdateParams) => {
    dispatch(shopUpdate(params))
  }

  const clearDetail = () => {
    dispatch(resetDetail)
  }

  const _metaCreate = createMetaSelector(shopUpdate)
  const metaUpdate = useAppSelector(_metaCreate)

  return {
    data,
    metaUpdate,
    clearDetail,
    fetch,
    update,
  }
}

export default useShopEdit
