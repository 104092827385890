import React from 'react'
import { useTranslation } from 'react-i18next'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import { Box, Alert } from '@mui/material'
import { FieldValues } from 'react-hook-form'
import _ from 'lodash'
import usePasswordForgotForm from './usePasswordForgotForm'
import { passwordForgot } from '@store/auth/actions'
import { createMetaSelector } from '@store/metadata/selectors'
import { PasswordForgotParams } from '@services/auth.services'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const PasswordForgotForm: React.FC = () => {
  const { t } = useTranslation()

  const { Controller, methods } = usePasswordForgotForm()
  const dispatch = useDispatch()

  const passwordMeta = createMetaSelector(passwordForgot)
  const meta = useSelector(passwordMeta)

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = methods

  const onSubmit = (values: PasswordForgotParams) => {
    if (_.isEmpty(errors)) {
      dispatch(passwordForgot(values))
    }
  }

  return (
    <>
      {meta.error ? (
        <Alert sx={{ mb: 1 }} severity="error">
          {t('ERROR.E000016')}
        </Alert>
      ) : null}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} method="post">
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <Box sx={{ mt: 1, mb: 3, height: 60 }}>
              <ClassicTextField
                {...rest}
                fullWidth
                inputRef={ref}
                name="email"
                placeholder={t('SYSCOMMON.email')}
                label={t('SYSCOMMON.email')}
                error={errors.email ? true : false}
                helperText={errors.email ? errors.email.message : ''}
              />
            </Box>
          )}
        />

        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          size="large"
          loading={meta.pending}
          disabled={meta.pending || !isDirty || !isValid}
          sx={{ mb: 3 }}
        >
          {t('SYSCOMMON.password_forgot_submit_button')}
        </LoadingButton>

        <Link to="/login">{t('SYSCOMMON.back_to_login')}</Link>
      </Box>
    </>
  )
}

export default PasswordForgotForm
