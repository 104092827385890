/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Container,
  Box,
  Alert,
} from '@mui/material/'
import { UploadOptions } from './types'
import { useDropzone } from 'react-dropzone'
import { getPresignedUrlResponse, uploadImage } from 'services/common.service'
import { LoadingButton } from '@mui/lab'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { defaultStyles, FileIcon } from 'react-file-icon'
import { getFileExtension } from '@utils/helper/fileHelper'

interface UploadDialogProps {
  open: boolean
  options: UploadOptions
  onCancel: () => void
  onConfirm: (obj: getPresignedUrlResponse) => void
  onClose: (event: Event, reason: string) => void
  replaceUri?: string
}

export interface ExtendedFile extends File {
  preview?: string
  customName?: string
}

interface ProgressProps extends LinearProgressProps {
  value: number
}

const LinearProgressWithLabel = (props: ProgressProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  )
}

const UploadDialog = ({
  options,
  onCancel,
  onConfirm,
  onClose,
  replaceUri,
  ...rest
}: UploadDialogProps) => {
  const { t } = useTranslation()
  const dialogOpen = rest.open
  const { title, dialogProps, upload_uri, maxSize, accept } = options
  const [file, setFile] = useState<ExtendedFile | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [fontFamily, setFontFamily] = useState<string | null>(null)
  const [customFileName, setCustomFileName] = useState<string | null>(null)
  useEffect(() => {
    if (!dialogOpen) reset()
    else open()
  }, [dialogOpen])

  const reset = () => {
    setFile(null)
    setLoading(false)
    setProgress(0)
    setError(null)
  }

  const handleProgress = (percent: number) => {
    setProgress(percent)
  }

  const handleFile = async (acceptedFile: File) => {
    setError(null)
    setCustomFileName(null)
    setFontFamily(null)
    if (acceptedFile) {
      if (acceptedFile.type.startsWith('image/')) {
        setFile(
          Object.assign(acceptedFile, {
            preview: URL.createObjectURL(acceptedFile),
          })
        )
      } else if (acceptedFile.type.startsWith('text/css') && replaceUri) {
        let cssText = await acceptedFile.text()
        const caseSecond = "url('"
        let mode = 1
        if (cssText.indexOf(caseSecond) > 0) {
          mode = 2
        }
        let counter = 0
        while (cssText.includes('/') && counter < 5) {
          if (mode == 1) {
            cssText = cssText.replace(/".*\//, '"')
          } else {
            cssText = cssText.replace(/'.*\//, "'")
          }
          counter++
        }
        //font-family
        const regex = /font-family:(.*?);/
        const match: RegExpExecArray | null = regex.exec(cssText)
        if (match) {
          let substring = match[1]
          substring = substring.trim()
          if (substring.charAt(0) == "'") {
            substring = substring.replaceAll("'", '')
          } else if (substring.charAt(0) == '"') {
            substring = substring.replaceAll('"', '')
          }
          setFontFamily(substring)
        }
        const woff2 =
          "src: url('" + replaceUri + "font.woff2') format('woff2'),"
        const woff = "url('" + replaceUri + "font.woff') format('woff');"
        const regexWoff = /src:(.[^\0]*?);/
        const matchWoff: RegExpExecArray | null = regexWoff.exec(cssText)
        if (matchWoff) {
          cssText = cssText.replace(/src(.[^\0]*?);/, woff2 + ' ' + woff)
        }
        const newBlob = new Blob([cssText], {
          type: acceptedFile.type,
        })
        const clone = new File([newBlob], acceptedFile.name, {
          type: acceptedFile.type,
          lastModified: acceptedFile.lastModified,
        })
        setFile(clone)
      } else if (
        acceptedFile.type.startsWith('font/woff2') ||
        acceptedFile.type.startsWith('font/woff') ||
        acceptedFile.type.startsWith('application/font-woff')
      ) {
        setFile(acceptedFile)
        setCustomFileName(
          acceptedFile.type.startsWith('font/woff2')
            ? 'font.woff2'
            : 'font.woff'
        )
      } else {
        setFile(acceptedFile)
      }
    }
  }

  const handleConfirm = async () => {
    setError(null)
    setLoading(true)
    try {
      if (file && upload_uri) {
        let g
        try {
          if (getFileExtension(file.name) === 'svg') {
            const svgText = await file.text()
            g = svgText.split('viewBox="0 0 350 450">')[1]
            g = g.split('</svg>')[0]
            g = g.trim()
            // eslint-disable-next-line no-console
          }
          const imgResponse = await uploadImage(
            upload_uri,
            file,
            handleProgress,
            customFileName
          )
          if (imgResponse) {
            if (getFileExtension(file.name) === 'svg') {
              onConfirm({ ...imgResponse, svgContent: g })
            } else if (getFileExtension(file.name) === 'css') {
              onConfirm({ ...imgResponse, fontFamily: fontFamily })
            }
            onConfirm(imgResponse)
            // after response
          }
        } catch (error: any) {
          // eslint-disable-next-line no-console
          setLoading(false)
          setError(t('ERROR.E000003'))
        }
      }
    } catch (err) {
      setLoading(false)
      setError(t('ERROR.E000003'))
    }
  }

  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    reset()
    onClose(event, reason)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: accept,
    maxSize: maxSize, // 100mb
    onDrop: (acceptedFiles) => {
      const singleFile = acceptedFiles[0]
      handleFile(singleFile)
    },
  })

  const thumbs = () => {
    return (
      <>
        <Box
          sx={{
            display: 'block',
            borderRadius: 1,
            border: '1px solid #eaeaea',
            marginBottom: 1,
            marginRight: 1,
            width: 100,
            height: 100,
            padding: 0.5,
            boxSizing: 'border-box',
            overflow: 'hidden',
          }}
        >
          {file && file.name ? (
            file.type.startsWith('image/') ? (
              <img
                src={file.preview}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  height: '100%',
                  p: 0.5,
                  '& svg': {
                    height: '100%',
                    width: 'auto',
                    maxWidth: 'auto',
                  },
                }}
              >
                <FileIcon
                  extension={getFileExtension(file.name)}
                  {...defaultStyles[getFileExtension(file.name)]}
                />
              </Box>
            )
          ) : null}
        </Box>
      </>
    )
  }

  return (
    <Dialog fullWidth {...dialogProps} open={dialogOpen} onClose={handleClose}>
      <Container maxWidth={false}>
        <DialogTitle sx={{ padding: 0, mt: 2, mb: 2, textAlign: 'center' }}>
          {title ? title : t('helpers:upload.file_upload_title')}
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Box
            display="flex"
            mb={1}
            mt={1}
            sx={{ height: 100, width: '100%' }}
            alignItems="center"
            justifyContent="center"
          >
            {thumbs()}
          </Box>
          <Box sx={{ textAlign: 'center', pb: 2 }}>
            {file ? <Typography noWrap>{file.name}</Typography> : ''}
          </Box>
          <Box
            {...getRootProps({ className: 'dropzone' })}
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              borderWidth: '2px',
              borderRadius: '2px',
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}
          >
            <input {...getInputProps()} />
            {/* <p>{t('helpers:upload.drag_message')}</p> */}
            <Button
              variant="outlined"
              disabled={loading}
              color={'success'}
              onClick={open}
            >
              {t('SYSCOMMON.select_file')}
            </Button>
          </Box>
          {loading ? (
            <Box sx={{ height: 20 }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          ) : (
            <Box sx={{ height: 20 }} />
          )}
          {error && (
            <Box mt={1}>
              <Alert severity="warning">{error}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ pt: 2, pb: 2, pl: 0, pr: 0, justifyContent: 'space-between' }}
        >
          <Button variant="outlined" onClick={onCancel}>
            {t('SYSCOMMON.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 100 }}
            loading={loading}
            onClick={handleConfirm}
            disabled={loading || !file}
          >
            {t('SYSCOMMON.upload')}
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default UploadDialog
