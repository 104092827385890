import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import {
  Design,
  DesignCopyDetail,
  DesignDetail,
  DesignItem,
} from '@services/design.services'
import { FontItem } from '@services/fonts.services'
import _ from 'lodash'
import { resetDetail } from '@store/order/actions'

type StateType = {
  list?: Design[]
  meta?: MetaPage
  top?: DesignItem
  bottom?: DesignItem
  fonts?: FontItem[]
  detail?: DesignDetail
  copy?: DesignCopyDetail
  compareItems?: DesignDetail[]
  sex?: number
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  top: undefined,
  bottom: undefined,
  fonts: undefined,
  compareItems: undefined,
  sex: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.designList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.createNew.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.top = action.payload.top ? action.payload.top.data : undefined
    state.bottom = action.payload.bottom
      ? action.payload.bottom.data
      : undefined
    state.sex = action.payload.sex ? action.payload.sex : undefined
  })
  builder.addCase(actions.fontListPublic.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.fonts = action.payload.data
  })
  builder.addCase(actions.detail.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.compareDesign.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.compareItems = action.payload.data
  })
  builder.addCase(actions.compareDesign.rejected, (state, action) => {
    // eslint-disable-next-line no-console
    if (_.isEmpty(action.meta.arg.codes)) {
      state.compareItems = []
    }
  })
  builder.addCase(resetDetail, (state) => {
    // eslint-disable-next-line no-console
    state.detail = undefined
  })
  builder.addCase(actions.copyDesign.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.copy = action.payload.data
  })
  builder.addCase(actions.designDelete.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.resetCopy, (state) => {
    // eslint-disable-next-line no-console
    state.copy = undefined
  })
})
