import React from 'react'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/system'
import { ReactSVG } from 'react-svg'

interface ColorPickerProps extends BoxProps {
  svg: string
  ratioHeight?: string
}

const DesignThumb: React.FC<ColorPickerProps> = ({
  svg,
  ratioHeight = '128%',
  ...rest
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: ratioHeight,
        '& svg': {
          position: ' absolute',
          height: '100%',
          width: '100%',
          left: 0,
          top: 0,
        },
        ...rest.sx,
      }}
    >
      <ReactSVG useRequestCache={false} src={svg} />
    </Box>
  )
}

export default DesignThumb
