import api from './api'
import axios from 'axios'
import { ExtendedFile } from '@components/@admin/FileUpload/UploaderDialog'
import { URI } from '@constants/uri.constants'

export type getPresignedUrlResponse = {
  url: string
  file_url: string
  file_name: string
  key: string
  svgContent?: string
  fontFamily?: string | null
}

export type ContentImageListResponse = {
  prefix: string
  contents: string[]
}

export type InquiryResponse = {
  status: string
}

export type InquiryParams = {
  name: string
  email: string
  phone: string
  company_name: string
  body: string
}

export const uploadImage = async (
  uri: string,
  file: ExtendedFile,
  cb?: (progress: number) => void,
  customFileName?: string | null
) => {
  const { data: presignedResponse } = await api.post<getPresignedUrlResponse>(
    uri,
    {
      file_name: customFileName ? customFileName : file.name,
      content_type: file.type,
    }
  )
  await axios.put(presignedResponse.url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress(e: ProgressEvent) {
      const percentCompleted = Math.round((e.loaded * 100) / e.total)
      cb && cb(percentCompleted)
    },
  })
  return presignedResponse
}

export const list = async (): Promise<ContentImageListResponse> => {
  const { data } = await api.get<ContentImageListResponse>(
    URI.CONTENT_IMAGE_LIST
  )
  return data
}

export const inquires = async (
  params: InquiryParams
): Promise<InquiryResponse> => {
  const { data } = await api.post<InquiryResponse>(URI.INQUIRY, params)
  return data
}
