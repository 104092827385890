import {
  GenreItem,
  GenreCreateParams,
  GenreUpdateParams,
  GenreDeleteParams,
} from '@services/genre.services'
import {
  genreListWithFilter,
  genreCreate,
  genreDelete,
  genreUpdate,
} from '@store/genre/actions'
import { genreListData } from '@store/genre/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'

interface ReturnType {
  data?: GenreItem[]
  fetch: (params: number) => void
  create: (params: GenreCreateParams) => void
  update: (params: GenreUpdateParams) => void
  deleteGenre: (params: GenreDeleteParams) => void
}

const useGenreList = (): ReturnType => {
  const data = useAppSelector(genreListData)
  const dispatch = useDispatch()
  const fetch = (params: number) => {
    dispatch(genreListWithFilter(params))
  }
  const create = (params: GenreCreateParams) => {
    dispatch(genreCreate(params))
  }
  const update = (params: GenreUpdateParams) => {
    dispatch(genreUpdate(params))
  }
  const deleteGenre = (params: GenreDeleteParams) => {
    dispatch(genreDelete(params))
  }

  return {
    fetch,
    data,
    create,
    update,
    deleteGenre,
  }
}

export default useGenreList
