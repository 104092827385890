import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { ShopItem } from '@services/shops.services'
import _ from 'lodash'
type StateType = {
  list?: ShopItem[]
  meta?: MetaPage
  detail?: ShopItem
  shops?: ShopItem[]
  shopPaging?: MetaPage
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  detail: undefined,
  shops: undefined,
  shopPaging: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.shopList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.shopCreate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.shopUpdate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.shopStatus.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list =
      state.list !== undefined
        ? _.map(state.list, (obj: ShopItem) => {
            if (action.meta.arg.ids.includes(Number(obj.id))) {
              return {
                ...obj,
                attributes: {
                  ...obj.attributes,
                  status: action.meta.arg.status,
                },
              }
            } else {
              return obj
            }
          })
        : undefined
  })
  builder.addCase(actions.shopDelete.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.shopDetail.fulfilled, (state, action) => {
    state.detail = action.payload.data
  })
  builder.addCase(actions.shopListPublic.fulfilled, (state, action) => {
    state.shops = action.payload.data
    state.shopPaging = action.payload.meta
  })

  builder.addCase(actions.resetDetail, (state) => {
    state.detail = undefined
  })
})
