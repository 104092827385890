import LoginLayout from '@containers/Auth/LoginLayout'
import PasswordResetForm from '@containers/Auth/PasswordResetForm'

const PasswordReset = () => {
  return (
    <LoginLayout>
      <PasswordResetForm />
    </LoginLayout>
  )
}

export default PasswordReset
