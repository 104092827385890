import * as React from 'react'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import Slider from './Slider'
import { ThemeColors } from '@theme/colors'
import i18n from '@locales/i18n'
import HTMLParser from '@components/HtmlParser'
import { Item } from '@services/item.services'
import _ from 'lodash'

export type FullScreenModalProps = {
  data: Item
}

const Detail: React.FC<FullScreenModalProps> = ({ data }) => {
  const { attributes } = data
  return (
    <Box sx={{ pt: 4, flexGrow: 1 }}>
      <Box sx={{ pb: 3 }}>
        <Grid container spacing={5} flexGrow={1}>
          <Grid lg={6} md={6} sm={12} xs={12} item>
            {attributes.item_images && <Slider data={attributes.item_images} />}
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12} item>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                {attributes.name}
              </Typography>
              <Typography variant="body2">
                <Chip
                  label={attributes.code}
                  size="small"
                  sx={{
                    fontSize: 10,
                    borderRadius: 0,
                    padding: 0,
                    lineHeight: 1.7,
                  }}
                />
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  fontSize: 20,
                  color: ThemeColors.secondary,
                }}
              >
                <Box
                  component="span"
                  sx={{
                    mr: 0.5,
                    position: 'relative',
                  }}
                >
                  ¥
                </Box>
                {`${attributes.price}`}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: 12, lineHeight: 1.7 }}
              >
                {attributes.descriptions}
              </Typography>
              <Grid container flexGrow={1} sx={{ mb: 2, mt: 2 }}>
                <Grid lg={12} md={12} sm={12} xs={12} sx={{ mb: 1 }}>
                  <Typography>{i18n.t('SYSCOMMON.size')}:</Typography>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12}>
                  <Stack direction="row" spacing={1}>
                    {!_.isEmpty(attributes.size) &&
                      attributes.size !== null &&
                      attributes.size.map((s, i) => {
                        return (
                          <Chip
                            label={s}
                            key={i}
                            sx={{
                              borderRadius: 1,
                              border: 1,
                              borderColor: ThemeColors.gray,
                              backgroundColor: ThemeColors.white,
                            }}
                          />
                        )
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="section"
        sx={{ pt: 3, pb: 3, borderTop: '1px solid #e3e4e5' }}
      >
        <HTMLParser
          html={attributes.article !== null ? attributes.article : ''}
        />
      </Box>
    </Box>
  )
}

export default Detail
