import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import useOrderList from '@containers/Orders/useOrderList'
import FilterForm, { FilterParams } from '@containers/Orders/OrderFilter'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import { ThemeColors } from '@theme/colors'
import { Box, TableCell, TableRow, Pagination, Stack } from '@mui/material'
import { Order } from '@services/order.services'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  SIMPLE_DATE_TIME_FORMAT,
  SIMPLE_DATE_FORMAT,
} from '@constants/common.constants'
import { currency } from 'utils/helper/common.helpers'
const Orders = () => {
  const { paging, data, fetch, meta } = useOrderList()
  const [filter, setFilter] = useState<any>({
    start_at: moment().subtract(1, 'months').format(SIMPLE_DATE_FORMAT),
    end_at: moment().format(SIMPLE_DATE_FORMAT),
  })

  useEffect(() => {
    fetch({ ...filter })
  }, [filter])

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
      ...filter,
    })
  }

  const handleFilter = (data: FilterParams) => {
    setFilter(data)
  }

  const renderRow = (item: Order, index: number) => {
    const data = item.attributes
    return (
      <TableRow
        key={index}
        sx={
          data.is_expired
            ? {
                backgroundColor: ThemeColors.expired,
              }
            : {}
        }
      >
        <TableCell align="left">
          <Box sx={{ pl: 1.5, pr: 1.5 }}>
            <a href={'/order/' + data.code} target="_blank" rel="noreferrer">
              {data.code}
            </a>
          </Box>
        </TableCell>
        <TableCell align="center">
          <a
            href={'/design/' + data.design_code}
            target="_blank"
            rel="noreferrer"
          >
            {data.design_code}
          </a>
        </TableCell>
        <TableCell align="right">{data.set_number}</TableCell>
        <TableCell align="right">{currency(data.price)}</TableCell>
        <TableCell align="center">
          {moment(data.created_at).format(SIMPLE_DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell align="center">{data.created_ip}</TableCell>
        <TableCell align="center">
          {moment(data.expire_at).format(SIMPLE_DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell align="center">
          <Box sx={{ pl: 1, pr: 1 }}>
            {moment(data.last_access).format(SIMPLE_DATE_TIME_FORMAT)}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={meta.pending}
        pagintionBox={{ sx: { pt: 2 } }}
        pagination={
          paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )
        }
        headSection={
          <TableHeadSection
            title={i18n.t('SYSCOMMON.orders')}
            total={data?.length}
            actions={
              <Stack justifyContent="flex-end" flexDirection={'row'}>
                <FilterForm onFilter={handleFilter} />
              </Stack>
            }
          />
        }
        head={
          <TableRow>
            <TableCell>
              <Box
                sx={{
                  pl: 1.5,
                  pr: 1.5,
                }}
              >
                {i18n.t('orders.code')}
              </Box>
            </TableCell>
            <TableCell align="center">{i18n.t('orders.design_code')}</TableCell>
            <TableCell
              sx={{
                maxWidth: '80px',
                width: '80px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.set_number')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '120px',
                width: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.price')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.created_at')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.created_ip')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '140px',
                width: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.expire_at')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '160px',
                width: '160px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              align="center"
            >
              {i18n.t('orders.last_access')}
            </TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default Orders
