import { ListItem, ListItemText, Box } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Item } from '@services/item.services'
import { currency } from '@utils/helper/common.helpers'

interface ProductListItemProps {
  data: Item
  onClick?: (id: number) => void
  isSelected?: boolean
}

const ProductListItem: React.FC<ProductListItemProps> = (props) => {
  const { data, onClick } = props

  const { attributes } = data

  return (
    <ListItem
      divider
      onClick={() => onClick && onClick(Number(data.id))}
      secondaryAction={<KeyboardArrowRightIcon />}
    >
      <Box
        sx={{
          background: `url(${attributes.thumb_url}) top center`,
          backgroundSize: 'cover',
          height: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          display: 'block',
          width: 80,
        }}
      ></Box>
      <ListItemText
        sx={{
          ml: '90px',
          '& .MuiListItemText-primary': {
            fontSize: 16,
            fontWeight: 500,
            cursor: 'pointer',
          },
          '& .MuiListItemText-secondary': {
            fontSize: 14,
            fontWeight: 500,
            cursor: 'pointer',
          },
        }}
        primary={attributes.name}
        secondary={`${currency(attributes.price)}`}
      />
    </ListItem>
  )
}

export default ProductListItem
