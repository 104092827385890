import { Box } from '@mui/material/'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import ProductItem from '@components/ProductItem'
import { ReactNode } from 'react'
import i18n from '@locales/i18n'
import { Item } from '@services/item.services'

export type SampleSelectorProps = {
  value?: number
  onChange: (id: number) => void
  data?: Item[]
  onView?: (id: string) => void
  placeholder?: ReactNode
}

const SampleSelector: React.FC<SampleSelectorProps> = ({
  onChange,
  value,
  data,
  onView,
  placeholder,
}) => {
  return (
    <Box className="sample-selector">
      <Swiper
        slidesPerView={5}
        spaceBetween={16}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {data?.map((c, i) => {
          return (
            <SwiperSlide key={i}>
              <ProductItem
                isSelected={value === Number(c.id) ? true : false}
                data={c}
                onClick={onChange}
                onView={(id) => onView && onView(id)}
              />
            </SwiperSlide>
          )
        })}
        {data === undefined ? (
          placeholder ? (
            placeholder
          ) : (
            <Box
              sx={{
                textAlign: 'center',
                height: 321,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {i18n.t('SYSCOMMON.no_data')}
            </Box>
          )
        ) : (
          ''
        )}
      </Swiper>
    </Box>
  )
}

export default SampleSelector
