import EditorContainer from '@containers/EditorContainer'
import { Box } from '@mui/material/'

const EditorPage: React.FC = () => {
  return (
    <Box sx={{ height: '100%', width: '100%', background: '#eee' }}>
      <EditorContainer />
    </Box>
  )
}

export default EditorPage
