import ColorEditor from '@components/@admin/Markings/ColorEditor'
import FontEditor from '@components/@admin/Markings/FontEditor'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import ClassicInputLabel from '@components/@material-extend/ClassInputLabel'
import { MARKING_TYPE } from '@constants/common.constants'
import { Check, Delete, DeleteForever, Save } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { FontItem } from '@services/fonts.services'
import { MarkingItem, PositionItem } from '@services/item.services'
import * as React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { initialValues } from './useMarkingForm'
import _ from 'lodash'
import PositionEditor from '@components/@admin/Markings/PositionEditor'
import TableLayout from '@components/@material-extend/TableLayout'
import { forwardRef, useImperativeHandle } from 'react'
import ClassicSelect from '@components/@material-extend/ClassicSelect'
import { Side } from '@constants/editor.constants'
import password from 'secure-random-password'
import i18n from '@locales/i18n'
import CurrencyInput from '@components/CurrencyInput'
import HorizontalTextField from '@components/@material-extend/HorizontalTextField'
import BoxedPicker from '@components/@editor-components/ColorPicker/BoxedPicker'

interface MarkingUpsertProps {
  fonts?: FontItem[]
  mode: 'create' | 'edit'
  data?: MarkingItem | null
  onSubmit: (values: any) => void
  onDelete: (marking_id: string) => void
  methods: any
  position: Omit<PositionItem, 'id' | 'side'>
  onPositionChange: (value: Omit<PositionItem, 'id' | 'side'>) => void
  Controller: any
  showEditable: boolean
  color: string
  setColor: (v: string) => void
}

export interface ClearForm {
  resetForm(): void
}

const MarkingUpsert = forwardRef<ClearForm, MarkingUpsertProps>(
  (props, ref) => {
    const {
      fonts,
      data,
      methods,
      mode,
      showEditable,
      position,
      setColor,
      onSubmit,
      color,
      onDelete,
      onPositionChange,
      Controller,
    } = props

    const { t } = useTranslation()

    const {
      control,
      handleSubmit,
      formState: { errors, isValid, isDirty },
      setValue,
      watch,
      reset,
    } = methods

    const colorValues = watch('colors')
    const type = watch('marking_type')
    const isCommon = watch('is_common')
    const fill = watch('fill')
    const fontIds = watch('fonts')
    const selectedFonts =
      fonts && fontIds ? _.chain(fonts).keyBy('id').at(fontIds).value() : []

    // const selectedActiveFonts = selectedFonts.filter(
    //   (obj) => obj.attributes && obj.attributes?.status == 1
    // )

    React.useEffect(() => {
      if (Number(type) === MARKING_TYPE.NUMBER && data) {
        setValue('max_size', data.attributes.max_size)
        setValue('sample_text', data.attributes.settings.sample_text)
      } else if (Number(type) === MARKING_TYPE.NUMBER && !data) {
        setValue('max_size', 3)
        setValue('sample_text', 10)
      } else {
        setValue('max_size', 10)
        setValue('sample_text', i18n.t('marking.default_text'))
      }
    }, [type])

    React.useEffect(() => {
      if (data && mode === 'edit') {
        const { attributes } = data
        let values
        if (attributes.marking_type !== MARKING_TYPE.LOGO) {
          values = {
            name: attributes.name,
            font_size: _.get(attributes, 'settings.font_size', undefined)
              ? _.get(attributes, 'settings.font_size', [])
              : [],
            additional_price: attributes.additional_price,
            marking_type: Number(attributes.marking_type),
            is_common: Number(attributes.is_common),
            fill: _.get(attributes, 'settings.color.fill', undefined)
              ? _.get(attributes, 'settings.color.fill', '')
              : '',
            colors:
              attributes.settings && attributes.settings.color
                ? attributes.settings.color.values
                : '',
            positions: _.get(attributes, 'settings.positions.values', undefined)
              ? _.get(attributes, 'settings.positions.values', [])
              : [],
            side: _.get(attributes, 'settings.positions.side', undefined)
              ? _.get(attributes, 'settings.positions.side', '')
              : '',
            fonts: _.get(attributes, 'settings.fonts', undefined)
              ? _.get(attributes, 'settings.fonts', [])
              : [],
            family: _.get(attributes, 'settings.family', undefined)
              ? _.get(attributes, 'settings.family', [])
              : [],
            max_size: _.get(attributes, 'max_size', undefined)
              ? _.get(attributes, 'max_size', 0)
              : [],
            sample_text: _.get(attributes, 'settings.sample_text', undefined)
              ? _.get(
                  attributes,
                  'settings.sample_text',
                  t('marking.default_text')
                )
              : [],
          }
        } else {
          values = {
            name: attributes.name,
            additional_price: attributes.additional_price,
            marking_type: Number(attributes.marking_type),
            is_common: Number(attributes.is_common),
            positions: _.get(attributes, 'settings.positions.values', undefined)
              ? _.get(attributes, 'settings.positions.values', [])
              : [],
            side: _.get(attributes, 'settings.positions.side', undefined)
              ? _.get(attributes, 'settings.positions.side', '')
              : '',
          }
        }

        reset(values as any)
      }
    }, [data, mode])

    React.useEffect(() => {
      if (mode === 'create') {
        reset(initialValues)
      }
    }, [mode])

    React.useEffect(() => {
      const subscription = watch((value: any, { name }: any) => {
        if (name === 'fonts') {
          if (!value.fonts || value.fonts.length === 0) {
            setValue('family', null)
          }
        }
      })
      return () => subscription.unsubscribe()
    }, [watch])

    const handleDefault = () => {
      setValue('fill', color, { shouldDirty: true })
    }

    const beforeSubmit = (values: any) => {
      onSubmit(values)
    }

    useImperativeHandle(ref, () => ({
      resetForm() {
        reset(initialValues)
      },
    }))

    const handleActivePosition = (
      p: PositionItem,
      value: PositionItem[],
      cb: (value: PositionItem[]) => void
    ) => {
      cb(
        _.map(value, (v) =>
          v.id === p.id
            ? {
                ...v,
                isActive: true,
              }
            : {
                ...v,
                isActive: false,
              }
        )
      )
    }

    const handleColorEditor = (
      value: string[],
      newValue: string,
      cb: (v: string[]) => void
    ) => {
      if (!_.includes(value, newValue)) {
        setColor(newValue)
        if (1 > _.size(value)) {
          setValue('fill', newValue)
        }

        cb([...value, newValue])
      }
    }

    const handleDelete = (value: string[], cb: (v: string[]) => void) => {
      if (color === fill) {
        setValue('fill', _.filter(value, (v) => v !== color)[0])
      }
      cb(_.filter(value, (v) => v !== color))
      setColor(_.filter(value, (v) => v !== color)[0])
    }

    return (
      <Box>
        <form key={1} onSubmit={handleSubmit(beforeSubmit)} method="post">
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg item>
              <Box sx={{ height: 80, minWidth: 140 }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { ref, ...rest } }: FieldValues) => (
                    <ClassicTextField
                      {...rest}
                      inputRef={ref}
                      fullWidth
                      label={t('marking.marking_name')}
                      placeholder={t('marking.marking_name')}
                      disabled={!showEditable}
                      error={errors.name ? true : false}
                      helperText={errors.name ? errors.name.message : ''}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid xs="auto" item>
              <Stack
                spacing={2}
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%',
                  minWidth: 400,
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ height: 80 }}>
                  <Controller
                    name="marking_type"
                    control={control}
                    render={({ field }: FieldValues) => (
                      <>
                        <ClassicInputLabel
                          label={t('marking.marking_type')}
                          id="marking_type"
                        />
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value={MARKING_TYPE.TEXT}
                            control={<Radio disabled={!showEditable} />}
                            label={t('marking.text')}
                          />
                          <FormControlLabel
                            value={MARKING_TYPE.NUMBER}
                            control={<Radio disabled={!showEditable} />}
                            label={t('marking.number')}
                          />
                          <FormControlLabel
                            value={MARKING_TYPE.LOGO}
                            control={<Radio disabled={!showEditable} />}
                            label={t('marking.logo')}
                          />
                        </RadioGroup>
                      </>
                    )}
                  />
                </Box>
                {Number(type) !== MARKING_TYPE.LOGO ? (
                  <Box>
                    <ClassicInputLabel
                      label={t('marking.is_common')}
                      id="marking_type"
                    />
                    <Box sx={{ height: 50 }}>
                      <Controller
                        name="is_common"
                        control={control}
                        render={({ field }: FieldValues) => (
                          <RadioGroup row {...field}>
                            <FormControlLabel
                              value={1}
                              control={<Radio disabled={!showEditable} />}
                              label={t('marking.is_common_true')}
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio disabled={!showEditable} />}
                              label={t('marking.is_common_false')}
                            />
                          </RadioGroup>
                        )}
                      />
                    </Box>
                    {!Number(isCommon) ? (
                      <Box sx={{ mt: 1 }}>
                        <Controller
                          name="additional_price"
                          control={control}
                          render={({
                            field: { ref, onChange, ...rest },
                          }: FieldValues) => (
                            <CurrencyInput
                              {...rest}
                              ref={ref}
                              fullWidth
                              maxInputWidth={140}
                              disabled={!showEditable}
                              customInput={HorizontalTextField}
                              onValueChange={onChange}
                              label={t('marking.additional_price')}
                              placeholder={t('marking.additional_price')}
                              inputProps={{ sx: { textAlign: 'right', pr: 1 } }}
                              endAdornment={<Box>円</Box>}
                              error={errors.additional_price ? true : false}
                              helperText={
                                errors.additional_price
                                  ? errors.additional_price.message
                                  : ''
                              }
                            />
                          )}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ fontWeight: '700' }}>{t('marking.position')}</Divider>

          <Box mb={3}>
            <Controller
              name="positions"
              control={control}
              render={({ field: { value, onChange } }: FieldValues) => (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={'auto'}>
                      <Stack direction="row" spacing={2}>
                        <Box sx={{ width: 100 }}>
                          <Controller
                            control={control}
                            name="side"
                            render={({
                              field: { onChange, value },
                            }: FieldValues) => (
                              <ClassicSelect
                                label={t('marking.position_side')}
                                value={value}
                                onChange={onChange}
                              >
                                <MenuItem value={Side.Front}>
                                  <ListItemText
                                    primary={t('SYSCOMMON.front')}
                                  />
                                </MenuItem>
                                <MenuItem value={Side.Back}>
                                  <ListItemText primary={t('SYSCOMMON.back')} />
                                </MenuItem>
                              </ClassicSelect>
                            )}
                          />
                        </Box>
                        {showEditable && (
                          <>
                            <PositionEditor
                              value={position}
                              onChange={onPositionChange}
                              onSubmit={(newValue) =>
                                onChange([
                                  ...value,
                                  {
                                    ...newValue,
                                    id: password.randomPassword({
                                      length: 3,
                                      characters: [password.digits],
                                    }),
                                    isActive: value.length === 0 ? true : false,
                                  },
                                ])
                              }
                            />
                            <FormHelperText
                              sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
                              error={errors.positions ? true : false}
                            >
                              {errors.positions ? errors.positions.message : ''}
                            </FormHelperText>
                          </>
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      md
                      xs={12}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {showEditable && (
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: 100,
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {i18n.t('marking.position_helper_text')}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 1 }}>
                    <TableLayout
                      tableProps={{
                        sx: {
                          minWidth: '100%',
                          '& .MuiTableCell-root': {
                            p: '2px 2px',
                            fontSize: 12,
                          },
                        },
                      }}
                      renderRow={(p: PositionItem, i: number) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">
                              <Radio
                                checked={p.isActive}
                                onClick={() =>
                                  !p.isActive &&
                                  handleActivePosition(p, value, onChange)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">{p.x}</TableCell>
                            <TableCell align="center">{p.y}</TableCell>
                            <TableCell align="center">
                              {showEditable && (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    onChange(
                                      _.filter(value, (v) => v.id !== p.id)
                                    )
                                  }}
                                >
                                  <Typography>
                                    {i18n.t('marking.delete')}
                                  </Typography>
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      }}
                      data={value}
                      head={
                        <TableRow>
                          <TableCell align="center">
                            {t('marking.active')}
                          </TableCell>
                          <TableCell align="center">{t('marking.x')}</TableCell>
                          <TableCell align="center">{t('marking.y')}</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      }
                    />
                  </Box>
                </>
              )}
            />
          </Box>
          {Number(type) !== MARKING_TYPE.LOGO ? (
            <>
              <Divider sx={{ fontWeight: '700', mb: 1 }}>
                {t('marking.selectable_font')}
              </Divider>
              <Grid spacing={2} container>
                <Grid item xs={12} md={5}>
                  <Box sx={{ height: 80 }}>
                    <Controller
                      name="fonts"
                      control={control}
                      render={({ field: { value, onChange } }: FieldValues) => (
                        <>
                          <ClassicInputLabel
                            label={t('marking.selectable_font')}
                            id="fonts"
                          />
                          <FontEditor
                            onChange={onChange}
                            disabled={!showEditable}
                            value={value}
                            fonts={fonts}
                          />
                          <FormHelperText
                            sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
                            error={errors.fonts ? true : false}
                          >
                            {errors.fonts ? errors.fonts.message : ''}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box sx={{ height: 80 }}>
                    <Controller
                      control={control}
                      name="family"
                      render={({ field: { onChange, value } }: FieldValues) => (
                        <ClassicSelect
                          fullWidth
                          label={t('marking.default_family')}
                          value={value}
                          onChange={onChange}
                        >
                          {selectedFonts &&
                            selectedFonts.map((f) => {
                              if (f) {
                                return (
                                  <MenuItem key={f.id} value={f.id}>
                                    <ListItemText
                                      primary={_.get(f, 'attributes.name', '')}
                                    />
                                  </MenuItem>
                                )
                              }
                            })}
                        </ClassicSelect>
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{ height: 80 }}>
                    <Controller
                      name="font_size"
                      control={control}
                      render={({
                        field: { ref, onChange, ...rest },
                      }: FieldValues) => (
                        <CurrencyInput
                          {...rest}
                          customInput={ClassicTextField}
                          disableGroupSeparators
                          allowNegativeValue={false}
                          onValueChange={onChange}
                          fullWidth
                          disabled={!showEditable}
                          inputProps={{
                            sx: { textAlign: 'right', pr: 1 },
                            inputRef: ref,
                          }}
                          label={t('marking.font_size')}
                          placeholder={t('marking.font_size')}
                          error={errors.font_size ? true : false}
                          helperText={
                            errors.font_size ? errors.font_size.message : ''
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ mt: 1, mb: 1 }}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={3} item>
                    <Box sx={{ height: 80 }}>
                      <Controller
                        name="max_size"
                        control={control}
                        render={({
                          field: { ref, onChange, ...rest },
                        }: FieldValues) => (
                          <CurrencyInput
                            {...rest}
                            customInput={ClassicTextField}
                            ref={ref}
                            disableGroupSeparators
                            allowNegativeValue={false}
                            onValueChange={onChange}
                            fullWidth
                            disabled={!showEditable}
                            inputProps={{
                              sx: { textAlign: 'right', pr: 1 },
                            }}
                            label={t('marking.max_length')}
                            placeholder={t('marking.max_length')}
                            error={errors.font_size ? true : false}
                            helperText={
                              errors.font_size ? errors.font_size.message : ''
                            }
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} md={9} item>
                    <Box sx={{ height: 80 }}>
                      <Controller
                        name="sample_text"
                        control={control}
                        render={({
                          field: { ref, onChange, ...rest },
                        }: FieldValues) =>
                          Number(type) === MARKING_TYPE.TEXT ? (
                            <ClassicTextField
                              {...rest}
                              inputRef={ref}
                              fullWidth
                              onChange={onChange}
                              label={t('marking.sample_text')}
                              placeholder={t('marking.sample_text')}
                              error={errors.sample_text ? true : false}
                              helperText={
                                errors.sample_text
                                  ? errors.sample_text.message
                                  : ''
                              }
                            />
                          ) : (
                            <CurrencyInput
                              {...rest}
                              ref={ref}
                              customInput={ClassicTextField}
                              disableGroupSeparators
                              allowNegativeValue={false}
                              fullWidth
                              inputProps={{ sx: { textAlign: 'right', pr: 1 } }}
                              onChange={onChange}
                              label={t('marking.sample_text')}
                              placeholder={t('marking.sample_text')}
                              error={errors.sample_text ? true : false}
                              helperText={
                                errors.sample_text
                                  ? errors.sample_text.message
                                  : ''
                              }
                            />
                          )
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ fontWeight: '700' }}>{t('part.color')}</Divider>
              <Controller
                name="colors"
                control={control}
                render={({ field: { value, onChange } }: FieldValues) => (
                  <>
                    <Box sx={{ width: 600, pb: 2 }}>
                      <BoxedPicker
                        colors={colorValues}
                        onChangeComplete={(v) => {
                          setColor(v)
                        }}
                        value={color}
                        size={50}
                      />

                      <Stack spacing={3} sx={{ mt: 1 }} direction="row">
                        <Controller
                          name="fill"
                          control={control}
                          render={({ field: { value } }: FieldValues) => (
                            <>
                              <Box
                                sx={{
                                  width: 35,
                                  height: 35,
                                  border: '1px solid #ccc',
                                  backgroundColor: value,
                                }}
                              />
                            </>
                          )}
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={!showEditable}
                          onClick={() => handleDefault()}
                        >
                          <Check sx={{ fontSize: 14 }} />
                          {t('part.default_color')}
                        </Button>
                        {showEditable && (
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleDelete(value, onChange)}
                            disabled={_.isEmpty(color)}
                          >
                            <Delete sx={{ fontSize: 14 }} />
                            {t('button.delete')}
                          </Button>
                        )}
                      </Stack>
                    </Box>

                    {showEditable && (
                      <>
                        <ColorEditor
                          colors={value}
                          onChange={(v) => {
                            handleColorEditor(value, v, onChange)
                          }}
                        />
                        <FormHelperText
                          sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
                          error={errors.colors ? true : false}
                        >
                          {errors.colors ? errors.colors.message : ''}
                        </FormHelperText>
                      </>
                    )}
                  </>
                )}
              />
            </>
          ) : null}

          {showEditable && (
            <Stack
              sx={{ width: '100%', mt: 1 }}
              direction="row"
              justifyContent={'space-between'}
            >
              <Box>
                {mode === 'edit' && (
                  <Button
                    variant="outlined"
                    onClick={() => data && onDelete(data.id)}
                    startIcon={<DeleteForever />}
                  >
                    {t('marking.remove_marking')}
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  startIcon={<Save />}
                  type="submit"
                  variant="contained"
                  disabled={!isValid || !isDirty}
                >
                  {mode === 'edit'
                    ? t('marking.update_marking')
                    : t('marking.add_marking')}
                </Button>
              </Box>
            </Stack>
          )}
        </form>
      </Box>
    )
  }
)

export default MarkingUpsert
