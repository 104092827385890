import * as React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Box, Stack, IconButton } from '@mui/material'
import { Image, PermMedia } from '@mui/icons-material'
import { useUpload } from '@components/@admin/FileUpload'
import { URI } from '@constants/uri.constants'
import {
  ContentImageListResponse,
  getPresignedUrlResponse,
} from '@services/common.service'
import DialogBase from '@components/@material-extend/DialogBase'
import { useState, useEffect } from 'react'
import FileList from './FileList'
import { Meta } from '@store/metadata/actions/types'

interface TextEditorProps {
  onChange: (data: any) => void
  value: string
  imgList?: ContentImageListResponse
  onImageOpen: () => void
  imgMeta: Meta
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  imgList,
  imgMeta,
  onImageOpen,
}) => {
  const editorRef = React.useRef()
  const upload = useUpload()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    onImageOpen()
  }, [open])

  return (
    <Box sx={{ position: 'relative' }}>
      <DialogBase
        open={open}
        onClose={() => setOpen(false)}
        content={
          <FileList
            value={imgList}
            meta={imgMeta}
            onInsert={(img) => {
              setOpen(false)
              if (editorRef && editorRef.current) {
                const editor: any = editorRef.current
                editor.execute('insertImage', {
                  source: img,
                })
              }
            }}
          />
        }
      />

      <Box
        sx={{
          position: 'absolute',
          zIndex: 1000,
          right: 5,
          top: 5,
        }}
      >
        <Stack direction="row" spacing={1}>
          {imgList && (
            <IconButton sx={{ border: '0 none' }} onClick={() => setOpen(true)}>
              <PermMedia />
            </IconButton>
          )}
          <IconButton
            sx={{ border: '0 none' }}
            onClick={() => {
              upload({
                upload_uri: URI.UPLOAD_CONTENT,
              })
                .then((res: getPresignedUrlResponse) => {
                  if (editorRef && editorRef.current) {
                    const editor: any = editorRef.current
                    editor.execute('insertImage', {
                      source: res.file_url,
                    })
                  }
                })
                .catch(() => null)
            }}
          >
            <Image />
          </IconButton>
        </Stack>
      </Box>
      <CKEditor
        editor={ClassicEditor}
        config={{
          removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
        }}
        data={value}
        onChange={(event: any, editor: any) => {
          const data = editor.getData()
          onChange(data)
        }}
        onReady={(editor: any) => {
          editorRef.current = editor
        }}
      />
    </Box>
  )
}

export default TextEditor
