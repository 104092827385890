import LoginLayout from '@containers/Auth/LoginLayout'
import LoginForm from '@containers/Auth/LoginForm'

const Login = () => {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  )
}

export default Login
