import { Close, Info } from '@mui/icons-material'
import { Typography, Paper, Stack, useMediaQuery } from '@mui/material/'

export type HelperTextType = {
  desktop: string
  mobile: string
}

export type HelperTextProps = {
  text: HelperTextType
  active: boolean
  onClose?: () => void
  onShow?: () => void
}

const HelperText: React.FC<HelperTextProps> = ({
  text,
  active,
  onClose,
  onShow,
}) => {
  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <Paper sx={{ p: 1, position: 'relative', pr: active ? '40px' : 1 }}>
      <Stack direction={'row'} spacing={1}>
        <Info
          sx={{
            position: 'relative',
            fontSize: 18,
            zIndex: 100,
            cursor: 'pointer',
          }}
          onClick={() => onShow && onShow()}
        />
        {active ? (
          <Typography>{isMobile ? text.mobile : text.desktop}</Typography>
        ) : null}
      </Stack>
      {active ? (
        <Close
          onClick={() => onClose && onClose()}
          sx={{
            position: 'absolute',
            right: 5,
            top: 8,
            cursor: 'pointer',
            fontSize: 20,
            zIndex: 100,
          }}
        />
      ) : null}
    </Paper>
  )
}

export default HelperText
