export const currency = (data: number): string => {
  const formatter = Number(data).toLocaleString('jp-JP', {
    style: 'decimal',
    minimumFractionDigits: 0,
  })
  return `¥ ${formatter}`
}

export const transformAttr = (data: any): any => {
  return data.attributes
}
