import React, { useState, useEffect, ReactNode } from 'react'
import LargeSideBar from '@layouts/AdminLayout/LargeSidebar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box } from '@mui/material'
import Header from '@layouts/AdminLayout/AdminHeader'
import useAuth from 'utils/useAuth'
import { useDispatch } from 'react-redux'
import { logOut } from '@store/auth/actions'
import { AdminMainMenu } from '@constants/menu.constants'
import MainMenuItem from './elements/MainMenuItem'

interface LayoutProps {
  children: ReactNode
  title?: string
}

const AdminLayout: React.FC<LayoutProps> = ({ children, title }) => {
  const [open, letOpen] = useState(false)
  const { user } = useAuth()
  // const theme = useTheme()
  const isMobile = useMediaQuery('(max-width:900px)')
  const dispatch = useDispatch()

  const openClass = open === true ? 'sidenav-open' : ''

  const setOpen = (val: boolean) => {
    letOpen(val)
  }

  useEffect(() => {
    if (isMobile) {
      letOpen(false)
    } else {
      letOpen(true)
    }
  }, [isMobile])

  const onCloseClick = () => setOpen(false)
  // eslint-disable-next-line no-console

  const handleLogOut = () => {
    dispatch(logOut())
  }

  return (
    <Box sx={{ width: '100%', background: 'rgb(245, 247, 250)' }}>
      <Box
        className={`main-content-wrap ${openClass}`}
        sx={{
          '&.sidenav-open .page-wrapper': {
            paddingLeft: {
              lg: '230px',
              xl: '230px',
              md: '230px',
              sm: 0,
              xs: 0,
            },
          },
          '&.sidenav-open .sidebar-main#sidebar-main': {
            left: '0',
            visibility: 'visible',
            opacity: 1,
          },
          '&.sidenav-open .navigation-content': {
            display: 'block',
          },
          '& .page-wrapper': {
            paddingLeft: 0,
          },
        }}
      >
        <LargeSideBar onCloseClick={onCloseClick}>
          <Box sx={{ position: 'relative', margin: '20px 15px' }}>
            {AdminMainMenu.map((route, index) => {
              return <MainMenuItem key={index} route={route} />
            })}
          </Box>
        </LargeSideBar>
        <Box
          className="page-wrapper"
          sx={{
            minHeight: '100vh',
            WebkitBoxShadow: '0 0 30px 0 #e4e7eb',
            boxShadow: '0 0 30px 0 #e4e7eb',
          }}
        >
          <Box
            className="main-content"
            sx={{
              WebkitBoxShadow: '0 0 30px 0 #e4e7eb',
              boxShadow: '0 0 30px 0 #e4e7eb',
              width: '100%',
              minHeight: '100vh',
              background: '#fff',
            }}
          >
            <Header
              title={title ? title : ''}
              userData={user}
              open={open}
              setOpen={setOpen}
              handleLogOut={handleLogOut}
            />
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AdminLayout
