import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/order.services'
import { ORDER_ACTION_TYPE } from './types'
import _ from 'lodash'

export const orderList = createAsyncThunk<
  services.OrderListResponse,
  services.OrderFilterParams
>(ORDER_ACTION_TYPE.LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.list(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const orderCreate = createAsyncThunk<
  services.OrderCreateResponse,
  services.OrderCreateParams
>(ORDER_ACTION_TYPE.CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(_.omit(params, 'type'))
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const showByCode = createAsyncThunk<
  services.OrderCreateResponse,
  services.OrderShowParams
>(ORDER_ACTION_TYPE.SHOW, async (params, { rejectWithValue }) => {
  try {
    const res = await services.show(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const resetDetail = createAction('order/resetDetail')

export const initOrder =
  (params: services.OrderShowParams) =>
  async (dispatch: any): Promise<any> => {
    Promise.resolve(dispatch(resetDetail())).then(() =>
      dispatch(showByCode(params))
    )
  }
