import CustomTextField from '@components/@material-extend/CustomTextField'
import { LoadingButton } from '@mui/lab'
import {
  Typography,
  Checkbox,
  Stack,
  Grid,
  Box,
  FormHelperText,
} from '@mui/material'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useInquiryForm from './useInquiryForm'
import { ReCaptcha } from '@containers/Recaptcha'
import { InquiryParams } from '@services/common.service'
import _ from 'lodash'
import { Meta } from '@store/metadata/actions/types'
import { Link } from 'react-router-dom'
import { ThemeColors } from '@theme/colors'
export type InquiryFormProps = {
  onSubmit: (params: InquiryParams) => void
  meta: Meta
}

const InquiryForm: React.FC<InquiryFormProps> = ({ onSubmit, meta }) => {
  const { t } = useTranslation()

  const { Controller, methods } = useInquiryForm()
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors, isValid },
  } = methods

  const onSubmitForm = (values: any) => {
    const params = _.omit(values, 'captchaToken')
    if (!meta.pending) onSubmit(params as InquiryParams)
  }

  const onVerifyCaptcha = (token: string) => {
    setValue('captchaToken', token)
    trigger('captchaToken')
  }

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '100%' },
        p: { xs: 1, sm: 1, md: 5 },
        margin: '0 auto',
      }}
    >
      <form onSubmit={handleSubmit(onSubmitForm)} method="post">
        <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
          <Grid item sm={12} md={6} xs={12}>
            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="last_name"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <CustomTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    required
                    label={t('inquiry.last_name')}
                    placeholder={t('inquiry.last_name')}
                    error={errors.last_name ? true : false}
                    helperText={
                      errors.last_name ? errors.last_name.message : ''
                    }
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="first_name"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <CustomTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    required
                    label={t('inquiry.first_name')}
                    placeholder={t('inquiry.first_name')}
                    error={errors.last_name ? true : false}
                    helperText={
                      errors.first_name ? errors.first_name.message : ''
                    }
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ref, ...rest } }) => (
              <CustomTextField
                {...rest}
                inputRef={ref}
                fullWidth
                required
                label={t('inquiry.email')}
                placeholder={t('inquiry.email')}
                error={errors.email ? true : false}
                helperText={errors.email ? errors.email.message : ''}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { ref, ...rest } }) => (
              <CustomTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('inquiry.phone')}
                placeholder={t('inquiry.phone')}
                error={errors.phone ? true : false}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            control={control}
            name="company_name"
            render={({ field: { ref, ...rest } }) => (
              <CustomTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('inquiry.company_name')}
                placeholder={t('inquiry.company_name')}
                error={errors.company_name ? true : false}
                helperText={
                  errors.company_name ? errors.company_name.message : ''
                }
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', mb: 1 }}>
          <Controller
            control={control}
            name="body"
            render={({ field: { ref, ...rest } }) => (
              <CustomTextField
                {...rest}
                inputRef={ref}
                fullWidth
                multiline
                maxRows={10}
                minRows={5}
                required
                label={t('inquiry.body')}
                placeholder={t('inquiry.body')}
                error={errors.body ? true : false}
                helperText={errors.body ? errors.body.message : ''}
              />
            )}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={8}></Grid>
          <Grid item lg={4} sm={12}>
            <Box sx={{ mt: 1 }}>
              <ReCaptcha onVerifyCaptcha={onVerifyCaptcha} />
              {errors.captchaToken && (
                <FormHelperText
                  sx={{ marginLeft: 0 }}
                  error={!!errors.captchaToken}
                >
                  {errors.captchaToken.message}{' '}
                </FormHelperText>
              )}
            </Box>
          </Grid>
        </Grid>
        <br />

        <Typography component={'div'} textAlign={'center'}>
          当社
          <Typography component={'span'}>
            <Link to="/terms" style={{ color: ThemeColors.dark }}>
              プライバシーポリシー
            </Link>{' '}
          </Typography>
          に同意頂ける場合は「同意する」にチェックを付け 送信してください。
        </Typography>
        <br />
        <Stack
          direction="row"
          spacing={1}
          alignItems={'center'}
          justifyContent={'center'}
          pr={1}
        >
          <Controller
            control={control}
            name="confirm"
            render={({ field: { ref, ...rest } }) => (
              <Checkbox
                {...rest}
                inputRef={ref}
                defaultChecked={false}
                required={true}
              />
            )}
          />
          <Typography
            style={{
              marginLeft: 0,
              fontWeight: 500,
              color: ThemeColors.dark,
              fontSize: 14,
            }}
          >
            {t('SYSCOMMON.terms_confirm')}
          </Typography>
        </Stack>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                mt: 1,
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={!isValid}
                fullWidth
                // loading={meta.pending}
                color="primary"
                type="submit"
              >
                {t('inquiry.submit')}
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default InquiryForm
