import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/auth.services'
import { AUTH_ACTION_TYPE } from './types'

export const loginByEmail = createAsyncThunk<
  services.UserLoginResponse,
  services.UserLoginParams
>(AUTH_ACTION_TYPE.LOGIN_BY_EMAIL, async (loginParam, { rejectWithValue }) => {
  try {
    const res = await services.login(loginParam)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const passwordForgot = createAsyncThunk<
  services.PlainResponse,
  services.PasswordForgotParams
>(AUTH_ACTION_TYPE.PASSWORD_FORGOT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.passwordForgot(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const passwordReset = createAsyncThunk<
  services.PlainResponse,
  services.PasswordResetParams
>(AUTH_ACTION_TYPE.PASSWORD_RESET, async (params, { rejectWithValue }) => {
  try {
    const res = await services.passwordReset(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const logOut = createAction('auth/logOut')
