import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { use100vh } from 'react-div-100vh'

const FullScreenLayout: React.FC<ReactNode> = ({ children }) => {
  const height = use100vh()
  return <Box sx={{ height: height, overflow: 'hidden' }}>{children}</Box>
}

export default FullScreenLayout
