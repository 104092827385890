/* eslint-disable no-console */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import { Box, InputAdornment, IconButton, Alert } from '@mui/material'
import { FieldValues } from 'react-hook-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import usePasswordResetForm from './usePasswordResetForm'
import { passwordReset } from '@store/auth/actions'
import { createMetaSelector } from '@store/metadata/selectors'
import { PasswordResetParams } from '@services/auth.services'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
const PasswordResetForm: React.FC = () => {
  const { t } = useTranslation()
  const { reset_password_token } = useParams()
  const { Controller, methods } = usePasswordResetForm()
  const dispatch = useDispatch()

  const passwordMeta = createMetaSelector(passwordReset)
  const meta = useSelector(passwordMeta)

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = methods
  const onSubmit = (values: PasswordResetParams) => {
    if (_.isEmpty(errors) && reset_password_token) {
      values.reset_password_token = reset_password_token
      dispatch(passwordReset(values))
    }
  }

  const [showPassword, setShowpassword] = useState<boolean>(false)
  const handleClickShowPassword = () => {
    setShowpassword(!showPassword)
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }
  return (
    <>
      {meta.error ? (
        <Alert sx={{ mb: 1 }} severity="error">
          {'BE000005' == _.get(meta, 'error.error.message')
            ? t('ERROR.BE000005')
            : t('ERROR.E000017')}
        </Alert>
      ) : null}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} method="post">
        <Controller
          name="new_password"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <Box sx={{ mt: 1, mb: 2, height: 80 }}>
              <ClassicTextField
                {...rest}
                fullWidth
                inputRef={ref}
                name="new_password"
                label={t('SYSCOMMON.new_password')}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('SYSCOMMON.new_password')}
                error={errors.new_password ? true : false}
                helperText={
                  errors.new_password ? errors.new_password.message : ''
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ fontSize: 20, marginRight: 5 }}
                        />
                      ) : (
                        <Visibility style={{ fontSize: 20, marginRight: 5 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          )}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          size="large"
          loading={meta.pending}
          disabled={meta.pending || !isDirty || !isValid}
          sx={{ mb: 3 }}
        >
          {t('SYSCOMMON.password_reset_submit_button')}
        </LoadingButton>

        <Link to="/login">{t('SYSCOMMON.back_to_login')}</Link>
      </Box>
    </>
  )
}

export default PasswordResetForm
