import { useConfirm } from '@components/Confirm'
import { Side } from '@constants/editor.constants'
import { LayoutContants } from '@constants/layout.constants'
import i18n from '@locales/i18n'
import { ArrowBack, Brush, Visibility } from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import customHistory from 'customHistory'
import React, { ReactNode } from 'react'

export type EditorLayoutProps = {
  children: ReactNode
  control: ReactNode
  side: Side
  onChangeSide: (side: Side) => void
  headerAction: ReactNode
  isLoading: boolean
  onCopyRemove: () => void
  onReset: () => void
  bottom?: ReactNode
  onSubmit: () => void
  preview: boolean
  setPreview: (value: boolean) => void
  isValid: boolean
}

const EditorLayout: React.FC<EditorLayoutProps> = ({
  children,
  control,
  onChangeSide,
  headerAction,
  onSubmit,
  setPreview,
  isValid,
  isLoading,
  preview,
  onCopyRemove,
  onReset,
  bottom,
  side,
}) => {
  const confirm = useConfirm()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMobile = useMediaQuery('(max-width:900px)')

  const handleBack = () => {
    confirm({
      title: i18n.t('SYSCOMMON.back_confirm_title'),
      description: i18n.t('SYSCOMMON.back_confirm_desc'),
    })
      .then(() => {
        customHistory.push('/')
        onCopyRemove()
      })
      .catch(() => null)
  }

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            background: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}

      <AppBar
        elevation={0}
        position="relative"
        color="default"
        sx={{
          background: '#fff',
          borderBottom: '1px solid #e3e4e5',
          boxShadow: '0 1px 3px rgb(0 0 0 / 4%)',
          p: 0,
          '& .MuiPaper-root-MuiAppBar-root': {
            borderRadius: 0,
          },
        }}
      >
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              minHeight: LayoutContants.appbarHeight,
              p: 0,
            },
          }}
        >
          <Grid container sx={{ height: '100%', p: 0 }}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Box display="flex" width={'100%'} justifyContent="center">
                <Stack direction="row" sx={{ p: 1, pl: 2 }}>
                  {!isMobile ? (
                    <IconButton
                      onClick={() => handleBack()}
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      sx={{
                        border: '0 none',
                        position: 'absolute',
                        left: 15,
                        top: -2,
                        display: 'block',
                        width: 60,
                        mt: 0.5,
                      }}
                    >
                      <ArrowBack />
                      <Box sx={{ fontSize: 10, fontWeight: '600' }}>戻る</Box>
                    </IconButton>
                  ) : null}

                  {isMobile ? (
                    <IconButton
                      onClick={() => handleBack()}
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      sx={{
                        border: '0 none',
                        position: 'absolute',
                        left: 15,
                        top: 7,
                        display: 'flex',
                        width: 60,
                        mt: 0.5,
                      }}
                    >
                      <ArrowBack />
                      <Box component={'span'}>戻る</Box>
                    </IconButton>
                  ) : null}

                  <Typography
                    sx={{ ml: 2, flex: 1, pt: 0.5 }}
                    variant="h4"
                    component="div"
                  >
                    デザイン
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    p: 1,
                    display: {
                      sm: 'block',
                      xs: 'block',
                      md: 'none',
                      lg: 'none',
                      xl: 'none',
                    },
                  }}
                >
                  {!isMobile ? (
                    preview ? (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => setPreview(false)}
                        startIcon={
                          <Brush
                            sx={{
                              color: ThemeColors.white,
                            }}
                          />
                        }
                      >
                        デザインを変更する
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setPreview(true)}
                        startIcon={
                          <Visibility
                            sx={{
                              color: ThemeColors.white,
                            }}
                          />
                        }
                      >
                        デザインを確認してみる
                      </Button>
                    )
                  ) : null}
                </Box>
              </Box>
            </Grid>
            {isMobile ? (
              <Box
                sx={{ p: 1, width: '100%', background: ThemeColors.secondary }}
              >
                {preview ? (
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="inherit"
                    sx={{ border: '1px solid #fff', color: '#fff' }}
                    onClick={() => setPreview(false)}
                    startIcon={
                      <Brush
                        sx={{
                          color: ThemeColors.white,
                        }}
                      />
                    }
                  >
                    デザインを変更する
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ border: '1px solid #fff', color: '#fff' }}
                    size="small"
                    fullWidth
                    onClick={() => setPreview(true)}
                    startIcon={
                      <Visibility
                        sx={{
                          color: ThemeColors.white,
                        }}
                      />
                    }
                  >
                    デザインを確認してみる
                  </Button>
                )}
              </Box>
            ) : (
              ''
            )}
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Box sx={{ position: 'relative', zIndex: 100 }}>
                {headerAction}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        role="main"
        component="main"
        sx={{
          height: '100%',
          paddingBottom: `${LayoutContants.appbarHeight * 2}px`,
        }}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            className={preview ? 'active-mobile' : ''}
            sx={{
              padding: isMobile ? 1 : 0,
              display: {
                sm: 'none',
                xs: 'none',
                lg: 'flex',
                md: 'flex',
                xl: 'flex',
              },
              '&.active-mobile': {
                display: {
                  sm: 'flex',
                  xs: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  xl: 'flex',
                },
              },
            }}
          >
            {children}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Paper
              sx={{
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                border: '1px solid #222',
                borderTop: '0 none',
                marginTop: '-1px',
                borderBottom: '0 none',
              }}
            >
              {control}
              {bottom && (
                <Box
                  sx={{
                    bottom: {
                      xs: LayoutContants.appbarHeight,
                      sm: LayoutContants.appbarHeight,
                      md: 0,
                    },
                    position: 'absolute',
                    width: '100%',
                    right: 0,
                  }}
                >
                  {bottom}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Paper
        className="editor-footer"
        sx={{
          position: 'absolute',
          bottom: 0,
          height: LayoutContants.appbarHeight,
          left: 0,
          right: 0,
          width: '100%',
          background: '#3e3e3e',
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Grid container sx={{ height: '100%' }}>
            {isMobile && preview ? (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 110,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  width: 210,
                }}
              >
                <ButtonGroup sx={{ p: 0.5, background: '#fff' }}>
                  <Button
                    onClick={() => onChangeSide(Side.Front)}
                    disableRipple
                    className={side === Side.Front ? 'is-active' : ''}
                    variant="outlined"
                    sx={{
                      color: '#222',
                      borderColor: '#999',
                      border: '0 none',
                      width: 100,
                      '&:hover': {
                        border: '0 none',
                      },
                      '&.is-active': {
                        color: '#fff',
                        background: ThemeColors.primary,
                      },
                    }}
                  >
                    {i18n.t('SYSCOMMON.front')}
                  </Button>
                  <Button
                    onClick={() => onChangeSide(Side.Back)}
                    disableRipple
                    className={side === Side.Back ? 'is-active' : ''}
                    sx={{
                      color: '#222',
                      border: '0 none',
                      width: 100,
                      '&.is-active': {
                        color: '#fff',
                        background: ThemeColors.primary,
                      },
                      '&:hover': {
                        border: '0 none',
                      },
                    }}
                  >
                    {i18n.t('SYSCOMMON.back')}
                  </Button>
                </ButtonGroup>
              </Box>
            ) : null}

            {isMobile && preview ? (
              <Box sx={{ position: 'absolute', bottom: 60, right: 8 }}>
                <Button
                  onClick={() => onReset()}
                  variant="outlined"
                  sx={{
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                >
                  初期状態に戻す
                </Button>
              </Box>
            ) : null}

            {!isMobile ? (
              <Grid item xs={0} sm={0} md={7} lg={7} xl={7}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                    pl: 0.5,
                    pr: 0.5,
                  }}
                >
                  <ButtonGroup sx={{ p: 0.5, background: '#fff' }}>
                    <Button
                      onClick={() => onChangeSide(Side.Front)}
                      disableRipple
                      className={side === Side.Front ? 'is-active' : ''}
                      variant="outlined"
                      sx={{
                        color: '#222',
                        borderColor: '#999',
                        border: '0 none',
                        width: 100,
                        '&:hover': {
                          border: '0 none',
                        },
                        '&.is-active': {
                          color: '#fff',
                          background: ThemeColors.primary,
                        },
                      }}
                    >
                      {i18n.t('SYSCOMMON.front')}
                    </Button>
                    <Button
                      onClick={() => onChangeSide(Side.Back)}
                      disableRipple
                      className={side === Side.Back ? 'is-active' : ''}
                      sx={{
                        color: '#222',
                        border: '0 none',
                        width: 100,
                        '&.is-active': {
                          color: '#fff',
                          background: ThemeColors.primary,
                        },
                        '&:hover': {
                          border: '0 none',
                        },
                      }}
                    >
                      {i18n.t('SYSCOMMON.back')}
                    </Button>
                  </ButtonGroup>
                  <Button
                    onClick={() => onReset()}
                    variant="outlined"
                    sx={{
                      color: '#fff',
                      border: '1px solid #eee',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    }}
                  >
                    初期状態に戻す
                  </Button>
                </Box>
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ borderLeft: '1px solid #555' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: ThemeColors.primary,
                  position: 'relative',
                  zIndex: 1000,
                  pl: 1,
                  pr: 1,
                }}
              >
                <Button
                  size="large"
                  fullWidth
                  disabled={!isValid}
                  onClick={() => onSubmit()}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <Box
                      sx={{
                        width: 13,
                        height: 13,
                        mr: 1,
                        position: 'relative',
                        top: '-17px',
                      }}
                    >
                      <Box
                        sx={{ width: '100%', height: 'auto' }}
                        component="img"
                        src="images/ribonicon.png"
                      ></Box>
                    </Box>
                  }
                  sx={{
                    fontSize: { xs: 12, sm: 14, md: 14, xl: 14, lg: 14 },
                    '&.Mui-disabled': {
                      color: '#eee',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                >
                  デザイン案保存
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default EditorLayout
