import LoginLayout from '@containers/Auth/LoginLayout'
import PasswordForgotForm from '@containers/Auth/PasswordForgotForm'

const PasswordForgot = () => {
  return (
    <LoginLayout>
      <PasswordForgotForm />
    </LoginLayout>
  )
}

export default PasswordForgot
