const API = 'https://api.bmt-simulator.tennic.co.jp'
export const URI = {
  API,
  LOGIN: '/admin/auth/login',
  PASSWORD_FORGOT: '/admin/admin_users/passwords/forgot',
  PASSWORD_RESET: '/admin/admin_users/passwords/reset_by_token',
  ADMIN_USERS: '/admin/admin_users',
  ITEM: '/admin/items',
  GENRES: '/admin/genres',
  SETTINGS: '/admin/system_settings',
  FONTS: '/admin/fonts',
  SHOPS: 'admin/shops',
  LOGOUT: '/admin/auth/logout',
  ORDERS: '/admin/orders',
  DESIGNS: '/admin/designs',
  DESIGN_ITEM: '/admin/designs/:id',
  UPLOAD_CONTENT: '/admin/s3/contents',
  UPLOAD_FONT: '/admin/s3/fonts/:id',
  UPLOAD_THUMB: '/admin/s3/item_thumb/:id',
  UPLOAD_IMAGE: '/admin/s3/item_images/:id',
  UPLOAD_LOGO: 'v1/designs/logos_url',
  CONTENT_IMAGE_LIST: '/admin/s3/content_list',
  PRODUCT_IMAGE: '/admin/items/:id/item_images',
  REFRESH: '/admin/auth/refresh_token',
  PARTS: '/admin/s3/item_parts/:id',
  PART: '/admin/items/:id/item_parts',
  MARKING: '/admin/items/:id/item_markings',
  TOP_LIST: '/v1/items',
  SHOPS_PUBLIC: 'v1/shops',
  ITEM_DETAIL: 'v1/items/:id',
  DESIGN_NEW: 'v1/designs/new',
  CLIENT_FONTS: 'v1/fonts/all',
  DESIGN_CREATE: 'v1/designs',
  DESIGN_DETAIL: 'v1/designs/:id',
  COMPARE: 'v1/designs/compare',
  COMPLETE: '/admin/items/complete/:id',
  CLIENT_ORDERS: 'v1/orders',
  CLIENT_SHOW_ORDER: 'v1/orders/show_by_code',
  SHARE: 'v1/designs/share',
  COPY: 'v1/designs/copy',
  INQUIRY: 'v1/inquires',
}
