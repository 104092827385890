import CategorySelector from '@components/CategorySelector'
import SampleSelector from '@components/SampleSelector'
import i18n from '@locales/i18n'
import { Box, Button, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ArrowRight } from '@mui/icons-material'
import FullScreenModal from '@components/FullScreenModal'
import Detail from '@components/Detail'
import { createSearchParams, useNavigate } from 'react-router-dom'
import usePublicItem from '@containers/Items/usePublicItem'
import { SEX } from '@constants/common.constants'
import _ from 'lodash'
import Spinner from '@components/@material-extend/Spinner'
import { LayoutContants } from '@constants/layout.constants'
import { ThemeColors } from '@theme/colors'

const CategoryOptions = [
  {
    label: ' Mens / Unisex',
    id: 1,
    image: 'images/unisex.png',
  },
  {
    label: 'Ladies',
    id: 2,
    image: 'images/ladies.png',
  },
]

export type SelectedType = {
  tops: number | undefined
  bottoms: number | undefined
}

const HomePage = () => {
  const [category, onCategoryChange] = useState<SEX>(SEX.UNISEX)
  const [open, setOpen] = useState<string | null>(null)
  const [selected, setSelected] = useState<SelectedType>({
    tops: undefined,
    bottoms: undefined,
  })

  const { tops, bottoms, fetch, detail, data, meta } = usePublicItem()

  const navigate = useNavigate()

  useEffect(() => {
    if (category) {
      fetch({ sex: category })
    }
  }, [category])

  useEffect(() => {
    setSelected({
      tops: undefined,
      bottoms: undefined,
    })
  }, [category])

  useEffect(() => {
    if (open) {
      detail(open)
    }
  }, [open])

  const renderContent = () => {
    if (data) {
      return <Detail data={data} />
    }
  }

  const renderLoader = () => {
    if (meta.pending && !meta.loaded && !meta.error) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 300,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  const handleNavigation = () => {
    const params = {
      t: _.get(selected, 'tops', '').toString(),
      b: _.get(selected, 'bottoms', '').toString(),
    }
    navigate({
      pathname: '/editor',
      search: createSearchParams(params).toString(),
    })
  }

  return (
    <>
      <Box
        sx={{
          background: '#222222',
          height: 180,
          color: '#fff',
          marginTop: `-${LayoutContants.appbarHeight}px`,
          pt: `${LayoutContants.appbarHeight + 20}px`,
          textAlign: 'center',
        }}
      >
        <Typography color="#fff" variant="h1" sx={{ fontWeight: 'bold' }}>
          Wear Color Simulation
        </Typography>
        <Typography color="#fff" variant="body1">
          □■□■□■□■□■□■□■□■□■□■□■□■□
        </Typography>
      </Box>
      <Container
        maxWidth={'lg'}
        sx={{ maxWidth: '100%', margin: '0 auto', mt: 1 }}
      >
        <Box component="section" sx={{ mt: 4 }}>
          <Typography
            align="center"
            variant="h2"
            sx={{
              background: ThemeColors.primary,
              color: '#fff',
              fontSize: 18,
              fontWeight: '500',
              pt: 1,
              pb: 1,
              mb: 2,
            }}
          >
            {i18n.t('SYSCOMMON.category_select')}
          </Typography>
          <CategorySelector
            data={CategoryOptions}
            value={category}
            onChange={onCategoryChange}
          />
        </Box>
        <Box component="section" sx={{ mt: 6 }}>
          <Typography
            align="center"
            variant="h2"
            sx={{
              background: ThemeColors.primary,
              color: '#fff',
              fontSize: 18,
              fontWeight: '500',
              pt: 1,
              pb: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box sx={{ height: 25, width: 25, mr: 1 }}>
              <Box
                component={'img'}
                sx={{ width: '100%', height: 'auto' }}
                src={'images/t-shirt.png'}
              ></Box>
            </Box>
            {i18n.t('SYSCOMMON.tops')}
          </Typography>
          {!_.isEmpty(tops) && (
            <SampleSelector
              onView={setOpen}
              data={tops}
              value={selected.tops}
              onChange={(id) =>
                setSelected({
                  ...selected,
                  tops: selected.tops === id ? undefined : id,
                })
              }
            />
          )}
          {renderLoader()}
        </Box>
        <Box component="section" sx={{ mt: 4 }}>
          <Typography
            align="center"
            variant="h2"
            sx={{
              background: ThemeColors.primary,
              color: '#fff',
              fontSize: 18,
              fontWeight: '500',
              pt: 1,
              pb: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box sx={{ height: 20, width: 20, mr: 1 }}>
              <Box
                component={'img'}
                sx={{ width: '100%', height: 'auto' }}
                src={'images/halfpants.png'}
              ></Box>
            </Box>
            {i18n.t('SYSCOMMON.bottoms')}
          </Typography>
          {!_.isEmpty(bottoms) && (
            <SampleSelector
              data={bottoms}
              onView={setOpen}
              value={selected.bottoms}
              onChange={(id) =>
                setSelected({
                  ...selected,
                  bottoms: selected.bottoms === id ? undefined : id,
                })
              }
            />
          )}
          {renderLoader()}
        </Box>
        <Box
          component="section"
          sx={{
            mt: 4,
            mb: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={!selected.tops && !selected.bottoms}
            endIcon={<ArrowRight />}
            size="large"
            sx={{ maxWidth: '400px', width: '100%' }}
            onClick={() => handleNavigation()}
          >
            {i18n.t('SYSCOMMON.simulate')}
          </Button>
        </Box>
        <FullScreenModal
          title={''}
          content={renderContent()}
          open={open ? true : false}
          onClose={() => setOpen(null)}
        />
      </Container>
    </>
  )
}

export default HomePage
