import Part from '@components/@admin/Part'
import PartPreview from '@components/@admin/Part/PartPreview'
import { useConfirm } from '@components/Confirm'
import { Side } from '@constants/editor.constants'
import PartCreate, { PartItemAdd } from '@containers/Parts/PartCreate'
import useParts from '@containers/Parts/useParts'
import i18n from '@locales/i18n'
import { Paper, Box, Stack, ButtonGroup, Button, Alert } from '@mui/material'
import { PartItem } from '@services/item.services'
import { ThemeColors } from '@theme/colors'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import DialogBase from '@components/@material-extend/DialogBase'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { Add, Close } from '@mui/icons-material'
import StepTitle from '@containers/Parts/StepTitle'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import Spinner from '@components/@material-extend/Spinner'
import password from 'secure-random-password'
import customHistory from 'customHistory'
import { useCallbackPrompt } from '@utils/useCallBackPromt'

const StepOne = () => {
  const { id } = useParams()
  const [side, onChangeSide] = useState(Side.Front)
  const [edit, setEdit] = useState<PartItem | null>(null)
  const [img, setImage] = useState<string | null>(null)
  const [parts, setParts] = useState<PartItem[] | undefined>(undefined)
  const [showForm, setShowForm] = useState<boolean>(false)

  const { fetch, list, create, item, fetchDetail, metaItem } = useParts()
  const showEditable = !item?.attributes?.has_design
  const isDirty = !_.isEqual(list, parts)
  const testNames = edit
    ? _.map(
        _.filter(parts, (p) => p.id !== edit.id),
        (obj) => obj.attributes.name
      )
    : _.map(parts, (obj) => obj.attributes.name)

  const isValid = _.every(parts, (obj) => {
    let hasPath = false
    if (_.get(obj, 'attributes.back_svg', undefined)) {
      hasPath = true
    }
    if (_.get(obj, 'attributes.front_svg', undefined)) {
      hasPath = true
    }
    return hasPath
  })

  useEffect(() => {
    if (id) {
      fetch(id)
      fetchDetail(id)
    }
  }, [])

  useEffect(() => {
    if (list) {
      setParts(list)
    }
  }, [list])

  const confirm = useConfirm()

  const handleDelete = (part_id: string) => {
    confirm({
      title: i18n.t('part.delete_confirm_title'),
      description: i18n.t('part.delete_confirm_desc'),
    })
      .then(() => {
        setParts(_.filter(parts, (p) => p.id !== part_id))
      })
      .catch(() => null)
  }

  const handleEdit = (item: PartItem) => {
    setEdit(item)
    setShowForm(true)
  }

  const handleBack = () => {
    if (isDirty) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          customHistory.push('/admin/items')
        })
        .catch(() => null)
    } else {
      customHistory.push('/admin/items')
    }
  }

  const reorder = (
    list: PartItem[],
    startIndex: number,
    endIndex: number
  ): PartItem[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const editPart = (value: PartItem) => {
    setParts(
      _.map(parts, (p) => {
        if (p.id === value.id) {
          return value
        } else {
          return p
        }
      })
    )
  }

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const _parts = parts ? [...parts] : []

    const items: PartItem[] = reorder(
      _parts,
      result.source.index,
      result.destination.index
    )

    const reOrder = _.map(items, (item, i) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          part_order: i,
        },
      }
    })

    setParts(reOrder)

    // change state
  }

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    listStyle: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen !important' : '#fff',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const onAdd = (part: PartItemAdd) => {
    const order = parts ? parts.length + 1 : 0

    if (parts && id) {
      const clientId = password.randomPassword({
        length: 2,
        characters: [password.digits],
      })
      setParts(
        _.concat(parts, {
          id: `client-${clientId}`,
          type: 'item_part',
          attributes: {
            ...part,
            part_order: order,
          },
        })
      )
    }
    setShowForm(false)
  }
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)

  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])

  const handleSaveAll = () => {
    if (id && isDirty && isValid && !_.isEmpty(parts)) {
      confirm({
        title: i18n.t('part.create_confirm_title'),
        description: i18n.t('part.create_confirm_desc'),
      })
        .then(() => {
          const partValues = _.map(parts, (p) => {
            const { id, attributes } = p
            if (!_.startsWith(id, 'client-')) {
              return {
                id: Number(id),
                name: attributes.name,
                part_order: attributes.part_order,
                front_path: attributes.front_path,
                is_editable: attributes.is_editable,
                back_path: attributes.back_path,
                colors: attributes.is_editable
                  ? attributes.colors
                  : {
                      fill: '',
                      values: [],
                    },
              }
            }
            return {
              name: attributes.name,
              part_order: attributes.part_order,
              front_path: attributes.front_path,
              is_editable: attributes.is_editable,
              back_path: attributes.back_path,
              colors: attributes.is_editable
                ? attributes.colors
                : {
                    fill: '',
                    values: [],
                  },
            }
          })
          if (id && parts) {
            create({
              id: id,
              params: {
                parts: partValues,
              },
            })
          }
        })
        .catch(() => null)
    } else if (!isDirty && isValid && !_.isEmpty(parts)) {
      customHistory.push(`/admin/items/${id}/step/2`)
    }
  }

  const renderLoader = () => {
    if (metaItem.pending && !item && !metaItem.error && !metaItem.loaded) {
      return (
        <Box
          sx={{
            width: '100%',
            height: 600,
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner loading={true} />
        </Box>
      )
    }
    return null
  }

  return (
    <ContentBox>
      {id && item && <StepTitle data={item} id={id} step={1} />}
      {renderLoader()}
      <DialogBase
        onClose={() => setImage(null)}
        open={img !== null ? true : false}
        contentProps={{ sx: { p: 3 } }}
        content={
          <>
            <Box
              sx={{
                backgroundSize: '30px 30px',
                backgroundColor: 'transparent',
                backgroundPosition: '0px 0px, 0px 15px, 15px -15px, -15px 0px',
                backgroundImage:
                  'linear-gradient(45deg, rgb(230, 230, 230) 25%, transparent 25%), linear-gradient(-45deg, rgb(230, 230, 230) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(230, 230, 230) 75%), linear-gradient(-45deg, transparent 75%, rgb(230, 230, 230) 75%)',
              }}
            >
              <Close
                onClick={() => setImage(null)}
                sx={{ position: 'absolute', top: 2, right: 2 }}
              />
              {img ? (
                <Box
                  sx={{ width: 350, height: 450 }}
                  component="img"
                  src={img}
                />
              ) : null}
            </Box>
          </>
        }
      />
      {item && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Box>
              <PartPreview data={parts ? parts : []} side={side} />
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonGroup>
                  <Button
                    onClick={() => onChangeSide(Side.Front)}
                    disableRipple
                    className={side === Side.Front ? 'is-active' : ''}
                    variant="outlined"
                    sx={{
                      color: '#111',
                      borderColor: '#999',
                      '&:hover': {
                        borderColor: '#aaa',
                      },
                      '&.is-active': {
                        color: ThemeColors.secondary,
                      },
                    }}
                  >
                    {i18n.t('SYSCOMMON.front')}
                  </Button>
                  <Button
                    onClick={() => onChangeSide(Side.Back)}
                    disableRipple
                    className={side === Side.Back ? 'is-active' : ''}
                    sx={{
                      color: '#111',
                      borderColor: '#999',
                      '&.is-active': {
                        color: ThemeColors.secondary,
                      },
                      '&:hover': {
                        borderColor: '#aaa',
                      },
                    }}
                    variant="outlined"
                  >
                    {i18n.t('SYSCOMMON.back')}
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              {renderLoader()}

              <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{ width: '100%' }}>
                  <Droppable droppableId="droppable-1">
                    {(provided, _): JSX.Element => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {parts &&
                          parts.map((p: PartItem, i: number) => {
                            return (
                              <Draggable
                                key={p.id}
                                draggableId={p.id}
                                index={i}
                                isDragDisabled={!showEditable || !!edit}
                              >
                                {(provided, snapshot): JSX.Element => (
                                  <div ref={provided.innerRef}>
                                    <Part
                                      isEdit={edit === p}
                                      showEditable={showEditable}
                                      dragHandleProps={provided.dragHandleProps}
                                      data={p}
                                      onDelete={handleDelete}
                                      onEdit={handleEdit}
                                      onView={setImage}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      {...provided.draggableProps}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                      </div>
                    )}
                  </Droppable>
                </Box>
              </DragDropContext>
              {!showForm && showEditable && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    sx={{ mb: 1 }}
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    onClick={() => setShowForm(true)}
                  >
                    {i18n.t('part.add_new_part')}
                  </Button>
                </Box>
              )}
              {id && showEditable && showForm && (
                <Paper elevation={2}>
                  <PartCreate
                    names={testNames}
                    onExitEdit={() => {
                      setEdit(null)
                      setShowForm(false)
                    }}
                    id={id}
                    edit={edit}
                    onCreate={onAdd}
                    onEdit={editPart}
                  />
                </Paper>
              )}
              {!isValid && (
                <Box mt={2}>
                  <Alert severity="warning">{i18n.t('ERROR.E000013')}</Alert>
                </Box>
              )}
              {_.isEmpty(parts) && (
                <Box mt={2}>
                  <Alert severity="warning">{i18n.t('ERROR.E000019')}</Alert>
                </Box>
              )}
            </Box>
          </Stack>

          <Stack
            direction="row"
            justifyContent={'space-between'}
            sx={{ width: '100%', mt: 2 }}
          >
            <Button variant="outlined" onClick={() => handleBack()}>
              {i18n.t('SYSCOMMON.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSaveAll()}
              color="secondary"
              disabled={_.isEmpty(parts)}
            >
              {i18n.t(
                showEditable && isDirty && isValid
                  ? 'part.save_and_next_step2'
                  : 'part.next_step2'
              )}
            </Button>
          </Stack>
        </>
      )}
    </ContentBox>
  )
}

export default StepOne
