import { createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/settings.services'
import { SETTINGS_ACTION_TYPE } from './types'

export const setting = createAsyncThunk<services.SettingsResponse>(
  SETTINGS_ACTION_TYPE.GET,
  async (_, { rejectWithValue }) => {
    try {
      const res = await services.getSettings()
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const settingsSave = createAsyncThunk<
  services.SettingsResponse,
  services.SettingCreateParams
>(SETTINGS_ACTION_TYPE.SAVE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.create(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
