import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material'
import { Link } from 'react-router-dom'
import { alpha } from '@mui/material/styles'

import i18n from '@locales/i18n'
import React, { ReactNode } from 'react'
import { ThemeColors } from '@theme/colors'
import { Logo } from '@layouts/MainLayout/elements/Header'

interface LayoutProps {
  children: ReactNode
}

const LoginLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: (theme) => theme.spacing(4),
      }}
    >
      <Box sx={{ mb: 3, display: 'inline-flex' }}>
        <Link to="#"></Link>
      </Box>
      <Card elevation={3} sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Box sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            component="img"
            src={'/images/02.jpg'}
            alt="abstract"
            height="200"
          />
          <Box
            sx={{
              flex: 1,
              inset: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: alpha(ThemeColors.primary, 0.78),
              p: (theme) => theme.spacing(3),
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant={'h2'}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0,
              }}
            >
              {i18n.t('SYSCOMMON.login_button')}
            </Typography>
            <Logo />
          </Box>
        </Box>
        <CardContent sx={{ pt: 0 }}>{children}</CardContent>
      </Card>
      <Typography variant={'body1'} mb={2}>
        Copyright © Accele. All Rights Reserved
      </Typography>
    </Box>
  )
}

export default LoginLayout
