import React, { useState } from 'react'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Alert,
  IconButton,
  InputAdornment,
  Stack,
  Button,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { AdminCreateParams } from '@services/admins.services'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFormCreate from './useFormCreate'
import { Meta } from '@store/metadata/actions/types'
import _ from 'lodash'
interface FilterFormProps {
  onCreate: (data: AdminCreateParams) => void
  meta: Meta
  close: () => void
}

const CreateForm: React.FC<FilterFormProps> = (props) => {
  const { onCreate, meta, close } = props
  const [showPassword, setShowpassword] = useState<boolean>(false)
  const handleClickShowPassword = () => {
    setShowpassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  const { t } = useTranslation()

  const { Controller, methods } = useFormCreate()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    // reset,
  } = methods

  const onSubmit = (values: any) => {
    onCreate({
      name: values.name,
      email: values.newemail,
      password: values.newpassword,
    })
  }

  return (
    <Box sx={{ width: { xs: '100%', sm: '100%', md: 400 } }}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        {meta.error && (
          <Box>
            <Alert severity="warning">
              {t('ERROR.' + _.get(meta, 'error.error.message'))}
            </Alert>
          </Box>
        )}
        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('admins.name')}
                placeholder={t('admins.name')}
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name.message : ''}
              />
            )}
          />
        </Box>

        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            name="newemail"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                autoComplete={'new-email'}
                label={t('admins.email')}
                placeholder={t('admins.email')}
                error={errors.newemail ? true : false}
                helperText={errors.newemail ? errors.newemail.message : ''}
              />
            )}
          />
        </Box>

        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            name="newpassword"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('admins.password')}
                placeholder={t('admins.password')}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                error={errors.newpassword ? true : false}
                helperText={
                  errors.newpassword ? errors.newpassword.message : ''
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ fontSize: 20, marginRight: 5 }}
                        />
                      ) : (
                        <Visibility style={{ fontSize: 20, marginRight: 5 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Box>

        <Stack justifyContent="space-between" direction={'row'} spacing={2}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              close()
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isValid || meta.pending}
            sx={{ flex: 1 }}
            loading={meta.pending}
            color="secondary"
            type="submit"
          >
            {t('button.create')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  )
}

export default CreateForm
