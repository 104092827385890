import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.items

export const list = createSelector(getRoot, (state) => state.list)

export const detail = createSelector(getRoot, (state) => state.detail)

export const pagemeta = createSelector(getRoot, (state) => state.meta)

export const productImages = createSelector(getRoot, (state) => state.imageList)

export const updateError = createSelector(getRoot, (state) => state.updateError)

export const partListData = createSelector(getRoot, (state) => {
  if (state.partList) {
    return _.orderBy(state.partList, 'part_order', 'desc')
  } else {
    return state.partList
  }
})

export const markingData = createSelector(getRoot, (state) => {
  if (state.markingList) {
    return _.orderBy(state.markingList, 'marking_order', 'desc')
  } else {
    return state.markingList
  }
})

export const tops = createSelector(getRoot, (state) => state.tops)

export const bottoms = createSelector(getRoot, (state) => state.bottoms)

export const publicItem = createSelector(getRoot, (state) => state.publicDetail)

export const deniedIds = createSelector(getRoot, (state) => state.deniedIds)
