import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import { ShopCreateParams } from '@services/shops.services'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFormCreate from './useFormCreate'
import { Meta } from '@store/metadata/actions/types'
interface FilterFormProps {
  onCreate: (data: ShopCreateParams) => void
  meta: Meta
  close: () => void
}

const CreateForm: React.FC<FilterFormProps> = (props) => {
  const { onCreate, meta, close } = props
  const { t } = useTranslation()

  const { Controller, methods } = useFormCreate()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    // reset,
  } = methods

  const onSubmit = (values: any) => {
    onCreate({
      name: values.name,
    })
  }

  return (
    <Box sx={{ width: { xs: '100%', sm: '100%', md: 400 } }}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Box sx={{ width: '100%', height: 80 }}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }: FieldValues) => (
              <ClassicTextField
                {...rest}
                inputRef={ref}
                fullWidth
                label={t('shops.name')}
                placeholder={t('shops.name')}
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name.message : ''}
              />
            )}
          />
        </Box>

        <Stack justifyContent="space-between" direction={'row'} spacing={2}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              close()
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isValid || meta.pending}
            sx={{ flex: 1 }}
            loading={meta.pending}
            color="secondary"
            type="submit"
          >
            {t('button.create')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  )
}

export default CreateForm
