import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions'
import { MetaPage } from '@constants/common.constants'
import { AdminItem } from '@services/admins.services'

type StateType = {
  list?: AdminItem[]
  meta?: MetaPage
  detail?: AdminItem
}

const initialState: StateType = {
  list: undefined,
  meta: undefined,
  detail: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.adminList.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.adminCreate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.adminUpdate.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.detail = action.payload.data
  })
  builder.addCase(actions.adminDelete.fulfilled, (state, action) => {
    // eslint-disable-next-line no-console
    state.list = action.payload.data
    state.meta = action.payload.meta
  })
  builder.addCase(actions.adminDetail.fulfilled, (state, action) => {
    state.detail = action.payload.data
  })
})
