import { UserLoginResponse } from '@services/auth.services'
import { logOut } from '@store/auth/actions'
import { getAuth, getIsAuthenticated } from '@store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'

interface Auth {
  signOut: () => void
  isLoggedIn: boolean
  user: UserLoginResponse
}

const useAuth = (): Auth => {
  const user = useSelector(getAuth)
  const isLoggedIn = useSelector(getIsAuthenticated)
  const dispatch = useDispatch()
  const signOut = () => dispatch(logOut())
  return { signOut, isLoggedIn, user }
}

export default useAuth
