import { Meta } from '@store/metadata/actions/types'
import { detail } from '@store/order/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'

import {
  OrderCreateDetail,
  OrderCreateParams,
  OrderShowParams,
} from '@services/order.services'
import {
  initOrder,
  orderCreate,
  resetDetail,
  showByCode,
} from '@store/order/actions'

interface ReturnType {
  meta: Meta
  create: (params: OrderCreateParams) => void
  order: OrderCreateDetail
  showOrder: (params: OrderShowParams) => void
  metaCreate: Meta
  clear: () => void
}

const useOrder = (): ReturnType => {
  const dispatch = useDispatch()
  const create = (params: OrderCreateParams) => dispatch(orderCreate(params))

  const _meta = createMetaSelector(showByCode)
  const meta = useAppSelector(_meta)
  const _metaCreate = createMetaSelector(orderCreate)
  const metaCreate = useAppSelector(_metaCreate)
  const showOrder = (params: OrderShowParams) => dispatch(initOrder(params))
  const clear = () => dispatch(resetDetail())

  const order = useAppSelector(detail)

  return {
    meta,
    showOrder,
    order,
    clear,
    metaCreate,
    create,
  }
}

export default useOrder
