/* eslint-disable no-console */
import { getPresignedUrlResponse } from '@services/common.service'
import ClassicTextField, {
  Label,
} from '@components/@material-extend/ClassicTextField'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useParams } from 'react-router-dom'
import { FieldValues } from 'react-hook-form'
import useFontEdit from '@containers/Fonts/useFontEdit'
import useFormFontEdit from '@containers/Fonts/useFormFontEdit'
import { useEffect } from 'react'
import {
  Box,
  Paper,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
} from '@mui/material'
import PaperHeader from '@components/PaperHeader'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useConfirm } from '@components/Confirm'
import { useUpload } from '@components/@admin/FileUpload'
import { ThemeColors } from '@theme/colors'
import customHistory from 'customHistory'
import { URI } from '@constants/uri.constants'
import {
  defaultUploadOptions,
  defaultCssUploadOptions,
  defaultWoffUploadOptions,
  FONT_STATUS,
} from '@constants/common.constants'
import { AddAPhoto, Edit } from '@mui/icons-material'
import Attachement from '@components/@admin/Attachement'
import i18n from '@locales/i18n'
const FontEdit = () => {
  const { id } = useParams()
  const { data, fetch, update, metaUpdate, updateFiles } = useFontEdit()
  const { Controller, methods } = useFormFontEdit()
  const { t } = useTranslation()
  const upload = useUpload()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods
  const confirm = useConfirm()
  useEffect(() => {
    if (id) {
      fetch(id)
    }
  }, [id])

  useEffect(() => {
    if (data) {
      const value = data.attributes
      reset({
        name: value.name,
        status: value.status === FONT_STATUS.FONT_STATUS_PUBLISHED,
      })
    }
  }, [data])

  const handleFileUploads = (attrName: any, options: any) => {
    if (id) {
      upload({
        upload_uri: URI.UPLOAD_FONT.replace(/:id/gi, id),
        ...options,
      })
        .then((res: getPresignedUrlResponse) => {
          const param = { id: id }
          param[attrName as keyof typeof param] = res.key
          if (res.fontFamily) {
            param['font_family' as keyof typeof param] = res.fontFamily
          }
          updateFiles(param)
        })
        .catch(() => null)
    }
  }

  const onSubmit = (values: any) => {
    if (data) {
      update({
        id: data.id.toString(),
        name: values.name,
        status:
          values.status === true || values.status === 'true'
            ? FONT_STATUS.FONT_STATUS_PUBLISHED
            : FONT_STATUS.FONT_STATUS_DRAFT,
      })
    }
  }

  return (
    <ContentBox maxWidth={1200}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Paper>
          <PaperHeader title={t('fonts.edit_title')} />
          <Box sx={{ p: 2 }}>
            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <ClassicTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    label={t('fonts.name')}
                    placeholder={t('fonts.name')}
                    error={errors.name ? true : false}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="status"
                control={control}
                render={({ field: { ...rest } }: FieldValues) => (
                  <>
                    <Stack direction="row" spacing={1}>
                      <Box>
                        <Label id="type">{t('fonts.status')}</Label>
                        <RadioGroup
                          row
                          aria-labelledby="type"
                          name="row-radio-buttons-group"
                          {...rest}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('fonts.published')}
                          />
                          <FormControlLabel
                            disabled={false}
                            value={false}
                            control={<Radio />}
                            label={t('fonts.unpublished')}
                          />
                        </RadioGroup>
                      </Box>
                    </Stack>
                  </>
                )}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Box>
                <Label>{t('SYSCOMMON.thumbnail')}</Label>
                <Box
                  onClick={() =>
                    handleFileUploads('thumb', defaultUploadOptions)
                  }
                  sx={{
                    width: 300,
                    paddingTop: '10%',
                    cursor: 'pointer',
                    position: 'relative',
                    mt: 0.5,
                    border: `1px solid ${ThemeColors.primary}`,
                    background: `url('${data?.attributes.thumb}') #eee`,
                    backgroundSize: 'cover',
                  }}
                >
                  {data?.attributes.thumb === null ? (
                    <AddAPhoto
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                      }}
                    />
                  ) : (
                    <Edit
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        fontSize: 14,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '100%', mt: 2 }}>
              <Stack direction="row" spacing={2}>
                <Box width={110}>
                  <Label>{t('fonts.style_file')}</Label>
                  <Attachement
                    onClick={() =>
                      handleFileUploads('style_path', {
                        ...defaultCssUploadOptions,
                        replaceUri: data?.attributes.replace_uri,
                      })
                    }
                    path={data?.attributes.style_path}
                  />
                </Box>

                <Box width={110}>
                  <Label>{t('fonts.woff_file')}</Label>
                  <Attachement
                    onClick={() =>
                      handleFileUploads('woff_path', defaultWoffUploadOptions)
                    }
                    path={data?.attributes.woff_path}
                  />
                </Box>

                <Box width={110}>
                  <Label>{t('fonts.woff2_file')}</Label>
                  <Attachement
                    onClick={() =>
                      handleFileUploads('woff2_path', defaultWoffUploadOptions)
                    }
                    path={data?.attributes.woff2_path}
                  />
                </Box>
              </Stack>
            </Box>
            <Box sx={{ width: '50%', mt: 2 }}>
              <Alert severity="warning">{t('fonts.upload_file_info')}</Alert>
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              if (isDirty) {
                confirm({
                  title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
                  description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
                }).then(() => {
                  customHistory.push('/admin/fonts')
                })
              } else {
                customHistory.push('/admin/fonts')
              }
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              disabled={!isValid || !isDirty || metaUpdate.pending}
              type="submit"
              loading={metaUpdate.pending}
              variant="contained"
              color="secondary"
            >
              {t('SYSCOMMON.save')}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </ContentBox>
  )
}

export default FontEdit
