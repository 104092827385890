import { GENRE_TYPES } from '@constants/common.constants'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import _ from 'lodash'

const getRoot = (state: RootState) => state.design

export const list = createSelector(getRoot, (state) => state.list)
export const pagemeta = createSelector(getRoot, (state) => state.meta)
export const top = createSelector(getRoot, (state) => state.top)
export const bottom = createSelector(getRoot, (state) => state.bottom)
export const sex = createSelector(getRoot, (state) => state.sex)
export const fonts = createSelector(getRoot, (state) => state.fonts)
export const detail = createSelector(getRoot, (state) => state.detail)
export const compareItems = createSelector(
  getRoot,
  (state) => state.compareItems
)

export const copyDetail = createSelector(getRoot, (state) => state.copy)

export const copyTop = createSelector(getRoot, (state) => {
  // eslint-disable-next-line no-console
  return _.find(
    state.copy?.attributes.design_items,
    (item) => item.attributes.genre_type === GENRE_TYPES.TOPS
  )
})

export const copyBottom = createSelector(getRoot, (state) => {
  return _.find(
    state.copy?.attributes.design_items,
    (item) => item.attributes.genre_type === GENRE_TYPES.BOTTOMS
  )
})
