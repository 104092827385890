import { Item, TopListParams } from '@services/item.services'
import { useAppSelector } from '@store/hooks'
import {
  fetchPublicDetail,
  fetchTopList,
  publicItem,
} from '@store/items/actions'
import {
  bottoms as BottomData,
  tops as TopData,
  publicItem as DetailData,
} from '@store/items/selectors'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { useDispatch } from 'react-redux'

interface ReturnType {
  tops?: Item[]
  bottoms?: Item[]
  meta: Meta
  fetch: (params: TopListParams) => void
  detail: (id: string) => void
  detailMeta: Meta
  data: Item
}

const usePublicItem = (): ReturnType => {
  const tops = useAppSelector(TopData)
  const bottoms = useAppSelector(BottomData)
  const data = useAppSelector(DetailData)
  const dispatch = useDispatch()
  const fetch = (params: TopListParams) => {
    dispatch(fetchTopList(params))
  }
  const detail = (id: string) => {
    dispatch(fetchPublicDetail(id))
  }

  const _meta = createMetaSelector(fetchTopList)
  const meta = useAppSelector(_meta)

  const _detailMeta = createMetaSelector(publicItem)
  const detailMeta = useAppSelector(_detailMeta)

  return {
    meta,
    fetch,
    detail,
    data,
    detailMeta,
    tops,
    bottoms,
  }
}

export default usePublicItem
