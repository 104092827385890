import DialogBase from '@components/@material-extend/DialogBase'
import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import { useConfirm } from '@components/Confirm'
import {
  PROCESS_STATUS,
  SexOptions,
  StatusOptions,
} from '@constants/common.constants'
import CreateForm from '@containers/Items/CreateForm'
import FilterForm, { FilterParams } from '@containers/Items/ItemsFilter'
import useItemList from '@containers/Items/useItemList'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import i18n from '@locales/i18n'
import { Create, Delete, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Checkbox,
  Pagination,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material'
import { Item, ItemCreateParams } from '@services/item.services'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ItemList = () => {
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const {
    data,
    meta,
    paging,
    fetch,
    changeStatus,
    genre,
    fetchGenre,
    create,
    clearCreateMeta,
    metaCreate,
    denyIds,
    clear,
  } = useItemList()
  const [filter, setFilter] = useState<FilterParams>({
    sex: undefined,
    genre_id: undefined,
    status: undefined,
    name: '',
  })
  const confirm = useConfirm()

  useEffect(() => {
    clear()
    fetch({ page: 1, per_page: 20, ...filter })
    fetchGenre()
    return () => {
      clear()
    }
  }, [])

  useEffect(() => {
    if (paging) {
      fetch({
        page: paging.current_page,
        per_page: paging.per_page,
        ...filter,
      })
    } else {
      fetch({
        page: 1,
        per_page: 20,
        ...filter,
      })
    }
  }, [filter])

  useEffect(() => {
    if (denyIds && !_.isEmpty(denyIds)) {
      setSelected(denyIds)
    } else {
      setSelected([])
    }
  }, [denyIds])

  const handleSelectAll = () => {
    const allSelected = selected.length === data?.length
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(_.map(data, (d) => Number(d.id)))
    }
  }

  const handleChecked = (id: number) => {
    const exist = !!_.find(selected, (i) => i === id)
    if (exist) {
      setSelected(_.filter(selected, (s) => s !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const navigate = useNavigate()
  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
      ...filter,
    })
  }

  useEffect(() => {
    if (metaCreate.loaded && !metaCreate.pending && !metaCreate.error) {
      setOpen(false)
    }
  }, [metaCreate])

  // const handlePerPage = (data: SelectChangeEvent<string | number>) => {
  //   setSearchParams({
  //     per_page: data.target.value.toString(),
  //     page: '1',
  //   })
  // }

  const handleFilter = (data: FilterParams) => {
    setFilter(data)
  }

  const onChangeStatus = (status: PROCESS_STATUS) => {
    confirm({
      title: i18n.t('SYSCOMMON.update_confirm_title'),
      description: i18n.t('SYSCOMMON.update_confirm_desc', {
        target: _.find(StatusOptions, (s) => s.value === status)?.name,
      }),
    })
      .then(() => {
        changeStatus({
          ids: selected,
          status: status,
        })
      })
      .catch(() => null)
  }

  const handleCreate = (params: ItemCreateParams) => {
    create(params)
  }

  const renderRow = (item: Item, index: number) => {
    const data = item.attributes
    return (
      <TableRow
        key={index}
        sx={{
          background:
            item.attributes.status === PROCESS_STATUS.ITEM_STATUS_IN_PROGRESS ||
            item.attributes.status === PROCESS_STATUS.ITEM_STATUS_DRAFT
              ? '#efefef'
              : 'transparent',
        }}
      >
        <TableCell>
          <Checkbox
            onClick={() => handleChecked(Number(item.id))}
            checked={!!_.find(selected, (id) => id === Number(item.id))}
          />
        </TableCell>
        <TableCell scope="row" sx={{ position: 'relative' }}>
          <Avatar
            alt={data.name}
            sx={{ borderRadius: '0' }}
            src={data.thumb_url}
          />
        </TableCell>
        <TableCell scope="row" sx={{ position: 'relative' }}>
          {data.name}
        </TableCell>
        <TableCell align="center">
          {_.find(SexOptions, (s) => s.value === data.sex)?.name}
        </TableCell>
        <TableCell align="center" sx={{ maxWidth: 100 }}>
          {data.genre_name ? data.genre_name.substring(0, 10) : data.genre_name}
        </TableCell>
        <TableCell align="center">
          {_.find(StatusOptions, (s) => s.value === data.status)?.name}
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate(`/admin/items/${item.id}/step/1`)}
            >
              {data.has_design
                ? i18n.t('SYSCOMMON.see_simulation')
                : i18n.t('SYSCOMMON.edit_simulation')}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate(`/admin/items/${item.id}/edit`)}
            >
              {i18n.t('SYSCOMMON.edit_detail')}
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <DialogBase
        onClose={() => setOpen(false)}
        open={open}
        title={i18n.t('SYSCOMMON.register')}
        content={
          <CreateForm
            meta={metaCreate}
            genre={genre}
            close={() => setOpen(false)}
            items={data ? data : []}
            onCreate={handleCreate}
          />
        }
      />
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={meta.pending}
        pagintionBox={{ sx: { pt: 2 } }}
        pagination={
          paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )
        }
        headSection={
          <TableHeadSection
            title={i18n.t('SYSCOMMON.merchandise')}
            total={paging?.total_count}
            actions={<FilterForm genre={genre} onFilter={handleFilter} />}
          />
        }
        filter={
          <Stack justifyContent="flex-start" direction={'row'} spacing={1}>
            <Button
              startIcon={<VisibilityOff />}
              color="primary"
              variant="outlined"
              disabled={_.isEmpty(selected) ? true : false}
              onClick={() => onChangeStatus(PROCESS_STATUS.ITEM_STATUS_DRAFT)}
            >
              {i18n.t('SYSCOMMON.private')}
            </Button>
            <Button
              startIcon={<Visibility />}
              color="primary"
              variant="outlined"
              disabled={_.isEmpty(selected) ? true : false}
              onClick={() =>
                onChangeStatus(PROCESS_STATUS.ITEM_STATUS_PUBLISHED)
              }
            >
              {i18n.t('SYSCOMMON.publish')}
            </Button>
            <Button
              startIcon={<Delete />}
              disabled={_.isEmpty(selected) ? true : false}
              color="primary"
              variant="outlined"
              onClick={() => onChangeStatus(PROCESS_STATUS.ITEM_STATUS_DELETED)}
            >
              {i18n.t('SYSCOMMON.delete')}
            </Button>
            <Button
              startIcon={<Create />}
              color="secondary"
              variant="contained"
              onClick={() => {
                setOpen(true)
                clearCreateMeta()
              }}
            >
              {i18n.t('SYSCOMMON.register')}
            </Button>
          </Stack>
        }
        head={
          <TableRow>
            <TableCell width={40} align="center">
              <Checkbox
                checked={
                  selected.length > 0 && selected.length === data?.length
                }
                onClick={handleSelectAll}
              />
            </TableCell>
            <TableCell sx={{ minWidth: 70, width: 60 }}></TableCell>
            <TableCell sx={{ minWidth: 70 }}>
              {i18n.t('SYSCOMMON.item_name')}
            </TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {i18n.t('SYSCOMMON.sex')}
            </TableCell>

            <TableCell sx={{ minWidth: 70 }} align="center">
              {i18n.t('SYSCOMMON.genre')}
            </TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {i18n.t('SYSCOMMON.status')}
            </TableCell>
            <TableCell
              sx={{ minWidth: 70, width: '300px' }}
              align="center"
            ></TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default ItemList
