import { useUpload } from '@components/@admin/FileUpload'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { BOOLEAN, defaultPartsUploadOptions } from '@constants/common.constants'
import { Side } from '@constants/editor.constants'
import { URI } from '@constants/uri.constants'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
} from '@mui/material'
import { getPresignedUrlResponse } from '@services/common.service'
import { PartCreateItem, PartItem } from '@services/item.services'
import { Meta } from '@store/metadata/actions/types'
import React, { useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import usePartCreateForm from './usePartCreateForm'
import _ from 'lodash'
import { AttachFile, Close } from '@mui/icons-material'
import { ThemeColors } from '@theme/colors'
import { getFileName } from '@utils/helper/fileHelper'
import { useConfirm } from '@components/Confirm'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
import i18n from '@locales/i18n'

export interface PartItemAdd extends PartCreateItem {
  is_editable: boolean
  back_svg: string
  front_svg: string
  front_url: string
  back_url: string
}

interface FilterFormProps {
  names: string[]
  id: string
  onCreate?: (data: PartItemAdd) => void
  meta?: Meta
  edit: PartItem | null
  onExitEdit: () => void
  onEdit?: (data: PartItem) => void
}

const CreateForm: React.FC<FilterFormProps> = (props) => {
  const { onCreate, meta, id, edit, onExitEdit, onEdit, names } = props
  const { t } = useTranslation()

  const { Controller, methods } = usePartCreateForm(names)
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    trigger,
    reset,
  } = methods
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)

  useEffect(() => {
    if (edit) {
      reset({
        front_path: edit.attributes.front_path
          ? edit.attributes.front_path
          : '',
        back_path: edit.attributes.back_path ? edit.attributes.back_path : '',
        front_svg: edit.attributes.front_svg ? edit.attributes.front_svg : '',
        back_svg: edit.attributes.back_svg ? edit.attributes.back_svg : '',
        name: edit.attributes.name,
        is_editable: Number(edit.attributes.is_editable),
        front_url: edit.attributes.front_url ? edit.attributes.front_url : '',
        back_url: edit.attributes.back_url ? edit.attributes.back_url : '',
      })
    } else {
      reset({
        name: '',
        front_path: '',
        back_path: '',
        is_editable: 1,
        front_svg: '',
        back_svg: '',
        front_url: '',
        back_url: '',
      })
    }
  }, [edit])

  const onSubmit = (values: any) => {
    const editMode = edit ? true : false

    if (editMode && edit) {
      const params: PartItem = {
        ...edit,
        attributes: {
          ...edit.attributes,
          name: values.name,
          is_editable: Boolean(Number(values.is_editable)),
          front_path: values.front_path,
          back_path: values.back_path,
          front_svg: values.front_svg,
          back_svg: values.back_svg,
          front_url: values.front_url,
          back_url: values.back_url,
        },
      }

      onEdit && onEdit(params)
      onExitEdit()
    } else {
      onCreate &&
        onCreate({
          name: values.name,
          front_path: values.front_path,
          back_path: values.back_path,
          is_editable: Boolean(Number(values.is_editable)),
          front_svg: values.front_svg,
          back_svg: values.back_svg,
          front_url: values.front_url,
          back_url: values.back_url,
        })
    }
    reset({
      name: '',
      front_path: '',
      back_path: '',
      front_svg: '',
      back_svg: '',
      is_editable: 1,
    })
  }

  const upload = useUpload()

  const handleUpload = (type: Side) => {
    upload({
      upload_uri: URI.PARTS.replace(/:id/gi, id),
      ...defaultPartsUploadOptions,
    })
      .then((res: getPresignedUrlResponse) => {
        const svgContent = res.svgContent ? res.svgContent : ''
        if (type === Side.Front) {
          setValue('front_path', res.key, { shouldDirty: true })
          setValue('front_svg', svgContent, { shouldDirty: true })
          setValue('front_url', res.file_url, { shouldDirty: true })
          trigger(['front_path', 'front_svg', 'front_url'])
        } else {
          setValue('back_path', res.key, { shouldDirty: true })
          setValue('back_svg', svgContent, { shouldDirty: true })
          setValue('back_url', res.file_url, { shouldDirty: true })
          trigger(['back_path', 'back_svg', 'back_url'])
        }
      })
      .catch(() => null)
  }

  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])
  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Box
        sx={{
          position: 'relative',
          width: {
            xs: '100%',
            sm: '100%',
          },
          p: 2,
          pt: 1,
          pb: 1,
          mb: 1,
          mt: 4,
        }}
      >
        <Box
          sx={{
            background: ThemeColors.primary,
            position: 'absolute',
            top: -20,
            left: 1,
            height: 20,
            color: '#fff',
            pl: 3,
            pr: 3,
          }}
        >
          {edit ? t('part.edit_part') : t('part.new_part')}
        </Box>

        <IconButton
          onClick={() => onExitEdit()}
          sx={{
            position: 'absolute',
            zIndex: 10,
            top: 5,
            right: 5,
            border: '0 none',
            cursor: 'pointer',
          }}
        >
          <Close />
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ height: 80 }}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <ClassicTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    label={t('part.part_name')}
                    placeholder={t('part.part_name')}
                    error={errors.name ? true : false}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="is_editable"
                control={control}
                render={({ field: { value, onChange } }: FieldValues) => {
                  return (
                    <FormControlLabel
                      value={value}
                      control={<Checkbox checked={value === BOOLEAN.TRUE} />}
                      onClick={() =>
                        value === BOOLEAN.TRUE
                          ? onChange(BOOLEAN.FALSE)
                          : onChange(BOOLEAN.TRUE)
                      }
                      label={t('part.is_editable') as string}
                    />
                  )
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                mt: { sm: 0, md: 3 },
                marginRight: { xs: '80px', sm: '100px', md: 0 },
              }}
            >
              <Box sx={{ minWidth: '50%' }}>
                <Box>
                  <Controller
                    control={control}
                    name="front_path"
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                      <>
                        <Button
                          variant="contained"
                          disabled={!_.isEmpty(value)}
                          color="secondary"
                          startIcon={<AttachFile />}
                          onClick={() => handleUpload(Side.Front)}
                        >
                          {t('SYSCOMMON.front_path')}
                        </Button>
                        {value ? (
                          <Chip
                            label={
                              <>
                                <AttachFile sx={{ fontSize: 13 }} />
                                {getFileName(value)}
                              </>
                            }
                            variant="outlined"
                            sx={{
                              fontSize: 10,
                              ml: 1,
                              maxWidth: { xs: 70, md: 150 },
                            }}
                            onDelete={() => {
                              onChange('')
                              setValue('front_svg', '')
                              setValue('front_url', '')
                            }}
                          />
                        ) : null}
                        <Box sx={{ display: 'none' }}>
                          <ClassicTextField
                            {...rest}
                            inputRef={ref}
                            fullWidth
                            sx={{ width: 120 }}
                            hidden={true}
                            value={value}
                            readOnly
                          />
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ minWidth: '50%' }}>
                <Box>
                  <Controller
                    name="back_path"
                    control={control}
                    render={({
                      field: { ref, value, onChange, ...rest },
                    }: FieldValues) => (
                      <>
                        <Button
                          variant="contained"
                          disabled={!_.isEmpty(value)}
                          startIcon={<AttachFile />}
                          color="secondary"
                          onClick={() => handleUpload(Side.Back)}
                        >
                          {t('SYSCOMMON.back_path')}
                        </Button>
                        {value ? (
                          <Chip
                            sx={{
                              fontSize: 10,
                              ml: 1,
                              maxWidth: { xs: 70, md: 150 },
                            }}
                            variant="outlined"
                            label={
                              <>
                                <AttachFile sx={{ fontSize: 13 }} />
                                {getFileName(value)}
                              </>
                            }
                            onDelete={() => {
                              onChange('')
                              setValue('back_svg', '')
                              setValue('back_url', '')
                            }}
                          />
                        ) : null}
                        <Box sx={{ display: 'none' }}>
                          <ClassicTextField
                            {...rest}
                            sx={{ width: 120 }}
                            inputRef={ref}
                            value={value}
                            fullWidth
                            hidden={true}
                            readOnly
                          />
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Stack>
          </Grid>

          <Box
            sx={{
              position: 'absolute',
              right: 16,
              bottom: 16,
            }}
          >
            <LoadingButton
              variant="contained"
              disabled={!isValid || meta?.pending || !isDirty}
              loading={meta?.pending}
              sx={{ minWidth: 120 }}
              color="secondary"
              type="submit"
            >
              {edit ? t('button.save') : t('part.add_new_part')}
            </LoadingButton>
          </Box>
        </Grid>
      </Box>
    </form>
  )
}

export default CreateForm
