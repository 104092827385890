import { SHOP_STATUS, MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'

export type ShopItem = {
  id: string
  type: 'shop'
  attributes: {
    name?: string
    image?: string
    descriptions?: string
    address?: string
    map_url?: string
    access_info?: string
    phone?: string
    fax?: string
    manager?: string
    time_table?: string
    status?: number
  }
}
export type ShopDetail = {
  data: ShopItem
}

export type ShopsParams = {
  page?: number
  per_page?: number
  name?: string | null
  phone?: string | null
  fax?: string | null
  manager?: string | null
  status?: number | null
}

export interface ShopCreateParams {
  name: string
}

export interface ShopUpdateParams {
  id: string
  name?: string
  image?: string
  descriptions?: string
  address?: string
  map_url?: string
  access_info?: string
  phone?: string
  fax?: string
  manager?: string
  time_table?: string
  status?: number
}

export type ShopsResponse = {
  data: ShopItem[]
  meta: MetaPage
}

export type ShopStatusParams = {
  ids: number[]
  status: SHOP_STATUS
}

export type ShopDeleteParams = {
  ids: number[]
}

export type ShopStatusResponse = {
  data: string
}

export const listPublic = async (
  params: ShopsParams
): Promise<ShopsResponse> => {
  const { data } = await api.get<ShopsResponse>(URI.SHOPS_PUBLIC, { params })
  return data
}

export const list = async (params: ShopsParams): Promise<ShopsResponse> => {
  const { data } = await api.get<ShopsResponse>(URI.SHOPS, { params })
  return data
}

export const create = async (params: ShopCreateParams): Promise<ShopDetail> => {
  const { data } = await api.post<ShopDetail>(URI.SHOPS, params)
  return data
}

export const update = async (params: ShopUpdateParams): Promise<ShopDetail> => {
  const { data } = await api.put<ShopDetail>(
    URI.SHOPS + '/' + params.id,
    params
  )
  return data
}

export const deleteShop = async (
  params: ShopDeleteParams
): Promise<ShopsResponse> => {
  const { data } = await api.delete<ShopsResponse>(URI.SHOPS + '/delete', {
    params,
  })
  return data
}

export const status = async (
  params: ShopStatusParams
): Promise<ShopStatusResponse> => {
  const { data } = await api.post<ShopStatusResponse>(
    `${URI.SHOPS}/update/status/`,
    params
  )
  return data
}

export const detail = async (id: string): Promise<ShopDetail> => {
  const { data } = await api.get<ShopDetail>(`${URI.SHOPS}/${id}`)
  return data
}
