import { FieldValues } from 'react-hook-form'
import ClassicTextField from '@components/@material-extend/ClassicTextField'
import { SexOptions, GenreTypeOptions } from '@constants/common.constants'
import { Button, TableCell, TableRow, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import i18n from '@locales/i18n'
import useUpdateFormGenre from './useUpdateFormGenre'
import { useTranslation } from 'react-i18next'
import { GenreUpdateParams, GenreItem } from '@services/genre.services'
import { useDispatch } from 'react-redux'
import { addToast } from '@store/common/actions'
import { AlertColor } from '@mui/material'
import _ from 'lodash'
interface UpdateFormProps {
  item: GenreItem
  sex: any
  list: GenreItem[] | undefined
  submit: (params: GenreUpdateParams) => void
  onCancel: () => void
}

const UpdateGenre: React.FC<UpdateFormProps> = (props) => {
  const dispatch = useDispatch()
  const { item, sex, list, submit, onCancel } = props
  const data = item.attributes
  const { t } = useTranslation()
  const { Controller, methods } = useUpdateFormGenre()
  const { control, handleSubmit, reset } = methods
  const {
    setError,
    formState: { errors },
  } = methods
  const isExist = (name: string) => {
    if (list) {
      const filtered = _.filter(
        list,
        (o) => o.id != item.id && o.attributes.name === name
      )
      return filtered.length > 0
    }
    return false
  }
  useEffect(() => {
    if (data) {
      const defaultValues: any = {}
      defaultValues.name = data.name
      reset({ ...defaultValues })
    }
  }, [data])

  const getItemCount = () => {
    if (data && data.item_count && data.item_count > 0) {
      return data.item_count
    }
    return 0
  }

  const onSubmit = (values: any) => {
    const formData = { ...values }
    if (isExist(formData.name)) {
      setError('name', { type: 'custom' })
      dispatch(
        addToast({
          message: i18n.t('ERROR.BE000011'),
          severity: 'warning' as AlertColor,
        })
      )
      return
    }
    formData.id = item.id
    formData.filter = sex
    submit(formData)
  }

  return (
    <TableRow>
      <TableCell>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...rest } }: FieldValues) => (
            <ClassicTextField
              {...rest}
              fullWidth
              inputRef={ref}
              placeholder={t('genre.genre_name')}
              error={errors.name ? true : false}
            />
          )}
        />
      </TableCell>
      <TableCell align="center">
        {_.find(SexOptions, (s) => s.value === data.sex)?.name}
      </TableCell>
      <TableCell align="center">
        {_.find(GenreTypeOptions, (s) => s.value === data.genre_type)?.name}
      </TableCell>
      <TableCell align="center">{getItemCount()}</TableCell>
      <TableCell>
        <Stack direction={'row'} spacing={1}>
          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {i18n.t('button.save')}
          </Button>
          <Button variant="outlined" onClick={() => onCancel()}>
            {i18n.t('button.cancel')}
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default UpdateGenre
