import React, { ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  Zoom,
} from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
export type DialogBaseProps = {
  open: boolean
  content: ReactNode
  actions?: ReactNode
  title?: string
  subheader?: ReactNode
  contentProps?: DialogContentProps
  onClose: () => void
  TransitionComponent?: any
}

const DialogBase: React.FC<DialogBaseProps & DialogProps> = ({
  open,
  content,
  actions,
  title,
  subheader,
  contentProps,
  onClose,
  TransitionComponent = Zoom,
  ...restProps
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      {...restProps}
      onClose={handleClose}
      TransitionComponent={TransitionComponent}
    >
      {title && (
        <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} />
      )}
      <DialogContent sx={{ p: 2, ...contentProps?.sx }} {...contentProps}>
        {content}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default DialogBase
