import {
  InputBase,
  styled,
  InputProps,
  FormHelperText,
  FormControl,
  alpha,
  SxProps,
  colors,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import theme from '@theme/index'
import { ReactElement } from 'react'
import ClassicInputLabel from '../ClassInputLabel'

export interface ClassicTextFieldProps extends InputProps {
  helperText?: string
  label?: string
  error?: boolean
  labelSecondary?: ReactElement
  required?: boolean
  labelStyle?: SxProps
  sx?: any
}

export const Label = styled('label')({
  padding: '2px 1px',
  fontSize: 12,
  marginRight: '3px',
})

const ClassicTextField: React.FC<ClassicTextFieldProps> = ({
  helperText,
  label,
  labelSecondary,
  error,
  required = false,
  labelStyle,
  sx,
  ...rest
}) => {
  const { readOnly } = rest
  return (
    <FormControl fullWidth={rest.fullWidth ? rest.fullWidth : false}>
      {(label || labelSecondary) && (
        <ClassicInputLabel
          required={required}
          label={label}
          id={rest.id ? rest.id : ''}
          labelStyle={labelStyle}
        />
      )}
      <InputBase
        sx={{
          '&.MuiInputBase-root': {
            background: ThemeColors.white,
            color: ThemeColors.text[900],
            border: `1px solid ${ThemeColors.text[600]}`,
            fontSize: 12,
            padding: '4px 8px',
            transition: theme.transitions.create([
              'border-color',
              'background-color',
              'box-shadow',
            ]),
            '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
              '& .MuiIconButton-root': {
                border: '0 none',
                marginRight: 0,
              },
            },
            '&.MuiInputBase-adornedStart ': {
              '& .MuiIconButton-root': {
                border: '0 none',
                marginRight: 0.5,
              },
            },
            '&.r-only': {
              background: '#f9f9f9',
              border: '1px solid #ddd',
            },
            '&.Mui-focused': {
              boxShadow: `${alpha(
                theme.palette.secondary.main,
                0.15
              )} 0 0 0 0.2rem`,
              borderColor: theme.palette.secondary.main,
            },

            '&.Mui-error': {
              borderColor: colors.red[400],
            },
            ...sx,
          },
        }}
        error={error}
        className={`${readOnly ? 'r-only' : ''}`}
        margin="dense"
        {...rest}
      />
      {helperText && (
        <FormHelperText
          sx={{ marginLeft: 0, fontSize: 11, m: 0 }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ClassicTextField
