import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/store'

const getRoot = (state: RootState) => state.admins

export const list = createSelector(getRoot, (state) => state.list)

export const detail = createSelector(getRoot, (state) => state.detail)

export const pagemeta = createSelector(getRoot, (state) => state.meta)
