import { ContentImageListResponse } from '@services/common.service'
import { GenreItem } from '@services/genre.services'
import {
  ImageCreateParams,
  ImageDeleteParams,
  ImageItem,
  Item,
  ItemUpdateParams,
} from '@services/item.services'
import { contentImageList } from '@store/common/actions'
import { getContentImgs } from '@store/common/selectors'
import { genreList } from '@store/genre/actions'
import { genreListData } from '@store/genre/selectors'
import { useAppSelector } from '@store/hooks'
import {
  fetchDetail,
  imageCreate,
  imageDelete,
  imageList,
  itemDetail,
  itemUpdate,
} from '@store/items/actions'
import { detail, productImages, updateError } from '@store/items/selectors'
import { Meta } from '@store/metadata/actions/types'
import { createMetaSelector } from '@store/metadata/selectors'
import { useDispatch } from 'react-redux'

interface ReturnType {
  data?: Item
  meta: Meta
  fetch: (id: string) => void
  fetchGenre: () => void
  genre: GenreItem[]
  update: (params: ItemUpdateParams) => void
  updateMeta: Meta
  fetchImages: () => void
  imgs: ContentImageListResponse
  imgMeta: Meta
  createImage: (params: ImageCreateParams) => void
  getImages: (params: string) => void
  deleteImage: (params: ImageDeleteParams) => void
  listImageMeta: Meta
  productImage?: ImageItem[]
  updateErrors?: any
}

const useItemEdit = (): ReturnType => {
  const data = useAppSelector(detail)
  const genre = useAppSelector(genreListData)

  const dispatch = useDispatch()
  const fetch = (id: string) => {
    dispatch(fetchDetail(id))
  }
  const fetchGenre = () => {
    dispatch(genreList())
  }
  const _meta = createMetaSelector(itemDetail)
  const meta = useAppSelector(_meta)
  const _updateMeta = createMetaSelector(itemUpdate)
  const updateMeta = useAppSelector(_updateMeta)
  const update = (params: ItemUpdateParams) => {
    dispatch(itemUpdate(params))
  }
  const _imgMeta = createMetaSelector(contentImageList)
  const imgMeta = useAppSelector(_imgMeta)
  const imgs = useAppSelector(getContentImgs)
  const _listImageMeta = createMetaSelector(imageList)
  const listImageMeta = useAppSelector(_listImageMeta)
  const productImage = useAppSelector(productImages)
  const updateErrors = useAppSelector(updateError)

  const fetchImages = () => {
    dispatch(contentImageList())
  }

  const createImage = (params: ImageCreateParams) => {
    dispatch(imageCreate(params))
  }

  const deleteImage = (params: ImageDeleteParams) => {
    dispatch(imageDelete(params))
  }

  const getImages = (params: string) => {
    dispatch(imageList(params))
  }

  return {
    createImage,
    meta,
    imgs,
    imgMeta,
    update,
    fetch,
    fetchGenre,
    updateMeta,
    data,
    getImages,
    deleteImage,
    productImage,
    listImageMeta,
    fetchImages,
    genre,
    updateErrors,
  }
}

export default useItemEdit
