import { MetaPage } from '@constants/common.constants'
import { URI } from '@constants/uri.constants'
import api from './api'
import _ from 'lodash'

export type Order = {
  id: string
  type: 'order_list'
  attributes: {
    created_ip: string
    code: string
    design_code: string
    set_number: number
    price: number
    last_access: string
    expire_at: string
    created_at: string
    is_expired: boolean
  }
}

export type OrderFilterParams = {
  page?: number
  per_page?: number
  start_at?: string
  end_at?: string
}

export type OrderListResponse = {
  data: Order[]
  meta: MetaPage
}

export interface MarkingValue {
  marking_id: string
  value: string
}

export interface OrderRowItem {
  set_order: number
  top_size: string
  bottom_size: string
  marking_values?: MarkingValue[] | null
}

export type OrderCreateParams = {
  design_code: string
  details: OrderRowItem[]
  type?: string
}

export type OrderShowParams = {
  code: string
}

export interface OrderCreateDetail {
  id: string
  type: 'order'
  attributes: {
    created_ip: string
    code: string
    design_id: number
    set_number: number
    price: number
    last_access: string
    expire_at: string
    created_at: string
    is_expired: boolean
    details: [
      {
        id: string
        type: 'order_detail'
        attributes: {
          tops_size: string
          bottoms_size: string
          set_order: number
          marking_values: null | MarkingValue[]
        }
      }
    ]
  }
}

export type OrderCreateResponse = {
  data: OrderCreateDetail
}

export const list = async (
  params: OrderFilterParams
): Promise<OrderListResponse> => {
  const { data } = await api.get<OrderListResponse>(URI.ORDERS, {
    params: params,
  })
  return data
}

export const create = async (
  params: OrderCreateParams
): Promise<OrderCreateResponse> => {
  const { data } = await api.post<OrderCreateResponse>(
    URI.CLIENT_ORDERS,
    _.omit(params, 'type')
  )
  return data
}

export const show = async (
  params: OrderShowParams
): Promise<OrderCreateResponse> => {
  const { data } = await api.post<OrderCreateResponse>(
    URI.CLIENT_SHOW_ORDER,
    params
  )
  return data
}
