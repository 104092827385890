import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { SIMPLE_DATE_FORMAT } from '@constants/common.constants'
export const initialValues = {
  start_at: moment().subtract(1, 'months').format(SIMPLE_DATE_FORMAT),
  end_at: moment().format(SIMPLE_DATE_FORMAT),
}

const useFormFont = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        start_at: Yup.string().required(),
        end_at: Yup.string().required(),
      }),
    []
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })

  return { Controller, methods, initialValues }
}

export default useFormFont
