import { Box, Button, IconButton, Typography } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import SlideInPanel from '../SlideInPanel'
import { Side, Variant } from '@constants/editor.constants'
import i18n from '@locales/i18n'
import { Item, MarkingItem, PartItem } from '@services/item.services'
import {
  DesignConfigItem,
  DesignItem,
  MarkConfig,
} from '@services/design.services'
import PanelList from '../PanelList'
import ProductListItem from '@components/ProductItem/ProductListItem'
import { Catalogy } from '@containers/EditorContainer'
import MarkingEditor from '../MarkingEditor'
import { FontItem } from '@services/fonts.services'
import useMarkingEditor from '../MarkingEditor/useMarkingEditor'
import { useWatch } from 'react-hook-form'
import _ from 'lodash'
import { Close } from '@mui/icons-material'
import BoxedPicker from '../ColorPicker/BoxedPicker'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: Variant
  value: Variant
}

export interface ClearForm {
  resetForm(): void
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export type ControllerProps = {
  top: DesignItem | undefined
  bottom: DesignItem | undefined
  value: Variant
  onColorChange: (color: string, part: PartItem) => void
  onMarkingChange?: (data: MarkConfig) => void
  onChangeSide: (side: Side) => void
  side: Side
  products: Item[] // change real data
  onCloseCatalogy: () => void
  onClose: () => void
  catalogy: Catalogy | null
  data: PartItem | null
  color: string
  setActiveItem: (value: PartItem) => void
  setMarkingItem: (value: MarkingItem) => void
  onChoose?: (id: string) => void
  onCloseMarking: () => void
  activeMarking: MarkingItem | null
  setPreview: (value: boolean) => void
  fonts?: FontItem[]
  settings: DesignConfigItem | null
  setErrors?: (errors: any) => void
}

export interface ChildComponentRef {
  setPositionValue(id: number): void
  resetScroll(): void
}

const Controller = forwardRef<ChildComponentRef, ControllerProps>(
  (props, ref) => {
    const {
      top,
      bottom,
      onColorChange,
      products,
      setMarkingItem,
      catalogy,
      value,
      data,
      onClose,
      onChoose,
      activeMarking,
      color,
      onCloseCatalogy,
      fonts,
      setActiveItem,
      onCloseMarking,
      onMarkingChange,
      setPreview,
      settings,
    } = props

    const handleEdit = (item: MarkingItem) => {
      setMarkingItem(item)
    }

    const anyRef: any = ref

    const parentRef = useRef<any>(null)

    const { Controller, methods } = useMarkingEditor()

    const {
      control,
      setValue,
      reset,
      getValues,
      formState: { errors },
    } = methods

    // useEffect(() => {
    //   if (errors) {
    //     // eslint-disable-next-line no-console
    //     console.log(errors)
    //     setErrors(errors)
    //   }
    // }, [errors])

    const form = useWatch({ control })

    useImperativeHandle(anyRef?.position, () => ({
      setPositionValue(id: any) {
        setValue('positionId', id as any)
      },
    }))

    useImperativeHandle(anyRef?.scroll, () => ({
      resetScroll() {
        executeScroll()
      },
    }))

    const executeScroll = () => {
      if (parentRef && parentRef.current) {
        parentRef.current.scrollTop = 0
      }
    }

    useEffect(() => {
      if (activeMarking) {
        const settingsData = _.find(settings?.marks, { id: activeMarking.id })
        if (settingsData) {
          reset({
            input_data: _.get(settingsData, 'input_data', '').toString(),
            family: _.get(settingsData, 'settings.family', ''),
            fill: _.get(settingsData, 'settings.fill', ''),
            font_size: _.get(settingsData, 'settings.font_size', ''),
            positionId: _.get(settingsData, 'settings.position_id', ''),
            is_common: Number(_.get(settingsData, 'is_common', 1)),
            image: _.get(settingsData, 'settings.image', ''),
          })
        } else {
          reset({
            input_data: _.get(
              activeMarking,
              'attributes.settings.sample_text',
              ''
            ).toString(),
            family: _.get(activeMarking, 'attributes.settings.family', ''),
            fill: _.get(activeMarking, 'attributes.settings.color.fill', ''),
            positionId: '', //need to fill blank position because hook form state is saved previous mount
            font_size: _.get(
              activeMarking,
              'attributes.settings.font_size',
              ''
            ),
            image: '',
            is_common: Number(_.get(activeMarking, 'attributes.is_common', 0)),
          })
        }
      }
    }, [activeMarking])

    useEffect(() => {
      if (activeMarking && form.positionId) {
        // eslint-disable-next-line no-console
        onMarkingChange &&
          onMarkingChange({
            id: activeMarking.id,
            input_data: _.get(form, 'input_data', '').toString(),
            is_common: Number(form.is_common),
            settings: {
              font_size: _.get(form, 'font_size', 0),
              position_id: form.positionId,
              family: Number(_.get(form, 'family', 0)),
              fill: _.get(form, 'fill', ''),
              image: _.get(form, 'image', ''),
            },
          })
      }
    }, [form])

    const handleReset = () => {
      if (activeMarking && form) {
        // eslint-disable-next-line no-console
        setPreview(true)
        onMarkingChange &&
          onMarkingChange({
            id: activeMarking.id,
            input_data: _.get(form, 'input_data', ''),
            is_common: Number(form.is_common),
            settings: {
              font_size: _.get(form, 'font_size', 0),
              position_id: '',
              family: Number(_.get(form, 'family', 0)),
              fill: _.get(form, 'fill', ''),
              image: _.get(form, 'image', ''),
            },
          })
        setValue('positionId', '')
      }
    }

    return (
      <Box sx={{ height: '100%', background: '#fff', position: 'relative' }}>
        <Box
          className="panel-parent"
          ref={parentRef}
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 1,
            left: 0,
            right: 0,
            overflowY: 'auto',
            pb: '160px',
          }}
        >
          {
            <SlideInPanel value={data ? true : false}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    borderBottom: '1px solid #ddd',
                    alignItems: 'center',
                    height: 44,
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {settings &&
                    data &&
                    _.find(settings.parts, { id: data.id }) ? (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          border: '1px solid #eee',
                          borderRadius: 20,
                          backgroundColor: _.find(settings.parts, {
                            id: data.id,
                          })?.color,

                          mr: 1,
                        }}
                      ></Box>
                    ) : (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 20,
                          border: '1px solid #eee',
                          backgroundColor: data?.attributes.colors?.fill,

                          mr: 1,
                        }}
                      ></Box>
                    )}
                    <Typography
                      variant="h5"
                      sx={{ margin: 0, p: 1.4, fontSize: 16, fontWeight: 500 }}
                    >
                      {data?.attributes.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: 160, width: '100%' }}
                      onClick={() => onClose()}
                    >
                      {i18n.t('SYSCOMMON.part_done')}
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ p: 1, height: '100%' }}>
                  {data && data.attributes.colors && (
                    <BoxedPicker
                      size={80}
                      value={color}
                      onChangeComplete={(c) => onColorChange(c, data)}
                      colors={data.attributes.colors.values}
                    />
                  )}
                </Box>
              </Box>
            </SlideInPanel>
          }

          <SlideInPanel value={activeMarking ? true : false}>
            {activeMarking ? (
              <MarkingEditor
                setPreview={setPreview}
                errors={errors}
                control={control}
                getValues={getValues}
                setValue={(field: any, value: any) =>
                  setValue(field, value, { shouldDirty: true })
                }
                onResetPosition={() => handleReset()}
                fonts={fonts}
                onClose={() => onCloseMarking()}
                data={activeMarking}
                Controller={Controller}
                // eslint-disable-next-line no-console
              />
            ) : null}
          </SlideInPanel>

          <SlideInPanel value={catalogy ? true : false}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  borderBottom: '1px solid #ddd',
                  height: 44,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ margin: 0, p: 1.4, fontSize: 16, fontWeight: 500 }}
                >
                  {catalogy && catalogy?.variant === Variant.Top
                    ? i18n.t('SYSCOMMON.tops')
                    : i18n.t('SYSCOMMON.bottoms')}
                </Typography>
                <Box
                  sx={{ borderLeft: '1px solid #ddd', p: 1, height: '100%' }}
                >
                  <IconButton
                    sx={{ border: '0 none' }}
                    onClick={() => onCloseCatalogy()}
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ p: 1 }}>
                {catalogy &&
                  products.map((p, i) => {
                    return (
                      <ProductListItem
                        isSelected={p.id === catalogy.id ? true : false}
                        key={i}
                        data={p}
                        onClick={() => onChoose && onChoose(p.id)}
                      />
                    )
                  })}
              </Box>
            </Box>
          </SlideInPanel>

          {top && !data && !activeMarking ? (
            <TabPanel value={value} index={Variant.Top}>
              <PanelList
                settings={settings}
                data={top}
                onClickPart={setActiveItem}
                onClickMarking={handleEdit}
              />
            </TabPanel>
          ) : null}

          {bottom && !data && !activeMarking ? (
            <TabPanel value={value} index={Variant.Bottom}>
              <PanelList
                settings={settings}
                data={bottom}
                onClickPart={setActiveItem}
                onClickMarking={handleEdit}
              />
            </TabPanel>
          ) : null}
        </Box>
      </Box>
    )
  }
)

export default Controller
