import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { Paper } from '@mui/material'
import { useParams } from 'react-router-dom'

const ItemDetail = () => {
  const { id } = useParams()
  return (
    <ContentBox maxWidth={900}>
      <Paper>asdsad{id}</Paper>
    </ContentBox>
  )
}

export default ItemDetail
