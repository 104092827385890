import ClassicTextField from '@components/@material-extend/ClassicTextField'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useParams } from 'react-router-dom'
import { FieldValues } from 'react-hook-form'
import useAdminEdit from '@containers/Admins/useAdminEdit'
import useFormAdminEdit from '@containers/Admins/useFormAdminEdit'
import { useEffect } from 'react'
import { Box, Paper, Stack, Button, Alert } from '@mui/material'
import PaperHeader from '@components/PaperHeader'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useConfirm } from '@components/Confirm'
import customHistory from 'customHistory'
import _ from 'lodash'
import { useCallbackPrompt } from '@utils/useCallBackPromt'
import i18n from '@locales/i18n'
const AdminEdit = () => {
  const { id } = useParams()
  const { data, fetch, update, metaUpdate, clearCreateMeta } = useAdminEdit()
  const { Controller, methods } = useFormAdminEdit()
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods
  const confirm = useConfirm()
  const [showPromt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty)
  useEffect(() => {
    if (id) {
      clearCreateMeta()
      fetch(id)
    }
  }, [id])

  useEffect(() => {
    if (data) {
      const value = data.attributes
      reset({
        name: value.name,
        email: value.email,
      })
    }
  }, [data])
  useEffect(() => {
    if (showPromt) {
      confirm({
        title: i18n.t('SYSCOMMON.unsaved_change_confirm_title'),
        description: i18n.t('SYSCOMMON.unsaved_change_confirm_desc'),
      })
        .then(() => {
          confirmNavigation()
        })
        .catch(() => cancelNavigation())
    }
  }, [showPromt])
  const onSubmit = (values: any) => {
    if (data) {
      update({
        id: data.id.toString(),
        name: values.name,
        email: values.email,
      })
    }
  }
  return (
    <ContentBox maxWidth={1200}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Paper>
          <PaperHeader title={t('admins.edit_title')} />
          <Box sx={{ p: 2 }}>
            {metaUpdate.error && (
              <Box>
                <Alert severity="warning">
                  {t('ERROR.' + _.get(metaUpdate, 'error.error.message'))}
                </Alert>
              </Box>
            )}
            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <ClassicTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    label={t('admins.name')}
                    placeholder={t('admins.name')}
                    error={errors.name ? true : false}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
            </Box>

            <Box sx={{ width: '100%', height: 80 }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...rest } }: FieldValues) => (
                  <ClassicTextField
                    {...rest}
                    inputRef={ref}
                    fullWidth
                    label={t('admins.email')}
                    placeholder={t('admins.email')}
                    error={errors.email ? true : false}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              customHistory.push('/admin/users')
            }}
          >
            {t('SYSCOMMON.cancel')}
          </Button>

          <Stack direction="row" spacing={1}>
            <LoadingButton
              disabled={!isValid || !isDirty || metaUpdate.pending}
              type="submit"
              loading={metaUpdate.pending}
              variant="contained"
              color="secondary"
            >
              {t('SYSCOMMON.save')}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </ContentBox>
  )
}

export default AdminEdit
