import { Middleware } from 'redux'
import { logOut } from '@store/auth/actions'
import {
  itemCreate,
  itemStatus,
  partOrderChange,
  partCreate,
  partUpdate,
  markingUpdate,
  markingCreate,
  markingDelete,
  itemList,
  itemUpdate,
  imageCreate,
} from '@store/items/actions'
import {
  fontCreate,
  fontDelete,
  fontStatus,
  fontUpdate,
  fontFilesUpdate,
} from '@store/fonts/actions'
import { shopCreate, shopDelete, shopStatus, shopUpdate } from './shops/actions'
import { adminCreate, adminDelete, adminUpdate } from './admins/actions'
import { passwordForgot, passwordReset } from './auth/actions'
import { AnyAction } from '@reduxjs/toolkit'
import { addToast } from '@store/common/actions'
import { AlertColor } from '@mui/material'
import i18n from '@locales/i18n'
import customHistory from 'customHistory'
import {
  copyDesign,
  createDesign,
  createNew,
  designDelete,
  detail,
  shareDesign,
} from './design/actions'
import { orderCreate, showByCode } from './order/actions'
import { settingsSave } from '@store/settings/actions'
import { genreCreate, genreDelete, genreUpdate } from '@store/genre/actions'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleLogout = (_action: AnyAction, _store: any) => {}

const handlePasswordForgot = (_action: AnyAction, _store: any) => {
  // eslint-disable-next-line no-console
  customHistory.push('/password/forgot/sent')
}

const handlePasswordReset = (_action: AnyAction, _store: any) => {
  // eslint-disable-next-line no-console
  customHistory.push('/login')
}

const handleNavigate = (action: AnyAction, _store: any) => {
  if (action.payload.data.id)
    customHistory.push(`/admin/items/${action.payload.data.id}/edit`)
}

const handleNavigateToFontEdit = (action: AnyAction, _store: any) => {
  if (action.payload.data.id)
    customHistory.push(`/admin/fonts/${action.payload.data.id}/edit`)
}

const handleNavigateToFontList = (action: AnyAction, _store: any) => {
  if (action.payload.data.id) customHistory.push(`/admin/fonts`)
}

const handleNavigateToShopEdit = (action: AnyAction, _store: any) => {
  if (action.payload.data.id)
    customHistory.push(`/admin/shops/${action.payload.data.id}/edit`)
}

const handleNavigateToUserEdit = (action: AnyAction, _store: any) => {
  if (action.payload.data.id) {
    window.location.reload()
  }
}

const handleNavigateDesignConfirm = (action: AnyAction, _store: any) => {
  if (action.payload.code) customHistory.push(`/design/${action.payload.code}`)
}

const redirectOrderPage = (action: AnyAction, store: any) => {
  if (action.meta.arg?.type === 'EC') {
    store.dispatch(
      addToast({
        message: i18n.t('orders.success_create_order_ec'),
        severity: 'success' as AlertColor,
        isDialog: { url: `/order/${action.payload.data?.attributes.code}` },
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('orders.success_create_order'),
        severity: 'success' as AlertColor,
        isDialog: { url: `/order/${action.payload.data?.attributes.code}` },
      })
    )
  }
  // if (action.payload.data)
  //   customHistory.push(`/order/${action.payload.data?.attributes.code}`)
}

const redirectStaffList = (action: AnyAction, store: any) => {
  const msg =
    action.meta && action.meta.arg && action.meta.arg.new_password
      ? 'admins.success_admin_password_change'
      : 'admins.success_admin_update'
  store.dispatch(
    addToast({
      message: i18n.t(msg),
      severity: 'success' as AlertColor,
    })
  )
  if (action.payload.data) {
    setTimeout(() => {
      customHistory.push('/admin/users')
    }, 1000)
  }
}
const handleRejectedEdit = (action: AnyAction, store: any) => {
  if (action.payload.error.code === 4031) {
    store.dispatch(
      addToast({
        message: i18n.t('ERROR.E000018'),
        severity: 'warning' as AlertColor,
      })
    )
    // eslint-disable-next-line no-console
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('ERROR.E000003'),
        severity: 'warning' as AlertColor,
      })
    )
  }
}

const handleRejectedGenreDelete = (action: AnyAction, store: any) => {
  if (action.payload.error && action.payload.error.code == 404) {
    store.dispatch(
      addToast({
        message: i18n.t('genre.not_found'),
        severity: 'warning' as AlertColor,
      })
    )
  }
}

const handleRejectedGenreAndFonts = (action: AnyAction, store: any) => {
  if (action.payload.error && action.payload.error.message) {
    store.dispatch(
      addToast({
        message: i18n.t('ERROR.' + action.payload.error.message),
        severity: 'warning' as AlertColor,
      })
    )
  }
}

const fetchListAgain = (action: AnyAction, store: any) => {
  let status_msg: any
  const status: number = action.meta?.arg?.status
  if (
    action.payload &&
    action.payload.denied_ids &&
    action.payload.denied_ids.length > 0
  ) {
    status_msg = {
      1: 'items.status_draft_with_deny_success',
      2: 'items.status_publish_with_deny_success',
      3: 'items.status_delete_with_deny_success',
    }[status]
  } else {
    status_msg = {
      1: 'items.status_draft_success',
      2: 'items.status_publish_success',
      3: 'items.status_delete_success',
    }[status]
  }

  store.dispatch(
    addToast({
      message: i18n.t(status_msg),
      severity: 'success' as AlertColor,
    })
  )
  store.dispatch(itemList({ per_page: 20, page: 1 }))
}

const handleFontToastMessages = (action: AnyAction, store: any) => {
  const status: number = action.meta?.arg?.status

  const status_msg: any = {
    1: 'fonts.status_draft_success',
    2: 'fonts.status_publish_success',
    3: 'fonts.status_delete_success',
  }[status]

  store.dispatch(
    addToast({
      message: i18n.t(status_msg),
      severity: 'success' as AlertColor,
    })
  )
}
const handleShopToastMessages = (action: AnyAction, store: any) => {
  const status: number = action.meta?.arg?.status

  const status_msg: any = {
    1: 'shops.status_draft_success',
    2: 'shops.status_publish_success',
  }[status]

  store.dispatch(
    addToast({
      message: i18n.t(status_msg),
      severity: 'success' as AlertColor,
    })
  )
}

const redirect404 = (action: AnyAction, _store: any) => {
  if (action.payload.error && action.payload.error.code === 404)
    customHistory.replace(`/404`)
}

const redirect404WithMessage = (action: AnyAction, _store: any) => {
  if (action.payload.error && action.payload.error.message)
    customHistory.replace('/404', { message: action.payload.error.message })
}

const fetchRelatedDesign = (action: AnyAction, store: any) => {
  if (action.payload.data && action.payload.data.attributes.design_code) {
    store.dispatch(detail(action.payload.data.attributes.design_code))
  }
}

const handleStepOneRedirect = (action: AnyAction, _store: any) => {
  if (action.payload.data)
    customHistory.push(
      `/admin/items/${action.payload.data[0]?.attributes.item_id}/step/2`
    )
}

const handleCopyReject = (action: AnyAction, _store: any) => {
  if (action.payload.error && action.payload.error.code === 4031)
    customHistory.replace(`/404`, { message: action.payload.error.message })
}

const actions = {
  [`${logOut}`]: handleLogout,
  [`${itemCreate.fulfilled}`]: handleNavigate,
  [`${fontCreate.fulfilled}`]: handleNavigateToFontEdit,
  [`${fontCreate.rejected}`]: handleRejectedGenreAndFonts,
  [`${fontUpdate.fulfilled}`]: handleNavigateToFontList,
  [`${fontUpdate.rejected}`]: handleRejectedGenreAndFonts,
  [`${shopCreate.fulfilled}`]: handleNavigateToShopEdit,
  [`${createDesign.fulfilled}`]: handleNavigateDesignConfirm,
  [`${adminCreate.fulfilled}`]: handleNavigateToUserEdit,
  [`${passwordForgot.fulfilled}`]: handlePasswordForgot,
  [`${passwordReset.fulfilled}`]: handlePasswordReset,
  [`${partCreate.rejected}`]: handleRejectedEdit,
  [`${partUpdate.rejected}`]: handleRejectedEdit,
  // [`${partOrderChange.rejected}`]: handlePasswordReset,
  [`${markingCreate.rejected}`]: handleRejectedEdit,
  [`${markingUpdate.rejected}`]: handleRejectedEdit,
  [`${markingDelete.rejected}`]: handleRejectedEdit,
  [`${itemStatus.fulfilled}`]: fetchListAgain,
  [`${orderCreate.fulfilled}`]: redirectOrderPage,
  [`${createNew.rejected}`]: redirect404,
  [`${showByCode.fulfilled}`]: fetchRelatedDesign,
  [`${showByCode.rejected}`]: redirect404WithMessage,
  [`${detail.rejected}`]: redirect404WithMessage,
  [`${partCreate.fulfilled}`]: handleStepOneRedirect,
  [`${adminUpdate.fulfilled}`]: redirectStaffList,
  [`${fontStatus.fulfilled}`]: handleFontToastMessages,
  [`${shopStatus.fulfilled}`]: handleShopToastMessages,
  [`${copyDesign.rejected}`]: handleCopyReject,
  [`${genreCreate.rejected}`]: handleRejectedGenreAndFonts,
  [`${genreUpdate.rejected}`]: handleRejectedGenreAndFonts,
  [`${genreDelete.rejected}`]: handleRejectedGenreDelete,
}

const messages = {
  // [`${itemCreate.rejected}`]: {
  //   message: i18n.t('ERROR.E000003'),
  //   severity: 'warning' as AlertColor,
  // },
  [`${partOrderChange.rejected}`]: {
    message: i18n.t('ERROR.E000003'),
    severity: 'warning' as AlertColor,
  },
  [`${partOrderChange.fulfilled}`]: {
    message: i18n.t('part.order_changed'),
    severity: 'success' as AlertColor,
  },
  [`${partCreate.fulfilled}`]: {
    message: i18n.t('part.create_success'),
    severity: 'success' as AlertColor,
  },
  [`${partUpdate.fulfilled}`]: {
    message: i18n.t('part.update_success'),
    severity: 'success' as AlertColor,
  },
  [`${markingUpdate.fulfilled}`]: {
    message: i18n.t('marking.update_success'),
    severity: 'success' as AlertColor,
  },
  [`${markingCreate.fulfilled}`]: {
    message: i18n.t('marking.create_success'),
    severity: 'success' as AlertColor,
  },
  [`${markingDelete.fulfilled}`]: {
    message: i18n.t('marking.delete_success'),
    severity: 'success' as AlertColor,
  },
  [`${orderCreate.rejected}`]: {
    message: i18n.t('orders.failed_create_order'),
    severity: 'warning' as AlertColor,
  },
  [`${shareDesign.rejected}`]: {
    message: i18n.t('designs.failed_share'),
    severity: 'warning' as AlertColor,
  },
  [`${shareDesign.fulfilled}`]: {
    message: i18n.t('designs.success_share'),
    severity: 'success' as AlertColor,
  },
  [`${itemUpdate.fulfilled}`]: {
    message: i18n.t('SYSCOMMON.item_updated'),
    severity: 'success' as AlertColor,
  },
  [`${settingsSave.fulfilled}`]: {
    message: i18n.t('settings.save_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${shopUpdate.fulfilled}`]: {
    message: i18n.t('shops.save_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${shopCreate.fulfilled}`]: {
    message: i18n.t('shops.create_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${shopDelete.fulfilled}`]: {
    message: i18n.t('shops.delete_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${fontUpdate.fulfilled}`]: {
    message: i18n.t('fonts.save_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${fontCreate.fulfilled}`]: {
    message: i18n.t('fonts.create_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${fontDelete.fulfilled}`]: {
    message: i18n.t('fonts.status_delete_success'),
    severity: 'success' as AlertColor,
  },
  [`${genreUpdate.fulfilled}`]: {
    message: i18n.t('genre.save_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${genreCreate.fulfilled}`]: {
    message: i18n.t('genre.create_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${genreDelete.fulfilled}`]: {
    message: i18n.t('genre.delete_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${designDelete.fulfilled}`]: {
    message: i18n.t('designs.delete_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${itemCreate.fulfilled}`]: {
    message: i18n.t('items.create_success'),
    severity: 'success' as AlertColor,
  },
  // [`${itemUpdate.fulfilled}`]: {
  //   message: i18n.t('items.update_success'),
  //   severity: 'success' as AlertColor,
  // },
  [`${adminDelete.fulfilled}`]: {
    message: i18n.t('admins.delete_admin_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${adminCreate.fulfilled}`]: {
    message: i18n.t('admins.create_admin_success_toast'),
    severity: 'success' as AlertColor,
  },
  [`${fontFilesUpdate.fulfilled}`]: {
    message: i18n.t('SYSCOMMON.file_uploaded'),
    severity: 'success' as AlertColor,
  },
  [`${imageCreate.fulfilled}`]: {
    message: i18n.t('SYSCOMMON.file_uploaded'),
    severity: 'success' as AlertColor,
  },
}
export const globalMiddleware: Middleware =
  (store: any) => (next) => (action) => {
    const globalAction = actions[action.type]

    if (globalAction) globalAction(action, store)

    const message = messages[action.type]
    if (message) {
      store.dispatch(addToast(message))
    }

    return next(action)
  }
