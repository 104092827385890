import { Box } from '@mui/material'
import React from 'react'

interface HamburgerProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const Hamburger: React.FC<HamburgerProps> = ({ open, setOpen }) => {
  return (
    <>
      <Box
        className={open ? 'is-active' : ''}
        sx={{
          padding: '0 10px',
          cursor: 'pointer',
          transitionProperty: 'opacity, filter',
          transitionDuration: '0.15s',
          transitionTimingFunction: 'linear',
          font: 'inherit',
          color: 'inherit',
          textTransform: 'none',
          backgroundColor: 'transparent',
          border: '0',
          margin: '0',
          overflow: 'visible',
          '&:hover': {
            opacity: 0.7,
          },
          '&.is-active .hamburger-inner': {
            transform: 'translate3d(0, 10px, 0) rotate(45deg)',
          },
          '&.is-active .hamburger-inner::before': {
            transform: 'rotate(-45deg) translate3d(-5.71429px, -6px, 0)',
            opacity: 0,
          },
          '&.is-active .hamburger-inner::after': {
            transform: ' translate3d(0, -20px, 0) rotate(-90deg)',
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <Box
          sx={{
            width: '26px',
            height: '26px',
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <Box
            className="hamburger-inner"
            sx={{
              width: '26px',
              height: '1px',
              backgroundColor: '#111',
              borderRadius: '4px',
              position: 'absolute',
              transitionProperty: 'transform',
              transitionDuration: '0.15s',
              transitionTimingFunction: 'ease',
              top: '6px',
              '&:before': {
                width: '26px',
                height: '1px',
                backgroundColor: '#111',
                borderRadius: '4px',
                position: 'absolute',
                transitionProperty: 'transform, opacity',
                transitionDuration: '0.15s',
                transitionTimingFunction: 'ease',
                content: '""',
                display: 'block',
                top: '10px',
              },
              '&:after': {
                width: '26px',
                height: '1px',
                backgroundColor: '#111',
                borderRadius: '4px',
                position: 'absolute',
                transitionProperty: 'transform',
                transitionDuration: '0.15s',
                transitionTimingFunction: 'ease',
                content: '""',
                display: 'block',
                bottom: '-10px',
                top: '20px',
              },
            }}
          />
        </Box>
      </Box>
    </>
  )
}

Hamburger.defaultProps = {
  open: false,
}

export default Hamburger
