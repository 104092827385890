import DesignConfirm from '@containers/DesignConfirm'
import { Box, Container, Stepper, Step, StepLabel, Paper } from '@mui/material'

const steps = ['注文情報入力画面', '注文情報保存画面']

const Design = () => {
  return (
    <Box>
      <Container sx={{ pl: 1, pr: 1, pb: 1 }} maxWidth={false}>
        <Paper
          sx={{
            p: 0.5,
            mt: 1,
            borderTop: '1px solid #ccc',
            background: '#fff',
          }}
        >
          <Stepper
            sx={{
              display: {
                md: 'flex',
              },
            }}
            activeStep={0}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <DesignConfirm />
      </Container>
    </Box>
  )
}

export default Design
