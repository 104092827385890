/* eslint-disable no-console */
import { Box, Grid, Typography } from '@mui/material'
import { ThemeColors } from '@theme/colors'
import { ReactNode } from 'react'

interface TableHeadSectionProps {
  title?: string
  total?: number
  actions?: ReactNode
  helperText?: ReactNode | string
}

const TableHeadSection: React.FC<TableHeadSectionProps> = ({
  title,
  actions,
  total,
  helperText,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Grid
        container
        alignItems={'center'}
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid xs={12} sm={12} md={4} item>
          {title && (
            <Typography variant="h2" sx={{ margin: 0 }}>
              {title}

              <Typography
                component={'span'}
                variant="body1"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  ml: 1,
                  color: ThemeColors.text[600],
                }}
              >
                {total && total > 0 ? '(' + total + ')' : ''}
              </Typography>
            </Typography>
          )}
          {helperText && <Typography variant="body2">{helperText}</Typography>}
        </Grid>
        <Grid xs={12} sm={12} md={8} item>
          {actions}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TableHeadSection
