import {
  MARKING_TYPE,
  MetaPage,
  PROCESS_STATUS,
  SEX,
} from '@constants/common.constants'
import { Side } from '@constants/editor.constants'
import { URI } from '@constants/uri.constants'
import api from './api'

export type Item = {
  id: string
  type: 'item_list'
  attributes: {
    name: string
    code: string
    price: number
    size: null | string[]
    descriptions: null | string
    has_design: boolean
    article: null | string
    status: number
    genre_id: number
    genre_name: string
    genre_type: number
    sex: number
    has_additional_price: boolean
    thumb_url: string
    item_images?: GalleryItem[]
    current_step: number
  }
}

export interface GalleryItem {
  id: string
  type: 'item_image'
  attributes: {
    item_id: number
    url: string
  }
}

export interface ItemCreateParams {
  name: string
  genre_id: number
  code: string
  copy_id?: number
}

export interface ItemUpdateParams {
  id: string
  params: {
    name?: string
    genre_id?: number
    code?: string
    size?: string
    descriptions?: string | null
    article?: null | string
    thumbnail?: string
  }
}

export type ItemListParams = {
  page?: number
  per_page?: number
  status?: number | null
  genre_id?: number | null
  sex?: number | null
  name?: string | null
}

export type ItemListResponse = {
  data: Item[]
  meta: MetaPage
}

export type ItemCreateResponse = {
  data: Item
}

export type ItemStatusParams = {
  ids: number[]
  status: PROCESS_STATUS
}

export type ImageCreateParams = {
  id: string
  values: {
    path: string
  }
}

export type ItemPartUpdate = {
  id: string
  part_id: string
  values: {
    name?: string
    part_order?: number
    front_path?: string
    back_path?: string
    colors?: null | PartColor
    is_editable?: boolean
  }
}

export type ImageDeleteParams = {
  id: string
  image_id: string
}

export type ItemStatusResponse = {
  status: string
  denied_ids: number[]
}

export interface ImageItem {
  id: string
  type: 'item_image'
  attributes: {
    item_id: number
    url: string
  }
}

export type ImageCreateResponse = {
  data: ImageItem
}

export type ImageListResponse = {
  data: ImageItem[]
}

export type ImageDeleteResponse = {
  data: { status: 'ok' }
}

export type ItemPartCreate = {
  id: string
  values: {
    name: string
    part_order?: number
    front_path?: string
    back_path?: string
    colors?: null | PartColor
    is_editable?: boolean
  }
}

export type PartCreateItem = {
  name: string
  part_order?: number
  front_path?: string
  back_path?: string
  colors?: null | PartColor
  is_editable?: boolean
}

export type PartUpdateItem = {
  id: number
  name: string
  part_order?: number
  front_path?: string
  back_path?: string
  colors?: null | PartColor
  is_editable?: boolean
}

export interface PartItem {
  id: string
  type: 'item_part'
  attributes: {
    back_path?: string
    back_svg?: string
    colors?: null | PartColor
    front_path?: string
    front_svg?: string
    is_editable: boolean
    item_id?: number
    front_url?: string
    back_url?: string
    name: string
    part_order: number
  }
}

export interface PartColor {
  fill?: string | null
  values: string[]
}

export type PartListResponse = {
  data: PartItem[]
}

export type PartCreateResponse = {
  data: PartItem[]
}

export type PartUpdateResponse = {
  data: PartItem
}

export type PartDeleteParams = {
  id: string
  part_id: string
}

export type ItemPartOrderParams = {
  id: string
  params: { orders: PartOrderItem[] }
}

export type PartOrderItem = {
  part_id: number
  part_order: number
}

export const list = async (
  params: ItemListParams
): Promise<ItemListResponse> => {
  const { data } = await api.get<ItemListResponse>(URI.ITEM, { params })
  return data
}

export const create = async (
  params: ItemCreateParams
): Promise<ItemCreateResponse> => {
  const { data } = await api.post<ItemCreateResponse>(URI.ITEM, params)
  return data
}

export const update = async (
  params: ItemUpdateParams
): Promise<ItemCreateResponse> => {
  const { data } = await api.put<ItemCreateResponse>(
    `${URI.ITEM}/${params.id}`,
    params.params
  )
  return data
}

export const detail = async (id: string): Promise<ItemCreateResponse> => {
  const { data } = await api.get<ItemCreateResponse>(`${URI.ITEM}/${id}`)
  return data
}

export const status = async (
  params: ItemStatusParams
): Promise<ItemStatusResponse> => {
  const { data } = await api.post<ItemStatusResponse>(
    `${URI.ITEM}/update/status/`,
    params
  )
  return data
}

export const listImage = async (id: string): Promise<ImageListResponse> => {
  const { data } = await api.get<ImageListResponse>(
    URI.PRODUCT_IMAGE.replace(/:id/gi, id)
  )
  return data
}

export const createImage = async (
  params: ImageCreateParams
): Promise<ImageCreateResponse> => {
  const { data } = await api.post<ImageCreateResponse>(
    URI.PRODUCT_IMAGE.replace(/:id/gi, params.id),
    params.values
  )
  return data
}

export const deleteImage = async (
  params: ImageDeleteParams
): Promise<ImageDeleteResponse> => {
  const { data } = await api.delete<ImageDeleteResponse>(
    `${URI.PRODUCT_IMAGE.replace(/:id/gi, params.id)}/${params.image_id}`
  )
  return data
}

export const partCreate = async (
  params: MultiCreateParams
): Promise<PartCreateResponse> => {
  const { data } = await api.post<PartCreateResponse>(
    URI.PART.replace(/:id/gi, params.id),
    params.params
  )
  return data
}

export const partUpdate = async (
  params: ItemPartUpdate
): Promise<PartUpdateResponse> => {
  const { data } = await api.put<PartUpdateResponse>(
    `${URI.PART.replace(/:id/gi, params.id)}/${params.part_id}`,
    params.values
  )
  return data
}

export const partList = async (id: string): Promise<PartListResponse> => {
  const { data } = await api.get<PartListResponse>(
    URI.PART.replace(/:id/gi, id)
  )
  return data
}

export const partDelete = async (params: PartDeleteParams): Promise<any> => {
  const { data } = await api.delete<any>(
    `${URI.PART.replace(/:id/gi, params.id)}/${params.part_id}`
  )
  return data
}

export const partOrder = async (
  params: ItemPartOrderParams
): Promise<PartListResponse> => {
  const { data } = await api.post<PartListResponse>(
    `${URI.PART.replace(/:id/gi, params.id)}/order`,
    params.params
  )
  return data
}

export const complete = async (id: string): Promise<any> => {
  const { data } = await api.post<any>(`${URI.COMPLETE.replace(/:id/gi, id)}`)
  return data
}

export type PositionItem = {
  isActive: boolean
  id: string
  x: number
  y: number
}

export type MarkingEdit = {
  name: string
  marking_order?: number
  is_common: boolean | number
  additional_price: number
  marking_type: MARKING_TYPE
  settings: SettingsItem
  max_size: number
}

export type SettingsItem = {
  font_size?: number
  fonts?: string[]
  color?: PartColor
  positions: {
    values: PositionItem[]
    side: Side
  }
  height?: number
  width?: number
  sample_text?: string
  family?: string
  image?: string
}

export type MarkingCreateParams = {
  id: string
  values: MarkingEdit
}
export type MarkingUpdateParams = {
  id: string
  values: MarkingEdit
  marking_id: string
}

export type MarkingDeleteParams = {
  id: string
  marking_id: string
}

export type MarkingItem = {
  id: string
  type: 'item_marking'
  attributes: {
    item_id: number
    name: string
    marking_order: number
    marking_type: MARKING_TYPE
    is_common: boolean
    additional_price: number
    max_size: number
    settings: SettingsItem
  }
}

export type MarkingCreateResponse = {
  data: MarkingItem
}

export type MarkingListResponse = {
  data: MarkingItem[]
}

export type MultiUpdateParams = {
  id: string
  params: {
    parts: PartUpdateItem[]
  }
}

export type MultiCreateParams = {
  id: string
  params: { parts: PartCreateItem[] }
}

export const mCreate = async (
  params: MarkingCreateParams
): Promise<MarkingCreateResponse> => {
  const { data } = await api.post<MarkingCreateResponse>(
    URI.MARKING.replace(/:id/gi, params.id),
    params.values
  )
  return data
}

export const mUpdate = async (
  params: MarkingUpdateParams
): Promise<MarkingCreateResponse> => {
  const { data } = await api.put<MarkingCreateResponse>(
    `${URI.MARKING.replace(/:id/gi, params.id)}/${params.marking_id}`,
    params.values
  )
  return data
}

export const mList = async (id: string): Promise<MarkingListResponse> => {
  const { data } = await api.get<any>(URI.MARKING.replace(/:id/gi, id))
  return data
}

export const mDelete = async (params: MarkingDeleteParams): Promise<any> => {
  const { data } = await api.delete<any>(
    `${URI.MARKING.replace(/:id/gi, params.id)}/${params.marking_id}`
  )
  return data
}

export type TopListParams = {
  sex: SEX
}

export type TopListResponse = {
  tops: {
    data: Item[]
  }
  bottoms: {
    data: Item[]
  }
}

export type PublicItemResponse = {
  data: Item
}

export const topList = async (
  params: TopListParams
): Promise<TopListResponse> => {
  const { data } = await api.get<any>(URI.TOP_LIST, {
    params: params,
  })
  return data
}

export const publicItemDetail = async (
  id: string
): Promise<PublicItemResponse> => {
  const { data } = await api.get<any>(URI.ITEM_DETAIL.replace(/:id/gi, id))
  return data
}
