import TableLayout from '@components/@material-extend/TableLayout'
import TableHeadSection from '@components/@material-extend/TableLayout/TableHeadSection'
import {
  Avatar,
  Button,
  Checkbox,
  Pagination,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material'
import {
  Attachment,
  Create,
  Delete,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { FONT_STATUS, FontStatusOptions } from '@constants/common.constants'
import { useTranslation } from 'react-i18next'
import useFonts from '@containers/Fonts/useFonts'
import CreateForm from '@containers/Fonts/CreateForm'
import { useConfirm } from '@components/Confirm'
import DialogBase from '@components/@material-extend/DialogBase'
import ContentBox from '@layouts/AdminLayout/elements/ContentBox'
import { useEffect, useState } from 'react'
import FilterForm, { FilterParams } from '@containers/Fonts/FontsFilter'
import { FontItem, FontCreateParams } from '@services/fonts.services'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const Fonts = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const {
    paging,
    data,
    meta,
    metaCreate,
    fetch,
    changeStatus,
    deleteFont,
    create,
  } = useFonts()
  const confirm = useConfirm()
  const [filter, setFilter] = useState<any>({
    status: undefined,
    name: '',
  })
  const getSearchParams = () => {
    let searchParams = {}
    if (paging) {
      searchParams = {
        page: paging.current_page,
        per_page: paging.per_page,
        ...filter,
      }
    } else {
      searchParams = {
        page: 1,
        per_page: 20,
        ...filter,
      }
    }
    return searchParams
  }
  useEffect(() => {
    fetch(getSearchParams())
  }, [filter])

  const handleSelectAll = () => {
    const allSelected = selected.length === data?.length
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(_.map(data, (d) => Number(d.id)))
    }
  }

  const handleChecked = (id: number) => {
    const exist = !!_.find(selected, (i) => i === id)
    if (exist) {
      setSelected(_.filter(selected, (s) => s !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const handleFilter = (data: FilterParams) => {
    setFilter(data)
  }

  const onChangeStatus = (status: FONT_STATUS) => {
    confirm({
      title: t('SYSCOMMON.update_confirm_title'),
      description: t('fonts.update_confirm_desc', {
        target: _.find(FontStatusOptions, (s) => s.value === status)?.name,
      }),
    })
      .then(() => {
        changeStatus({
          ids: selected,
          status: status,
        })
      })
      .catch(() => null)
  }

  const deleteFonts = () => {
    confirm({
      title: t('fonts.delete_confirm_title'),
      description: t('fonts.delete_confirm_desc', {
        target: _.find(FontStatusOptions, (s) => s.value === status)?.name,
      }),
    })
      .then(() => {
        const searchParams = getSearchParams()
        const currentSelected = [...selected]
        deleteFont({
          ids: currentSelected,
          ...searchParams,
        })
        setSelected([])
      })
      .catch(() => null)
  }

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetch({
      page: page,
      per_page: 20,
      ...filter,
    })
  }

  const handleCreate = (params: FontCreateParams) => {
    create(params)
    setOpen(false)
  }

  const renderRow = (item: FontItem, index: number) => {
    const data = item.attributes
    return (
      <TableRow key={index}>
        <TableCell>
          <Checkbox
            onClick={() => handleChecked(Number(item.id))}
            checked={!!_.find(selected, (id) => id === Number(item.id))}
          />
        </TableCell>
        <TableCell scope="row" sx={{ position: 'relative' }}>
          <Avatar alt={data.name} sx={{ borderRadius: '0' }} src={data.thumb} />
        </TableCell>
        <TableCell scope="row" sx={{ position: 'relative' }}>
          {data.name}
        </TableCell>
        <TableCell align="center">
          {data.style_path ? (
            <a href={data.style_path} target="_blank" rel="noreferrer">
              <Attachment />
            </a>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="center">
          {data.woff_path ? (
            <a href={data.woff_path} target="_blank" rel="noreferrer">
              {' '}
              <Attachment />
            </a>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="center">
          {data.woff2_path ? (
            <a href={data.woff2_path} target="_blank" rel="noreferrer">
              {' '}
              <Attachment />
            </a>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="center">
          {_.find(FontStatusOptions, (s) => s.value === data.status)?.name}
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" spacing={1}>
            <Button size="small" variant="outlined">
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={`/admin/fonts/${item.id}/edit`}
              >
                {t('button.edit')}
              </Link>
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ContentBox>
      <DialogBase
        onClose={() => setOpen(false)}
        open={open}
        title={t('SYSCOMMON.register')}
        content={
          <CreateForm
            meta={metaCreate}
            onCreate={handleCreate}
            close={() => setOpen(false)}
          />
        }
      />
      <TableLayout
        renderRow={renderRow}
        data={!_.isEmpty(data) && data ? data : []}
        isLoading={meta.pending}
        pagintionBox={{ sx: { pt: 2 } }}
        pagination={
          paging && (
            <Pagination
              page={paging?.current_page}
              count={paging?.total_pages}
              onChange={paginationHandler}
            />
          )
        }
        headSection={
          <TableHeadSection
            title={t('SYSCOMMON.fonts_management')}
            total={paging?.total_count}
            actions={
              <Stack justifyContent="flex-end" flexDirection={'column'}>
                <FilterForm onFilter={handleFilter} />
              </Stack>
            }
          />
        }
        filter={
          <Stack justifyContent="flex-start" direction={'row'} spacing={1}>
            <Button
              startIcon={<VisibilityOff />}
              color="primary"
              variant="outlined"
              disabled={_.isEmpty(selected) ? true : false}
              onClick={() => onChangeStatus(FONT_STATUS.FONT_STATUS_DRAFT)}
            >
              {t('SYSCOMMON.private')}
            </Button>
            <Button
              startIcon={<Visibility />}
              color="primary"
              variant="outlined"
              disabled={_.isEmpty(selected) ? true : false}
              onClick={() => onChangeStatus(FONT_STATUS.FONT_STATUS_PUBLISHED)}
            >
              {t('SYSCOMMON.publish')}
            </Button>
            <Button
              startIcon={<Delete />}
              disabled={_.isEmpty(selected) ? true : false}
              color="primary"
              variant="outlined"
              onClick={() => deleteFonts()}
            >
              {t('SYSCOMMON.delete')}
            </Button>
            <Button
              startIcon={<Create />}
              color="secondary"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              {t('SYSCOMMON.register')}
            </Button>
          </Stack>
        }
        head={
          <TableRow>
            <TableCell width={40} align="center">
              <Checkbox
                checked={
                  selected.length > 0 && selected.length === data?.length
                }
                onClick={handleSelectAll}
              />
            </TableCell>
            <TableCell sx={{ minWidth: 70, width: 60 }}></TableCell>
            <TableCell sx={{ minWidth: 70 }}>{t('fonts.name')}</TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {t('fonts.style_path')}
            </TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {t('fonts.woff_path')}
            </TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {t('fonts.woff2_path')}
            </TableCell>
            <TableCell sx={{ minWidth: 70 }} align="center">
              {t('SYSCOMMON.status')}
            </TableCell>
            <TableCell
              sx={{ minWidth: 70, width: '100px' }}
              align="center"
            ></TableCell>
          </TableRow>
        }
      />
    </ContentBox>
  )
}

export default Fonts
