import { URI } from '@constants/uri.constants'
import api from './api'

export type UserLoginParams = {
  email: string
  password: string
}

export type PasswordForgotParams = {
  email: string
}

export type PasswordResetParams = {
  reset_password_token: string
  new_password: string
}

export type PlainResponse = {
  status: string
}

export type UserLoginResponse = {
  id: number
  name: string
  email: string
  access_token: string
  refresh_token: string
}

export const login = async (
  params: UserLoginParams
): Promise<UserLoginResponse> => {
  const { data } = await api.post<UserLoginResponse>(URI.LOGIN, params)
  return data
}

export const passwordForgot = async (
  params: PasswordForgotParams
): Promise<PlainResponse> => {
  const { data } = await api.post<PlainResponse>(URI.PASSWORD_FORGOT, params)
  return data
}

export const passwordReset = async (
  params: PasswordResetParams
): Promise<PlainResponse> => {
  const { data } = await api.post<PlainResponse>(URI.PASSWORD_RESET, params)
  return data
}
