import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { ThemeColors } from '@theme/colors'

const font = "'Noto Sans JP', Arial, sans-serif;"

const $titlefamily = "'Noto Sans JP', Arial, sans-serif;"

const theme = createTheme({
  palette: {
    primary: {
      main: ThemeColors.primary,
    },
    secondary: {
      main: ThemeColors.secondary,
    },
    error: {
      main: red.A400,
    },
    info: {
      main: ThemeColors.info,
    },
    background: {
      default: '#EFEFEF',
    },
    text: {
      primary: ThemeColors.text[700],
    },
  },

  typography: {
    fontFamily: font,
    h1: {
      fontSize: 28,
      fontWeight: 500,
      marginTop: 14,
      marginBottom: 14,
      fontFamily: $titlefamily,
      color: ThemeColors.dark,
    }, // lets all big titles
    h2: {
      fontSize: 18,
      marginTop: 10,
      marginBottom: 10,
      fontWeight: 700,
      fontFamily: $titlefamily,
      color: ThemeColors.dark,
    },
    h3: {
      fontSize: 18,
      fontFamily: $titlefamily,
      fontWeight: 400,
      color: ThemeColors.text[900],
      marginTop: 8,
      marginBottom: 8,
    },
    h4: {
      fontSize: 16,
      fontFamily: $titlefamily,
      fontWeight: 600,
      color: ThemeColors.text[700],
      marginTop: 5,
      marginBottom: 5,
    },
    h5: {
      fontSize: 14,
      fontFamily: $titlefamily,
      fontWeight: 600,
      color: ThemeColors.text[700],
      marginBottom: 3,
    },
    h6: {
      fontSize: 11,
      fontFamily: $titlefamily,
      fontWeight: 600,
      lineHeight: 1.3,
      color: ThemeColors.text[700],
      textTransform: 'uppercase',
      marginTop: 3,
      marginBottom: 3,
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
    },
    body2: {
      fontSize: 11,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
    },
    overline: {
      fontSize: 10,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {},
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${ThemeColors.text[700]}`,
          borderRadius: '3px',
          padding: '4px',
          fontSize: 14,
          color: ThemeColors.text[700],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#333',
          '&.MuiTableCell-head': {
            color: '#111',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          lineHeight: 1.85,
        },
        sizeMedium: {
          padding: '5px 12px',
        },
        sizeSmall: {
          padding: '4px 12px',
        },
      },
    },
  },
})

export default theme
