import { Side, Variant } from '@constants/editor.constants'
import i18n from '@locales/i18n'
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ThemeColors } from '@theme/colors'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import { RemoveCircle } from '@mui/icons-material'
import { use100vh } from 'react-div-100vh'
import { LayoutContants } from '@constants/layout.constants'
import { DesignConfigItem, DesignItem } from '@services/design.services'
import _ from 'lodash'
import DesignPreview from '../DesignPreview'
import { MarkingItem } from '@services/item.services'
import { FontItem } from '@services/fonts.services'
import HelperText, { HelperTextType } from '@components/HelperText'

export type PreviewLayoutProps = {
  active: Variant
  onClickDetail: (id: string) => void
  onClickChangeItem: (id: string, variant: Variant) => void
  topDetail: DesignItem
  bottomDetail: DesignItem
  onRemove: (type: Variant) => void
  activeMarking: MarkingItem | null
  fonts: FontItem[]
  onPositionChoose: (id: number) => void
  topSettings: DesignConfigItem | null
  bottomSettings: DesignConfigItem | null
  side: Side
  onCloseHelper?: () => void
  helperText: HelperTextType
  isHelperActive: boolean
  onShowHelper?: () => void
}

const PreviewLayout: React.FC<PreviewLayoutProps> = ({
  active,
  onClickDetail,
  onClickChangeItem,
  side,
  topDetail,
  activeMarking,
  fonts,
  bottomDetail,
  onPositionChoose,
  topSettings,
  helperText,
  bottomSettings,
  onRemove,
  onCloseHelper,
  isHelperActive,
  onShowHelper,
}) => {
  const height = use100vh()
  const isMobile = useMediaQuery('(max-width:900px)')
  const innerHeight = height ? height - LayoutContants.appbarHeight * 2 : 200

  const innerHeightMobile = height
    ? height - LayoutContants.appbarHeight * 2 - 220
    : 200

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: isMobile ? innerHeightMobile : innerHeight,
        position: 'relative',
        border: '1px solid #ccc',
      }}
    >
      {!isMobile ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'none', // remove temp
            zIndex: '100',
            bottom: { xs: 60, sm: 60, md: 20, lg: 20 },
            right: { xs: 5, sm: 5, md: 20 },
            left: {
              xs: isHelperActive ? 5 : 'auto',
              sm: isHelperActive ? 5 : 'auto',
              md: 'auto',
            },
          }}
        >
          <HelperText
            onClose={onCloseHelper}
            text={helperText}
            active={isHelperActive}
            onShow={onShowHelper}
          />
        </Box>
      ) : null}

      {topDetail ? (
        <Box
          className={`top-area ${active === Variant.Top ? 'selected' : ''}`}
          sx={{
            flex: 1,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
            },
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            '&.selected': {
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              },
            },
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              left: 10,
              top: 10,
              display: 'flex',
            }}
            direction={'row'}
            spacing={1}
          >
            <IconButton
              onClick={() => onClickChangeItem(topDetail.id, Variant.Top)}
              sx={{ border: '0 none', display: 'none' }}
              //temp hide
            >
              <AutoAwesomeMotionIcon />
            </IconButton>
            {bottomDetail ? (
              <IconButton
                onClick={() => onRemove(Variant.Top)}
                sx={{ border: '0 none' }}
              >
                <RemoveCircle />
              </IconButton>
            ) : null}
          </Stack>
          <Chip
            className={active === Variant.Top ? 'is-active' : ''}
            size="small"
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              fontSize: 16,
              background: '#000',
              p: 1,
              pt: 2,
              pb: 2,
              border: '1px solid #ddd',
              color: '#fff',
              '&.is-active': {
                color: '#fff',
                background: ThemeColors.secondary,
              },
            }}
            label={i18n.t('SYSCOMMON.tops')}
          />
          <Box
            sx={{
              textAlign: isMobile ? 'center' : 'left',
              position: 'absolute',
              left: 10,
              bottom: 10,
            }}
          >
            {topDetail ? (
              <Box onClick={() => onClickDetail(topDetail.id)}>
                <Typography
                  variant="h5"
                  sx={{
                    display: '-webkit-box',
                    margin: '0 auto',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: 20,
                    fontWeight: 500,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': {
                      transition: 'all 0.3s ease',
                      color: ThemeColors.secondary,
                    },
                  }}
                  component="div"
                >
                  {_.get(topDetail, 'attributes.name', '')}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box>
            {topSettings && topDetail ? (
              <div
                id={`top-preview-${Side.Front}`}
                style={{
                  width: isMobile ? '280px' : '350px',
                  height: `${innerHeight / 2}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: side === Side.Front ? 'flex' : 'none',
                }}
              >
                <DesignPreview
                  onPositionChoose={onPositionChoose}
                  fonts={fonts}
                  svgId={`top-preview-${Side.Front}`}
                  activeMarking={
                    active === Variant.Top ? activeMarking : undefined
                  }
                  settings={topSettings}
                  marking={topDetail.attributes.item_markings}
                  side={Side.Front}
                  data={_.get(topDetail, 'attributes.item_parts', [])}
                />
              </div>
            ) : null}

            {topSettings && topDetail ? (
              <div
                id={`top-preview-${Side.Back}`}
                style={{
                  width: isMobile ? '280px' : '350px',
                  height: `${innerHeight / 2}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: side === Side.Back ? 'flex' : 'none',
                }}
              >
                <DesignPreview
                  onPositionChoose={onPositionChoose}
                  fonts={fonts}
                  activeMarking={
                    active === Variant.Top ? activeMarking : undefined
                  }
                  svgId={`top-preview-${Side.Back}`}
                  settings={topSettings}
                  marking={topDetail.attributes.item_markings}
                  side={Side.Back}
                  data={_.get(topDetail, 'attributes.item_parts', [])}
                />
              </div>
            ) : null}
          </Box>
        </Box>
      ) : null}
      {!isMobile ? <Divider sx={{ borderBottom: '1px dotted #ccc' }} /> : null}
      {bottomDetail ? (
        <Box
          className={`bottom-area ${
            active === Variant.Bottom ? 'selected' : ''
          }`}
          sx={{
            flex: 1,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
            },
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            '&.selected': {
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              },
            },
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              left: 10,
              top: 10,
              right: isMobile ? 10 : 'auto',
              display: 'flex',
            }}
            direction={'row'}
            spacing={1}
          >
            <IconButton
              onClick={() => onClickChangeItem(bottomDetail.id, Variant.Bottom)}
              sx={{ border: '0 none', display: 'none' }}
              //temp hide
            >
              <AutoAwesomeMotionIcon />
            </IconButton>
            {topDetail ? (
              <IconButton
                onClick={() => onRemove(Variant.Bottom)}
                sx={{ border: '0 none' }}
              >
                <RemoveCircle />
              </IconButton>
            ) : null}
          </Stack>

          <Box
            sx={{
              textAlign: isMobile ? 'center' : 'left',
              position: 'absolute',
              left: 10,
              bottom: 10,
            }}
          >
            {bottomDetail ? (
              <Box onClick={() => onClickDetail(bottomDetail.id)}>
                <Typography
                  variant="h5"
                  sx={{
                    display: '-webkit-box',
                    margin: '0 auto',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textDecoration: 'underline',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    height: 20,
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      transition: 'all 0.3s ease',
                      color: ThemeColors.secondary,
                    },
                  }}
                  component="div"
                >
                  {_.get(bottomDetail, 'attributes.name', '')}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Chip
            className={active === Variant.Bottom ? 'is-active' : ''}
            color="default"
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              background: '#222',
              color: '#fff',
              fontSize: 16,
              border: '1px solid #ddd',
              p: 1,
              pt: 2,
              pb: 2,
              '&.is-active': {
                color: '#fff',
                background: ThemeColors.secondary,
              },
            }}
            size="small"
            label={i18n.t('SYSCOMMON.bottoms')}
          />
          <Box>
            {bottomDetail && bottomSettings ? (
              <div
                id={`bottom-preview-${Side.Front}`}
                style={{
                  width: '350px',
                  height: `${innerHeight / 2}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: side === Side.Front ? 'flex' : 'none',
                }}
              >
                <DesignPreview
                  onPositionChoose={onPositionChoose}
                  fonts={fonts}
                  settings={bottomSettings}
                  activeMarking={
                    active === Variant.Bottom ? activeMarking : undefined
                  }
                  svgId={`bottom-preview-${Side.Front}`}
                  marking={bottomDetail.attributes.item_markings}
                  side={Side.Front}
                  data={_.get(bottomDetail, 'attributes.item_parts', [])}
                />
              </div>
            ) : null}

            {bottomDetail && bottomSettings ? (
              <div
                id={`bottom-preview-${Side.Back}`}
                style={{
                  width: '350px',
                  height: `${innerHeight / 2}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: side === Side.Back ? 'flex' : 'none',
                }}
              >
                <DesignPreview
                  onPositionChoose={onPositionChoose}
                  fonts={fonts}
                  svgId={`bottom-preview-${Side.Back}`}
                  settings={bottomSettings}
                  activeMarking={
                    active === Variant.Bottom ? activeMarking : undefined
                  }
                  marking={bottomDetail.attributes.item_markings}
                  side={Side.Back}
                  data={_.get(bottomDetail, 'attributes.item_parts', [])}
                />
              </div>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default PreviewLayout
