import { MetaPage } from '@constants/common.constants'
import { Meta } from '@store/metadata/actions/types'
import {
  ShopItem,
  ShopCreateParams,
  ShopUpdateParams,
  ShopsParams,
  ShopStatusParams,
  ShopDeleteParams,
} from '@services/shops.services'
import {
  shopList,
  shopCreate,
  shopUpdate,
  shopDelete,
  shopStatus,
} from '@store/shops/actions'
import { list, pagemeta } from '@store/shops/selectors'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { createMetaSelector } from '@store/metadata/selectors'
interface ReturnType {
  data?: ShopItem[]
  paging: MetaPage
  meta: Meta
  metaCreate: Meta
  fetch: (params: ShopsParams) => void
  create: (params: ShopCreateParams) => void
  update: (params: ShopUpdateParams) => void
  changeStatus: (params: ShopStatusParams) => void
  deleteShop: (params: ShopDeleteParams) => void
}

const useShops = (): ReturnType => {
  const data = useAppSelector(list)
  const paging = useAppSelector(pagemeta)
  const dispatch = useDispatch()
  const fetch = (params: ShopsParams) => {
    dispatch(shopList(params))
  }
  const create = (params: ShopCreateParams) => {
    dispatch(shopCreate(params))
  }
  const update = (params: ShopUpdateParams) => {
    dispatch(shopUpdate(params))
  }

  const changeStatus = (params: ShopStatusParams) => {
    dispatch(shopStatus(params))
  }

  const deleteShop = (params: ShopDeleteParams) => {
    dispatch(shopDelete(params))
  }

  const _meta = createMetaSelector(shopList)
  const meta = useAppSelector(_meta)
  const _metaCreate = createMetaSelector(shopCreate)
  const metaCreate = useAppSelector(_metaCreate)

  return {
    fetch,
    paging,
    meta,
    data,
    create,
    update,
    deleteShop,
    metaCreate,
    changeStatus,
  }
}

export default useShops
